import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  kebabCase,
  upperCase,
  groupBy,
  orderBy,
  isEmpty,
  get,
  isNull,
} from 'lodash'
import { format, parseISO, addHours, isAfter, differenceInDays } from 'date-fns'
import Swal from 'sweetalert2'
import { Controller, useForm } from 'react-hook-form'
import ApexLineChart from 'components/ApexChart/LineChart'

import {
  Box,
  Button,
  chakra,
  DrawerCloseButton,
  DrawerHeader,
  Flex,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Interfaces
import {
  UserDataApiResponse,
  NumberOfQuestionsPerDayApiResponse,
  CorrectPercentagePerDayApiResponse,
  StudiedMinutesPerDayApiResponse,
  TrackDataApiResponse,
  Theme,
  Subject,
  ReportAvailabilityGroup,
  ReportAvailability,
  PerformanceApiResponse,
  PerformanceTabForm,
} from './interfaces'

// Styles
import {
  Container,
  Content,
  ChipsContainer,
  Chip,
  ChipText,
  ChartContainer,
  GraphContainer,
  UserDataRow,
  UserDataLabel,
  UserDataValue,
} from './styles'

import Layout from 'layouts/Logged'
import { TabCalendar } from './components/tabs/TabCalendar'
import { formatTimer } from 'helpers/formatTimer'
import { formatPercentage } from 'helpers/formatPercentage'
import { GetInTouch } from 'components/GetInTouch'
import { TabActions } from 'features/students'
import { useAuth } from 'contexts/auth'
import { formatDateUTC } from 'helpers/formatDate'
import { useStudentRecordByAdvisor } from 'components/UserTable/queries/use-fetch-student-record'
import { UserTable } from 'components/UserTable'
import Pagination from 'components/Pagination'
import { NoDataTableError } from 'components/MonitorTable/components/NoDataTable'
import { usePatchStudentData } from './queries/use-patch-student'
import { usePostEnableOnboarding } from './queries/use-post-enable-onboarding'
import AccordionListTable from './components/project-x-reset/AccordionListTable'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { AxiosError } from 'axios'

type Monitor = {
  id: number
  name: string
  email: string
  mobilePhone?: string
  studentsCount: string
}

type MonitorFormInputs = {
  monitorUserId: string
}

type NameFormInputs = {
  name: string
}

type EditTelStudent = {
  tel: string
}

type EditEmailStudent = {
  email: string
}

const UserDetail: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [user, setUser] = useState<UserDataApiResponse>()

  const [monitors, setMonitors] = useState<Monitor[]>([])

  const [isFetchingData, setIsFetchingData] = useState(false)

  const [isResendingWelcomeEmail, setIsResendingWelcomeEmail] = useState(false)

  const [isGeneratingReport, setIsGeneratingReport] = useState(false)

  const [track, setTrack] = useState<TrackDataApiResponse>()

  const [selectedFieldId, setSelectedFieldId] = useState<number>()

  const [performance, setPerformance] = useState<PerformanceApiResponse>()

  const [availableThemes, setAvailableThemes] = useState<Theme[]>([])

  const [availableSubjects, setAvailableSubjects] = useState<Subject[]>([])
  const [studiedHours, setStudiedHours] = useState<ApexAxisChartSeries>()
  const [
    correctPercentage,
    setCorrectPercentage,
  ] = useState<ApexAxisChartSeries>()
  const [
    numberOfQuestions,
    setNumberOfQuestions,
  ] = useState<ApexAxisChartSeries>()

  const [availableGroups, setAvailableGroups] = useState<
    ReportAvailabilityGroup[]
  >([])

  const [range, setRange] = useState('week')

  const [page, setPage] = useState(1)
  const [orderByDate, setOrderByDate] = useState<'+date' | '-date'>('-date')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const { userId } = useParams<{ userId?: string }>()
  const toast = useToast({ position: 'top-start' })
  const { user: userLoginInfo, userRolesSlugs } = useAuth()
  const {
    isOpen: isMonitorModalOpen,
    onOpen: onMonitorModalOpen,
    onClose: onMonitorModalClose,
  } = useDisclosure()

  const {
    isOpen: isTelEditOpen,
    onOpen: onTelEditModalOpen,
    onClose: onTelEditModalClose,
  } = useDisclosure()

  const {
    isOpen: isNameEditOpen,
    onOpen: onNameEditModalOpen,
    onClose: onNameEditModalClose,
  } = useDisclosure()

  const {
    isOpen: isEmailEditOpen,
    onOpen: onEmailEditModalOpen,
    onClose: onEmailEditModalClose,
  } = useDisclosure()

  const {
    register: monitorFormRegister,
    handleSubmit: monitorFormHandleSubmit,
  } = useForm<MonitorFormInputs>()

  const {
    control: NameFormControl,
    handleSubmit: NameFormHandleSubmit,
  } = useForm<NameFormInputs>({
    defaultValues: {
      name: user?.name,
    },
  })

  const {
    control: EmailFormControl,
    handleSubmit: EmailFormHandleSubmit,
  } = useForm<EditEmailStudent>({
    defaultValues: {
      email: user?.email,
    },
  })

  const {
    control: PhoneFormControl,
    handleSubmit: PhoneFormHandleSubmit,
  } = useForm<EditTelStudent>({
    defaultValues: {
      tel: user?.mobile_phone,
    },
  })

  const {
    control: performanceTabControl,
    reset: performanceTabReset,
    watch: performanceTabWatch,
  } = useForm<PerformanceTabForm>()

  const patchStudent = usePatchStudentData()

  const enableOnboarding = usePostEnableOnboarding()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchDailyRecordsGraphData = useCallback(
    async (
      userId: number,
      range: string,
      fieldId?: number,
      themeId?: number,
      subjectId?: number,
    ) => {
      setIsFetchingData(true)

      const baseUrl = '/admin/daily_records/'
      const params = { fieldId, userId, range, themeId, subjectId }

      try {
        const [questions, percentage, minutes] = await Promise.all([
          api.get<NumberOfQuestionsPerDayApiResponse>(
            baseUrl + 'number_of_questions_per_day',
            { params },
          ),
          api.get<CorrectPercentagePerDayApiResponse>(
            baseUrl + 'correct_percentage_per_day',
            { params },
          ),
          api.get<StudiedMinutesPerDayApiResponse>(
            baseUrl + 'studied_minutes_per_day',
            { params },
          ),
        ])

        setStudiedHours([
          {
            name: 'Horas de estudo',
            data: minutes.data.map(apiData => ({
              x: format(addHours(parseISO(apiData.date), 12), 'dd/MM'),
              y: apiData.studied_minutes,
              tooltipTitle: 'Dia',
            })),
          },
        ])

        setCorrectPercentage([
          {
            name: '% de acertos',
            data: percentage.data.map(apiData => ({
              x: format(addHours(parseISO(apiData.date), 12), 'dd/MM'),
              y: apiData.correct_percentage,
              tooltipTitle: 'Dia',
            })),
          },
        ])

        setNumberOfQuestions([
          {
            name: 'Número de questões',
            data: questions.data.map(apiData => ({
              x: format(addHours(parseISO(apiData.date), 12), 'dd/MM'),
              y: apiData.number_of_questions,
              tooltipTitle: 'Dia',
            })),
          },
        ])
      } catch (err) {
        Swal.fire({
          title: 'Aviso',
          text:
            'Não foi possível carregar o painel geral nesse momento. Mais dados do erro no console.',
          buttonsStyling: false,
          icon: 'error',
        })
        console.trace('Error on fetching graph data: ', err)
      } finally {
        setIsFetchingData(false)
      }
    },
    [],
  )

  const resendWelcomeEmail = useCallback(() => {
    if (!user) return
    setIsResendingWelcomeEmail(true)
    api
      .post(`/admin/users/${user.id}/resend_welcome_email`)
      .then(() => {
        Swal.fire({
          buttonsStyling: false,
          icon: 'success',
          title: 'Sucesso',
          text: 'E-mail reenviado com sucesso!',
        })
      })
      .catch(() => {
        Swal.fire({
          buttonsStyling: false,
          icon: 'error',
          title: 'Aviso',
          text: 'Falha ao enviar o e-mail.',
        })
      })
      .finally(() => setIsResendingWelcomeEmail(false))
  }, [user])

  const generateStudentReport = useCallback(
    (trackGoal: ReportAvailability) => {
      if (!user) return

      const { name, id } = user
      const { id: trackGoalId, year, month } = trackGoal

      setIsGeneratingReport(true)

      api
        .post(
          '/app/reports/generate_pdf',
          { name, id, trackGoalId },
          { responseType: 'blob' },
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${kebabCase(name)}-${year}-${month}.pdf`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            text: 'Não foi possível gerar o relatório nesse momento',
            icon: 'error',
          })
          console.trace(err)
        })
        .finally(() => setIsGeneratingReport(false))
    },
    [user],
  )

  const removeUser = useCallback(
    (userId: number) => {
      if (!userId) return
      api
        .delete(`/admin/users/${userId}`)
        .then(() => {
          Swal.fire({
            buttonsStyling: false,
            title: 'Usuário removido com sucesso',
            icon: 'success',
            confirmButtonText: 'Voltar',
          }).then(() => {
            history.goBack()
          })
        })
        .catch(() => {
          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            text: 'Não foi possível remover o usuário.',
          })
        })
    },
    [history],
  )

  const toggleUserEngagementFlag = useCallback((userId: number) => {
    if (!userId) return
    api
      .put(`/admin/users/${userId}/toggle_engagement_tracking_flag`)
      .then(() => {
        setUser(
          user =>
            user && {
              ...user,
              is_engagement_tracking_enabled: !user.is_engagement_tracking_enabled,
            },
        )
      })
      .catch(() => {
        Swal.fire({
          buttonsStyling: false,
          title: 'Aviso',
          text: 'Não foi possível concluir a operação.',
        })
      })
  }, [])

  const removeUserHandler = useCallback(
    (userId: number) => {
      if (!userId) return
      Swal.fire({
        title: 'Aviso',
        icon: 'warning',
        text: 'Essa ação é irreversível. Deseja prosseguir?',
        denyButtonText: 'Remover',
        showDenyButton: true,
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        showConfirmButton: false,
      }).then(({ isDenied }) => {
        if (!isDenied) return
        removeUser(userId)
      })
    },
    [removeUser],
  )

  const getUserById = useCallback(
    (userId: number) => {
      if (!userId) return
      setIsFetchingData(true)
      api
        .get(`/admin/users/${userId}`)
        .then(response => {
          setUser(response.data)
        })
        .catch(() => {
          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            text: 'Não foi possível carregar os dados do usuário.',
          })
          history.replace('/users')
        })
        .finally(() => setIsFetchingData(false))
    },
    [history],
  )

  const attachStudentToMonitor = useCallback(
    async (data: { studentUserId: number; monitorUserId: number }) => {
      try {
        await api.post('/admin/users/monitors/student', data)
        toast({
          duration: 3000,
          title: 'Aluno vinculado com sucesso!',
          status: 'success',
          isClosable: true,
        })
      } catch (error) {
        const message =
          error?.response?.data?.errors
            ?.map((error: any) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Monitor não pôde ser alterado'
        toast({
          duration: 3000,
          title: message,
          status: 'error',
          isClosable: true,
        })
      }
    },
    [toast],
  )

  const handleAttachStudentToMonitor = monitorFormHandleSubmit(
    async ({ monitorUserId }) => {
      if (!user) return

      try {
        await attachStudentToMonitor({
          monitorUserId: Number(monitorUserId),
          studentUserId: user.id,
        })

        await getUserById(user.id)

        onMonitorModalClose()
      } catch (error: any) {
        const message =
          error?.response?.data?.errors
            ?.map((error: any) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Monitor não pôde ser alterado'

        toast({
          duration: 3000,
          title: message,
          status: 'error',
          isClosable: true,
        })
      }
    },
  )

  const handleChangePhoneNumber = async (data: EditTelStudent) => {
    if (!user) return

    try {
      await patchStudent.mutateAsync({
        user_id: user.id,
        mobilePhone: data.tel,
      })

      await getUserById(user.id)

      toast({
        duration: 3000,
        title: 'Telefone modificado.',
        status: 'success',
        isClosable: true,
      })

      onTelEditModalClose()
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível realizar a edição'

      toast({
        duration: 3000,
        title: message,
        status: 'error',
        isClosable: true,
      })
    }
  }

  const handleChangeName = async (data: NameFormInputs) => {
    if (!user) return

    try {
      await patchStudent.mutateAsync({
        user_id: user.id,
        name: data.name,
      })

      await getUserById(user.id)

      toast({
        duration: 3000,
        title: 'Nome modificado.',
        status: 'success',
        isClosable: true,
      })

      onNameEditModalClose()
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível realizar a edição'

      toast({
        duration: 3000,
        title: message,
        status: 'error',
        isClosable: true,
      })
    }
  }

  const handleChangeEmail = async (data: EditEmailStudent) => {
    if (!user) return

    try {
      await patchStudent.mutateAsync({
        user_id: user.id,
        email: data.email,
      })

      await getUserById(user.id)

      toast({
        duration: 3000,
        title: 'Email modificado.',
        status: 'success',
        isClosable: true,
      })

      onEmailEditModalClose()
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível realizar a edição'

      toast({
        duration: 3000,
        title: message,
        status: 'error',
        isClosable: true,
      })
    }
  }

  const handleEnableOnboarding = async () => {
    if (!user) return

    try {
      await enableOnboarding.mutateAsync({
        user_id: user.id,
      })

      await getUserById(user.id)

      Swal.fire({
        title: 'Sucesso',
        buttonsStyling: false,
        html: '<p style="text-align:center">Onboarding habilitado.</p>',
      })
    } catch (error) {
      let errorMessage =
        'Infelizmente houve um erro ao resetar o onboarding. Verifique se o usuário é um aluno. Se, mesmo assim, o erro persistir, entre em contato com o suporte.'

      const apiErrorString = get(error, 'response.data')
      if (typeof apiErrorString === 'string') {
        errorMessage = apiErrorString
      }

      Swal.fire({
        title: 'Aviso',
        buttonsStyling: false,
        html: `<p style="text-align:center">${errorMessage}</p>`,
      })
    }
  }

  const {
    data: dataAttendance,
    isFetching,
    isError,
  } = useStudentRecordByAdvisor({
    studentId: user?.id ?? 0,
    page: page,
    limit: 6,
    sort: orderByDate,
  })

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    if (!userId) return
    getUserById(Number(userId))
  }, [getUserById, userId])

  useEffect(() => {
    if (!user) return
    fetchDailyRecordsGraphData(
      user.id,
      range,
      selectedFieldId,
      performanceTabWatch('themeId.value'),
      performanceTabWatch('subjectId.value'),
    )
  }, [
    fetchDailyRecordsGraphData,
    range,
    selectedFieldId,
    performanceTabWatch,
    user,
  ])

  useEffect(() => {
    if (!user || !user.contracts.length) return

    api
      .get<TrackDataApiResponse>(`/admin/tracks/${user.contracts[0].track_id}`)
      .then(({ data }) => {
        setTrack(data)
      })
      .catch(() => {
        Swal.fire({
          buttonsStyling: false,
          title: 'Aviso',
          text: 'Falha ao carregar dados da track do usuário',
          icon: 'error',
        })
        history.replace('/users')
      })
  }, [history, user])

  useEffect(() => {
    if (!selectedFieldId) {
      performanceTabReset({
        themeId: null,
        subjectId: null,
      })
      setAvailableThemes([])
      setAvailableSubjects([])
      return
    }

    if (!track) return

    const selectedField = track.fields.find(field => {
      return field.id === selectedFieldId
    })

    if (!selectedField) return

    performanceTabReset({
      themeId: null,
      subjectId: null,
    })

    setAvailableThemes(selectedField.themes)
    setAvailableSubjects(selectedField.subjects)
  }, [selectedFieldId, track, performanceTabReset])

  useEffect(() => {
    if (!user) return
    const { id } = user
    api
      .get<ReportAvailability[]>('/app/reports/availability', {
        params: { studentUserId: id },
      })
      .then(({ data }) => {
        const groupedData = groupBy(data, 'year')

        const orderedData = orderBy(
          Object.entries(groupedData).map(([key, value]) => ({
            year: key,
            availableReports: value,
          })),
          'year',
          'desc',
        )

        setAvailableGroups(orderedData)
      })
      .catch(err => {
        // Swal.fire({
        //   title: 'Aviso',
        //   text: 'Não foi possível buscar os relatórios nesse momento',
        //   icon: 'error',
        //   buttonsStyling: false,
        // })
        console.trace('Error on fetching report availabilities', err)
      })
  }, [user])

  useEffect(() => {
    if (!user) return
    api
      .get(`/admin/users/${user.id}/performance`)
      .then(({ data }) => {
        setPerformance(data)
      })
      .catch(err => {
        console.trace('Error on fetching performance data', err)
      })
  }, [user])

  useEffect(() => {
    if (!user?.contracts?.[0]?.track_id) return

    api
      .get<Monitor[]>(
        `/admin/users/monitors/all/?activityStatus=ACTIVE${
          user?.contracts?.[0]?.track_id
            ? `&trackId=${user?.contracts?.[0]?.track_id}`
            : ''
        }`,
      )
      .then(({ data }) => {
        setMonitors(data)
      })
  }, [user?.contracts])

  // tag after  name

  const isContractCanceled = (
    finalDate: string,
    canceledAt: string | null,
  ): boolean => {
    if (!isNull(canceledAt)) return true

    if (isAfter(new Date(), parseISO(finalDate)) && isNull(canceledAt)) {
      return false
    }

    return true
  }

  const DifferenceBetweenDays = useCallback((date: string) => {
    const today = new Date()

    const diferenceBetweenDates = differenceInDays(today, new Date(date))

    return diferenceBetweenDates
  }, [])

  const isContractActivated = useMemo(() => {
    if (user?.contracts && user?.contracts.length > 0) {
      const userActivated = user.contracts.filter(user =>
        isContractCanceled(user.final_date, user.canceled_at),
      )
      return userActivated[0]
    }
    return null
  }, [user?.contracts])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header title="Perfil" subTitle="Principais informações do aluno(a)" />
        <Content>
          <Tabs variant="soft-rounded" colorScheme="brand" mt={8}>
            <TabList>
              <Tab>Perfil</Tab>
              <Tab ml={2}>Contratos</Tab>
              <Tab ml={2}>Ações</Tab>
              <Tab ml={2}>Performance</Tab>
              <Tab ml={2}>Revisões inteligentes</Tab>
            </TabList>
            <TabPanels>
              <TabPanel id="profile-tab">
                <>
                  <SectionTitle>Usuário</SectionTitle>

                  <UserDataRow>
                    <UserDataLabel>Nome: </UserDataLabel>
                    <UserDataValue>
                      {user?.name}
                      {isContractActivated &&
                        DifferenceBetweenDays(
                          isContractActivated.initial_date,
                        ) <= 15 && (
                          <chakra.span
                            color={'#0DDF15'}
                            fontFamily="Mulish"
                            fontWeight="700"
                            fontSize="12px"
                            lineHeight="18px"
                            ml="10px"
                          >
                            {'Novo'}
                          </chakra.span>
                        )}

                      {isContractActivated &&
                        DifferenceBetweenDays(
                          isContractActivated.initial_date,
                        ) > 15 &&
                        DifferenceBetweenDays(
                          isContractActivated.initial_date,
                        ) <= 30 && (
                          <chakra.span
                            color={'#E5C009'}
                            fontFamily="Mulish"
                            fontWeight="700"
                            fontSize="12px"
                            lineHeight="18px"
                            ml="10px"
                          >
                            {'Junior'}
                          </chakra.span>
                        )}

                      {isContractActivated &&
                        DifferenceBetweenDays(
                          isContractActivated.initial_date,
                        ) > 30 && (
                          <chakra.span
                            color={'#E56000'}
                            fontFamily="Mulish"
                            fontWeight="700"
                            fontSize="12px"
                            lineHeight="18px"
                            ml="10px"
                          >
                            {'Veterano'}
                          </chakra.span>
                        )}

                      {userRolesSlugs.includes('admin') && (
                        <Button
                          ml={4}
                          variant="link"
                          colorScheme="brand"
                          bgColor="transparent"
                          onClick={onNameEditModalOpen}
                        >
                          Alterar Nome
                        </Button>
                      )}
                    </UserDataValue>
                  </UserDataRow>
                  {user?.created_at && (
                    <UserDataRow>
                      <UserDataLabel>Conta criada em: </UserDataLabel>
                      <UserDataValue>
                        {format(new Date(user.created_at), 'dd/MM/yyyy HH:mm')}
                      </UserDataValue>
                    </UserDataRow>
                  )}
                  <UserDataRow>
                    <UserDataLabel>Email: </UserDataLabel>
                    <UserDataValue>{user?.email}</UserDataValue>
                    {userRolesSlugs.includes('admin') && (
                      <Button
                        ml={4}
                        variant="link"
                        colorScheme="brand"
                        bgColor="transparent"
                        onClick={onEmailEditModalOpen}
                      >
                        Alterar E-mail
                      </Button>
                    )}
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>Telefone: </UserDataLabel>
                    <UserDataValue>
                      {user?.mobile_phone ? user?.mobile_phone : 'NENHUM'}
                    </UserDataValue>
                    {userRolesSlugs.includes('admin') && (
                      <Button
                        ml={4}
                        variant="link"
                        colorScheme="brand"
                        bgColor="transparent"
                        onClick={onTelEditModalOpen}
                      >
                        Alterar telefone
                      </Button>
                    )}
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>CPF: </UserDataLabel>
                    <UserDataValue>
                      {user?.cpf ? user?.cpf : 'NENHUM'}
                    </UserDataValue>
                  </UserDataRow>

                  {user?.purchased_plataform_product?.productDescription && (
                    <UserDataRow>
                      <UserDataLabel>descrição produto:</UserDataLabel>
                      <UserDataValue>
                        {user?.purchased_plataform_product?.productDescription}
                      </UserDataValue>
                    </UserDataRow>
                  )}

                  {!!user?.contracts && user.contracts.length > 0 && (
                    <UserDataRow>
                      <UserDataLabel>Monitor: </UserDataLabel>
                      <UserDataValue>
                        {user?.studentMonitor[0]?.name || 'NENHUM'}
                        {userLoginInfo &&
                          userLoginInfo?.roles?.find(
                            role => role.slug == 'admin',
                          ) && (
                            <Button
                              ml={4}
                              variant="link"
                              colorScheme="brand"
                              bgColor="transparent"
                              onClick={onMonitorModalOpen}
                            >
                              Alterar monitor
                            </Button>
                          )}
                      </UserDataValue>
                    </UserDataRow>
                  )}
                  {performance && (
                    <UserDataRow>
                      <UserDataLabel>Performance geral: </UserDataLabel>
                      <UserDataValue>
                        {performance?.generalPerformance}% em{' '}
                        {performance?.lastMeasurableMonth.month}/
                        {performance?.lastMeasurableMonth.year}
                      </UserDataValue>
                    </UserDataRow>
                  )}
                  <UserDataRow>
                    <UserDataLabel>Conta validada: </UserDataLabel>
                    <UserDataValue>
                      {user?.is_email_validated ? 'Sim' : 'Não'}
                    </UserDataValue>
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>ano de prova: </UserDataLabel>
                    <UserDataValue>{user?.profile?.trial_year}</UserDataValue>
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>cursinho: </UserDataLabel>
                    <UserDataValue>{user?.profile?.prep_school}</UserDataValue>
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>instituição pretendida: </UserDataLabel>
                    <UserDataValue>
                      {user?.profile?.intended_institutions.join(', ')}
                    </UserDataValue>
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>Faculdade: </UserDataLabel>
                    <UserDataValue>
                      {user?.profile?.attended_college}
                    </UserDataValue>
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>Comprou outros produtos: </UserDataLabel>
                    <UserDataValue>
                      {user?.profile?.is_already_our_student
                        ? user?.profile?.is_already_our_student.join(', ')
                        : ''}
                    </UserDataValue>
                  </UserDataRow>
                  <UserDataRow>
                    <UserDataLabel>Link de ingresso: </UserDataLabel>
                    <UserDataValue>
                      {!user?.is_email_validated
                        ? `https://aluno.mentoriaresidencia.com.br/validate_account?token=${user?.validation_email_token}`
                        : 'Conta ativada.'}
                    </UserDataValue>
                  </UserDataRow>
                  {user?.lastContactAt && (
                    <UserDataRow>
                      <UserDataLabel>Data de contato: </UserDataLabel>
                      <UserDataValue>
                        {format(new Date(user.lastContactAt), 'dd/MM/yyyy')}
                      </UserDataValue>
                    </UserDataRow>
                  )}

                  {user && (
                    <UserDataRow>
                      <UserDataLabel>resetado: </UserDataLabel>
                      <UserDataValue>
                        {user?.revisionResetHistory[0]?.created_at
                          ? 'Sim'
                          : 'Não'}
                      </UserDataValue>
                    </UserDataRow>
                  )}

                  {user?.is_revision_reset_executed && (
                    <UserDataRow>
                      <UserDataLabel>Período do resete: </UserDataLabel>
                      <UserDataValue>
                        {user.revisionResetHistory[0].starts_at &&
                          user.revisionResetHistory[0].finishes_at &&
                          `${formatDateUTC(
                            user.revisionResetHistory[0].starts_at,
                          )} a ${formatDateUTC(
                            user.revisionResetHistory[0].finishes_at,
                          )}`}
                      </UserDataValue>
                    </UserDataRow>
                  )}

                  {user?.is_revision_reset_executed && (
                    <UserDataRow>
                      <UserDataLabel>Dia do resete: </UserDataLabel>
                      <UserDataValue>
                        {user.revisionResetHistory[0].created_at &&
                          `${formatDateUTC(
                            user.revisionResetHistory[0].created_at,
                          )}`}
                      </UserDataValue>
                    </UserDataRow>
                  )}

                  {user?.contract_accepted_at && (
                    <UserDataRow>
                      <UserDataLabel>Contrato aceito em: </UserDataLabel>
                      <UserDataValue>
                        {user.contract_accepted_at &&
                          `${formatDateUTC(user.contract_accepted_at)}`}
                      </UserDataValue>
                    </UserDataRow>
                  )}

                  {user && <AccordionListTable user={user} />}

                  <Box mt="32px" maxW="620px">
                    <Text color="#E296FD" fontSize="18px" fontWeight="700">
                      Atendimentos
                    </Text>

                    {!isFetching && !isEmpty(dataAttendance?.data) && (
                      <UserTable
                        onDateClickButton={() =>
                          setOrderByDate(prev =>
                            prev === '-date' ? '+date' : '-date',
                          )
                        }
                        dateOrder={orderByDate}
                        setDateOrder={setOrderByDate}
                        data={dataAttendance?.data ?? undefined}
                      />
                    )}

                    {!isFetching && isEmpty(dataAttendance?.data) && (
                      <Box mt="1rem">
                        <NoDataTableError />
                      </Box>
                    )}

                    {!isFetching && !isEmpty(dataAttendance?.data) && (
                      <Pagination
                        totalCountOfRegisters={dataAttendance?.meta.total ?? 0}
                        currentPage={page}
                        onPageChange={setPage}
                        registersPerPage={6}
                      />
                    )}
                  </Box>
                </>
              </TabPanel>
              <TabPanel id="contracts-tab">
                <TabActions user={user} />
              </TabPanel>
              <TabPanel id="actions-tab">
                <SectionTitle>Ações</SectionTitle>
                <HStack spacing={4}>
                  {!user?.is_email_validated && (
                    <Button
                      isLoading={isResendingWelcomeEmail}
                      onClick={resendWelcomeEmail}
                    >
                      Reenviar convite
                    </Button>
                  )}

                  {user?.mobile_phone && (
                    <GetInTouch
                      size="md"
                      studentId={user.id}
                      contacted={user.contacted}
                      phoneNumber={user.mobile_phone}
                    />
                  )}

                  {userRolesSlugs.includes('admin') && (
                    <Button
                      colorScheme="brand"
                      isLoading={isResendingWelcomeEmail}
                      onClick={() => user && removeUserHandler(user.id)}
                    >
                      Remover usuário
                    </Button>
                  )}

                  {userRolesSlugs.includes('admin') && (
                    <Button
                      colorScheme="brand"
                      isLoading={enableOnboarding.isLoading}
                      onClick={() => user && handleEnableOnboarding()}
                    >
                      Habilitar Onboarding
                    </Button>
                  )}
                  <Button
                    colorScheme="brand"
                    isLoading={isResendingWelcomeEmail}
                    onClick={() => user && toggleUserEngagementFlag(user.id)}
                  >
                    {`${
                      user?.is_engagement_tracking_enabled
                        ? 'Remover'
                        : 'Ativar'
                    } tracking`}
                  </Button>
                  <Button colorScheme="brand" onClick={history.goBack}>
                    Voltar
                  </Button>
                </HStack>
              </TabPanel>
              <TabPanel id="performance-tab">
                <>
                  <SectionTitle>Relatórios</SectionTitle>
                  {availableGroups.map(({ year, availableReports }) => (
                    <React.Fragment key={`group-key-${year}`}>
                      <SectionTitle>{upperCase(year)}</SectionTitle>
                      <ChipsContainer>
                        {availableReports.map(trackGoal => (
                          <Chip
                            isActive={false}
                            disabled={isGeneratingReport}
                            key={`month-key-${trackGoal.month}-${trackGoal.year}`}
                            onClick={() => generateStudentReport(trackGoal)}
                          >
                            <ChipText>
                              {isGeneratingReport
                                ? 'Processando'
                                : trackGoal.month}
                            </ChipText>
                          </Chip>
                        ))}
                      </ChipsContainer>
                    </React.Fragment>
                  ))}

                  <SectionTitle>Matérias</SectionTitle>
                  <ChipsContainer>
                    {track?.fields.map(field => (
                      <Chip
                        onClick={() => setSelectedFieldId(field.id)}
                        isActive={selectedFieldId === field.id}
                        key={`fields-id-${field.id}`}
                      >
                        <ChipText>{field.name}</ChipText>
                      </Chip>
                    ))}
                    <Chip
                      onClick={() => setSelectedFieldId(undefined)}
                      isActive={selectedFieldId === undefined}
                      key={'fields-id-undefined'}
                    >
                      <ChipText>GERAL</ChipText>
                    </Chip>
                  </ChipsContainer>
                  <Flex maxW="380px" flexDir="column" gap="1rem">
                    <FieldSelectController
                      placeholder="Selecione um tema (opcional)"
                      name="themeId"
                      control={performanceTabControl}
                      label="Temas"
                      options={availableThemes?.map(theme => ({
                        label: theme.name,
                        value: theme.id,
                      }))}
                    />

                    <FieldSelectController
                      placeholder="Selecione um subtema (opcional)"
                      name="subjectId"
                      control={performanceTabControl}
                      label="Subtemas"
                      options={availableSubjects?.map(theme => ({
                        label: theme.name,
                        value: theme.id,
                      }))}
                    />
                  </Flex>

                  <SectionTitle>Período</SectionTitle>
                  <ChipsContainer>
                    {[
                      { value: 'week', label: 'SEMANA' },
                      { value: 'month', label: 'MÊS' },
                      { value: 'year', label: 'ANO' },
                    ].map(rangeOption => (
                      <Chip
                        onClick={() => setRange(rangeOption.value)}
                        isActive={range === rangeOption.value}
                        key={`fields-id-${rangeOption.value}`}
                      >
                        <ChipText>{rangeOption.label}</ChipText>
                      </Chip>
                    ))}
                  </ChipsContainer>

                  {isFetchingData && <p>Carregando ...</p>}
                  {!isFetchingData && (
                    <>
                      <SectionTitle>
                        Horas estudadas (teórica e prática)
                      </SectionTitle>
                      <ChartContainer>
                        <GraphContainer>
                          <ApexLineChart
                            series={studiedHours}
                            dataLabels={{
                              formatter: formatTimer,
                            }}
                            yaxis={{
                              max: max => {
                                return (Math.round(max / 30) + 1) * 30
                              },
                              title: {
                                text: 'Horas de estudos',
                              },
                              labels: {
                                formatter: formatTimer,
                              },
                            }}
                            xaxis={{
                              title: {
                                text: 'Dias',
                              },
                            }}
                          />
                        </GraphContainer>
                      </ChartContainer>

                      <SectionTitle>Quantidade de questões</SectionTitle>
                      <ChartContainer>
                        <ApexLineChart
                          series={numberOfQuestions}
                          yaxis={{
                            title: {
                              text: 'Quantidade de questões',
                            },
                          }}
                          xaxis={{
                            title: {
                              text: 'Dias',
                            },
                          }}
                        />
                      </ChartContainer>

                      <SectionTitle>
                        Percentual de acerto médio (%)
                      </SectionTitle>
                      <ChartContainer>
                        <ApexLineChart
                          series={correctPercentage}
                          dataLabels={{
                            formatter: formatPercentage,
                          }}
                          yaxis={{
                            title: {
                              text: 'Parcentual médio (%)',
                            },
                            max: 100,
                            labels: {
                              formatter: formatPercentage,
                            },
                          }}
                          xaxis={{
                            title: {
                              text: 'Dias',
                            },
                          }}
                        />
                      </ChartContainer>
                    </>
                  )}
                </>
              </TabPanel>

              <TabPanel>
                <TabCalendar />
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Modal
            isOpen={isMonitorModalOpen}
            onClose={onMonitorModalClose}
            isCentered
            closeOnEsc
          >
            <ModalOverlay />
            <ModalContent>
              <Box as="form" onSubmit={handleAttachStudentToMonitor}>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  Alterar monitor
                </DrawerHeader>

                <ModalBody>
                  <Stack spacing="24px">
                    <Box>
                      <FormLabel htmlFor="trackName">Trilha</FormLabel>
                      <Select
                        id="monitorUserId"
                        {...monitorFormRegister('monitorUserId')}
                        focusBorderColor="purple"
                      >
                        {monitors.map(monitor => (
                          <option key={monitor.email} value={monitor.id}>
                            {monitor.name} ({monitor.studentsCount} alunos)
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="brand" mr={3} type="submit">
                    Salvar
                  </Button>
                  <Button onClick={onMonitorModalClose}>Cancelar</Button>
                </ModalFooter>
              </Box>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={isTelEditOpen}
            onClose={onTelEditModalClose}
            isCentered
            closeOnEsc
          >
            <ModalOverlay />
            <ModalContent>
              <Box
                as="form"
                onSubmit={PhoneFormHandleSubmit(handleChangePhoneNumber)}
              >
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  Alterar monitor
                </DrawerHeader>

                <ModalBody>
                  <Stack spacing="24px">
                    <Box>
                      <FormLabel htmlFor="trackTel">Telefone</FormLabel>
                      <Controller
                        name="tel"
                        control={PhoneFormControl}
                        defaultValue={user?.mobile_phone}
                        render={({ field }) => (
                          <Input
                            {...field}
                            id="trackTel"
                            focusBorderColor="purple"
                          />
                        )}
                      />
                    </Box>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="brand" mr={3} type="submit">
                    Salvar
                  </Button>
                  <Button onClick={onTelEditModalClose}>Cancelar</Button>
                </ModalFooter>
              </Box>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={isNameEditOpen}
            onClose={onNameEditModalClose}
            isCentered
            closeOnEsc
          >
            <ModalOverlay />
            <ModalContent>
              <Box as="form" onSubmit={NameFormHandleSubmit(handleChangeName)}>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  Alterar nome
                </DrawerHeader>

                <ModalBody>
                  <Stack spacing="24px">
                    <Box>
                      <FormLabel htmlFor="name">Nome</FormLabel>
                      <Controller
                        name="name"
                        control={NameFormControl}
                        defaultValue={user?.name}
                        render={({ field }) => <Input {...field} id="name" />}
                      />
                    </Box>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="brand" mr={3} type="submit">
                    Salvar
                  </Button>
                  <Button onClick={onNameEditModalClose}>Cancelar</Button>
                </ModalFooter>
              </Box>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={isEmailEditOpen}
            onClose={onEmailEditModalClose}
            isCentered
            closeOnEsc
          >
            <ModalOverlay />
            <ModalContent>
              <Box
                as="form"
                onSubmit={EmailFormHandleSubmit(handleChangeEmail)}
              >
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  Alterar Email
                </DrawerHeader>

                <ModalBody>
                  <Stack spacing="24px">
                    <Box>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Controller
                        name="email"
                        control={EmailFormControl}
                        defaultValue={user?.email}
                        render={({ field }) => <Input {...field} id="email" />}
                      />
                    </Box>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="brand" mr={3} type="submit">
                    Salvar
                  </Button>
                  <Button onClick={onEmailEditModalClose}>Cancelar</Button>
                </ModalFooter>
              </Box>
            </ModalContent>
          </Modal>
        </Content>
      </Container>
    </Layout>
  )
}

export default UserDetail
