import { useForm } from 'react-hook-form'
import React, { useCallback } from 'react'

import {
  Box,
  Input,
  Modal,
  Stack,
  Button,
  Select,
  useToast,
  FormLabel,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react'

import { Contract, ContractType } from 'contexts/auth/interfaces'
import Swal from 'sweetalert2'
import api from 'services/api'

interface Props {
  userId: number
  isOpen: boolean
  contract: Contract
  onClose: () => void
  getUserById: (userId: number) => Promise<void>
}

interface FormData {
  finalDate: string
  trackName: string
  type: keyof typeof ContractType
}

export function ModalEditContract({
  isOpen,
  userId,
  onClose,
  contract,
  getUserById,
}: Props) {
  const toast = useToast()

  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      type: contract.type,
      finalDate: contract.final_date,
      trackName: contract.track.name,
    },
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        await api.put(`/admin/users/${userId}/contracts/${contract.id}`, data)

        toast({
          duration: 3000,
          title: 'Contrato atualizado com sucesso!',
          status: 'success',
        })

        onClose()
        await getUserById(userId)
        reset()
      } catch (error) {
        console.trace(error)
        Swal.fire('Atenção', 'Não foi possível atualizar contrato.', 'error')
      }
    },
    [contract.id, getUserById, onClose, reset, toast, userId],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnEsc>
      <ModalOverlay />

      <ModalContent>
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Editar um contrato
          </DrawerHeader>

          <ModalBody>
            <Stack spacing="24px">
              <Box>
                <FormLabel htmlFor="finalDate">Data de término</FormLabel>

                <Input
                  type="date"
                  id="finalDate"
                  focusBorderColor="purple"
                  placeholder="Selecione uma data"
                  {...register('finalDate')}
                />
              </Box>

              <Box>
                <FormLabel htmlFor="trackName">Trilha</FormLabel>
                <Select
                  id="trackName"
                  focusBorderColor="purple"
                  {...register('trackName')}
                >
                  <option value="R1">R1</option>
                  <option value="R+ CGE">R+ CGE</option>
                  <option value="R+ CM">R+ CM</option>
                  <option value="R+ GO-MASTOLOGIA">R+ GO-MASTOLOGIA</option>
                  <option value="R+ PED">R+ PED</option>
                  <option value="R+ ENDOSCOPIA">R+ ENDOSCOPIA</option>
                </Select>
              </Box>

              <Box>
                <FormLabel htmlFor="type">Tipo de contrato</FormLabel>

                <Select id="type" {...register('type')}>
                  <option value={ContractType.TRIAL}>Trial</option>
                  <option value={ContractType.PURCHASE}>Purchase</option>
                </Select>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              type="submit"
              colorScheme="brand"
              isDisabled={!isDirty}
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  )
}
