import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable, { Filter } from 'material-table'
import Swal from 'sweetalert2'

// Providers
import { localization } from 'providers/material-table/localization'
import { options } from 'providers/material-table/options'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Contexts
import { useAuth } from 'contexts/auth'

// Services
import api from 'services/api'

// Types
import { User, UsersApiResponse } from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import { ClearButton, Container, TableContainer } from './styles'

const Users: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user } = useAuth()
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalTests, setTotalTests] = useState(0)
  const [filters, setFilters] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchUsers = useCallback(
    (page: number, limit: number, filters: string) => {
      setIsLoading(true)
      api
        .get<UsersApiResponse>(
          `/admin/users?page=${page}&limit=${limit}${filters}`,
        )
        .then(({ data }) => {
          setUsers(data.data)
          setTotalTests(data.meta.total)
        })
        .catch()
        .finally(() => setIsLoading(false))
    },
    [],
  )

  const removeUser = useCallback(
    (userId: number) => {
      if (user && userId === user.id) {
        Swal.fire({
          buttonsStyling: false,
          title: 'Aviso',
          text:
            'Você não pode deletar a sua própria conta. Entre em contato com o administrador do sistema.',
        })

        return
      }
      api.delete(`/admin/users/${userId}`).then(() => {
        setUsers(currents => currents.filter(current => current.id !== userId))
      })
    },
    [user],
  )

  const newFiltersHandler = useCallback((newFilters: Filter<User>[]) => {
    setFilters(
      newFilters
        .map(
          ({ column: { field }, operator, value }) =>
            `&${field}${operator}${value}`,
        )
        .join(''),
    )
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    fetchUsers(currentPage, perPage, filters)
  }, [currentPage, fetchUsers, filters, perPage])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Usuários"
          subTitle="Lista completa de usuários da plataforma"
        />
        <SectionTitle>Base de alunos</SectionTitle>
        <TableContainer>
          <MaterialTable
            title="Lista de usuários"
            localization={localization}
            /* editable={{
              onRowDelete: async d => removeUser(d.id),
            }} */
            columns={[
              {
                title: 'Nome',
                field: 'name',
                resizable: true,
              },
              {
                title: 'Email',
                field: 'email',
                type: 'string',
                resizable: true,
              },
              {
                title: 'Telefone',
                field: 'mobile_phone',
                type: 'string',
              },
              {
                title: 'Conta validada?',
                field: 'is_email_validated',
                type: 'boolean',
              },
              {
                title: 'Último login',
                field: 'last_login_at',
                type: 'datetime',
              },
            ]}
            page={currentPage - 1}
            totalCount={totalTests}
            onChangePage={(newPage, perPage) => {
              setCurrentPage(newPage + 1)
              setPerPage(perPage)
            }}
            onChangeRowsPerPage={newPerPage => setPerPage(newPerPage)}
            onRowClick={(_, rowData) => {
              history.push(`/users/${rowData?.id}`)
            }}
            onFilterChange={newFiltersHandler}
            data={users}
            isLoading={isLoading}
            options={options}
          />
          {filters.length > 0 && (
            <ClearButton onClick={() => setFilters('')}>
              limpar filtros
            </ClearButton>
          )}
        </TableContainer>
      </Container>
    </Layout>
  )
}

export default Users
