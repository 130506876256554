import styled from 'styled-components'
import { ResponsiveContainer } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;

  ${ResponsiveContainer}
  overflow-y: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 20px;
  padding-top: 16px;

  button {
    margin-top: 16px;
  }
`

export const AvatarImage = styled.img`
  display: flex;
  border-radius: 24px;

  object-fit: cover;
  object-position: center;
`
