import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import {
  NewWhatsappGroupFormInput,
  NewWhatsappGroupFormOutput,
} from '../../interfaces/WhatsappGroupsInterfaces'

import { Icon } from '@iconify/react'
import { AxiosError } from 'axios'
import { useCreateWhatsappGroup } from 'features/whatsapp-groups/mutations/use-mutate-create-whatsapp-group'

interface ModalNewWhatsapGroupProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
}

export default function ModalNewWhatsappGroup(
  props: ModalNewWhatsapGroupProps,
) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, ...rest } = props

  const RESET_FORM: NewWhatsappGroupFormInput = useMemo(() => {
    return {
      trackId: null,
      name: '',
      mentoriaGroupLink: '',
      membersLimit: 0,
      groupType: null,
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const createWhatsappGroupMutation = useCreateWhatsappGroup()

  const showToast = useToastMessage()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<NewWhatsappGroupFormInput>({
    resolver: yupResolver(schema),
    defaultValues: RESET_FORM,
    shouldUnregister: true,
  })

  const { data: trackAll } = useGetAllTracks()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: NewWhatsappGroupFormOutput) => {
    try {
      await createWhatsappGroupMutation.mutateAsync(data)
      showToast({
        title: 'Sucesso',
        description: 'Grupo criado com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset(RESET_FORM)

      onClose()
    } catch (error) {
      reset(RESET_FORM)

      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível criar o grupo no momento.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | useEffect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (props.isOpen) {
      reset(RESET_FORM)
    }
  }, [RESET_FORM, props.isOpen, reset])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        w="full"
        maxW="520px"
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Cadastro de grupo
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="1rem">
            <FieldSelectController
              placeholder="Selecione a trilha de estudo"
              name="trackId"
              control={control}
              label="Trilha de Estudo"
              options={trackAll?.data?.map(item => {
                return {
                  label: item.name,
                  value: item.id,
                }
              })}
              error={errors.trackId as any}
            />

            <FieldInputController
              placeholder="Insira o nome do grupo"
              name="name"
              control={control}
              label="Nome"
              error={errors.name}
            />

            <FieldSelectController
              placeholder="Insira o tipo do grupo"
              name="groupType"
              control={control}
              options={[
                {
                  value: 'NEWBIE',
                  label: 'Novato',
                },
                {
                  value: 'VETERAN',
                  label: 'Veterano',
                },
              ]}
              label="Tipo do grupo"
              error={errors.groupType as any}
            />

            <Box w="full">
              <Box position={'relative'}>
                <FieldInputController
                  label={'Link do Whatsapp'}
                  control={control}
                  name="mentoriaGroupLink"
                  placeholder="Insira o link"
                />

                <Icon
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '8%',
                    transform: 'translateY(-50%)',
                    zIndex: 9999999999999,
                  }}
                  icon="carbon:link"
                  color="#8F5CF1"
                />
              </Box>

              {errors?.mentoriaGroupLink?.message && (
                <Text
                  mt="8px"
                  fontSize="0.875rem"
                  color="#E53E3E"
                  textAlign="start"
                >
                  {errors?.mentoriaGroupLink?.message}
                </Text>
              )}
            </Box>

            <FieldInputController
              placeholder="Insira o limite de alunos"
              name="membersLimit"
              type="number"
              control={control}
              label="Limite de alunos"
              error={errors.membersLimit}
            />
          </VStack>
        </ModalBody>

        <ModalFooter p="24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              reset(RESET_FORM)
            }}
            w="full"
            variant="ghost"
            label="Limpar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            label="Criar grupo"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
