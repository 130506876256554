import React from 'react'
import Chart from 'react-apexcharts'

interface PieChartProps {
  title: string
  legendPosition?: 'bottom' | 'top' | 'right' | 'left'
  series: number[]
  labels: string[]
  width?: string
  height?: string
}

export const PieChart: React.FC<PieChartProps> = ({
  title,
  series,
  labels,
  legendPosition = 'bottom',
  width,
  height,
}) => (
  <Chart
    options={{
      legend: {
        position: legendPosition,
      },
      labels,
      title: {
        text: title,
      },
      chart: {
        animations: {
          enabled: false,
        },
      },
      theme: {
        palette: 'palette8',
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          colors: ['#555'],
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: true,
          },
        },
      },
    }}
    series={series}
    type="donut"
    width={width}
    height={height}
  />
)
