import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider, theme } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import AppContextProvider from 'contexts'
import Routes from 'routes'
import GlobalStyles from 'styles/global'

export const queryClient = new QueryClient()

export default function App() {
  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <AppContextProvider>
            <GlobalStyles />
            <Routes />
          </AppContextProvider>
        </ChakraProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}
