import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface Params {
  monitorId?: number
  month?: number
  year?: number
}

interface MetaResponse {
  students: {
    noAccessInTenDays: number
    total: number
  }
  revisions: {
    conclusionAvg: number
    lateAvg: number
  }
  globalGoal: {
    conclusionAvg: number
    studentsWhoAchieved: number
  }
}

export interface StudentData {
  id: number
  name: string
  email: string
  lastLoginAt: string
  daysWithoutAccess: number
  revisions: {
    total: number
    isDone: number
    donePercentage: number
  }
  lateStudies: number
  trackGoal: {
    id: number
    year: number
    month: number
    trackId: number
    trialYear: number
    weeklyGoal: {
      correctAnswersPercentage: string
      numberOfQuestions: number
      studyMinutes: number
    }
    monthlyGoal: {
      correctAnswersPercentage: string
      numberOfQuestions: number
      studyMinutes: number
    }
    reached: {
      correctAnswersPercentage: string
      numberOfQuestions: number
      studyMinutes: number
      numberOfCorrectAnswers: number
    }
    goalPercentageAchieved: {
      correctAnswersPercentage: string
      numberOfQuestions: string
      studyMinutes: string
      globalGoal: string
    }
  }
}

export interface GetDashboardAdvisor {
  meta: MetaResponse
  studentsData: StudentData[]
}

async function GetDashboardAdvisor(
  params?: Params,
): Promise<GetDashboardAdvisor | undefined> {
  const { data } = await api.get<{
    meta: MetaResponse
    studentsData: StudentData[]
  }>('/advisor/dashboard', {
    params: {
      monitorId: params?.monitorId,
      month: params?.month,
      year: params?.year,
    },
  })

  return data
}

export function useGetAdvisorDashboard(params?: Params) {
  return useQuery(
    ['advisorFetchDashboard', params],
    () => GetDashboardAdvisor(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
