import { useQuery } from '@tanstack/react-query'
import { TrackDataApiResponse } from 'pages/UserDetail/interfaces'
import api from 'services/api'

interface Params {
  trackId: number | undefined
}

export async function fetchArea(
  params: Params,
): Promise<TrackDataApiResponse | undefined | null> {
  if (!params.trackId) return

  const { data } = await api.get<TrackDataApiResponse>(
    `/admin/tracks/${params.trackId}`,
  )

  return data
}

export function useFetchArea(params: Params) {
  return useQuery(['fetch-area-by-track', params], () => fetchArea(params))
}
