import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { ListPaginationItem } from './list-pagination-item'

interface Props {
  totalCountOfRegisters?: number
  registersPerPage?: number
  currentPage?: number
  onChangePage: (page: number) => void
}

const siblingsCount = 1

function generatePagesArray(from: number, to: number) {
  return [...Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter(page => page > 0)
}

export function ListPagination({
  currentPage = 1,
  onChangePage,
  registersPerPage = 10,
  totalCountOfRegisters = 0,
}: Props) {
  const lastPage = Math.floor(totalCountOfRegisters / registersPerPage)

  const previousPage =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPage =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage),
        )
      : []

  if (totalCountOfRegisters === 0) return null

  return (
    <Stack
      mt="8"
      spacing="6"
      align="center"
      justify="space-between"
      direction={['column', 'row']}
    >
      <Box>
        <strong>{registersPerPage * (currentPage - 1) + 1}</strong> -{' '}
        <strong>
          {registersPerPage * (currentPage - 1) + totalCountOfRegisters}
        </strong>{' '}
        de <strong>{totalCountOfRegisters}</strong>
      </Box>

      <Stack direction="row" spacing="2">
        {currentPage > 1 + siblingsCount && (
          <>
            <ListPaginationItem onChangePage={onChangePage} number={1} />

            {currentPage > 2 + siblingsCount && (
              <Text color="gray.300" w="8" textAlign="center">
                ...
              </Text>
            )}
          </>
        )}

        {previousPage.length > 0 &&
          previousPage.map(page => (
            <ListPaginationItem
              onChangePage={onChangePage}
              key={page}
              number={page}
            />
          ))}

        <ListPaginationItem
          onChangePage={onChangePage}
          number={currentPage}
          isCurrent
        />

        {nextPage.length > 0 &&
          nextPage.map(page => (
            <ListPaginationItem
              onChangePage={onChangePage}
              key={page}
              number={page}
            />
          ))}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && (
              <Text color="gray.300" w="8" textAlign="center">
                ...
              </Text>
            )}

            <ListPaginationItem onChangePage={onChangePage} number={lastPage} />
          </>
        )}
      </Stack>
    </Stack>
  )
}
