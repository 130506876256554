import { validateFileIsRequired, validateFileSize } from 'helpers/validateFile'
import * as Yup from 'yup'

export const FILE_SIZE = 1 * 1000 * 1000 // 1MB
export const FILE_SIZE_BYTES = FILE_SIZE / 1000 / 1000 // 1MB

export const avatarSchema = Yup.object().shape({
  avatar: Yup.mixed()
    .test(
      'req',
      'Por favor, insira a imagem do seu avatar',
      validateFileIsRequired,
    )
    .test(
      'fileSize',
      `O arquivo deve ser menor que ${FILE_SIZE_BYTES} MB`,
      value => {
        if (!value) return true
        return validateFileSize(value, FILE_SIZE)
      },
    ),
})
