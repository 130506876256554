import { useQuery } from '@tanstack/react-query'
import { formatMeta } from 'helpers/formatMeta'
import api from 'services/api'
import { MetaResponse, MetaResponseApi } from 'types/response'

interface GetStudentsByMonitorResponseAPI {
  id: number
  name: string
  email: string
  mobile_phone: string
}

export interface GetStudentsByMonitor {
  id: number
  name: string
  email: string
  mobilePhone: string
}

export interface GetStudentsByMonitorResponse {
  students: GetStudentsByMonitor[]
  meta: MetaResponse
}

export interface Query {
  page: number
  monitorId: number | null
  daysWithoutAccess?: number
}

export async function getStudentsByMonitor(
  query: Query,
): Promise<GetStudentsByMonitorResponse> {
  const { page, daysWithoutAccess, monitorId } = query

  if (!monitorId) return { students: [], meta: {} as MetaResponse }

  const { data: studentsInput } = await api.get<{
    data: GetStudentsByMonitorResponseAPI[]
    meta: MetaResponseApi
  }>(`/admin/monitor/${monitorId}/students`, {
    params: {
      page,
      daysWithoutAccess: daysWithoutAccess || 0,
    },
  })

  const studentOutput = studentsInput.data.map(student => ({
    id: student.id,
    name: student.name,
    email: student.email,
    mobilePhone: student.mobile_phone,
  }))

  return {
    students: studentOutput,
    meta: formatMeta(studentsInput.meta),
  }
}

export function useGetStudentsByMonitor(query: Query) {
  return useQuery(
    ['students-by-monitor', { query }],
    () => getStudentsByMonitor(query),
    {
      staleTime: 1000 * 60,
    },
  )
}
