import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface Params {
  imageId: string
  questionId: number
}

async function deleteImage(params: Params) {
  await api.delete(
    `/admin/mock-exam-questions/${params.questionId}/image/${params.imageId}`,
  )
}

export function useDeleteImage() {
  return useMutation(deleteImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-question-by-id'])
    },
  })
}
