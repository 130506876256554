import Swal from 'sweetalert2'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import api from 'services/api'
import { useToast } from '@chakra-ui/react'
import { UserApiResponse } from '../types/user'

export function useUser() {
  const toast = useToast()
  const history = useHistory()

  const [user, setUser] = React.useState<UserApiResponse | null>(null)
  const [isLoadingUser, setIsLoadingUser] = React.useState(false)

  const getUserById = useCallback(
    async (userId: number) => {
      if (!userId) return

      try {
        setIsLoadingUser(true)
        const response = await api.get(`/admin/users/${userId}`)

        setUser(response.data)
      } catch {
        Swal.fire({
          buttonsStyling: false,
          title: 'Aviso',
          text: 'Não foi possível carregar os dados do usuário.',
        })
        history.replace('/users')
      } finally {
        setIsLoadingUser(false)
      }
    },
    [history],
  )

  const handleToggleContractStatus = useCallback(
    async (userId, contractId) => {
      if (!userId || !contractId) return

      try {
        await api.patch(`/admin/users/${userId}/contracts/${contractId}/toggle`)

        toast({
          duration: 3000,
          title: 'Contrato atualizado com sucesso!',
          status: 'success',
        })
      } catch {
        toast({
          duration: 3000,
          title: 'Não foi possível atualizar o contrato.',
          status: 'error',
        })
      } finally {
        getUserById(userId)
      }
    },
    [getUserById, toast],
  )

  return {
    user,
    isLoadingUser,
    getUserById,
    handleToggleContractStatus,
  }
}
