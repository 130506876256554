import React from 'react'

import Header from 'components/Header'

import { Container } from './styles'
import Layout from 'layouts/Logged'
import WhatsappGroupsTemplate from 'features/whatsapp-groups'

const WhatsappGroups: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Grupos Whatsapp"
          subTitle="Gerencie os grupos de whatsapp."
        />

        <WhatsappGroupsTemplate />
      </Container>
    </Layout>
  )
}

export default WhatsappGroups
