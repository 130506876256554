import React, { useCallback } from 'react'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'
import { Container, Content } from './styles'
import Layout from 'layouts/Logged'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import NewUserTemplate from 'features/new-user'
import { useHistory } from 'react-router-dom'

const NewUser: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Callbacks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const goToUsersList = useCallback(() => {
    history.push('/users')
  }, [history])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Cadastro de Usuários"
          subTitle="Insira novos alunos, monitores e administradores aqui"
        />
        <Content>
          <SectionTitle>Listagem</SectionTitle>
          <DefaultButton maxW="189px" label="Exibir" onClick={goToUsersList} />

          <SectionTitle>Cadastro</SectionTitle>

          <NewUserTemplate />
        </Content>
      </Container>
    </Layout>
  )
}

export default NewUser
