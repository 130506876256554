import { chakra, Flex, HStack, StackProps, Text } from '@chakra-ui/react'
import React from 'react'

interface MonthStudentCardProps extends StackProps {
  studentNumberContacted: number
  percentage: number
  totalStudents: number
}

export const MonthStudentCard = (props: MonthStudentCardProps) => {
  const { percentage, studentNumberContacted, totalStudents, ...rest } = props

  return (
    <>
      <HStack
        p={'12px 12px'}
        spacing="11px"
        maxW={'235px'}
        w="100%"
        h={'auto'}
        backgroundColor="#302F37"
        borderRadius={'4px'}
        alignItems="center"
        {...rest}
      >
        <Text
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="700"
          fontSize="24px"
          lineHeight="30px"
          color="#FFFFFF"
          p="8px"
        >
          {studentNumberContacted}
        </Text>

        <Text
          font-family="Mulish"
          font-style="normal"
          font-weight="700"
          font-size="16px"
          line-height="20px"
          color="#FFFFFF"
        >
          Alunos contactados{' '}
          <chakra.span
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize="14px"
            lineHeight="18px"
            color="#E296FD"
          >
            {`${percentage.toFixed(2)}% `}
          </chakra.span>
          <chakra.span
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight="18px"
            color="#BDBDC7"
          >
            de
          </chakra.span>
          <chakra.span
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="500"
            fontSize="14px"
            lineHeight="18px"
            color="#FFFF"
          >
            {` ${totalStudents} alunos`}
          </chakra.span>
        </Text>
      </HStack>

      <Text
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="400"
        fontSize="12px"
        lineHeight="15px"
        color="#BDBDC7"
        mt="8px"
      >
        Relativo ao{' '}
        <chakra.span
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="400"
          fontSize="12px"
          lineHeight="15px"
          color="#FFFF"
        >
          {'"mês atual"'}
        </chakra.span>
      </Text>
    </>
  )
}
