import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponse } from 'types/response'

export interface NotifiedStudent {
  id: number
  name: string
  email: string
  cpf: string
  mobile_phone: string
  birthdate: string
  canAccessSeren: boolean
  is_viewed: boolean
}

export type GetNotifiedStudentsResponse = {
  meta: MetaResponse
  data: NotifiedStudent[]
}

type QueryParams = {
  limit: number
  page: number
  notificationId: number | undefined
}

export async function getNotifiedStudentsByNotificationId(
  params: QueryParams,
): Promise<GetNotifiedStudentsResponse | undefined> {
  if (!params.notificationId) return

  const { limit, page } = params
  const { data } = await api.get<GetNotifiedStudentsResponse>(
    `/admin/notifications/${params.notificationId}/students`,
    {
      params: {
        limit,
        page,
      },
    },
  )

  return data
}

export function useGetNotifiedStudentsByNotificationId(params: QueryParams) {
  return useQuery(
    ['get-notified-students-by-notification-id', params],
    () => getNotifiedStudentsByNotificationId(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
