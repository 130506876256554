import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface Params {
  sort?: string // by field. Ex: sort=-name / sort=+name
  limit?: number
  page?: number
  studentId?: number
}

interface MetaResponse {
  total: number
  per_page: number
  current_page: number
  last_page: number
  first_page: number
  first_page_url: string
  last_page_url: string
  next_page_url: string | null
  previous_page_url: string | null
}

export interface Advisor {
  advisor: {
    avatar: string | null
    id: number
    name: string
  }
  advisor_user_id: number
  created_at: string
  date: string
  description: string
  id: number
  is_red_flag: boolean
  status: string
  student_user_id: number
  updated_at: string
}

interface GetAdvisorResponse {
  meta: MetaResponse
  data: Advisor[]
}

async function getStudentRecordByAdvisor(
  params?: Params,
): Promise<GetAdvisorResponse | undefined> {
  if (!params?.studentId) return

  const { data } = await api.get<{
    meta: MetaResponse
    data: Advisor[]
  }>(`/advisor/student/${params?.studentId}/student_record`, {
    params: {
      sort: params?.sort && params.sort,
      limit: params?.limit,
      page: params?.page,
    },
  })

  return data
}

export function useStudentRecordByAdvisor(params?: Params) {
  return useQuery(
    ['studentRecordByAdvisor', params],
    () => getStudentRecordByAdvisor(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
