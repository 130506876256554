import React from 'react'
import Layout from 'layouts/Logged'
import { Container } from './styles'
import Header from 'components/Header'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import VideoOrientation from 'components/VideoOrientation'
import SimulationReview from 'features/simulation/simulation-review'

export default function SimulationReviewPage() {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const isMd = useBreakpointValue({ base: false, lg: true })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Simulados Reta Final"
          subTitle="Crie provas e simulados para os alunos"
          hasVideoOrientation={isMd ? true : false}
          linkVideo="https://www.youtube.com/watch?v=lLNinALZzfc"
        />

        {!isMd && (
          <Flex justifyContent={'flex-start'}>
            <VideoOrientation
              linkVideo="https://www.youtube.com/watch?v=lLNinALZzfc"
              mt="8px"
            />
          </Flex>
        )}

        <SimulationReview />
      </Container>
    </Layout>
  )
}
