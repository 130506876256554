import {
  Flex,
  Text,
  HStack,
  Box,
  VStack,
  Button,
  Textarea,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react'
import Switch from 'react-switch'
import { RiArrowDropDownLine } from 'react-icons/ri'
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { Icon } from '@iconify/react'
import './style.css'
import { FieldDate } from 'components/Form/FieldDate'
import { Controller, useForm } from 'react-hook-form'
import { schema } from './shema'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldInputSearch } from 'components/Form/FieldInputSearch'
import { useCreateAdvisorRecord } from './queries/use-mutation-advisor'
import { formatDateUTC } from 'helpers/formatDate'
import { get } from 'lodash'
import Swal from 'sweetalert2'
import { useUpdateAdvisorRecord } from './queries/use-patch-edit-registry'
type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: Size
  children?: ReactNode
  closeIcon: () => void
  showLastEdition?: boolean
  lastEditionDate?: string
  isPatch: boolean
  dateDefaultValue?: Date
  textDefaultValue?: string
  redFlagDefaultValue?: boolean
  registryId?: number
  disableTextArea?: boolean
  disableDatePicker?: boolean
  disableSubmitButton?: boolean
} & ReactModalProps

interface FormData {
  date: Date
  flag: boolean
  describeText: string
}

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '256px',
    sm: '454px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalEditAdvisor = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size,
    children,
    closeIcon,
    isPatch,
    showLastEdition,
    lastEditionDate,
    textDefaultValue,
    dateDefaultValue,
    redFlagDefaultValue,
    registryId,
    disableTextArea,
    disableDatePicker,
    disableSubmitButton,
    ...reactModalProps
  } = props

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      flag: useMemo(() => {
        return props.redFlagDefaultValue
      }, [props]),
      date: useMemo(() => {
        return props.dateDefaultValue
      }, [props]),
      describeText: useMemo(() => {
        return props.textDefaultValue
      }, [props]),
    },
  })

  const dateWatch = watch('date')
  const flagWatch = watch('flag')
  const describeTextWatch = watch('describeText')

  const createRecord = useCreateAdvisorRecord()
  const updateRecord = useUpdateAdvisorRecord()

  useEffect(() => {
    reset({
      flag: redFlagDefaultValue!,
      date: dateDefaultValue!,
      describeText: textDefaultValue!,
    })
  }, [
    dateDefaultValue,
    getValues,
    props.redFlagDefaultValue,
    redFlagDefaultValue,
    reset,
    setValue,
    textDefaultValue,
  ])

  const onCloseAndClear = useCallback(() => {
    setValue('flag', false)
    setValue('date', new Date())
    setValue('describeText', '')
    onClose()
  }, [onClose, setValue])

  const onSubmit = async (data: FormData) => {
    try {
      if (!registryId) return null

      await createRecord.mutateAsync({
        studentId: registryId,
        date: formatDateUTC(data.date.toDateString(), 'yyyy-MM-dd'),
        description: data.describeText,
        isRedFlag: data.flag,
      })

      Swal.fire({
        title: 'Sucesso',
        icon: 'success',
        html:
          '<p style="text-align:center">Registro adicionado com sucesso!</p>',
        buttonsStyling: false,
      })
    } catch (err) {
      let errorMessage = 'O registro não pode ser efetuado.'

      if (get(err, 'response.status') === 422) {
        const errors = get(err, 'response.data.errors')
        if (Array.isArray(errors)) {
          errorMessage = errors
            .map(error => `\n${error.field} - ${error.message}`)
            .join('')
        }
      }

      Swal.fire({
        buttonsStyling: false,
        title: 'Aviso',
        html: `<p style="text-align:center">${errorMessage}</p>`,
        icon: 'error',
      })
    } finally {
      onCloseAndClear()
    }
  }

  const onSubmitPatch = async (data: FormData) => {
    try {
      if (!registryId) return null

      if (registryId && data) {
        await updateRecord.mutateAsync({
          registryId: registryId,
          date: formatDateUTC(data.date.toDateString(), 'yyyy-MM-dd'),
          description: data.describeText,
          isRedFlag: data.flag,
        })
      }

      Swal.fire({
        title: 'Sucesso',
        icon: 'success',
        html: '<p style="text-align:center">Registro editado com sucesso!</p>',
        buttonsStyling: false,
      })
    } catch (err) {
      let errorMessage = 'O registro não pode ser efetuado.'

      if (get(err, 'response.status') === 422) {
        const errors = get(err, 'response.data.errors')
        if (Array.isArray(errors)) {
          errorMessage = errors
            .map(error => `\n${error.field} - ${error.message}`)
            .join('')
        }
      }

      Swal.fire({
        buttonsStyling: false,
        title: 'Aviso',
        html: `<p style="text-align:center">${errorMessage}</p>`,
        icon: 'error',
      })
    } finally {
      onCloseAndClear()
    }
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCloseAndClear}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          padding: '24px',
          position: 'absolute',
          width: '100%',
          maxWidth: getWidthBySize('sm'),
          height: '100%',
          maxHeight: '722px',
          left: '50%',
          top: '50%',
          overflow: 'auto',
          transform: 'translate(-50%, -50%)',
          background: 'linear-gradient(71.48deg, #1D1D1F 0%, #28272D 98.51%)',
          border: '0px',
          borderRadius: '8px',
        },
        overlay: {
          position: 'fixed',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 999999999,
          backgroundColor: 'rgba(14, 14, 15, 0.3)',
          height: '100%',
          overflowY: 'auto',
        },
      }}
      {...reactModalProps}
    >
      <form
        onSubmit={
          isPatch ? handleSubmit(onSubmitPatch) : handleSubmit(onSubmit)
        }
        className="formEditAdvisor"
      >
        <Flex
          w="100%"
          gap={'32px'}
          justifyContent={'flex-start'}
          flexDir="column"
        >
          <Box>
            <HStack mb="8px" align="center" justify="space-between">
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="700"
                fontSize={'24px'}
                color={'#E296FD'}
              >
                Prontuário do orientador
              </Text>

              <Icon
                fontSize={'24px'}
                onClick={closeIcon}
                color="#FFFFFF"
                icon="eva:close-fill"
                cursor={'pointer'}
              />
            </HStack>
          </Box>

          <VStack alignItems={'flex-start'}>
            <Text
              fontFamily={'Mulish'}
              fontWeight="600"
              fontSize={'16px'}
              lineHeight="20px"
              color={'#FFF'}
            >
              Data
            </Text>

            <Box w="156px">
              <Controller
                name="date"
                control={control}
                defaultValue={dateDefaultValue}
                render={({ field }) => (
                  <FieldDate
                    name={field.name}
                    selected={field.value}
                    startDate={field.value}
                    onChange={field.onChange}
                    disabled={disableDatePicker}
                    dropdownMode="select"
                    /* error={errors.startDate} */
                    autoComplete="off"
                    isRequired
                    customInput={
                      <FieldInputSearch
                        height="44px"
                        width="156px"
                        maxW={'156px'}
                        border="none"
                        borderRadius="8px"
                        autoComplete="off"
                        backgroundColor="#393940"
                        color="#FFFFFF"
                        InputRightCustomIcon={RiArrowDropDownLine}
                        _placeholder={{
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#D7D7D9',
                        }}
                      />
                    }
                    placeholderText="00/00/0000"
                    error={errors.date}
                  />
                )}
              />
            </Box>
          </VStack>

          <VStack alignItems={'flex-start'}>
            <Text
              fontFamily={'Mulish'}
              fontWeight="600"
              fontSize={'16px'}
              lineHeight="20px"
              color={'#FFF'}
            >
              Necessita de uma atenção urgente de seu Monitor?
            </Text>

            <Controller
              control={control}
              name="flag"
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  ref={field.ref}
                  onChange={field.onChange}
                  onColor={'#E296FD'}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  width={32}
                  height={20}
                />
              )}
            />
          </VStack>

          <VStack alignItems={'flex-start'}>
            <Text
              fontFamily={'Mulish'}
              fontWeight="600"
              fontSize={'16px'}
              lineHeight="20px"
              color={'#FFF'}
            >
              Breve descrição da sessão
            </Text>

            <Flex w="100%" flexDir={'column'}>
              <FormControl isInvalid={!!errors.describeText}>
                <Controller
                  control={control}
                  name="describeText"
                  rules={{
                    required: {
                      value: false,
                      message: 'A descrição é obrigatória',
                    },
                  }}
                  defaultValue={textDefaultValue}
                  render={({ field }) => (
                    <Textarea
                      padding="16px 16px 16px 12px"
                      w="100%"
                      disabled={disableTextArea}
                      fontWeight="400"
                      fontSize="16px"
                      lineHeight="20px"
                      placeholder="Digite seu texto aqui"
                      backgroundColor={'#393940'}
                      borderRadius="8px"
                      color=" #FFFFFF"
                      maxLength={1500}
                      _focus={
                        !errors.describeText
                          ? { outlineColor: '#7D46B1' }
                          : { outlineColor: 'none' }
                      }
                      border="0px"
                      minH={'282px'}
                      {...field}
                    />
                  )}
                />

                {showLastEdition && (
                  <Flex justifyContent={'space-between'}>
                    <Text
                      mt="4px"
                      fontFamily="Mulish"
                      fontStyle="normal"
                      fontWeight="500"
                      fontSize="12px"
                      lineHeight="15px"
                      color={'#B8B8C1'}
                    >
                      {showLastEdition && `Última edição ${lastEditionDate}`}
                    </Text>

                    <Text
                      alignSelf={'flex-end'}
                      mt="4px"
                      fontFamily="Mulish"
                      fontStyle="normal"
                      fontWeight="500"
                      fontSize="12px"
                      lineHeight="15px"
                      color={'#B8B8C1'}
                    >
                      Até 1500 caracteres
                    </Text>
                  </Flex>
                )}

                {!showLastEdition && (
                  <Flex w="100%" justifyContent={'flex-end'}>
                    <Text
                      alignSelf={'flex-end'}
                      mt="4px"
                      fontFamily="Mulish"
                      fontStyle="normal"
                      fontWeight="500"
                      fontSize="12px"
                      lineHeight="15px"
                      color={'#B8B8C1'}
                    >
                      Até 1500 caracteres
                    </Text>
                  </Flex>
                )}

                {!!errors.describeText && (
                  <FormErrorMessage>
                    {errors.describeText.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>
          </VStack>

          <Button
            _hover={
              dateWatch === undefined ||
              describeTextWatch === '' ||
              flagWatch === undefined
                ? { backgroundColor: '#60606C' }
                : { backgroundColor: '#be4bfc' }
            }
            type="submit"
            isLoading={isSubmitting}
            w="100%"
            borderRadius="12px"
            backgroundColor={
              dateWatch === undefined ||
              describeTextWatch === '' ||
              flagWatch === undefined
                ? '#60606C'
                : '#be4bfc'
            }
            border={
              dateWatch === undefined ||
              describeTextWatch === '' ||
              flagWatch === undefined
                ? ''
                : '1px solid #7D46B1'
            }
            height="50px"
            fontFamily="Mulish"
            fontWeight="700"
            fontSize="16px"
            color="#FFFFFF"
            disabled={disableSubmitButton}
          >
            Enviar
          </Button>
        </Flex>
      </form>
    </ReactModal>
  )
}
