import styled from 'styled-components'
import { transparentize } from 'polished'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  width: 100%;

  /* @media (max-width: 650px) {
    flex-direction: column;
    gap: 20px;
  } */
`

export const HeaderTitleContainer = styled.div``

export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;

  mix-blend-mode: normal;
  opacity: 0.9;

  margin-bottom: 4px;
`

export const HeaderSubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: ${({ theme: { colors } }) => colors.primary};
`

export const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 28px;
  padding: 4px 8px;
  background: ${({ theme: { colors } }) => colors.primary};
  border-radius: 14px;

  margin-right: 24px;

  svg {
    color: ${({ theme: { colors } }) => colors.white};
    margin-right: 4px;
  }
`

export const NotificationText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  text-align: right;
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 16px;
`

export const ProfileAvatar = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 4px;

  margin-right: 8px;

  object-fit: cover;
`

export const ProfileName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: right;

  mix-blend-mode: normal;
`

export const LogOutButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 28px;
  width: 28px;
  background: transparent;
  border-radius: 8px;
  border-color: ${({ theme: { colors } }) =>
    transparentize(0.6, colors.primary)};

  transition: all 0.4s;

  svg {
    color: ${({ theme: { colors } }) => colors.white};

    transition: all 0.4s;
  }

  &:hover {
    border: 1px solid
      ${({ theme: { colors } }) => transparentize(0.6, colors.primary)};

    svg {
      color: ${({ theme: { colors } }) => colors.primary};
    }
  }
`
