import { Box, Circle, Flex, Table, Tbody, Text } from '@chakra-ui/react'
import { FieldSelect } from 'components/Form/FieldSelect'
import { ModalEditAdvisor } from 'components/Modal/ModalEditAdvisor'
import { ModalWhatsAppAdvisorTable } from 'components/Modal/ModalWhatsAppAdvisorTable'
import { SearchSectionField } from 'components/MonitorTable/components/SearchSection'
import Pagination from 'components/Pagination'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TrTableBodyAdvisor } from './components/TrTableBodyAdvisor'
import { TrTableHeadAdvisor } from './components/TrTableHeadAdvisor'
import { Option } from './type'
import { useGetAdvisor } from './queries/use-fetch-advisor'
import { Advisor } from './queries/use-fetch-advisor'
import { useAuth } from 'contexts/auth'
import { chunk, debounce, orderBy } from 'lodash'
import { NoDataTableError } from 'components/MonitorTable/components/NoDataTable'
import { MonthStudentCard } from './components/StudentCard'
import { CustomOption } from 'features/dashboard/dashboard-advisor/Types'
import { differenceInCalendarDays, differenceInDays } from 'date-fns'
import { formatDateUTCDate } from 'helpers/formatDate'
import { usePathView } from './queries/use-patch-view-advisor'
import Swal from 'sweetalert2'
import { OptionTypeBase } from 'react-select'

export function AdvisorTable() {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalWhatsAppAdvisorTable, setModalWhatsAppAdvisorTable] = useState(
    false,
  )
  const [modalEditAdvisor, setModalEditAdvisor] = useState(false)
  const [selectedUser, setSelectedUser] = useState<Advisor | null>()
  const [search, setSearch] = useState('')
  const [query, setQuery] = useState('')
  const [daysWithoutAcessOrder, setDaysWithoutAcessOrder] = useState<
    '+daysWithoutAccess' | '-daysWithoutAccess' | null
  >(null)
  const [percentualFilter, setPercentualFilter] = useState<Option>({
    label: 'Todas opções',
    value: 4,
  })
  const [monitorFilter, setMonitorFilter] = useState<Option>({
    value: 9999,
    label: 'Todos os monitores',
  })
  const [seniorityFilter, setSeniorityFilter] = useState<Option>({
    label: 'Nenhum',
    value: 9999,
  })
  const [seniorityOrder, setSeniorityOrder] = useState<
    '+contractCreatedAt' | '-contractCreatedAt' | null
  >(null)
  const [studentViewOrder, setStudentViewOrder] = useState<
    '+isStudentRecordViewed' | '-isStudentRecordViewed' | null
  >(null)
  const [page, setPage] = useState(1)

  /*
  |-----------------------------------------------------------------------------
  | Const
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user, updateUserData } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    updateUserData()
  }, [updateUserData])

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const avatarEndpoint = useCallback((user: string) => {
    if (!user) return ''

    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${user.replaceAll(' ', '+')}`
  }, [])

  const { data, isFetched, isError } = useGetAdvisor({
    globalGoalMax:
      percentualFilter.value === 0
        ? 999999
        : percentualFilter.value === 1
        ? 100
        : percentualFilter.value === 2
        ? 80
        : percentualFilter.value === 3
        ? 60
        : undefined,
    globalGoalMin:
      percentualFilter.value === 0
        ? 101
        : percentualFilter.value === 1
        ? 81
        : percentualFilter.value === 2
        ? 61
        : percentualFilter.value === 3
        ? 0
        : undefined,
    monitorId: +monitorFilter.value !== 9999 ? +monitorFilter.value : undefined,
    q: query.length >= 3 ? query : undefined,
    sort: daysWithoutAcessOrder
      ? daysWithoutAcessOrder
      : /* : seniorityOrder
      ? seniorityOrder */
      studentViewOrder
      ? studentViewOrder
      : '+globalGoal',
    page: seniorityOrder ? undefined : page,
    limit: seniorityOrder ? 999999 : 10,
    contractTime:
      seniorityFilter.value !== 9999 ? seniorityFilter.value : undefined,
  })
  const patchView = usePathView()

  const debouncedSearch = React.useMemo(
    () =>
      debounce(val => {
        setQuery(val)
      }, 1000),
    [setQuery],
  )

  const isNewStudentAfter = useCallback((date: string) => {
    const today = new Date()

    const diferenceBetweenDates = differenceInDays(today, new Date(date))

    return diferenceBetweenDates
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | CallBack
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
      debouncedSearch(e.target.value)
    },
    [debouncedSearch],
  )

  const handleChangeView = useCallback(
    async (idStudent: number, isChecked: boolean) => {
      try {
        await patchView.mutateAsync({
          studentId: idStudent,
          isStudentRecordViewed: !isChecked,
        })
      } catch (error: any) {
        const message =
          error?.response?.data?.errors
            ?.map((error: any) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Views não pode ser modificada.'

        Swal.fire({
          title: 'Erro',
          text: message,
          icon: 'error',
          buttonsStyling: false,
        })
      }
    },
    [patchView],
  )

  return (
    <>
      <Flex flexDir={'column'} mb="16px" mt="32px" w="100%">
        <MonthStudentCard
          studentNumberContacted={data?.contactedStudents.totalContacted ?? 0}
          percentage={data?.contactedStudents.totalContactedPercentage ?? 0}
          totalStudents={data?.contactedStudents.totalStudents ?? 0}
        />

        <Text
          fontFamily={'Mulish'}
          mb="1rem"
          mt="2rem"
          fontSize={'24px'}
          fontWeight="700"
          lineHeight={'30px'}
        >
          Filtros
        </Text>

        <Flex
          justifyContent={{ base: 'flex-start', sm: 'space-between' }}
          flexDir={{ base: 'column', lg: 'row' }}
          gap={{ base: '1rem', lg: '0px' }}
        >
          <Flex
            flexDir={{ base: 'column', sm: 'row' }}
            gap={'16px'}
            maxW="630px"
            w="100%"
          >
            <FieldSelect
              maxW="210px"
              w="100%"
              placeholder="Senioridade"
              options={[
                {
                  label: 'Novo',
                  value: 'new',
                },
                {
                  label: 'Junior',
                  value: 'junior',
                },
                {
                  label: 'Veterano',
                  value: 'veteran',
                },
                {
                  label: 'Nenhum',
                  value: 9999,
                },
              ]}
              onChange={(e: OptionTypeBase) => setSeniorityFilter(e as Option)}
              value={seniorityFilter}
            />

            <FieldSelect
              maxW="210px"
              w="100%"
              placeholder="Escolha o monitor(a)"
              options={
                user?.advisorMonitors
                  ? [
                      ...user?.advisorMonitors?.map(monitors => {
                        return {
                          label: monitors.name,
                          value: monitors.id,
                          avatar: monitors.avatar?.url,
                        }
                      }),
                      {
                        label: 'Todos monitores',
                        value: 9999,
                        avatar: null,
                      },
                    ]
                  : []
              }
              formatOptionLabel={(person: OptionTypeBase) =>
                CustomOption({
                  avatarEndpoint: avatarEndpoint(person.label),
                  person: person,
                })
              }
              onChange={(e: OptionTypeBase) => setMonitorFilter(e as Option)}
              value={monitorFilter}
            />

            <FieldSelect
              maxW="216px"
              w="100%"
              placeholder="Percentual de metas"
              options={[
                { label: 'Acima de 100%', value: 0 },
                { label: '81% - 100%', value: 1 },
                { label: '61% - 80%', value: 2 },
                { label: '0% - 60%', value: 3 },
                { label: 'Todas opções', value: 4 },
              ]}
              onChange={(e: OptionTypeBase) => setPercentualFilter(e as Option)}
              value={percentualFilter}
              formatOptionLabel={(label: OptionTypeBase) => {
                if (!label) return
                if (label.value === 0) {
                  return (
                    <Flex gap={'8px'} alignItems="center">
                      <Circle bg={'#0DDF15'} size="12px" />
                      <Text>{label.label}</Text>
                    </Flex>
                  )
                }
                if (label.value === 1) {
                  return (
                    <Flex gap={'8px'} alignItems="center">
                      <Circle bg={'#0DDF15'} size="12px" />
                      <Text>{label.label}</Text>
                    </Flex>
                  )
                }
                if (label.value === 2) {
                  return (
                    <Flex gap={'8px'} alignItems="center">
                      <Circle bg={'#E5C009'} size="12px" />
                      <Text>{label.label}</Text>
                    </Flex>
                  )
                }
                if (label.value === 3) {
                  return (
                    <Flex gap={'8px'} alignItems="center">
                      <Circle bg={'#FF6363'} size="12px" />
                      <Text>{label.label}</Text>
                    </Flex>
                  )
                }
                if (label.value === 4) {
                  return (
                    <Flex gap={'8px'} alignItems="center">
                      <Text ml="20px">{label.label}</Text>
                    </Flex>
                  )
                }
              }}
            />
          </Flex>

          <SearchSectionField
            setInput={setSearch}
            search={search}
            maxWInputGroup="316px"
            fontSize={'14px'}
            _placeholder={{ color: '#BDBDC7' }}
            placeholder="Buscar pelo nome (mínimo 3 caracteres)"
            onChange={handleChange}
            value={search}
          />
        </Flex>
      </Flex>
      <Box
        mt="1rem"
        maxH="508px"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#D9D9D9',
            borderRadius: '17px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#60606C',
            borderRadius: '17px',
            height: '8px',
          },
        }}
        overflow="auto"
        w={'100%'}
      >
        <Table background={'#302F37'} borderRadius="6px">
          <TrTableHeadAdvisor
            onViewClickOrderButton={() =>
              setStudentViewOrder(prev =>
                prev === null
                  ? '+isStudentRecordViewed'
                  : prev === '+isStudentRecordViewed'
                  ? '-isStudentRecordViewed'
                  : null,
              )
            }
            viewOrder={studentViewOrder}
            onDaysOrder={() =>
              setDaysWithoutAcessOrder(prev =>
                prev === null
                  ? '+daysWithoutAccess'
                  : prev === '+daysWithoutAccess'
                  ? '-daysWithoutAccess'
                  : null,
              )
            }
            daysWithoutAccessOrder={daysWithoutAcessOrder}
            onStudentOrderClick={() => {
              setSeniorityOrder(prev =>
                prev === null
                  ? '-contractCreatedAt'
                  : prev === '-contractCreatedAt'
                  ? '+contractCreatedAt'
                  : null,
              )
            }}
            seniorityOrder={seniorityOrder}
          />
          <Tbody borderBottomWidth={'20px'} borderBottomColor="#302F37">
            {!seniorityOrder &&
              isFetched &&
              data?.data?.map((item, index) => {
                return (
                  <TrTableBodyAdvisor
                    key={`TableSectionData${index}`}
                    onClick={() => {
                      setSelectedUser(item)
                    }}
                    userId={item.id}
                    avatar={item.avatar?.url && item.avatar?.url}
                    student={item.name ?? 'Sem dados'}
                    daysWithoutAccess={item.daysWithoutAccess ?? 'Sem dados'}
                    globalGoal={item.globalGoal}
                    questions={item.numberOfQuestions ?? 'Sem dados'}
                    correctAnswersPercentage={item?.correctAnswersPercentage}
                    effort={item.effortPerceptionPercentage}
                    view={item.isStudentRecordViewed}
                    isNewStudentUntil15Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) <= 15
                    }
                    isNewStudentUntil30Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) > 15 &&
                      isNewStudentAfter(item?.contract[0]?.initial_date) <= 30
                    }
                    isVeteranAfter30Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) > 30
                    }
                    onClickOpenWhatsLink={() =>
                      setModalWhatsAppAdvisorTable(true)
                    }
                    onEditClickButton={() => setModalEditAdvisor(true)}
                    isModalOpen={modalEditAdvisor}
                    onViewSetToFalse={() =>
                      handleChangeView(item.id, item.isStudentRecordViewed)
                    }
                    onViewSetToTrue={() =>
                      handleChangeView(item.id, item.isStudentRecordViewed)
                    }
                  />
                )
              })}

            {seniorityOrder === '+contractCreatedAt' &&
              isFetched &&
              chunk(orderBy(data?.data, 'contract[0].initial_date', 'asc'), 10)[
                page - 1
              ]?.map((item, index) => {
                return (
                  <TrTableBodyAdvisor
                    key={`TableSectionData${index}`}
                    onClick={() => {
                      setSelectedUser(item)
                    }}
                    userId={item.id}
                    avatar={item.avatar?.url && item.avatar?.url}
                    student={item.name ?? 'Sem dados'}
                    daysWithoutAccess={item.daysWithoutAccess ?? 'Sem dados'}
                    globalGoal={item.globalGoal}
                    questions={item.numberOfQuestions ?? 'Sem dados'}
                    correctAnswersPercentage={item?.correctAnswersPercentage}
                    effort={item.effortPerceptionPercentage}
                    view={item.isStudentRecordViewed}
                    isNewStudentUntil15Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) <= 15
                    }
                    isNewStudentUntil30Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) > 15 &&
                      isNewStudentAfter(item?.contract[0]?.initial_date) <= 30
                    }
                    isVeteranAfter30Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) > 30
                    }
                    onClickOpenWhatsLink={() =>
                      setModalWhatsAppAdvisorTable(true)
                    }
                    onEditClickButton={() => setModalEditAdvisor(true)}
                    isModalOpen={modalEditAdvisor}
                    onViewSetToFalse={() =>
                      handleChangeView(item.id, item.isStudentRecordViewed)
                    }
                    onViewSetToTrue={() =>
                      handleChangeView(item.id, item.isStudentRecordViewed)
                    }
                  />
                )
              })}

            {seniorityOrder === '-contractCreatedAt' &&
              isFetched &&
              chunk(
                orderBy(data?.data, 'contract[0].initial_date', 'desc'),
                10,
              )[page - 1]?.map((item, index) => {
                return (
                  <TrTableBodyAdvisor
                    key={`TableSectionData${index}`}
                    onClick={() => {
                      setSelectedUser(item)
                    }}
                    userId={item.id}
                    avatar={item.avatar?.url && item.avatar?.url}
                    student={item.name ?? 'Sem dados'}
                    daysWithoutAccess={item.daysWithoutAccess ?? 'Sem dados'}
                    globalGoal={item.globalGoal}
                    questions={item.numberOfQuestions ?? 'Sem dados'}
                    correctAnswersPercentage={item?.correctAnswersPercentage}
                    effort={item.effortPerceptionPercentage}
                    view={item.isStudentRecordViewed}
                    isNewStudentUntil15Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) <= 15
                    }
                    isNewStudentUntil30Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) > 15 &&
                      isNewStudentAfter(item?.contract[0]?.initial_date) <= 30
                    }
                    isVeteranAfter30Days={
                      isNewStudentAfter(item?.contract[0]?.initial_date) > 30
                    }
                    onClickOpenWhatsLink={() =>
                      setModalWhatsAppAdvisorTable(true)
                    }
                    onEditClickButton={() => setModalEditAdvisor(true)}
                    isModalOpen={modalEditAdvisor}
                    onViewSetToFalse={() =>
                      handleChangeView(item.id, item.isStudentRecordViewed)
                    }
                    onViewSetToTrue={() =>
                      handleChangeView(item.id, item.isStudentRecordViewed)
                    }
                  />
                )
              })}
          </Tbody>
        </Table>

        {isError && (
          <Flex w="100%" justifyContent={'center'}>
            <NoDataTableError />
          </Flex>
        )}

        <ModalWhatsAppAdvisorTable
          isOpen={modalWhatsAppAdvisorTable}
          onClose={() => setModalWhatsAppAdvisorTable(false)}
          size={'sm'}
          linkWhats={selectedUser?.mobilePhone}
          onConfirmClick={() => setModalWhatsAppAdvisorTable(false)}
        />

        <ModalEditAdvisor
          isOpen={modalEditAdvisor}
          onClose={() => setModalEditAdvisor(false)}
          closeIcon={() => setModalEditAdvisor(false)}
          registryId={selectedUser?.id && selectedUser?.id}
          isPatch={false}
        />
      </Box>
      <Pagination
        totalCountOfRegisters={data?.meta?.total ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={10}
      />
    </>
  )
}
