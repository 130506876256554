import { transparentize } from 'polished'
import styled from 'styled-components'
import { ResponsiveContainer } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;

  overflow-y: auto;

  ${ResponsiveContainer}

  > button {
    margin: 32px 0px;
  }
`

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 16px;
`

export const Chip = styled.button<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 166px;
  height: 42px;

  margin-right: 16px;
  margin-bottom: 16px;

  border-radius: 10px;
  border-width: 1px;
  border-color: red;

  background: transparent;

  border: 1px solid ${transparentize(0.8, '#ffffff')};

  transition: all 0.4s;

  overflow: hidden;

  &:hover {
    border: 1px solid
      ${({ theme: { colors } }) => transparentize(0.6, colors.primary)};
    background: ${({ theme: { colors } }) =>
      transparentize(0.95, colors.primary)};

    p {
      color: ${transparentize(0.55, '#ffffff')};
    }
  }
`

export const ChipText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${transparentize(0.7, '#ffffff')};

  transition: all 0.4s;

  user-select: none;

  padding: 8px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin: 32px 0px;

  > button:first-child {
    margin-right: 8px;
  }
`
