import React, { useMemo } from 'react'

import { Box, Grid } from '@chakra-ui/react'

import { CardKpi } from 'features/dashboard'

import SectionTitle from 'components/SectionTitle'

import { FiTarget } from 'react-icons/fi'
import { MdSchool } from 'react-icons/md'
import { IoIosTimer } from 'react-icons/io'

export interface KpiListProps {
  globalGoal?: {
    conclusionAvg: number
    studentsWhoAchieved: number
  }

  revisions?: {
    lateAvg: number
    conclusionAvg: number
  }

  students?: {
    total: number
    noAccessInTwoWeeks: number
  }
}

export const KpiList: React.FC<KpiListProps> = ({
  students,
  revisions,
  globalGoal,
}) => {
  const studentsWhoAchievedPercentage = useMemo(() => {
    if (!globalGoal || !students) return 0

    return Math.round((globalGoal.studentsWhoAchieved / students.total) * 100)
  }, [globalGoal, students])

  return (
    <Box>
      <SectionTitle>KPI&apos;s</SectionTitle>

      <Grid
        gap={4}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(4, 1fr)',
        }}
      >
        <CardKpi
          icon={FiTarget}
          title="Meta global"
          href="/users/global-meta"
          stats={[
            {
              label: 'Média das metas',
              value: Math.floor(globalGoal?.conclusionAvg || 0),
            },
            {
              label: 'Alunos que atigiram',
              value: globalGoal?.studentsWhoAchieved || 0,
              subValue: `${studentsWhoAchievedPercentage}% de ${
                students?.total || 0
              } alunos`,
            },
          ]}
        />

        <CardKpi
          icon={IoIosTimer}
          title="Revisões"
          stats={[
            {
              label: 'Percentual médio de concluidas',
              value: `${revisions?.conclusionAvg || 0}%`,
            },
            {
              label: 'Média de atrasadas',
              value: revisions?.lateAvg || 0,
            },
          ]}
        />

        <CardKpi
          icon={MdSchool}
          title="Alunos"
          href="/users/without-access"
          stats={[
            {
              label: 'Sem acesso há 02 semanas',
              value: students?.noAccessInTwoWeeks || 0,
            },
          ]}
        />
      </Grid>
    </Box>
  )
}
