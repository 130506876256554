import React, { useCallback, useState } from 'react'
import Swal from 'sweetalert2'
import { Button as ChakraButton, VStack, Text, Flex } from '@chakra-ui/react'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Contexts
import { useAuth } from 'contexts/auth'

// Services
import api from 'services/api'

// Styles
import { Container, Content, AvatarImage, AvatarContainer } from './styles'
import Layout from 'layouts/Logged'
import { Icon } from '@iconify/react'
import { CustomModalApp } from 'components/Modal/CustomModalApp'
import { queryClient } from 'App'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldInput } from 'components/Form/FieldInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { avatarSchema } from './schema'

interface AvatarForm {
  avatar: File
}

const Account: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Contexts.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user, updateUserData, userRolesSlugs } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isPostingNewAvatar, setIsPostingNewAvatar] = useState(false)
  const [isUpdateAvatarEnabled, setIsUpdateAvatarEnabled] = useState(() =>
    user?.avatar?.url ? false : true,
  )
  const [resetModalOpen, setResetModalOpen] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AvatarForm>({
    resolver: yupResolver(avatarSchema),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const fileSubmitHandler = useCallback(
    (filePayload: any) => {
      if (!filePayload?.avatar) return

      const fileMultipartFormData = new FormData()
      fileMultipartFormData.append('avatar', filePayload?.avatar[0])

      setIsPostingNewAvatar(true)

      api
        .post('app/avatars', fileMultipartFormData)
        .then(() => {
          Swal.fire({
            title: 'Sucesso',
            text: 'Foto de perfil atualizada com sucesso!',
            icon: 'success',
            buttonsStyling: false,
            timer: 2000,
          }).then(() => {
            updateUserData()
            setIsUpdateAvatarEnabled(false)
          })
        })
        .catch(err => {
          console.trace('Error uploading avatar image. ', err)
          Swal.fire({
            title: 'Aviso',
            text: 'Não foi possível atualizar a sua foto de perfil agora.',
            icon: 'error',
            buttonsStyling: false,
          })
        })
        .finally(() => setIsPostingNewAvatar(false))
    },
    [updateUserData],
  )

  const patchAllResetViews = useCallback(async () => {
    try {
      await api.patch('/advisor/student_record/reset')

      Swal.fire({
        title: 'Sucesso',
        text: 'Reset de views realizada com sucesso. ',
        icon: 'success',
        buttonsStyling: false,
        didClose() {
          setResetModalOpen(false)
        },
      })
    } catch (error: any) {
      const message =
        error?.response?.data?.errors
          ?.map((error: any) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Reset de views não pode ser realizada.'

      Swal.fire({
        title: 'Erro',
        text: message,
        icon: 'error',
        buttonsStyling: false,
      })
    } finally {
      queryClient.invalidateQueries(['advisorFetchTable'])
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Minha conta"
          subTitle="Imagem de perfil e dados pessoais"
        />
        <Content>
          <SectionTitle>Foto de perfil</SectionTitle>
          {user?.avatar?.url && !isUpdateAvatarEnabled && (
            <AvatarContainer>
              <AvatarImage width="256" height="256" src={user.avatar.url} />
              <DefaultButton
                label="Alterar"
                maxW="189px"
                onClick={() => setIsUpdateAvatarEnabled(true)}
              />
            </AvatarContainer>
          )}

          {isUpdateAvatarEnabled && (
            <Flex
              as="form"
              flexDir="column"
              pl="20px"
              pt="16px"
              onSubmit={handleSubmit(fileSubmitHandler)}
            >
              <input
                type="file"
                {...register('avatar', {
                  required: 'Selecione um arquivo de imagem',
                })}
                onChange={e => {
                  const file = e?.target?.files?.[0]
                  return file
                }}
              />

              {errors?.avatar && (
                <Text color={'red.500'}>{errors?.avatar?.message}</Text>
              )}

              <DefaultButton
                maxW="189px"
                mt="1rem"
                label="Salvar"
                type="submit"
                isLoading={isPostingNewAvatar}
              />
            </Flex>
          )}

          <SectionTitle>Cadastro</SectionTitle>
          <Flex
            as="form"
            maxW="380px"
            gap="1rem"
            onSubmit={d => d.preventDefault()}
            flexDir="column"
            pl="20px"
            pt="16px"
          >
            <FieldInput
              defaultValue={user?.name || ''}
              isDisabled
              name="name"
              type="text"
              placeholder="Nome"
            />

            <FieldInput
              defaultValue={user?.email || ''}
              isDisabled
              name="email"
              type="email"
              placeholder="E-mail"
            />

            <FieldInput
              defaultValue={user?.mobile_phone || ''}
              isDisabled
              name="mobilePhone"
              placeholder="Telefone"
            />

            <DefaultButton
              maxW="189px"
              mt="16px"
              type="submit"
              label="Editar"
            />
          </Flex>

          {userRolesSlugs && userRolesSlugs.includes('advisor') && (
            <VStack spacing={'16px'} mt="32px" align={'flex-start'}>
              <Text
                fontFamily="Mulish"
                fontWeight="700"
                fontSize="12px"
                lineHeight="23px"
                color="#FFFFFF"
              >
                RESET DE VIEWS
              </Text>

              <ChakraButton
                bg="#302F37"
                _hover={{ background: '#302f36c7' }}
                borderRadius={'6px'}
                _active={{ background: '#302f36c7' }}
                maxW="175px"
                p="12px 16px"
                leftIcon={<Icon icon={'eva:refresh-fill'} color="#E296FD" />}
                iconSpacing={'6px'}
                onClick={() => setResetModalOpen(true)}
              >
                Reset de views
              </ChakraButton>
            </VStack>
          )}
        </Content>

        <CustomModalApp
          isOpen={resetModalOpen}
          onClose={() => setResetModalOpen(false)}
          onConfirmClicked={patchAllResetViews}
        />
      </Container>
    </Layout>
  )
}

export default Account
