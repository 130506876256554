import { map } from 'lodash'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  title: Yup.string().required('O nome é obrigatório.'),

  startsAt: Yup.string()
    .transform((_, originalValue: Date) => {
      if (originalValue) {
        return originalValue?.toISOString()
      }

      return ''
    })
    .required('A data de publicação é obrigatória.'),

  expiresAt: Yup.string()
    .nullable()
    .transform((_, originalValue: Date) => {
      if (originalValue) {
        return originalValue?.toISOString()
      }

      return ''
    })
    .test(
      'is-greater-than-startsAt',
      'A data de expiração deve ser maior que a data de publicação.',
      (value, context) => {
        if (!value || !context.parent.startsAt) {
          return true
        }

        const sameHour =
          new Date(value).toLocaleString() ===
          new Date(context.parent.startsAt).toLocaleString()

        const isAfter = new Date(value) > new Date(context.parent.startsAt)

        console.log(
          new Date(value).toLocaleString() ===
            new Date(context.parent.startsAt).toLocaleString(),
        )

        return isAfter && !sameHour
      },
    )
    .required('A data de expiração é obrigatória.'),

  filter: Yup.string()
    .transform(value => value && value.value)
    .nullable(),

  contractType: Yup.string().transform((_, value) => {
    if (value) {
      return value.value
    }
  }),

  trackIds: Yup.array().when('filter', {
    is: (value: string) => value === 'Trilha de Estudo',
    then: Yup.array()
      .nullable()
      .min(1, 'A trilha é obrigatória.')
      .typeError('A trilha é obrigatória.')
      .required('A trilha é obrigatória.')
      .transform((_, originalValue: { label: string; value: string }) => {
        if (originalValue) {
          return map(originalValue, 'value')
        }

        return null
      }),
  }),

  examYears: Yup.array().when('filter', {
    is: (value: string) => value === 'Ano de Prova',
    then: Yup.array()
      .nullable()
      .min(1, 'O ano de prova é obrigatório.')
      .typeError('O ano de prova é obrigatório.')
      .required('O ano de prova é obrigatório.')
      .transform((_, originalValue: { label: string; value: string }) => {
        if (originalValue) {
          return map(originalValue, 'value')
        }

        return null
      }),
  }),

  monitorUserIds: Yup.array().when('filter', {
    is: (value: string) => value === 'Monitor',
    then: Yup.array()
      .nullable()
      .min(1, 'O Monitor é obrigatório.')
      .typeError('O Monitor é obrigatório.')
      .required('O Monitor é obrigatório.')
      .transform((_, originalValue: { label: string; value: string }) => {
        if (originalValue) {
          return map(originalValue, 'value')
        }

        return null
      }),
  }),

  studentUserIds: Yup.array().when('filter', {
    is: (value: string) => value === 'Aluno',
    then: Yup.array()
      .nullable()
      .min(1, 'O Aluno é obrigatório.')
      .typeError('O Aluno é obrigatório.')
      .required('O Aluno é obrigatório.')
      .transform((_, originalValue: { label: string; value: string }) => {
        if (originalValue) {
          return map(originalValue, 'value')
        }

        return null
      }),
  }),

  message: Yup.string().required('A mensagem é obrigatória.'),

  urlLink: Yup.string(),
})
