import React, { useMemo, useRef, useState, useCallback } from 'react'
import {
  Box,
  HStack,
  InputGroup,
  InputRightElement,
  Tooltip,
  Text,
  IconButton,
  VStack,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import { List } from 'features/ui/list'
import { ListHeader } from 'features/ui/list/list-header'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import Pagination from 'components/Pagination'
import { useHistory } from 'react-router-dom'
import { MoreVertical, Circle } from 'react-feather'
import Navigation from '../navigation'
import { GetExams, useGetExams } from '../mutations/use-get-exams'
import { usePublishExam } from '../mutations/use-mutate-publish-exam'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { useDeleteExam } from '../mutations/use-mutate-delete-exam'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { format } from 'date-fns'
import { Icon } from '@iconify/react'
import { useDebounce } from 'hooks/use-debounce'
import ModalSimulationNew from '../Modal/ModalSimulationNew'
import ModalPreviewPDF from '../Modal/ModalViewPDF'
import { capitalize } from 'lodash'
import { FieldInput } from 'components/Form/FieldInput'

export default function SimulationTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [search, setSearch] = useState('')
  const [sort, setSort] = useState<'-created_at' | '+created_at'>('-created_at')
  const [isNewModalOpen, setIsNewModalOpen] = useState(false)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteModalId, setdeleteModalId] = useState<number>()
  const [page, setPage] = useState(1)
  const [registersPerPage] = useState(6)
  const ref = useRef<HTMLInputElement>(null)
  const [modalPdfTerms, setModalPdfTerms] = useState(false)
  const [selectedInstitution, setSelectedInstitution] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const debouncedSearch = useDebounce(search, 800)
  const { data: dataGetExams, isLoading: isLoadingGetExams } = useGetExams({
    page,
    limit: 6,
    title: debouncedSearch ? debouncedSearch : undefined,
    sort,
  })
  const patchPublishExam = usePublishExam()
  const deleteExam = useDeleteExam()
  const showToast = useToastMessage()
  const { push } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Callbacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handleDeleteExam = useCallback(
    async (id: number) => {
      try {
        await deleteExam.mutateAsync({
          id,
        })

        showToast({
          title: 'Sucesso',
          description: 'Simulado excluído com sucesso.',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch (error) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível excluir o simulado.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [deleteExam, showToast],
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: <Text textAlign="center">Ações</Text>,
        fn(_, value) {
          return (
            <Box textAlign="center">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MoreVertical size="20px" color="#7D46B1" />}
                  bg="#393940"
                  height={'26px'}
                  width={'26px'}
                  minW={'26px !important'}
                  p="0px"
                />
                <Portal>
                  <MenuList
                    bg="#393940"
                    color="#ffffff"
                    border="1px solid #7D46B1"
                    minW="fit-content"
                    py="0px"
                  >
                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        push(`/simulation/review/${+value.id}`)
                      }}
                    >
                      Visualizar
                    </MenuItem>

                    {!value.published ? (
                      <MenuItem
                        _hover={{ backgroundColor: '#302F37' }}
                        _focus={{ backgroundColor: '#302F37' }}
                        borderBlockEnd="1px solid #2C2C31"
                        onClick={async () => {
                          try {
                            await patchPublishExam.mutateAsync({
                              id: value.id,
                              published: !value.published,
                              trackId: value.trackId,
                            })

                            showToast({
                              title: 'Sucesso',
                              description: 'Simulado atualizado com sucesso.',
                              type: 'success',
                              duration: 2000,
                              mode: 'dark',
                            })
                          } catch (error) {
                            const message: AxiosError =
                              error?.response?.data?.errors
                                ?.map((error: AxiosError) => error.message)
                                ?.join(', ') ||
                              error?.response?.data ||
                              'Não foi possível atualizar o status do simulado.'

                            showToast({
                              title: 'Erro',
                              description: message.message
                                ? message.message
                                : message,
                              type: 'error',
                              duration: 2000,
                              mode: 'dark',
                            })
                          }
                        }}
                      >
                        Publicar
                      </MenuItem>
                    ) : (
                      <MenuItem
                        _hover={{ backgroundColor: '#302F37' }}
                        _focus={{ backgroundColor: '#302F37' }}
                        borderBlockEnd="1px solid #2C2C31"
                        onClick={async () => {
                          try {
                            await patchPublishExam.mutateAsync({
                              id: value.id,
                              published: !value.published,
                              trackId: value.trackId,
                            })

                            showToast({
                              title: 'Sucesso',
                              description: 'Simulado atualizado com sucesso.',
                              type: 'success',
                              duration: 2000,
                              mode: 'dark',
                            })
                          } catch (error) {
                            const message: AxiosError =
                              error?.response?.data?.errors
                                ?.map((error: AxiosError) => error.message)
                                ?.join(', ') ||
                              error?.response?.data ||
                              'Não foi possível atualizar o status do simulado.'

                            showToast({
                              title: 'Erro',
                              description: message.message
                                ? message.message
                                : message,
                              type: 'error',
                              duration: 2000,
                              mode: 'dark',
                            })
                          }
                        }}
                      >
                        Despublicar
                      </MenuItem>
                    )}

                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        push(`/simulation/edit/${value.id}`)
                      }}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      onClick={() => {
                        setdeleteModalId(value.id)
                        setConfirmDeleteModalOpen(true)
                      }}
                    >
                      Excluir
                    </MenuItem>
                  </MenuList>
                </Portal>

                <ModalDefaultTwoButton
                  size="sm"
                  isOpen={confirmDeleteModalOpen}
                  onClose={() => setConfirmDeleteModalOpen(false)}
                  onConfirmClick={async () => {
                    deleteModalId && handleDeleteExam(deleteModalId)
                    setConfirmDeleteModalOpen(false)
                    setdeleteModalId(undefined)
                  }}
                  confirmButtonIsLoading={deleteExam.isLoading}
                  title={'Tem certeza que deseja remover o simulado?'}
                  subTitle={
                    'Se continuar, o simulado será removido e esta ação é irreversível.'
                  }
                  isVerticalButton
                  modalOverlayProps={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
                />
              </Menu>
            </Box>
          )
        },
      },

      {
        label: 'Título do Simulado',
        fn(_, value) {
          return (
            <HStack minW={'150px'} noOfLines={1} w="100%">
              <Tooltip
                w="100%"
                label={`${value?.title} ${
                  value?.institution && `- ${value?.institution}`
                }`}
                hasArrow
              >
                <Text maxW={'240px'} noOfLines={1}>{`${value?.title} ${
                  value?.institution && ` (${value?.institution})`
                }`}</Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">% de acertos</Text>,
        accessor: 'percentage',
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={!value ? '-' : value.percentCorrectAnswers.toFixed(2)}
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value ? '-' : value.percentCorrectAnswers.toFixed(2)}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Questões</Text>,
        accessor: 'questions',
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={!value ? '-' : value.countQuestions}
                hasArrow
              >
                <Text noOfLines={1}>{!value ? '-' : value.countQuestions}</Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Publicado</Text>,
        accessor: 'published',
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={!value ? 'não publicado' : value.published}
                hasArrow
              >
                <HStack>
                  {!value || !value.published ? (
                    <>
                      <Text noOfLines={1}>não</Text>
                      <Circle color="#EB6830" fill="#EB6830" size="8px" />
                    </>
                  ) : (
                    <>
                      <Text noOfLines={1}>sim</Text>
                      <Circle color="#0DDF15" fill="#0DDF15" size="8px" />
                    </>
                  )}
                </HStack>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify="center">
            <Text textAlign="center">Data de criação</Text>
            {sort === '-created_at' ? (
              <Icon
                cursor="pointer"
                onClick={() => setSort('+created_at')}
                icon={'uil:sort-amount-down'}
              />
            ) : (
              <Icon
                cursor="pointer"
                onClick={() => setSort('-created_at')}
                icon={'uil:sort-amount-up'}
              />
            )}
          </HStack>
        ),
        accessor: 'created_at',
        fn(_, value) {
          return (
            <HStack w="100%" justifyContent={'center'}>
              <Tooltip
                label={
                  !value ? '-' : format(new Date(value.createdAt), 'dd/LL/yyyy')
                }
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value
                    ? '-'
                    : format(new Date(value.createdAt), 'dd/LL/yyyy')}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify="center">
            <Text textAlign="center">Código do Produto</Text>
          </HStack>
        ),
        accessor: 'examProductCode',
        fn(_, value) {
          return (
            <HStack w="100%" justifyContent={'center'} align="center">
              <Tooltip
                label={value.examProductCode ? value.examProductCode : '-'}
                hasArrow
              >
                <Text maxW="129px" noOfLines={1} textAlign="center">
                  {value.examProductCode ? value.examProductCode : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify="center">
            <Text textAlign="center">Termos PDF</Text>
          </HStack>
        ),
        fn(_, value) {
          return (
            <HStack w="100%" justifyContent={'center'} align="center">
              {value.examProductCode ? (
                <>
                  <IconButton
                    backgroundColor="#393940"
                    _hover={{
                      backgroundColor: '#393940',
                      outline: '1px solid #E296FD',
                    }}
                    borderRadius={'4px'}
                    _focus={{
                      boxShadow: 'none',
                      outline: 'none',
                    }}
                    w="24px"
                    h="24px"
                    minW={'24px !important'}
                    aria-label={'modal pdf'}
                    icon={<Icon icon={'fa6-regular:file-pdf'} />}
                    onClick={() => {
                      setModalPdfTerms(true)
                      setSelectedInstitution(value.institution)
                    }}
                  />
                  <ModalPreviewPDF
                    isOpen={modalPdfTerms}
                    onClose={() => setModalPdfTerms(false)}
                    institution={selectedInstitution}
                  />
                </>
              ) : (
                <Text>-</Text>
              )}
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Contrato do usuário</Text>,
        accessor: 'contractType',
        fn(value) {
          return (
            <Tooltip
              label={value ? capitalize(value) : '-'}
              hasArrow
              maxW="150px"
              w="100%"
            >
              <Text noOfLines={1} textAlign="center">
                {value ? capitalize(value) : '-'}
              </Text>
            </Tooltip>
          )
        },
      },
    ] as TableHeader<GetExams>[]
  }, [
    sort,
    confirmDeleteModalOpen,
    deleteExam.isLoading,
    push,
    patchPublishExam,
    showToast,
    deleteModalId,
    handleDeleteExam,
    modalPdfTerms,
    selectedInstitution,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack alignItems={'start'} py="2rem" w="100%" spacing={'2rem'}>
      <Navigation
        onButtonClick={() => {
          setIsNewModalOpen(true)
        }}
      />
      <Stack w="100%">
        <InputGroup
          maxW={{ base: '100%', sm: '300px' }}
          w="100%"
          position={'relative'}
        >
          <FieldInput
            value={search}
            paddingInlineEnd={'40px'}
            onChange={event => {
              setPage(1)
              setSearch(event.target.value)
            }}
            ref={ref}
            placeholder="Pesquisar por título do simulado"
          />
          <InputRightElement>
            <BiSearch color="#7D46B1" onClick={() => ref?.current?.focus()} />
          </InputRightElement>
        </InputGroup>
      </Stack>

      <List w="100%" background="#302F37" px="20px" py="10px" my="0px">
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={false}
          isFetching={false}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={dataGetExams?.data}
          isLoading={isLoadingGetExams}
          tableCellProps={{
            border: 'none !important',
            p: '8px 12px',
            _notFirst: {
              paddingLeft: '12px',
            },
            borderRight: '1px solid #60606C !important',
            _last: {
              borderRight: 'none !important',
              paddingLeft: '12px',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '16px',
            textTransform: 'none !important',
            p: '8px',
            _notFirst: { paddingLeft: '12px' },
            _last: { paddingLeft: '12px' },
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={dataGetExams?.meta.total ?? 0}
          registersPerPage={registersPerPage}
          fontSize="12px"
          mt="16px !important"
          px="8px"
        />
      </List>

      <ModalSimulationNew
        isOpen={isNewModalOpen}
        onClose={() => setIsNewModalOpen(false)}
      />
    </VStack>
  )
}
