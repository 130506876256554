// Vendors
import React from 'react'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'

// Styles
import { FieldInput, FieldInputProps } from '../FieldInput'

// Interfaces
export type FieldMaskProps = FieldInputProps &
  InputMaskProps & {
    name: string
  }

const FieldMaskBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldMaskProps
> = (props: FieldMaskProps, ref): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { name, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <FieldInput
      as={InputMask}
      id={name}
      ref={ref}
      name={name}
      placeholder="Digite ..."
      autoComplete="off"
      {...rest}
    />
  )
}

export const FieldMask = React.forwardRef(FieldMaskBase)
