import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export interface Advisor {
  id: number
  name: string
  email: string
}

export async function fetchAllAdvisors(): Promise<Advisor[]> {
  const { data } = await api.get<Advisor[]>('/admin/users/role?role=advisor')

  return data
}

export function useGetAllAdvisors() {
  return useQuery(['fetchAdvisorsList'], fetchAllAdvisors)
}
