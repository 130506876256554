import { Box, Flex, Table, Tbody } from '@chakra-ui/react'
import { ModalEditMonitor } from 'components/Modal/ModalEditMonitor'
import React, { useCallback, useEffect, useState } from 'react'
import { SearchSectionField } from './components/SearchSection'
import TrTableBody from './components/TrTableBodyMonitor'
import { TrTableHead } from './components/TrTableHeadMonitor'
import { useHistory } from 'react-router-dom'
import { NoDataTableError } from './components/NoDataTable'
import { Monitors, useGetAllMonitors } from './queries/use-fetch-monitors'
import { get } from 'lodash'
import Swal from 'sweetalert2'
import './swal.css'
import { useUpdateStatusMonitor } from './queries/use-patch-status'
import api from 'services/api'
import { FormatTrack, FormatYear } from './ModalHelper'
import { FieldSelect } from 'components/Form/FieldSelect'
import { Track } from 'contexts/track/interfaces'
import { useDebounce } from 'hooks/use-debounce'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'

export const MonitorList = () => {
  const { push } = useHistory()
  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isModalMonitorOpen, setIsModalMonitorOpen] = useState(false)

  const [tracks, setTracks] = useState<Track[]>()

  const [search, setSearch] = useState('')

  const [trackFilter, setTrackFilter] = useState<{
    label: string
    value: string | undefined
  }>({
    label: 'Todas as trilhas',
    value: undefined,
  })

  const [typeFilterMonitor, setTypeFilterMonitor] = useState<{
    label: string
    value: string | undefined
  }>({
    label: 'Todos os tipos',
    value: undefined,
  })

  const [filterActivity, setFilterActivity] = useState<{
    label: string
    value: 'ACTIVE' | 'INACTIVE'
  }>({
    label: 'Ativos',
    value: 'ACTIVE',
  })

  const [selectedRow, setSelectedRow] = useState<Monitors | undefined>(
    undefined,
  )

  const debouncedSearch = useDebounce(search, 800)

  const { data: ListMonitor, error: GetAllMonitorsError } = useGetAllMonitors({
    name: debouncedSearch ? debouncedSearch : undefined,
    monitorType: typeFilterMonitor?.value
      ? typeFilterMonitor?.value
      : undefined,
    trackId: trackFilter?.value ? Number(trackFilter?.value) : undefined,
    activityStatus: filterActivity?.value ? filterActivity?.value : undefined,
  })

  const statusMonitorMutate = useUpdateStatusMonitor()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    api
      .get<{ data: Track[] }>('/admin/tracks?limit=1000')
      .then(({ data: { data } }) => setTracks(data))
  }, [])

  const downloadReport = useCallback(
    async (type: string, filename: string) => {
      try {
        const { data } = await api.get(`/admin/extractions/students/${type}`, {
          responseType: 'blob',
        })
        const href = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `Report - ${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
      } catch (error) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível fazer o download do relatório no momento.'

        showToast({
          title: 'Erro',
          description: message?.message ? message?.message : message,
          type: 'error',
          duration: 4000,
          mode: 'dark',
        })
      }
    },
    [showToast],
  )

  const onEditOppositeStatus = async (
    monitorId: number,
    monitorStatus: 'OPEN' | 'CLOSED',
  ) => {
    try {
      await statusMonitorMutate.mutateAsync({
        monitorId: monitorId,
        monitorStatus: monitorStatus,
      })
      Swal.fire({
        buttonsStyling: false,
        title: 'Sucesso',
        text: 'Modificação realizada com sucesso!',
        icon: 'success',
      })
    } catch (err: any) {
      let errorMessage = 'O cadastro não pode ser concluído.'

      if (get(err, 'response.status') === 422) {
        const errors = get(err, 'response.data.errors')
        if (Array.isArray(errors)) {
          errorMessage = errors
            .map(error => `\n${error.field} - ${error.message}`)
            .join('')
        }
      }

      Swal.fire({
        buttonsStyling: false,
        title: 'Aviso',
        text: errorMessage,
        icon: 'error',
      })
    } finally {
      setIsModalMonitorOpen(false)
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  const trailOptions = () => {
    const options = tracks
      ? tracks.map(item => {
          return { label: item.name, value: item.id }
        })
      : [{}]

    return [{ label: 'Todas as trilhas', value: undefined }, ...options]
  }

  return (
    <Flex flexDir={'column'} gap="16px">
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        gap="16px"
        alignItems={{ base: 'flex-start', md: 'center' }}
        justifyContent="flex-end"
        mt="32px"
        w="full"
      >
        <DefaultButton
          label="Download Onboarding completo"
          w="full"
          fontSize="14px"
          onClick={() => downloadReport('onboarded', 'Onboarding Completo')}
        />
        <DefaultButton
          label="Download Onboarding incompleto"
          w="full"
          fontSize="14px"
          onClick={() =>
            downloadReport('onboarding-in-progress', 'Onboarding Incompleto')
          }
        />
        <DefaultButton
          label="Download Onboarding não iniciado"
          w="full"
          fontSize="14px"
          onClick={() =>
            downloadReport('not-onboarded', 'Onboarding não iniciado')
          }
        />
      </Flex>
      <Flex
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        flexDir={{ base: 'column', md: 'row' }}
        gap="16px"
        alignItems={{ base: 'flex-start', md: 'center' }}
        w="100%"
      >
        <Box w="full">
          <FieldSelect
            placeholder="Filtrar por Trilha"
            onChange={(e: { label: string; value: string | undefined }) =>
              setTrackFilter(e)
            }
            value={trackFilter}
            options={trailOptions()}
          />
        </Box>

        <Box w="full">
          <FieldSelect
            placeholder="Filtrar por Tipo de Monitor"
            onChange={(e: { label: string; value: string | undefined }) =>
              setTypeFilterMonitor(e)
            }
            value={typeFilterMonitor}
            options={[
              {
                label: 'Todos os tipos',
                value: undefined,
              },
              {
                label: 'PURCHASE',
                value: 'PURCHASE',
              },
              {
                label: 'TRIAL',
                value: 'TRIAL',
              },
            ]}
          />
        </Box>

        <Box w="full">
          <FieldSelect
            placeholder="Filtrar por Tipo Status"
            onChange={(e: { label: string; value: 'ACTIVE' | 'INACTIVE' }) =>
              setFilterActivity(e)
            }
            value={filterActivity}
            options={[
              {
                label: 'Ativos',
                value: 'ACTIVE',
              },
              {
                label: 'Inativos',
                value: 'INACTIVE',
              },
              {
                label: 'Todos',
                value: undefined,
              },
            ]}
          />
        </Box>
      </Flex>

      <Flex justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
        <SearchSectionField
          setInput={setSearch}
          search={search}
          onChange={e => {
            setSearch(e.target.value)
          }}
          maxWInputGroup="356px"
        />
      </Flex>

      {GetAllMonitorsError ? (
        <Box>
          <NoDataTableError />
        </Box>
      ) : (
        <>
          <Box
            maxH="508px"
            sx={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#D9D9D9',
                borderRadius: '17px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#60606C',
                borderRadius: '17px',
                height: '8px',
              },
            }}
            overflow="auto"
            w={'100%'}
          >
            <Table background={'#302F37'} borderRadius="6px">
              <TrTableHead />
              <Tbody>
                {ListMonitor?.map((item, index) => {
                  return (
                    <TrTableBody
                      key={`TableSectionData${index}`}
                      monitor={item?.name}
                      trail={item && FormatTrack(item)}
                      year={item && FormatYear(item)}
                      avatar={item?.avatar?.url}
                      orientator={
                        item.monitorDetail?.advisorUser &&
                        item.monitorDetail?.advisorUser.name
                      }
                      studentNumber={item?.studentsCount}
                      status={
                        item.monitorDetail?.monitor_status &&
                        item.monitorDetail?.monitor_status
                      }
                      onEditClickButton={() => {
                        setSelectedRow(item)
                        setIsModalMonitorOpen(true)
                      }}
                      sendToStudentsMonitorPage={() => {
                        push(`/monitors/${item.id}`)
                      }}
                      onClickClosedState={async () => {
                        onEditOppositeStatus(item.id, 'OPEN')
                      }}
                      onClickOpenState={async () => {
                        onEditOppositeStatus(item.id, 'CLOSED')
                      }}
                    />
                  )
                })}
              </Tbody>
            </Table>
          </Box>

          <ModalEditMonitor
            onClose={() => setIsModalMonitorOpen(false)}
            isOpen={isModalMonitorOpen}
            selectedRow={selectedRow}
          />
        </>
      )}
    </Flex>
  )
}
