import React, { useCallback, useEffect, useState } from 'react'
import { upperCase } from 'lodash'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Types
import { IncidenceApiResponse, IncidenceGroup, Incidence } from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import { Container, ChipsContainer, Chip, ChipText } from './styles'
import { Flex } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const Incidences: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [incidenceGroups, setIncidenceGroups] = useState<IncidenceGroup[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const downloadIncidenceFile = useCallback((incidenceId: number) => {
    api
      .get(`/admin/incidences/${incidenceId}`)
      .then(({ data }) => {
        const newWindow = window.open(data, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      })
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Não foi possível fazer o download do arquivo.',
          buttonsStyling: false,
        })
        console.trace(err)
      })
  }, [])

  const removeIncidenceFile = useCallback((incidenceId: number) => {
    api
      .delete(`/admin/incidences/${incidenceId}`)
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        Swal.fire({
          buttonsStyling: false,
          title: 'Aviso',
          text: 'Não foi possível remover o arquivo. Tente novamente.',
        })
      })
  }, [])

  const incidenceClickHandler = useCallback(
    (incidence: Incidence) => {
      Swal.fire({
        buttonsStyling: false,
        title: 'O que você deseja?',
        text: `${incidence.institution_name} - ${incidence.location_name}`,
        confirmButtonText: 'baixar',
        showDenyButton: true,
        denyButtonText: 'remover',
        showCloseButton: true,
      }).then(({ isConfirmed, isDenied }) => {
        if (isDenied) return removeIncidenceFile(incidence.id)
        if (isConfirmed) return downloadIncidenceFile(incidence.id)
      })
    },
    [downloadIncidenceFile, removeIncidenceFile],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    api
      .get<IncidenceApiResponse>('/admin/incidences')
      .then(({ data: { data } }) => {
        const newIncidenceGroups: IncidenceGroup[] = []
        data.forEach(incidence => {
          const index = newIncidenceGroups.findIndex(
            nig => nig.locationName === incidence.location_name,
          )
          if (index > -1) {
            newIncidenceGroups[index].incidences.push(incidence)
          } else {
            newIncidenceGroups.push({
              locationName: incidence.location_name,
              incidences: [incidence],
            })
          }
        })
        setIncidenceGroups(newIncidenceGroups)
      })
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Não foi possível buscar as incidências nesse momento',
          icon: 'error',
          buttonsStyling: false,
        })
        console.trace('Error on fetching incidences', err)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Incidência de assuntos"
          subTitle="Administre os arquivos disponíveis"
        />
        {incidenceGroups.map(({ locationName: cityName, incidences }) => (
          <React.Fragment key={`group-key-${cityName}`}>
            <SectionTitle>{upperCase(cityName)}</SectionTitle>
            <ChipsContainer>
              {incidences.map(incidence => (
                <Chip
                  key={`institution-name-key-${incidence.institution_name}`}
                  onClick={() => incidenceClickHandler(incidence)}
                >
                  <ChipText>{incidence.institution_name}</ChipText>
                </Chip>
              ))}
            </ChipsContainer>
          </React.Fragment>
        ))}
        {!incidenceGroups.length && (
          <>
            <SectionTitle>Incidências</SectionTitle>
            <p>Nenhum arquivo encontrado..</p>
          </>
        )}
        <Flex maxW="189px" my="16px">
          <DefaultButton
            w="full"
            label="Adicionar novo"
            onClick={() => history.push('/incidences/new')}
          />
        </Flex>
      </Container>
    </Layout>
  )
}

export default Incidences
