import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from 'services/api'
import { MetaResponse } from 'types/response'

type Params = {
  name?: string
  trackId?: number
  limit?: number
  page?: number
}

export type WhatsGroup = {
  id: number
  name: string
  mentoriaGroupLink: string
  membersLimit: number
  groupType: 'NEWBIE' | 'VETERAN'
  monitorUserId: number | null
  trackId: number
  createdAt: string
  updatedAt: string
  monitorUser: {
    id: number
    name: string
    canAccessSeren: boolean
  } | null
  track: {
    id: number
    name: string
  }
  totalStudents: number
}

type GetWhatsappGroupsResponse = {
  data: WhatsGroup[]
  meta: MetaResponse
}

export async function getWhatsappGroups(
  params: Params,
): Promise<GetWhatsappGroupsResponse> {
  const { data } = await api.get<GetWhatsappGroupsResponse>(
    '/admin/mentoria_groups',
    { params },
  )
  return data
}

export function useGetWhatsappGroups(params: Params) {
  return useQuery<GetWhatsappGroupsResponse, AxiosError>(
    ['get-whatsapp-groups', params],
    () => getWhatsappGroups(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
