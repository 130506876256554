import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

export interface ParamsCreateQuestion {
  statement: string
  alternativeA: string
  alternativeB: string
  alternativeC: string
  alternativeD: string
  alternativeE?: string
  correctAlternative: 'a' | 'b' | 'c' | 'd' | 'e'
  comment: string
  mockExamId: number
  commentImageFile: FileList | null
  imageHighlightFile: FileList | null
  essentialConceptImageFile: FileList | null
  essentialConcept: string
}

async function createQuestion(params: any) {
  await api.post('/admin/mock-exam-questions', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function useCreatequestion() {
  return useMutation(createQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-simulation-exams'])
      queryClient.invalidateQueries(['get-question-by-id'])
      queryClient.invalidateQueries(['get-simulation-by-id'])
    },
  })
}
