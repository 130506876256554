import { isAfter, parseISO } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'

// Contexts
import { Contract } from 'contexts/auth/interfaces'

// Features
import { Empty, ListTable } from 'features/ui'
import { ModalEditContract, useUser } from 'features/students'

// Types
import { TableHeader } from 'features/ui/list/list-table'
import { UserApiResponse } from 'features/students/types/user'

// Helpers
import { formatDate } from 'helpers/formatDate'

// Styles
import { Badge, ButtonGroup, IconButton, useDisclosure } from '@chakra-ui/react'
import { NotAllowedIcon, RepeatClockIcon, EditIcon } from '@chakra-ui/icons'

interface Props {
  user?: UserApiResponse
}

export function TabActions({ user }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose() {
      setSelectedContract(null)
    },
  })
  const {
    user: updateUser,
    isLoadingUser,
    getUserById,
    handleToggleContractStatus,
  } = useUser()

  const [selectedContract, setSelectedContract] = useState<Contract | null>(
    null,
  )

  function getContractStatus(
    finalDate: string,
    canceledAt?: string,
  ): 'Cancelado' | 'Finalizado' | 'Ativo' {
    if (canceledAt) {
      return 'Cancelado'
    }

    if (isAfter(new Date(), parseISO(finalDate))) {
      return 'Finalizado'
    }

    return 'Ativo'
  }

  const handleSelectContract = useCallback(
    (contract: Contract) => {
      setSelectedContract(contract)
      onOpen()
    },
    [onOpen],
  )

  const headers = useMemo(() => {
    return [
      {
        label: 'Tipo',
        accessor: 'type',
        fn(value) {
          const colorBadge = value === 'PURCHASE' ? 'green' : 'trial'

          return <Badge colorScheme={colorBadge}>{value}</Badge>
        },
      },
      {
        label: 'Status',
        fn: (_, data) => {
          return getContractStatus(data.final_date, data.canceled_at)
        },
      },
      {
        label: 'Trilha',
        accessor: 'track.name',
      },
      {
        label: 'Data de início',
        accessor: 'initial_date',
        fn(value) {
          return formatDate(value)
        },
      },
      {
        label: 'Data de término',
        accessor: 'final_date',
        fn(value) {
          return formatDate(value)
        },
      },
      {
        label: 'Ações',
        // eslint-disable-next-line react/display-name
        fn: (_, data) => {
          const status = getContractStatus(data.final_date, data.canceled_at)
          const canToggle = status !== 'Finalizado'
          const isActive = status === 'Ativo'

          return (
            <ButtonGroup>
              <IconButton
                disabled={!canToggle}
                aria-label="toggle user contract"
                colorScheme={isActive ? 'red' : 'green'}
                icon={isActive ? <NotAllowedIcon /> : <RepeatClockIcon />}
                onClick={() =>
                  handleToggleContractStatus(data.user_id, data.id)
                }
              />

              <IconButton
                ml={2}
                colorScheme="whiteAlpha"
                icon={<EditIcon />}
                aria-label="edit user contract"
                onClick={() => handleSelectContract(data)}
              />
            </ButtonGroup>
          )
        },
      },
    ] as TableHeader<Contract>[]
  }, [handleSelectContract, handleToggleContractStatus])

  if (!user || user.contracts.length === 0) {
    return <Empty message="Nenhum contrato para exibir." />
  }

  return (
    <>
      {selectedContract && (
        <ModalEditContract
          isOpen={isOpen}
          userId={user.id}
          onClose={onClose}
          getUserById={getUserById}
          contract={selectedContract}
        />
      )}

      <ListTable
        headers={headers}
        isLoading={isLoadingUser}
        data={updateUser?.contracts || user.contracts}
      />
    </>
  )
}
