import { transparentize } from 'polished'
import { css } from 'styled-components'

export const Scroll = css`
  &::-webkit-scrollbar {
    background: #2a2a2d;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${transparentize(0.94, '#dfe0eb')};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${transparentize(0.4, '#dfe0eb')};
    border-radius: 16px;
  }
`

export const ResponsiveContainer = css`
  @media (max-width: 800px) {
    padding-left: 16px;
    padding-right: 8px;
  }
`
