import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  overflow-x: hidden;
  position: relative;

  max-width: 1440px;
  width: 100vw;

  max-height: 1024px;
  height: 100vh;

  background: linear-gradient(71.48deg, #1d1d1f 0%, #28272d 98.51%);
  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);

  /* Mobile */
  @media (max-width: 1100px) {
  }

  /* Latpot */
  @media (min-width: 1100px) {
  }

  /* Extra Large */
  @media (min-width: 1440px) {
    border-radius: 16px;
    max-height: min(calc(100vh - 64px), 1024px);
  }
`
