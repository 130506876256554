import * as Yup from 'yup'

export const NewSubjectSchema = Yup.object().shape({
  name: Yup.string().required('O nome do tema é obrigatório.'),
  fieldId: Yup.number()
    .required('O campo é obrigatório.')
    .nullable()
    .transform((_, originalValue) => {
      if (originalValue) {
        return originalValue.value
      }

      return null
    }),
  trackId: Yup.number()
    .required('O campo é obrigatório.')
    .nullable()
    .transform((_, originalValue) => {
      if (originalValue) {
        return originalValue.value
      }

      return null
    }),
})
