import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import Header from 'components/Header'
import { schema } from './schema'
// interfaces
import { NewIncidenceFormInput, Track } from './interfaces'

// Services
import api from 'services/api'

// Styles
import { Container } from './styles'
import Layout from 'layouts/Logged'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldFile } from 'components/Form/FieldFile'
import { get } from 'lodash'
import { VStack } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'

interface AxiosErrorResponse {
  errors: Array<{
    args: {
      size: string
      extnames: Array<string>
    }
    size: string
    field: string
    message: string
  }>
}

const NewIncidence: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<NewIncidenceFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      incidence: null,
      institutionName: '',
      locationName: '',
      isFree: null,
      trackId: null,
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [availableTracks, setAvailableTracks] = useState<Track[]>([])
  const [isFetchingTracks, setIsFetchingTracks] = useState(true)

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    api
      .get<{ data: Track[] }>('/admin/tracks?limit=1000')
      .then(({ data: { data } }) => {
        setAvailableTracks(data)
        setIsFetchingTracks(false)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Callbacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const fileSubmitHandler = useCallback(
    (formData: any) => {
      if (!formData.incidence) return

      const fileMultipartFormData = new FormData()
      fileMultipartFormData.append('incidence', formData.incidence[0])
      fileMultipartFormData.append('locationName', formData.locationName)
      fileMultipartFormData.append('institutionName', formData.institutionName)
      fileMultipartFormData.append('trackId', formData.trackId)
      fileMultipartFormData.append('isFree', formData.isFree)

      api
        .post('admin/incidences', fileMultipartFormData)
        .then(() => {
          reset()
          Swal.fire({
            title: 'Sucesso',
            text: 'Arquivo adicionado!',
            icon: 'success',
            buttonsStyling: false,
            timer: 2000,
          })
        })
        .catch((err: AxiosError<AxiosErrorResponse>) => {
          const messageApiError =
            err.response &&
            err.response.data.errors.map(message => {
              return message.message
            })
          const convertedMessageAPIError = messageApiError?.toString()

          /* console.trace('Error uploading avatar image. ', err) */
          Swal.fire({
            title: 'Aviso',
            text: convertedMessageAPIError
              ? convertedMessageAPIError
              : 'Não foi possível submeter o novo arquivo.',
            icon: 'error',
            buttonsStyling: false,
          })
        })
    },
    [reset],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Incidência de assuntos"
          subTitle="Administre os arquivos disponíveis"
        />

        <VStack
          as="form"
          spacing={'1rem !important'}
          maxW="380px"
          pt="2rem"
          onSubmit={handleSubmit(fileSubmitHandler)}
        >
          <FieldFile
            fileName={get(watch('incidence'), '[0].name')}
            size="md"
            label="Upload do Arquivo"
            {...register('incidence')}
            type="file"
            allowedExtensions={['PDF', 'XLSX', 'DOCX', 'CFB']}
            error={errors?.incidence}
          />

          <FieldInputController
            name="locationName"
            placeholder="Localidade"
            control={control}
            error={errors?.locationName}
          />

          <FieldInputController
            name="institutionName"
            placeholder="Instituição"
            control={control}
            error={errors?.institutionName}
          />

          <FieldSelectController
            isLoading={isFetchingTracks}
            name="trackId"
            placeholder="Selecione uma trilha"
            options={availableTracks.map(track => ({
              label: track.name,
              value: track.id,
            }))}
            control={control}
            error={errors?.trackId as any}
          />

          <FieldSelectController
            name="isFree"
            placeholder="Selecione a disponibilidade"
            options={[
              { label: 'Para pagantes', value: false },
              { label: 'Para todos', value: true },
            ]}
            control={control}
            error={errors?.isFree as any}
          />

          <DefaultButton
            w="100%"
            label="Salvar"
            type="submit"
            isLoading={isSubmitting}
          />
        </VStack>
      </Container>
    </Layout>
  )
}

export default NewIncidence
