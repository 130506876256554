import api from 'services/api'
import { useMutation } from '@tanstack/react-query'

interface PatchStudentProps {
  mobilePhone?: string
  name?: string
  user_id: number
  email?: string
}

async function PatchStudent(params: PatchStudentProps) {
  await api.patch(`/admin/users/${params.user_id}`, {
    mobilePhone: params.mobilePhone,
    name: params.name,
    email: params.email,
  })
}

export function usePatchStudentData() {
  return useMutation(PatchStudent)
}
