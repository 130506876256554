import React from 'react'

import { AuthContextProvider } from './auth'
import { TrackContextProvider } from './track'
import { InterceptorProvider } from './interceptor'
import { StyledComponentsThemeProvider } from 'styles/ThemeProvider'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'styles/chakra-theme'

export const AppContextProvider: React.FC = ({ children }) => {
  return (
    <ChakraProvider resetCSS={false} theme={theme}>
      <StyledComponentsThemeProvider>
        <AuthContextProvider>
          <InterceptorProvider>
            <TrackContextProvider>{children}</TrackContextProvider>
          </InterceptorProvider>
        </AuthContextProvider>
      </StyledComponentsThemeProvider>
    </ChakraProvider>
  )
}

export default AppContextProvider
