import React from 'react'

import Header from 'components/Header'

// Styles
import Layout from 'layouts/Logged'
import { Container } from './styles'
import { MonitorList } from 'components/MonitorTable'

export const NewMonitors: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Monitores"
          subTitle="Principais informações os sobre monitores e cadastro de monitores com alunos."
          /* hasVideoOrientation={true}
          linkVideo="https://www.youtube.com/watch?v=t96mST_wzhE" */
        />
        <MonitorList />
      </Container>
    </Layout>
  )
}
