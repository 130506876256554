import styled from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-y: auto;

  max-width: 1440px;
  width: 100vw;

  max-height: 1024px;
  height: 100vh;

  background: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    ${({ theme: { colors } }) => colors.darkGray} 0%,
    ${({ theme: { colors } }) => colors.darkerGray} 100%,
    ${({ theme: { colors } }) => colors.darkerGray} 100%
  );

  box-shadow: 0px 60px 60px
    ${({ theme: { colors } }) => transparentize(0.75, colors.black)};

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;

    color: ${({ theme: { colors } }) => colors.lightGray};
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: 442px;
      margin-left: 32px;
    }
  }

  #bernardo-welcome {
    margin-left: 64px;
  }

  /* Mobile */
  @media (max-width: 1100px) {
    #bernardo-welcome {
      display: none;
    }
  }

  /* Latpot */
  @media (min-width: 1100px) {
    #logo {
      display: none;
    }

    > p {
      position: absolute;
      bottom: 56px;
    }
  }

  /* Extra Large */
  @media (min-width: 1440px) {
    border-radius: 16px;
    max-height: min(calc(100vh - 64px), 1024px);
  }
`
