import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'

import {
  FormLabel,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react'
import { FieldAsyncSelect, FieldSelectProps } from '.'

type FieldAsyncSelectController = FieldSelectProps & {
  name: string
  label?: string
  control: Control<any>
  error?: FieldError
  helperText?: string | React.ReactNode
}

export const FieldAsyncSelectController: React.FC<FieldAsyncSelectController> = ({
  name,
  label,
  error,
  control,
  helperText,
  defaultValue,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error} mt="0px !important">
      {label && (
        <FormLabel mb="8px" htmlFor={name}>
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="600"
            fontSize="16px"
            lineHeight="20px"
            color="#FFFFFF"
          >
            {label}
          </Text>
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => <FieldAsyncSelect {...rest} {...field} />}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
