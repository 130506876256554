import { Button, Flex, Text, Link } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { ImYoutube } from 'react-icons/im'
import './style.css'
type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size: Size
  children?: ReactNode
  CancelButtonClose: any
  linkVideo?: string
  onConfirmClick: () => void
} & ReactModalProps

const contentStyles = () => {
  return {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  }
}

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '252px',
    sm: '366px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalVideoOrientation = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size,
    children,
    CancelButtonClose,
    linkVideo,
    onConfirmClick,
    ...reactModalProps
  } = props

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          ...contentStyles(),
          width: getWidthBySize(size),
          padding: '34px',
          background: '#393940',
          border: '0px',
        },
        overlay: {
          zIndex: 999999999,
          backgroundColor: 'rgba(14, 14, 15, 0.40)',
        },
      }}
      {...reactModalProps}
    >
      <Flex w="100%" justifyContent={'center'} flexDir="column">
        <Flex justifyContent={'center'}>
          <ImYoutube size={'44px'} color="#E56000" />
        </Flex>

        <Flex mt="24px" flexDir="column">
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize={{ base: '16px', sm: '17.8px' }}
            color={'#FFFFFF'}
            mb="8px"
            textAlign={{ base: 'center', sm: 'unset' }}
          >
            Tem certeza que deseja continuar?
          </Text>
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            textAlign={'center'}
            px={{ base: '0px', sm: '2rem' }}
            fontSize="12px"
            lineHeight="15px"
            color="rgba(255, 255, 255, 0.8)"
          >
            Se continuar, você será direcionado para um vídeo no youtube de
            tutorial.
          </Text>
        </Flex>

        <Flex
          mt="24px"
          alignItems={'flex-end'}
          gap="12px"
          justifyContent={'center'}
        >
          <Button
            fontFamily="Mulish"
            font-weight="700"
            font-size="16px"
            color="#FFFFFF"
            height="38px"
            borderRadius="12px"
            onClick={CancelButtonClose}
            variant="ghost"
            maxW="116px"
            w="100%"
            border="1px solid #FFFFFF"
            _hover={{ backgroundColor: '#45454d' }}
          >
            Cancelar
          </Button>
          <Button
            _hover={{ backgroundColor: '#7d39be' }}
            /* target="_blank" */
            colorScheme="red"
            w="100%"
            borderRadius="12px"
            variant="outline"
            background={'#7D46B1'}
            border="1px solid #7D46B1"
            maxW={'124px'}
            height="38px"
            fontFamily="Mulish"
            font-weight="700"
            font-size="16px"
            color="#FFFFFF"
            onClick={onConfirmClick}
          >
            <Link
              href={linkVideo}
              textDecoration="none !important"
              target="_blank"
            >
              Continuar
            </Link>
          </Button>
        </Flex>
      </Flex>
      {children}
    </ReactModal>
  )
}
