import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from './Route'

import { ROLES } from 'constants/roles'

import LogIn from 'pages/LogIn'
import ValidateAccount from 'pages/ValidateAccount'

import ForgotPasswordNew from 'pages/ForgotPasswordNew'
import ForgotPasswordReset from 'pages/ForgotPasswordReset'

import Users from 'pages/Users'
import NewUser from 'pages/NewUser'
import Monitors from 'pages/Monitors'
import Engagement from 'pages/Engagement'
import UserDetail from 'pages/UserDetail'
import StudentReport from 'pages/StudentReport'
import MonitorStudents from 'pages/MonitorStudents'
import AdminMonitorStudents from 'pages/AdminMonitorStudents'

import Account from 'pages/Account'
import Contact from 'pages/Contact'
import Calendar from 'pages/Calendar'
import Dashboard from 'pages/Dashboard'
import Extractions from 'pages/Extractions'
import Notifications from 'pages/Notifications'

import Incidences from 'pages/Incidences'
import NewIncidence from 'pages/NewIncidence'

import Goals from 'pages/Goals'
import NewGoal from 'pages/NewGoal'

import ThemesNew from 'pages/Themes/new'
import ThemesList from 'pages/Themes/list'

import SubjectsNew from 'pages/Subjects/new'
import SubjectsList from 'pages/Subjects/list'
import { DashboardMonitor } from 'pages/Dashboard/monitor/DashboardMonitor'
import { UsersWithoutAccess } from 'pages/UsersWithoutAccess'
import { UsersGlobalMeta } from 'pages/UsersGlobalMeta'
import ListWithoutAccessPage from 'pages/Students/ListWithoutAccess'
import { NewMonitors } from 'pages/NewMonitors'
import { AdvisorTablePage } from 'pages/AdvisorTable'
import { KillerConcepts } from 'pages/KillerConcepts'

import SimulationPage from 'pages/Simulation'
import SimulationReviewPage from 'pages/Simulation/Review'
import SimulationEditPage from 'pages/Simulation/Edit'
import { NotificationsByStudents } from 'pages/NotificationsByStudent'
import Events from 'pages/Events'
import WhatsappGroups from 'pages/WhatsappGroups'

const Routes = () => (
  <React.Fragment>
    <Switch>
      {/* Public routes */}
      <Route path="/" exact component={LogIn} />
      <Route path="/validate_account" exact component={ValidateAccount} />
      <Route path="/forgot_password/reset" component={ForgotPasswordReset} />
      <Route path="/forgot_password/new" component={ForgotPasswordNew} />
      <Route path="/student/report" component={StudentReport} />

      {/* Private routes */}
      <Route
        exact
        isPrivate
        path="/dashboard"
        component={Dashboard}
        rolesAllowed={[ROLES.admin, ROLES.monitor, ROLES.advisor]}
      />
      <Route
        exact
        isPrivate
        path="/events"
        component={Events}
        rolesAllowed={[ROLES.admin, ROLES.support]}
      />
      <Route path="/calendar" exact component={Calendar} isPrivate />
      <Route path="/account" exact component={Account} isPrivate />
      <Route
        path="/notifications"
        rolesAllowed={[ROLES.admin]}
        exact
        component={Notifications}
        isPrivate
      />
      <Route
        path="/notifications/:notificationId"
        rolesAllowed={[ROLES.admin]}
        exact
        component={NotificationsByStudents}
        isPrivate
      />
      {/* <Route path="/contact" exact component={Contact} isPrivate /> */}

      <Route path="/incidences" exact component={Incidences} isPrivate />
      <Route path="/incidences/new" exact component={NewIncidence} isPrivate />

      <Route path="/users" exact component={Users} isPrivate />
      <Route
        exact
        isPrivate
        path="/users/without-access"
        component={UsersWithoutAccess}
      />
      <Route
        exact
        isPrivate
        path="/users/global-meta"
        component={UsersGlobalMeta}
      />
      {/* <Route path="/monitors" exact component={Monitors} isPrivate /> */}
      <Route path="/extractions" exact component={Extractions} isPrivate />
      <Route
        path="/monitors/:monitorUserId"
        component={AdminMonitorStudents}
        isPrivate
      />
      <Route
        path="/students/list-without-access"
        component={ListWithoutAccessPage}
        isPrivate
      />

      <Route path="/students" component={MonitorStudents} isPrivate />
      <Route path="/engagement" component={Engagement} isPrivate />
      <Route
        path="/users/new"
        exact
        component={NewUser}
        isPrivate
        rolesAllowed={[ROLES.admin]}
      />
      <Route path="/users/:userId" component={UserDetail} isPrivate />

      <Route path="/goals" exact component={Goals} isPrivate />
      <Route path="/goals/new" exact component={NewGoal} isPrivate />

      <Route path="/themes" exact component={ThemesList} isPrivate />
      <Route path="/themes/new" exact component={ThemesNew} isPrivate />

      <Route path="/subjects" exact component={SubjectsList} isPrivate />
      <Route path="/subjects/new" exact component={SubjectsNew} isPrivate />

      <Route
        path="/monitors"
        rolesAllowed={[ROLES.admin]}
        exact
        component={NewMonitors}
        isPrivate
      />
      <Route
        path="/whatsapp-groups"
        rolesAllowed={[ROLES.admin]}
        exact
        component={WhatsappGroups}
        isPrivate
      />

      <Route
        path="/advisorTable"
        exact
        component={AdvisorTablePage}
        isPrivate
      />

      <Route
        path="/killerConcepts"
        exact
        component={KillerConcepts}
        isPrivate
        rolesAllowed={[ROLES.admin, ROLES.support]}
      />

      <Route
        path="/simulation"
        rolesAllowed={[ROLES.admin, ROLES.support]}
        exact
        component={SimulationPage}
        isPrivate
      />
      <Route
        path="/simulation/edit/:id"
        rolesAllowed={[ROLES.admin, ROLES.support]}
        exact
        component={SimulationEditPage}
        isPrivate
      />
      <Route
        path="/simulation/review/:id"
        rolesAllowed={[ROLES.admin, ROLES.support]}
        exact
        component={SimulationReviewPage}
        isPrivate
      />

      {/* On not found */}
      <Redirect to="/" />
    </Switch>
  </React.Fragment>
)

export default Routes
