import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponse } from 'types/response'

export type GetExams = {
  id: number
  title: string
  countQuestions: string
  countCorrectAnswers: string
  percentCorrectAnswers: number
  createdAt: string
  published: boolean
  institution: string
  trackId: number
  examProductCode?: string
  contractType: string
}

export type GetExamsResponse = {
  meta: MetaResponse
  data: GetExams[]
}

type Params = {
  limit: number
  page: number
  sort?: string
  title?: string
}

export async function getExams(params: Params): Promise<GetExamsResponse> {
  const { limit, page, sort, title } = params
  const { data } = await api.get<GetExamsResponse>('/admin/mock-exams', {
    params: {
      limit,
      page,
      sort,
      title,
    },
  })

  return data
}

export function useGetExams(params: Params) {
  return useQuery(['get-simulation-exams', params], () => getExams(params), {
    refetchOnWindowFocus: false,
  })
}
