import React, { useEffect, useState } from 'react'
import {
  AspectRatio,
  Box,
  Heading,
  HStack,
  Progress,
  RadioGroup,
  Stack,
  Text,
  Image,
  VStack,
  Spinner,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { FieldRadio } from 'components/Form/FieldRadio'
import { useHistory, useParams } from 'react-router-dom'
import ErrorPage from '../error'
import Pagination from 'components/Pagination'
import { useGetExamsById } from '../mutations/use-get-exams-by-id'
import { useGetQuestionById } from '../mutations/use-get-question-by-id'
import { DefaultButton } from 'components/Buttons/DefaultButton'

export default function SimulationReview() {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [value, setValue] = useState('')
  const [activeQuestion, setActiveQuestion] = useState<number | null>(1)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const {
    data: exam,
    error: errorGetExamsById,
    isLoading: isLoadingGetExamsById,
  } = useGetExamsById({
    id,
  })

  const {
    data: question,
    isLoading: isLoadingGetQuestionById,
  } = useGetQuestionById({
    id: exam?.questions?.[activeQuestion! - 1]?.id
      ? exam?.questions?.[activeQuestion! - 1]?.id
      : undefined,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const handlePageChange = (page: number) => {
    if (!activeQuestion || !exam) return

    setActiveQuestion(page)
  }

  /*
  |-----------------------------------------------------------------------------
  | useEffect
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    setActiveQuestion(1)
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!Number(id)) {
    return <ErrorPage />
  }

  if (isLoadingGetExamsById || isLoadingGetQuestionById) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  if (exam?.questions.length === 0) {
    return (
      <>
        <HStack
          w="fit-content"
          my="1em"
          _hover={{ translateY: '1px', fontWeight: 600 }}
          as="button"
          onClick={() => push('/simulation')}
        >
          <Icon icon={'bi:arrow-left'} fontSize={'16px'} />
          <Text>Voltar para simulados</Text>
        </HStack>
        <VStack h="full" justifyContent="center">
          <Text fontWeight={500} fontSize={'16px'} lineHeight={'20px'}>
            Esse simulado ainda não tem questões cadastradas.
          </Text>
          <DefaultButton
            label="Cadastrar questões"
            variant="ghost"
            maxW={{ base: 'unset', sm: '258px' }}
            onClick={() => push(`/simulation/edit/${id}`)}
          />
        </VStack>
      </>
    )
  }

  return (
    <>
      <HStack
        w="fit-content"
        my="1em"
        _hover={{ translateY: '1px', fontWeight: 600 }}
        as="button"
        onClick={() => push('/simulation')}
      >
        <Icon icon={'bi:arrow-left'} fontSize={'16px'} />
        <Text>Voltar para simulados</Text>
      </HStack>

      <HStack my="2rem" w="100%" maxW={'706px'}>
        <Progress
          colorScheme={'brand'}
          value={
            (exam?.questions &&
              (activeQuestion! / exam?.questions?.length) * 100) ||
            1
          }
          h="5px"
          w="95%"
          borderRadius={'12px'}
          bg={'#2A2A30'}
        />

        <Text fontWeight={'400'} fontSize={'14px'}>{`${activeQuestion}/${
          exam?.questions && exam?.questions?.length
        }`}</Text>
      </HStack>

      <Box as="form" flexDir={'column'} w="full" maxW={'868px'}>
        <VStack align={'flex-start'}>
          <Heading
            fontWeight={700}
            fontFamily={'Mulish, sans-serif'}
            fontSize={'22px'}
            as={'h2'}
          >
            {exam?.title} ({exam?.institution})
          </Heading>

          <Text fontWeight={500} fontSize={'16px'}>
            {activeQuestion}. {question?.statement}
          </Text>

          <HStack pb="1rem">
            <Text fontWeight={500} fontSize={'16px'} lineHeight={'20px'}>
              Alternativa {question?.correct_alternative} - Correta{' '}
            </Text>
            <Icon
              icon={'akar-icons:circle-check-fill'}
              fontSize={'16px'}
              color="#0DDF15"
            />
          </HStack>
        </VStack>

        {question?.image_highlight && (
          <AspectRatio my="1rem" ratio={16 / 9} sx={{ objectFit: 'contain' }}>
            <Image
              src={question.image_highlight}
              alt="img"
              sx={{ objectFit: 'contain !important' }}
            />
          </AspectRatio>
        )}

        <RadioGroup value={value} onChange={setValue} position="relative">
          <HStack
            _first={{ mt: '0px' }}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'18px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'a' ? '#0DDF15' : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio value="a" />
            <Text>a) {question?.alternative_a}</Text>
          </HStack>

          <HStack
            _first={{ mt: '0px' }}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'18px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'b' ? '#0DDF15' : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio value="b" />
            <Text>b) {question?.alternative_b}</Text>
          </HStack>

          <HStack
            _first={{ mt: '0px' }}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'18px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'c' ? '#0DDF15' : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio value="c" />
            <Text>c) {question?.alternative_c}</Text>
          </HStack>

          <HStack
            _first={{ mt: '0px' }}
            mt={'1rem'}
            bg={'#2A2A30'}
            borderRadius={'8px'}
            p={'8px'}
            spacing={'18px'}
            border={'1px solid'}
            borderColor={
              question?.correct_alternative === 'd' ? '#0DDF15' : 'transparent'
            }
            cursor={'pointer'}
          >
            <FieldRadio value="d" />
            <Text>d) {question?.alternative_d}</Text>
          </HStack>
          {question?.alternative_e && (
            <HStack
              _first={{ mt: '0px' }}
              mt={'1rem'}
              bg={'#2A2A30'}
              borderRadius={'8px'}
              p={'8px'}
              spacing={'18px'}
              border={'1px solid'}
              borderColor={
                question?.correct_alternative === 'e'
                  ? '#0DDF15'
                  : 'transparent'
              }
              cursor={'pointer'}
              onClick={() => {
                setValue('e')
              }}
            >
              <FieldRadio value="e" />
              <Text>e) {question?.alternative_e}</Text>
            </HStack>
          )}
        </RadioGroup>

        {question?.comment || question?.comment_image ? (
          <Box mt="2rem">
            <Heading
              fontWeight={700}
              fontFamily={'Mulish, sans-serif'}
              fontSize={'18px'}
            >
              Comentário da questão
            </Heading>

            <Text
              mt="1rem"
              fontWeight={500}
              fontSize={'16px'}
              lineHeight={'20px'}
            >
              {question?.comment}
            </Text>

            {question?.comment_image && (
              <AspectRatio
                my="1rem"
                ratio={16 / 9}
                sx={{ objectFit: 'contain' }}
              >
                <Image
                  src={question?.comment_image}
                  alt="img"
                  sx={{ objectFit: 'contain !important' }}
                />
              </AspectRatio>
            )}
          </Box>
        ) : null}

        {question?.essential_concept || question?.image_essential_concept ? (
          <Box my="1.5rem" border={'1px solid #FFFFFF'} borderRadius={'4px'}>
            <Box p={'15px'}>
              <Heading
                fontWeight={700}
                fontFamily={'Mulish, sans-serif'}
                fontSize={'18px'}
              >
                Conceito Matador
              </Heading>
              <Text
                mt="24px"
                fontWeight={500}
                fontSize={'16px'}
                lineHeight={'20px'}
              >
                {question?.essential_concept}
              </Text>{' '}
            </Box>

            {question?.image_essential_concept && (
              <AspectRatio
                my="1rem"
                ratio={16 / 9}
                sx={{
                  objectFit: 'contain',
                }}
              >
                <Image
                  src={question?.image_essential_concept}
                  alt="img"
                  sx={{ objectFit: 'contain !important' }}
                />
              </AspectRatio>
            )}
          </Box>
        ) : null}

        <Stack
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: '0.5rem', sm: '1rem' }}
          justify={'flex-end'}
          my={'16px'}
        >
          <DefaultButton
            w="full"
            label="Voltar"
            variant="ghost"
            disabled={activeQuestion === 1}
            maxW={{ base: 'unset', sm: '258px' }}
            onClick={() => {
              if (!activeQuestion && exam?.questions) {
                return setActiveQuestion(exam?.questions?.length - 1)
              }

              return setActiveQuestion(activeQuestion =>
                activeQuestion && activeQuestion > 0
                  ? activeQuestion - 1
                  : null,
              )
            }}
          />
          <DefaultButton
            label="Avançar"
            w="full"
            maxW={{ base: 'unset', sm: '278px' }}
            disabled={activeQuestion === exam?.questions.length}
            onClick={() => {
              if (!exam?.questions?.length || !activeQuestion) return

              if (activeQuestion === exam.questions.length! + 1) {
                return
              }

              if (activeQuestion === exam.questions.length) {
                setActiveQuestion(activeQuestion => activeQuestion! + 1)
                return
              }

              return setActiveQuestion(activeQuestion => activeQuestion! + 1)
            }}
          />
        </Stack>
        <Pagination
          currentPage={activeQuestion ?? 1}
          onPageChange={handlePageChange}
          totalCountOfRegisters={exam?.questions?.length! ?? 0}
          registersPerPage={1}
          showRegistersPerPage={false}
          fontSize="12px"
          mt="16px !important"
          px="8px"
        />
      </Box>
    </>
  )
}
