// interfaces
export interface FormData {
  monitor: {
    label: string
    value: number
  } | null

  trail:
    | {
        label: string | undefined
        value: number | undefined
      }[]
    | null

  year:
    | {
        label: number | undefined
        value: number | undefined
      }[]
    | null

  orientator: {
    label: string | undefined
    value: number | undefined
  } | null
  mentoriaGroupIds: Array<{
    label: string | undefined
    value: number | undefined
  }> | null
  monitorVideoLinks: string
  activityStatus: boolean
}

// const

export const RESET_FORM = {
  monitor: null,
  trail: null,
  year: null,
  orientator: null,
  whatsLink: '',
  mentoriaGroupIds: null,
  monitorVideoLinks: '',
  activityStatus: false,
}
