export enum ActionTypes {
  FILTER_MONITOR = 'FILTER_MONITOR',
  CHANGE_PAGE = 'CHANGE_PAGE',
  FILTER_BY_DAYS_WITHOUT_ACCESS = 'FILTER_BY_DAYS_WITHOUT_ACCESS',
}

export function filterByMonitorAction(monitorId: number) {
  return {
    type: ActionTypes.FILTER_MONITOR,
    payload: {
      monitorId,
    },
  }
}

export function onChangePageAction(page: number) {
  return {
    type: ActionTypes.CHANGE_PAGE,
    payload: {
      page,
    },
  }
}

export function filterByDaysWithoutAccessAction(daysWithoutAccess: number) {
  return {
    type: ActionTypes.FILTER_BY_DAYS_WITHOUT_ACCESS,
    payload: {
      daysWithoutAccess,
    },
  }
}
