import { VStack, Text, Circle } from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'

import React from 'react'
import { BsExclamationLg } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'

export default function ErrorPage() {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { goBack } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack h="full" alignItems={'center'} justifyContent={'center'}>
      <Circle size={'35.6px'} border={'4px solid #7D46B1'}>
        <BsExclamationLg color="#7D46B1" />
      </Circle>

      <Text>Simulado não encontrado</Text>

      <DefaultButton label={'Retornar '} onClick={goBack} />
    </VStack>
  )
}
