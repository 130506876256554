import * as Yup from 'yup'

export const NewUserSchema = Yup.object().shape({
  roles: Yup.array()
    .transform(value => {
      if (!value) return

      return value.map((item: { value: string }) => item.value)
    })
    .min(1, 'Campo obrigatório')
    .required('Campo obrigatório'),
  name: Yup.string()
    .min(5, 'Deve possui mínimo de 5 caracteres')
    .max(80)
    .required('Campo obrigatório'),
  mobilePhone: Yup.string()
    .required('Campo obrigatório')
    .matches(/\+\d{1,4}\s?\(?\d{1,9}\)?\s?\d{1,9}/gm, {
      message: 'Formato inválido. Use +55 00 000000000',
    }),
  email: Yup.string()
    .email('Por favor informe um e-mail válido.')
    .required('Campo obrigatório'),
  cpf: Yup.string()
    .optional()
    .when('roles', {
      is: (value: number[]) => {
        // student role
        if (value?.includes(3)) {
          return true
        }
      },
      then: Yup.string()
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
          {
            message: 'Formato inválido. Use um número de 11 dígitos.',
          },
        )
        .required('Campo obrigatório'),
    }),
  productCode: Yup.string()
    .optional()
    .when('roles', {
      is: (value: number[]) => {
        // student role
        if (value?.includes(3)) {
          return true
        }
      },
      then: Yup.string()
        .transform((item: { label: string; value: string }) => {
          if (!item) return

          return item?.value
        })
        .required('Selecione um plano'),
    }),
})
