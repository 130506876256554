import React from 'react'

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import { FieldDate } from 'components/Form/FieldDate'
import { Controller, useForm } from 'react-hook-form'
import api from 'services/api'
import Swal from 'sweetalert2'
import { format } from 'date-fns'

interface IModalFilterDateProps {
  isOpen: boolean
  onClose: () => void
}

interface IFormData {
  startDate: Date
  endDate: Date
}

export default function ModalFilterDate({
  isOpen,
  onClose,
}: IModalFilterDateProps) {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormData>()

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const onSubmit = React.useCallback(
    (data: IFormData) => {
      const { startDate, endDate } = data

      const start = format(startDate, 'yyyy-MM-dd')
      const end = format(endDate, 'yyyy-MM-dd')

      api
        .get(
          `/admin/extractions/students?onlyTrial=true&startDate=${start}&endDate=${end}`,
          { responseType: 'blob' },
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'trial.csv')
          document.body.appendChild(link)
          link.click()

          handleClose()
        })
        .catch(err => {
          Swal.fire({
            title: 'Aviso',
            text: 'Não foi possível fazer o download do arquivo.',
            buttonsStyling: false,
          })
          console.trace(err)
        })
    },
    [handleClose],
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Exportar alunos com status trial</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack>
            <Box>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: 'Data de início obrigatório',
                }}
                render={({ field }) => (
                  <FieldDate
                    label="Data de início"
                    name={field.name}
                    selected={field.value}
                    startDate={field.value}
                    endDate={watch('endDate')}
                    onChange={field.onChange}
                    error={errors.startDate}
                    isRequired
                  />
                )}
              />
            </Box>

            <Box flex="1">
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: 'Data de término obrigatório',
                }}
                render={({ field }) => (
                  <FieldDate
                    label="Data de término"
                    name={field.name}
                    minDate={watch('startDate')}
                    startDate={watch('startDate')}
                    disabled={!watch('startDate')}
                    endDate={field.value}
                    selected={field.value}
                    error={errors.endDate}
                    onChange={field.onChange}
                    isRequired
                  />
                )}
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            color="blackAlpha.800"
            variant="outline"
            mr={3}
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button
            color="whiteAlpha.900"
            type="submit"
            bgColor="brand.600"
            transition="all 0.2s"
            isLoading={isSubmitting}
            _active={{ filter: 'brightness(1)' }}
            _hover={{ filter: 'brightness(0.85)' }}
          >
            Baixar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
