import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'
import { Notification } from './use-get-notifications'

interface Params {
  notificationId: number
  title: string
  message: string
  urlLink: string
  startsAt: string
  expiresAt: string
}

async function updateNotification(
  params: Params,
): Promise<Omit<Notification, 'createdByUser' | 'studentCount'>> {
  const response = await api.put(
    `/admin/notifications/${params.notificationId}`,
    params,
  )

  return response.data
}

export function useUpdateNotification() {
  return useMutation(updateNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-notifications'])
    },
  })
}
