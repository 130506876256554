import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable, { Filter } from 'material-table'

// Providers
import { localization } from 'providers/material-table/localization'
import { options } from 'providers/material-table/options'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Types
import { Theme, UsersApiResponse } from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import { ClearButton, Container, TableContainer } from './styles'

const Themes: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isLoading, setIsLoading] = useState(false)
  const [themes, setThemes] = useState<Theme[]>([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalThemes, setTotalThemes] = useState(0)
  const [filters, setFilters] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchThemes = useCallback(
    (page: number, limit: number, filters: string) => {
      setIsLoading(true)
      api
        .get<UsersApiResponse>(
          `/admin/themes?page=${page}&limit=${limit}${filters}`,
        )
        .then(({ data }) => {
          setThemes(data.data)
          setTotalThemes(data.meta.total)
        })
        .catch(console.trace)
        .finally(() => setIsLoading(false))
    },
    [],
  )

  const newFiltersHandler = useCallback((newFilters: Filter<Theme>[]) => {
    setCurrentPage(1)
    setFilters(
      newFilters
        .map(
          ({ column: { field }, operator, value }) =>
            `&${field}${operator}${value}`,
        )
        .join(''),
    )
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    fetchThemes(currentPage, perPage, filters)
  }, [currentPage, fetchThemes, filters, perPage])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Temas"
          subTitle="Lista completa de temas da plataforma"
        />
        <SectionTitle>Temas</SectionTitle>
        <TableContainer>
          <MaterialTable
            title="Lista de temas"
            localization={localization}
            // editable={{
            //   onRowDelete: async d => removeTheme(d.id),
            // }}
            editable={{
              onRowDelete: async rowData => {
                await api.delete(`/admin/themes/${rowData.id}`)
                location.reload()
              },
            }}
            columns={[
              {
                title: 'Nome',
                field: 'name',
                type: 'string',
                resizable: true,
              },
              {
                title: 'Grande Área',
                field: 'field.name',
                type: 'string',
                resizable: true,
                filtering: false,
              },
              {
                title: 'Trilha',
                field: 'field.track.name',
                type: 'string',
                resizable: true,
                filtering: false,
              },
            ]}
            page={currentPage - 1}
            totalCount={totalThemes}
            onChangePage={(newPage, perPage) => {
              setCurrentPage(newPage + 1)
              setPerPage(perPage)
            }}
            onChangeRowsPerPage={newPerPage => setPerPage(newPerPage)}
            // onRowClick={(_, rowData) => {
            //   history.push(`/users/${rowData?.id}`)
            // }}
            onFilterChange={newFiltersHandler}
            data={themes}
            isLoading={isLoading}
            options={options}
          />
          {filters.length > 0 && (
            <ClearButton onClick={() => setFilters('')}>
              limpar filtros
            </ClearButton>
          )}
        </TableContainer>
      </Container>
    </Layout>
  )
}

export default Themes
