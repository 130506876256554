import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  Text,
  Button,
  VStack,
} from '@chakra-ui/react'
import { List } from 'features/ui/list'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import React, { useMemo, useState } from 'react'
import { Icon } from '@iconify/react'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import { MoreVertical } from 'react-feather'
import { Link } from 'react-router-dom'
import Pagination from 'components/Pagination'
import { ListHeader } from 'features/ui'
import { format } from 'date-fns'
import ModalNewNotification from './modal/ModalNewNotification'
import { useGetNotifications } from './mutations/use-get-notifications'
import { Notification } from './mutations/use-get-notifications'
import { useDeleteNotification } from './mutations/use-mutate-delete-notification'
import { AxiosError } from 'axios'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import ModalEditNotification from './modal/ModalEditNotification'
import { pt } from 'date-fns/locale'

export default function NotificationTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [expiresFilter, setExpiresFilter] = useState<
    '+expires_at' | '-expires_at' | undefined
  >('+expires_at')
  const [startsAtFilter, setStartsAtFilter] = useState<
    '+starts_at' | '-starts_at' | undefined
  >(undefined)
  const [modalNotification, setModalNotification] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedRow, setSelectedRow] = useState<Notification | undefined>(
    undefined,
  )
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const [modalEditNotification, setModalEditNotification] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()
  const {
    data: notificationData,
    isLoading: isLoadingNotification,
    isFetching: isFetchingNotification,
  } = useGetNotifications({
    page: page,
    limit: 6,
    sort: expiresFilter
      ? expiresFilter
      : startsAtFilter
      ? startsAtFilter
      : undefined,
  })
  const deleteNotification = useDeleteNotification()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: <Text textAlign="center">Ações</Text>,
        fn(_, value) {
          return (
            <Box textAlign="center">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MoreVertical size="20px" color="#7D46B1" />}
                  bg="#393940"
                  height={'26px'}
                  width={'26px'}
                  minW={'26px !important'}
                  p="0px"
                />
                <Portal>
                  <MenuList
                    bg="#393940"
                    color="#ffffff"
                    border="1px solid #7D46B1"
                    minW="fit-content"
                    py="0px"
                  >
                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        setSelectedRow(value)
                        setModalConfirmDelete(true)
                      }}
                    >
                      Excluir
                    </MenuItem>

                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        setSelectedRow(value)
                        setModalEditNotification(true)
                      }}
                    >
                      Editar
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          )
        },
      },
      {
        label: 'Título da Notificação',
        fn(_, value) {
          return (
            <HStack minW={'150px'} noOfLines={1} w="100%">
              <Tooltip w="100%" label={`${value?.title}`} hasArrow>
                <Text maxW={'240px'} noOfLines={1}>
                  {value?.title ? `${value?.title}` : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Escrito por</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={
                  !value?.createdByUser?.name ? '-' : value?.createdByUser?.name
                }
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value?.createdByUser?.name
                    ? '-'
                    : value?.createdByUser?.name}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Alunos Notificados</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Link to={`/notifications/${value?.id}`}>
                <Button
                  p="0px 8px"
                  h="28px"
                  borderRadius={'4px'}
                  backgroundColor="#7D46B1"
                  fontSize="12px"
                  _hover={{ backgroundColor: '#703e9e' }}
                  _active={{ backgroundColor: '#703e9e' }}
                  noOfLines={1}
                >
                  {!value ? '-' : value?.studentsCount}
                </Button>
              </Link>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify="center">
            <Text textAlign="center">Disparo</Text>
            {startsAtFilter === '-starts_at' ? (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setExpiresFilter(undefined)
                  setStartsAtFilter('+starts_at')
                }}
                icon={'uil:sort-amount-down'}
              />
            ) : (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setExpiresFilter(undefined)
                  setStartsAtFilter('-starts_at')
                }}
                icon={'uil:sort-amount-up'}
              />
            )}
          </HStack>
        ),
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={
                  !value.starts_at
                    ? '-'
                    : // eslint-disable-next-line quotes
                      format(new Date(value.starts_at), "dd/MM/yyyy 'às' HH:mm")
                }
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value.starts_at
                    ? '-'
                    : format(
                        new Date(value.starts_at),
                        // eslint-disable-next-line quotes
                        "dd/MM/yyyy 'às' HH:mm",
                      )}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify="center">
            <Text textAlign="center">Expira em</Text>
            {expiresFilter === '-expires_at' ? (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setStartsAtFilter(undefined)
                  setExpiresFilter('+expires_at')
                }}
                icon={'uil:sort-amount-down'}
              />
            ) : (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setStartsAtFilter(undefined)
                  setExpiresFilter('-expires_at')
                }}
                icon={'uil:sort-amount-up'}
              />
            )}
          </HStack>
        ),
        accessor: 'expires_at',
        fn(value) {
          return (
            <HStack w="100%" justifyContent={'center'}>
              <Tooltip
                label={
                  !value
                    ? '-'
                    : // eslint-disable-next-line quotes
                      format(new Date(value), "dd/MM/yyyy 'às' HH:mm", {
                        locale: pt,
                      })
                }
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value
                    ? '-'
                    : // eslint-disable-next-line quotes
                      format(new Date(value), "dd/MM/yyyy 'às' HH:mm", {
                        locale: pt,
                      })}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
    ] as TableHeader<Notification>[]
  }, [expiresFilter, startsAtFilter])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack spacing="2rem" mt="2rem" align="flex-start">
      <DefaultButton
        label="Nova Notificação"
        leftIcon={<Icon icon="mdi-light:bell" />}
        onClick={() => setModalNotification(true)}
      />

      <ModalNewNotification
        isOpen={modalNotification}
        onClose={() => setModalNotification(false)}
      />

      <ModalEditNotification
        isOpen={modalEditNotification}
        onClose={() => setModalEditNotification(false)}
        notification={selectedRow}
      />

      <ModalDefaultTwoButton
        isOpen={modalConfirmDelete}
        subTitle="Tem certeza que deseja excluir esta notificação?"
        title="Excluir notificação"
        onClose={() => setModalConfirmDelete(false)}
        confirmButtonIsLoading={deleteNotification.isLoading}
        onConfirmClick={async () => {
          if (!selectedRow) return

          try {
            await deleteNotification.mutateAsync({
              notificationId: selectedRow.id,
            })

            showToast({
              title: 'Sucesso',
              description: 'Notificação deletada com sucesso.',
              type: 'success',
              duration: 2000,
              mode: 'dark',
            })

            setModalConfirmDelete(false)
          } catch (error) {
            const message: AxiosError =
              error?.response?.data?.errors
                ?.map((error: AxiosError) => error.message)
                ?.join(', ') ||
              error?.response?.data ||
              'Não foi possível deletar a notificação.'

            showToast({
              title: 'Erro',
              description: message?.message ? message?.message : message,
              type: 'error',
              duration: 2000,
              mode: 'dark',
            })
          }
        }}
      />

      <List
        background="#302F37"
        maxW="800px"
        w="full"
        px="20px"
        py="10px"
        my="0px"
      >
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={isLoadingNotification}
          isFetching={isFetchingNotification}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={notificationData?.data}
          isLoading={isLoadingNotification}
          tableCellProps={{
            border: 'none !important',
            p: '8px 12px',
            borderRight: '1px solid rgba(255, 255, 255, 0.06) !important',
            _last: {
              borderRight: 'none !important',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '14px',
            textTransform: 'none !important',
            p: '8px 12px',
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={notificationData?.meta?.total || 0}
          registersPerPage={6}
          fontSize="12px"
          mt="16px !important"
          px="8px"
          buttonProps={{
            fontSize: '12px',
            fontWeight: '600',
            minH: '6',
            h: '6',
            w: '6',
            minW: '6',
          }}
        />
      </List>
    </VStack>
  )
}
