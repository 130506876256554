import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useParams, useHistory } from 'react-router-dom'

// Components
import Header from 'components/Header'

// Services
import api from 'services/api'

// Types
import { Monitor } from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import { Container, ButtonContainer } from './styles'
import { ListStudents } from 'features/students'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const Engagement: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { monitorUserId } = useParams<{ monitorUserId?: string }>()
  const { replace } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [monitor, setMonitor] = useState<Monitor>()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!monitorUserId) return

    api
      .get(`/admin/users/${monitorUserId}`)
      .then(({ data }) => {
        setMonitor(data)
      })
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Não foi possível buscar o monitor nesse momento',
          icon: 'error',
          buttonsStyling: false,
        })
        console.trace('Error on fetching monitor', err)
      })
  }, [monitorUserId])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title={monitor?.name && `Alunos de ${monitor?.name}`}
          subTitle="Lista completa de alunos"
        />

        <ButtonContainer>
          <DefaultButton
            maxW="190px"
            w="full"
            label="Voltar"
            onClick={() => replace('/monitors')}
          />
        </ButtonContainer>

        <ListStudents />
      </Container>
    </Layout>
  )
}

export default Engagement
