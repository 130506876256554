import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

type Params = {
  institution: string
}

export async function getPdfByInstitution(params: Params): Promise<string> {
  if (!params.institution) {
    return ''
  }

  const { data } = await api.get<string>(
    `/admin/institutions/${params.institution}/download`,
  )

  return data
}

export function useGetPdfByInstitution(params: Params) {
  return useQuery(
    ['get-pdf-by-institution', params],
    () => getPdfByInstitution(params),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
