import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Schemas
import { NewGoalSchema } from './schemas'

// Constants
import { MONTHS } from 'constants/months'

// Services
import api from 'services/api'

// Styles
import { Container, Content } from './styles'
import Layout from 'layouts/Logged'

// Interfaces
import {
  NewGoalFormDataInput,
  NewGoalFormDataOutput,
  Track,
} from './interfaces'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import { Flex } from '@chakra-ui/react'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'
import { useCreateGoal } from './mutations/use-mutate-create-goal'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { DefaultButton } from 'components/Buttons/DefaultButton'

interface CalcGoalAchived {
  token: string
  minusMonths: number
}

const NewGoal: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const RESET_FORM: NewGoalFormDataInput = useMemo(() => {
    return {
      trackId: null,
      trialYear: null,
      year: null,
      month: null,
      correctAnswersPercentage: '',
      numberOfQuestions: '',
      studyMinutes: '',
      intendedSpecialty: null,
      studentSeniority: null,
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const showToast = useToastMessage()

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<NewGoalFormDataInput>({
    resolver: yupResolver(NewGoalSchema),
    defaultValues: RESET_FORM,
    shouldUnregister: true,
  })

  const { data: trackAll } = useGetAllTracks()
  const createGoalMutation = useCreateGoal()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const goToGoalsList = useCallback(() => {
    history.push('/goals')
  }, [history])

  const onSubmit = async (data: NewGoalFormDataOutput) => {
    const isRCGESelected =
      getValues('trackId')?.label.trim().replaceAll(' ', '') === 'R+CGE'
    const isRCMSelected =
      getValues('trackId')?.label.trim().replaceAll(' ', '') === 'R+CM'

    try {
      await createGoalMutation.mutateAsync({
        ...data,
        intendedSpecialty:
          isRCGESelected || isRCMSelected ? data.intendedSpecialty : 'Outros',
      })

      await api.post<{
        data: CalcGoalAchived
      }>('/admin/users/calc_goal_achieved', {
        token: 'CALC_STUDENT_GOAL_ACHIEVED_TOKEN',
        minusMonths: 1,
      })

      showToast({
        title: 'Sucesso',
        description: 'Meta criada com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset()
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível criar a meta no momento.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Cadastro de Metas"
          subTitle="Insira novas metas por trilha e ano de prova"
        />
        <Content>
          <SectionTitle>Listagem</SectionTitle>
          <DefaultButton maxW="189px" label="Exibir" onClick={goToGoalsList} />
          <SectionTitle>Cadastro</SectionTitle>

          <Flex
            flexDir="column"
            gap="16px"
            as="form"
            onSubmit={handleSubmit(onSubmit as any)}
            maxW="380px"
          >
            <FieldSelectController
              placeholder="Selecione a trilha de estudo"
              name="trackId"
              control={control}
              options={trackAll?.data?.map(item => {
                return {
                  label: item.name,
                  value: item.id,
                }
              })}
              error={errors.trackId as any}
            />

            {(watch('trackId')?.label.trim().replaceAll(' ', '') === 'R+CGE' ||
              watch('trackId')?.label.trim().replaceAll(' ', '') ===
                'R+CM') && (
              <FieldSelectController
                placeholder="Selecione a especialidade"
                name="intendedSpecialty"
                control={control}
                options={
                  watch('trackId')?.label.trim().replaceAll(' ', '') === 'R+CGE'
                    ? [
                        {
                          label: 'Urologia',
                          value: 'Urologia',
                        },
                        {
                          label: 'Cirurgia plástica',
                          value: 'Cirurgia plástica',
                        },
                        {
                          label: 'Outros',
                          value: 'Outros',
                        },
                      ]
                    : watch('trackId')?.label.trim().replaceAll(' ', '') ===
                      'R+CM'
                    ? [
                        {
                          label: 'Endocrinologia e metabologia',
                          value: 'Endocrinologia e metabologia',
                        },
                        {
                          label: 'Outros',
                          value: 'Outros',
                        },
                      ]
                    : []
                }
                error={errors.intendedSpecialty as any}
              />
            )}

            {watch('trackId')?.label.trim().replaceAll(' ', '') === 'R1' && (
              <FieldSelectController
                placeholder="Selecione a senioridade"
                name="studentSeniority"
                control={control}
                options={
                  [
                    {
                      label: 'Veterano',
                      value: 'VETERAN',
                    },
                    {
                      label: 'Novato',
                      value: 'NEWBIE',
                    },
                  ] ?? []
                }
                error={errors.studentSeniority as any}
              />
            )}

            <FieldSelectController
              placeholder="Selecione o ano de prova"
              name="trialYear"
              control={control}
              // label="Ano de Prova"
              options={[
                {
                  label: new Date().getFullYear(),
                  value: new Date().getFullYear(),
                },
                {
                  label: new Date().getFullYear() + 1,
                  value: new Date().getFullYear() + 1,
                },
                {
                  label: new Date().getFullYear() + 2,
                  value: new Date().getFullYear() + 2,
                },
              ]}
              error={errors.trialYear as any}
            />

            <FieldSelectController
              placeholder="Selecione o ano de criação da meta"
              name="year"
              control={control}
              // label="Ano da meta"
              options={[
                {
                  label: new Date().getFullYear(),
                  value: new Date().getFullYear(),
                },
                {
                  label: new Date().getFullYear() + 1,
                  value: new Date().getFullYear() + 1,
                },
              ]}
              error={errors.year as any}
            />

            <FieldSelectController
              placeholder="Selecione o mês da meta"
              name="month"
              control={control}
              // label="Mês da meta"
              options={MONTHS.map((month, index) => ({
                label: month,
                value: index + 1,
              }))}
              error={errors.month as any}
            />

            <FieldInputController
              placeholder="Defina o percentual de acertos da meta"
              name="correctAnswersPercentage"
              control={control}
              type="number"
              // label="Percentual de acertos"
              error={errors.correctAnswersPercentage}
            />

            <FieldInputController
              placeholder="Defina o número de questões da meta"
              name="numberOfQuestions"
              control={control}
              type="number"
              // label="Número de questões"
              error={errors.numberOfQuestions}
            />

            <FieldInputController
              placeholder="Defina o tempo de estudo em minutos"
              name="studyMinutes"
              control={control}
              type="number"
              // label="Tempo de estudo"
              error={errors.studyMinutes}
            />
            <Flex mt="12px" gap="12px">
              <DefaultButton
                isLoading={isSubmitting}
                type="submit"
                w="full"
                label="Cadastrar"
              />
              <DefaultButton
                w="full"
                onClick={() => reset()}
                variant="ghost"
                label="Limpar"
              />
            </Flex>
          </Flex>
        </Content>
      </Container>
    </Layout>
  )
}

export default NewGoal
