import { Flex, Th, Thead, Text, TableHeadProps } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React from 'react'

interface TrTableHeaderUserProps extends TableHeadProps {
  onDateClickButton: () => void
  setDateOrder: React.Dispatch<React.SetStateAction<'+date' | '-date'>>
  dateOrder: '+date' | '-date'
}

export const TrTableHeadUser = (props: TrTableHeaderUserProps) => {
  const { dateOrder, onDateClickButton, setDateOrder } = props

  return (
    <Thead textAlign={'center'}>
      <Th
        w={'180px !important'}
        minW="180px !important"
        textTransform={'none'}
        p="16px 0px 16px 16px"
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="16px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
      >
        <Flex w="100%" justifyContent={'flex-start'}>
          <Text>Nome</Text>
        </Flex>
      </Th>

      <Th
        w="115px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex px="1rem" w="100%" gap={'10px'} alignItems="center">
          Data
          {dateOrder === '-date' ? (
            <Icon
              fontSize={'18px'}
              cursor={'pointer'}
              icon={'uil:sort-amount-down'}
              color="#FFFF"
              onClick={onDateClickButton}
            />
          ) : (
            <Icon
              fontSize={'18px'}
              cursor={'pointer'}
              icon={'uil:sort-amount-up'}
              color="#FFFF"
              onClick={onDateClickButton}
            />
          )}
        </Flex>
      </Th>

      <Th
        w="100px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Red flag
        </Flex>
      </Th>

      <Th
        minW="105px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Prontuários
        </Flex>
      </Th>
    </Thead>
  )
}
