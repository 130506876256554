import React from 'react'

// Components
import Header from 'components/Header'

// Styles
import { Container, ContainerAdvisor } from './styles'

import Layout from 'layouts/Logged'
import { DashboardAdmin, DashboardMonitor } from 'features/dashboard'
import { useAuth } from 'contexts/auth'
import { DashboardAdvisor } from 'features/dashboard/dashboard-advisor'

const Dashboard: React.FC = () => {
  const { isLoggedIn, isLoading, userRolesSlugs } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (
    userRolesSlugs.includes('admin') ||
    (isLoggedIn && !isLoading && userRolesSlugs.includes('monitor'))
  ) {
    return (
      <Layout>
        <Container>
          <Header
            title="Painel Principal"
            subTitle="Principais informações sobre a plataforma"
          />

          {isLoggedIn && !isLoading && userRolesSlugs.includes('admin') && (
            <DashboardAdmin />
          )}

          {isLoggedIn && !isLoading && userRolesSlugs.includes('monitor') && (
            <DashboardMonitor />
          )}
        </Container>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <ContainerAdvisor>
          <Header
            title="Painel Principal"
            subTitle="Principais informações sobre a plataforma"
          />

          {isLoggedIn && !isLoading && userRolesSlugs.includes('advisor') && (
            <DashboardAdvisor />
          )}
        </ContainerAdvisor>
      </Layout>
    )
  }
}

export default Dashboard
