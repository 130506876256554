import {
  Button,
  ButtonProps,
  Circle,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ModalVideoOrientation } from 'components/Modal/ModalVideoOrientation'
import React, { useCallback, useEffect, useState } from 'react'
import { BiPlay } from 'react-icons/bi'

interface VideoOrientationProps extends ButtonProps {
  linkVideo?: string
}

export default function VideoOrientation({
  linkVideo,
  ...rest
}: VideoOrientationProps) {
  const [info, setInfo] = useState('Ver Vídeo explicativo')
  const [modalVideoOrientation, setModalVideoOrientation] = useState(false)
  const isMobile = useBreakpointValue({ base: true, sm: false })

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setInfo('')
    }, 8000)

    return () => window.clearTimeout(timeoutID)
  }, [])

  return (
    <>
      <Button
        {...rest}
        ml={{ base: 'none', md: '10px !important' }}
        alignSelf={{ base: 'none', md: 'center' }}
        onClick={() => setModalVideoOrientation(true)}
        maxW="173px"
        minW="26px !important"
        minH={'26px !important'}
        w={info === '' ? '26px !important' : '100%'}
        h={info === '' ? '26px !important' : '36px !important'}
        background="#393940"
        _hover={{ backgroundColor: '#393940' }}
        borderRadius="35px"
        fontStyle="normal"
        px="0 !important"
        fontWeight="700"
        fontSize="12px"
        lineHeight="15px"
        color="#FFFFFF"
        onMouseOver={() => setInfo('Ver Vídeo explicativo')}
        onMouseLeave={() => setInfo('')}
        transition="all 0.8s"
        leftIcon={
          <Circle
            display={'flex'}
            justifyContent={'center'}
            alignSelf="center"
            alignItems="center"
            mr={!info ? '-8px !important' : '8px'}
            sx={
              !info
                ? { marginRight: '-8px !important' }
                : { marginRight: '0px !important' }
            }
            size={'16px'}
            background={'#7C45AF'}
          >
            <BiPlay style={{ marginLeft: '1px' }} size="11px" color="#393940" />
          </Circle>
        }
      >
        {info}
      </Button>
      <ModalVideoOrientation
        isOpen={modalVideoOrientation}
        onClose={() => setModalVideoOrientation(false)}
        CancelButtonClose={() => setModalVideoOrientation(false)}
        size={isMobile ? 'xsm' : 'sm'}
        linkVideo={linkVideo}
        onConfirmClick={() => setModalVideoOrientation(false)}
      />
    </>
  )
}
