import React from 'react'
import ReactApexChart from 'react-apexcharts'

import SectionTitle from 'components/SectionTitle'

import { Box, Tooltip } from '@chakra-ui/react'
import { FaInfoCircle, FaRegHandPaper } from 'react-icons/fa'

interface ScatterChartProps {
  series: any[]
  maxRangeChart?: number | undefined
}

const ScatterChart: React.FC<ScatterChartProps> = ({
  series,
  maxRangeChart,
}) => {
  return (
    <Box
      sx={{
        '.apexcharts-tooltip': {
          color: '#000',
        },
        '.apexcharts-tooltip-custom': {
          padding: '10px',
        },
      }}
    >
      <SectionTitle>
        Gráfico dos alunos (apenas visualização)
        <Tooltip
          label={
            <p>
              Para ver alunos acima de 100% selecione a opção{' '}
              <FaRegHandPaper style={{ display: 'inline-block' }} /> e arraste
              para esquerda
            </p>
          }
          hasArrow
          shouldWrapChildren
        >
          <FaInfoCircle
            style={{ display: 'inline', marginLeft: '8px' }}
            size="16"
          />
        </Tooltip>
      </SectionTitle>

      <ReactApexChart
        type="scatter"
        series={series}
        options={{
          chart: {
            height: 350,
            type: 'scatter',
            foreColor: '#FFFFFF',
            zoom: {
              enabled: true,
              type: 'xy',
            },
          },
          xaxis: {
            tickAmount: 10,
            max: maxRangeChart,
            min: 0,
            title: {
              text: 'Meta global',
            },
          },
          yaxis: {
            tickAmount: 7,
            title: {
              text: 'Dias sem acesso',
            },
          },
          tooltip: {
            custom: (test: any) => {
              const data =
                test.w.globals.initialSeries[test.seriesIndex].data[
                  test.dataPointIndex
                ]
              const [firstItem, daysWithouthAcess, name] = data

              return (
                '<ul class="apexcharts-tooltip-custom">' +
                '<li><b>Nome</b>: ' +
                name +
                '<li><b>Meta Global</b>: ' +
                firstItem +
                '<li><b>Dias sem acesso</b>: ' +
                daysWithouthAcess +
                '</li>' +
                '</li>' +
                '</ul>'
              )
            },
          },
          annotations: {
            position: 'back',
            xaxis: [
              {
                x: 0,
                x2: 61,
                fillColor: '#FEB2B2',
              },
              {
                x: 61,
                x2: 81,
                fillColor: '#FAF089',
              },
              {
                x: 81,
                x2: maxRangeChart,
                fillColor: '#9AE6B4',
              },
            ],
          },
        }}
      />
    </Box>
  )
}

export default ScatterChart
