import * as Yup from 'yup'

export const schema = Yup.object().shape({
  title: Yup.string().required('O nome é obrigatório.'),

  startsAt: Yup.string()
    .nullable()
    .transform((_, originalValue: Date) => {
      if (originalValue) {
        return originalValue?.toISOString()
      }

      return ''
    })
    .required('A data de publicação é obrigatória.'),

  expiresAt: Yup.string()
    .nullable()
    .transform((_, originalValue: Date) => {
      if (originalValue) {
        return originalValue?.toISOString()
      }

      return ''
    })
    .test(
      'is-greater-than-startsAt',
      'A data de expiração deve ser maior que a data de publicação.',
      (value, context) => {
        if (!value || !context.parent.startsAt) {
          return true
        }

        const sameHour =
          new Date(value).toLocaleString() ===
          new Date(context.parent.startsAt).toLocaleString()

        const isAfter = new Date(value) > new Date(context.parent.startsAt)

        return isAfter && !sameHour
      },
    )
    .required('A data de expiração é obrigatória.'),

  message: Yup.string().required('A mensagem é obrigatória.'),

  urlLink: Yup.string(),
})
