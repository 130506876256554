import * as Yup from 'yup'

export const NewGoalSchema = Yup.object().shape({
  trackId: Yup.object()
    .shape({
      label: Yup.string().required('A trilha é obrigatória.'),
      value: Yup.string().required('A trilha é obrigatória.'),
    })
    .typeError('A trilha é obrigatória.')
    .required('A trilha é obrigatória.'),
  trialYear: Yup.number()
    .typeError('O ano de prova é obrigatório.')
    .required('O ano de prova é obrigatório.')
    .transform((_, originalValue: { label: string; value: string }) => {
      if (originalValue) {
        return Number(originalValue.value)
      }
      return null
    }),
  year: Yup.number()
    .typeError('O ano da meta é obrigatório.')
    .required('O ano da meta é obrigatório.')
    .transform((_, originalValue: { label: string; value: string }) => {
      if (originalValue) {
        return Number(originalValue.value)
      }
      return null
    }),
  month: Yup.number()
    .typeError('O mês da meta é obrigatório.')
    .required('O mês da meta é obrigatório.')
    .transform((_, originalValue: { label: string; value: string }) => {
      if (originalValue) {
        return Number(originalValue.value)
      }
      return null
    }),
  correctAnswersPercentage: Yup.number()
    .typeError('O percentual de acertos deve ser um número.')
    .required('O percentual de acertos é obrigatório.'),
  numberOfQuestions: Yup.number()
    .typeError('O número de questões deve ser um número.')
    .required('O número de questões é obrigatório.'),
  studyMinutes: Yup.number()
    .typeError('O tempo de estudo deve ser um número.')
    .required('O tempo de estudo é obrigatório.'),
  studentSeniority: Yup.string()
    .nullable()
    .when('trackId', {
      is: (value: { label: string; value: string }) => {
        if (value?.label.trim().replaceAll(' ', '') === 'R1') {
          return true
        }
      },
      then: Yup.string()
        .transform((_, originalValue: { label: string; value: string }) => {
          if (originalValue) {
            return originalValue?.value
          }

          return null
        })
        .typeError('A senioridade é obrigatória.')
        .required('A senioridade é obrigatória.'),
    }),
  intendedSpecialty: Yup.string()
    .nullable()
    .when('trackId', {
      is: (value: { label: string; value: string }) => {
        if (
          value?.label.trim().replaceAll(' ', '') === 'R+CGE' ||
          value?.label.trim().replaceAll(' ', '') === 'R+CM'
        ) {
          return true
        }
      },
      then: Yup.string()
        .transform((_, originalValue: { label: string; value: string }) => {
          if (originalValue) {
            return originalValue?.value
          }

          return null
        })
        .typeError('A especialidade é obrigatória.')
        .required('A especialidade é obrigatória.'),
    }),
})
