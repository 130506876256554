import styled, { css } from 'styled-components'
import { transparentize as trans } from 'polished'
import { ResponsiveContainer } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;

  ${ResponsiveContainer}

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #d9d9d9;
    height: 8px;
    border-radius: 17px;
  }

  ::-webkit-scrollbar-thumb {
    background: #60606c;
    height: 8px;
    border-radius: 17px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin: 32px 0px;
  }
`

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 16px;
`

const activeChipStyle = css`
  background: ${({ theme: { colors } }) => colors.primary};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  p {
    color: #ffffff;
  }
  &:hover {
    background: ${({ theme: { colors } }) => colors.primary};
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    p {
      color: #ffffff;
    }
  }
`

export const Chip = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 166px;
  height: 42px;

  margin-right: 16px;
  margin-bottom: 16px;

  border-radius: 10px;
  border-width: 1px;

  background: transparent;

  border: 1px solid ${trans(0.8, '#ffffff')};

  transition: all 0.4s;

  &:hover {
    border: 1px solid ${({ theme: { colors } }) => trans(0.6, colors.primary)};
    background: ${({ theme: { colors } }) => trans(0.95, colors.primary)};

    p {
      color: ${trans(0.55, '#ffffff')};
    }
  }

  ${({ isActive }) => isActive && activeChipStyle}
`

export const ChipText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${trans(0.7, '#ffffff')};

  transition: all 0.4s;

  user-select: none;
`

export const ChartContainer = styled.div`
  height: 640px;
  width: 90%;
  position: relative;
`

export const GraphContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 5;
`

export const UserDataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`

export const UserDataLabel = styled.p`
  font-weight: 100;
  color: rgba(255, 255, 255, 0.7);
  text-transform: lowercase;
  letter-spacing: 0.8px;
  font-size: 14px;
`

export const UserDataValue = styled.p`
  margin-left: 8px;
  color: #fff;
`
