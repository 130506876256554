import { addMinutes, format, parseISO } from 'date-fns'

export function formatDate(date: string, pattern = 'dd/MM/yyyy'): string {
  return format(parseISO(date), pattern)
}

export function formatDateUTC(
  date: string,
  pattern = 'dd/MM/yyyy',
  options?: {
    locale?: Locale
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  },
): string {
  const dateString = new Date(date)

  const diferenceBetweenDatesUTC = dateString.getTimezoneOffset()

  const dateUTC = addMinutes(dateString, diferenceBetweenDatesUTC)

  return format(dateUTC, pattern, options)
}

export function formatDateUTCDate(date: string): Date {
  const dateString = new Date(date)

  const diferenceBetweenDatesUTC = dateString.getTimezoneOffset()

  const dateUTC = addMinutes(dateString, diferenceBetweenDatesUTC)

  return dateUTC
}
