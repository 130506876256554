import { map } from 'lodash'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  title: Yup.string().required('O nome do evento é obrigatório.'),

  startsAt: Yup.string()
    .transform((_, originalValue: Date) => {
      if (originalValue) {
        return originalValue?.toISOString()
      }

      return ''
    })
    .required('A data do evento é obrigatória.'),

  filter: Yup.string()
    .transform(value => value && value.value)
    .nullable(),

  contractType: Yup.string().transform((_, value) => {
    if (value) {
      return value.value
    }
  }),

  trackIds: Yup.array().when('filter', {
    is: (value: string) => value === 'Trilha de Estudo',
    then: Yup.array()
      .nullable()
      .min(1, 'A trilha é obrigatória.')
      .typeError('A trilha é obrigatória.')
      .required('A trilha é obrigatória.')
      .transform((_, originalValue: { label: string; value: string }) => {
        if (originalValue) {
          return map(originalValue, 'value')
        }

        return null
      }),
  }),

  monitorUserIds: Yup.array().when('filter', {
    is: (value: string) => value === 'Monitor',
    then: Yup.array()
      .nullable()
      .min(1, 'O Monitor é obrigatório.')
      .typeError('O Monitor é obrigatório.')
      .required('O Monitor é obrigatório.')
      .transform((_, originalValue: { label: string; value: string }) => {
        if (originalValue) {
          return map(originalValue, 'value')
        }

        return null
      }),
  }),

  hostUserId: Yup.number()
    .transform((_, originalValue: { label: string; value: number }) => {
      if (originalValue?.value) {
        return Number(originalValue.value)
      }

      return null
    })
    .nullable()
    .required('O host do evento é obrigatório.'),

  description: Yup.string().required('A descrição do evento é obrigatória.'),

  urlLink: Yup.string(),

  recordedMeeting: Yup.boolean(),
})
