import React, { useCallback, useMemo } from 'react'
import { Bell, Power } from 'react-feather'
import Swal from 'sweetalert2'

import { useAuth } from 'contexts/auth'

import {
  HeaderContainer,
  HeaderTitleContainer,
  HeaderSubTitle,
  HeaderButtonsContainer,
  NotificationContainer,
  NotificationText,
  ProfileContainer,
  ProfileAvatar,
  ProfileName,
  LogOutButton,
} from './styles'
import { useWindowSize } from 'hooks/useWindowsSize'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import VideoOrientation from 'components/VideoOrientation'

interface HeaderProps {
  title: string | React.ReactNode
  subTitle: string
  hasVideoOrientation?: boolean
  linkVideo?: string
}

const Header: React.FC<HeaderProps> = ({
  title,
  subTitle,
  linkVideo,
  hasVideoOrientation,
}) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { logOut, user } = useAuth()
  const { width } = useWindowSize()
  const responsiveBreakpointInPixels = 900
  const isMd = useBreakpointValue({ base: true, md: false })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const isMobile = useMemo(() => {
    if (!width) return false
    return width <= responsiveBreakpointInPixels
  }, [width])

  const confirmLogOut = useCallback(() => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Você relmente deseja fazer logout?',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      buttonsStyling: false,
    }).then(({ isConfirmed }) => isConfirmed && logOut())
  }, [logOut])

  const avatarEndpoint = useMemo(() => {
    if (!user) return ''

    /**
     * See here for more options:
     * https://ui-avatars.com/
     */
    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${user.name.replaceAll(' ', '+')}`
  }, [user])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        <Flex
          fontStyle="normal"
          fontWeight="bold"
          fontSize="30px"
          lineHeight="40px"
          mix-blend-mode="normal"
          opacity="0.9"
          margin-bottom="4px"
          w={hasVideoOrientation && !isMd ? '510px' : 'unset'}
        >
          {title}{' '}
          {hasVideoOrientation && (
            <VideoOrientation
              display={{ base: 'none', md: 'flex' }}
              linkVideo={linkVideo}
              ml={hasVideoOrientation ? '10px' : '0px'}
            />
          )}
        </Flex>
        <HeaderSubTitle>{subTitle}</HeaderSubTitle>
        <VideoOrientation
          display={hasVideoOrientation ? { base: 'flex', md: 'none' } : 'none'}
          linkVideo={linkVideo}
          ml={hasVideoOrientation ? '16px' : '0px'}
          mt={hasVideoOrientation ? '20px' : '0px'}
        />
      </HeaderTitleContainer>
      <HeaderButtonsContainer>
        {!isMobile && (
          <>
            <NotificationContainer>
              <Bell size={14} />
              <NotificationText>0</NotificationText>
            </NotificationContainer>
            <ProfileContainer>
              <ProfileAvatar
                src={user?.avatar?.url || avatarEndpoint}
                alt="avatar"
              />
              <ProfileName>{user?.name}</ProfileName>
            </ProfileContainer>
          </>
        )}
        <LogOutButton onClick={confirmLogOut}>
          <Power size={20} strokeWidth={1} />
        </LogOutButton>
      </HeaderButtonsContainer>
    </HeaderContainer>
  )
}

export default Header
