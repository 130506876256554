import { useMutation } from '@tanstack/react-query'
import api from 'services/api'
import { queryClient } from 'App'

interface Params {
  studentId: number
  description: string
  date: string
  isRedFlag: boolean
}

export async function advisorRecord(params: Params) {
  await api.post('/advisor/student_record', params)
}

export function useCreateAdvisorRecord() {
  return useMutation(advisorRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries(['advisorFetchTable'])
    },
  })
}
