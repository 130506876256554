import { Textarea, TextareaProps } from '@chakra-ui/react'
import React from 'react'

const TextArea: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextareaProps
> = (props, ref) => {
  return (
    <Textarea
      {...props}
      ref={ref}
      mt="0px"
      background="#2A2A30"
      borderRadius={'8px'}
      data-qa={props.name}
      position={'relative'}
      transition={'none'}
      border={'0px'}
      _focus={{
        boxShadow: 'none',
        border: '1px solid #E296FD',
      }}
      maxBlockSize={'md'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#D9D9D9',
          borderRadius: '17px',
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#E296FD',
          borderRadius: '17px',
          height: '8px',
        },
        '&::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
        },
      }}
    />
  )
}

export const TextAreaField = React.forwardRef(TextArea)
