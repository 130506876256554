import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface Params {
  id: number
}

async function deleteExam(params: Params) {
  await api.delete(`/admin/mock-exams/${params.id}`)
}

export function useDeleteExam() {
  return useMutation(deleteExam, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-simulation-exams'])
    },
  })
}
