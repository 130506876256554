import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'
import {
  NewEventFormOutput,
  NewEventResponse,
} from '../interfaces/EventsInterfaces'

async function createEvent(
  params: NewEventFormOutput,
): Promise<NewEventResponse> {
  const response = await api.post('/admin/scheduled_events', params)

  return response.data
}

export function useCreateEvent() {
  return useMutation(createEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-events'])
    },
  })
}
