import React, { forwardRef } from 'react'
import { Radio, RadioProps } from '@chakra-ui/react'

export interface RadioComponentProps extends RadioProps {}

const RadioComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioComponentProps
> = (props, ref) => {
  return (
    <Radio
      _focus={{ boxShadow: 'none' }}
      colorScheme={'brand'}
      _checked={{
        padding: '1px',
        background: '#7D46B1',
        border: '1px solid #212121',
        outline: '2px solid #7D46B1',
        outlineOffset: '1px',
      }}
      data-qa={props.name}
      {...props}
      ref={ref}
    />
  )
}

export const FieldRadio = forwardRef(RadioComponent)
