import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  VStack,
  Stack,
  Box,
  ModalFooter,
  ModalProps,
  Text,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Icon } from '@iconify/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { TextAreaController } from 'components/Form/TextArea/TextAreaController'
import { useToastMessage } from 'components/Toast'
import { parseISO } from 'date-fns'
import { pt } from 'date-fns/locale'
import {
  EditNotificationFormInput,
  EditNotificationFormOutput,
} from 'features/notifications/type'
import React, { useEffect, useMemo } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { Notification } from '../../mutations/use-get-notifications'
import { useUpdateNotification } from 'features/notifications/mutations/use-mutate-update-notification'
import { AxiosError } from 'axios'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldDateController } from 'components/Form/FieldDate/FieldDateController'

interface ModalEditNotificationProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  notification: Notification | undefined
}

export default function ModalEditNotification(
  props: ModalEditNotificationProps,
) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, notification, ...rest } = props
  registerLocale('pt', pt)
  setDefaultLocale('pt')

  /*
  |-----------------------------------------------------------------------------
  | Memo.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const RESET_FORM: EditNotificationFormInput = useMemo(() => {
    return {
      title: '',
      startsAt: null,
      expiresAt: null,
      urlLink: '',
      message: '',
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()
  const updateNotification = useUpdateNotification()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<EditNotificationFormInput>({
    resolver: yupResolver(schema),
    defaultValues: RESET_FORM,
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: EditNotificationFormOutput) => {
    if (!notification) return

    try {
      await updateNotification.mutateAsync({
        notificationId: notification.id,
        ...data,
      })

      showToast({
        title: 'Sucesso',
        description: 'Notificação editada com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset(RESET_FORM)

      onClose()
    } catch (error) {
      reset(RESET_FORM)

      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível criar a questão.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Effect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!notification) return

    if (isOpen) {
      notification.title && setValue('title', notification.title)
      notification.message && setValue('message', notification.message)
      notification.url_link && setValue('urlLink', notification.url_link)
      notification.starts_at &&
        setValue('startsAt', new Date(parseISO(notification.starts_at)))
      notification.expires_at &&
        setValue('expiresAt', new Date(parseISO(notification.expires_at)))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        w="full"
        maxW="720px"
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Editar Notificação
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="20px">
            <FieldInputController
              placeholder="Digite o título da notificação"
              name="title"
              control={control}
              label="Título da Notificação"
              error={errors.title}
            />

            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing="10px"
              color="white"
              w="full"
              align={'flex-start'}
              mt="0px !important"
            >
              <FieldDateController
                label="Data de Publicação"
                name="startsAt"
                control={control}
                autoComplete={'off'}
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeSelect
                error={errors.startsAt}
              />

              <FieldDateController
                label="Data de Expiração"
                name="expiresAt"
                control={control}
                autoComplete={'off'}
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeSelect
                error={errors.expiresAt}
              />
            </Stack>

            <Box w="full">
              <TextAreaController
                name="message"
                label={'Mensagem'}
                backgroundColor="#393940"
                placeholder="Escreva a mensagem"
                color="white"
                control={control}
                boxShadow="none !important"
                error={errors.message}
                maxLength={2000}
                resize={'none'}
                minH="140px"
                helperText={`Caracteres: ${watch('message')?.length || 0}/600`}
              />

              {watch('message')?.length >= 600 && (
                <Text color="#E5C009" w="full" textAlign="start" mt="8px">
                  O número recomendado de caracteres é de 600. Apesar disso,
                  será possível o cadastro.
                </Text>
              )}
            </Box>

            <Box mt="0px !important" w="full">
              <Box position={'relative'}>
                <FieldInputController
                  label={'Link'}
                  control={control}
                  name="urlLink"
                  placeholder="Insira o link"
                />

                <Icon
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '8%',
                    transform: 'translateY(-50%)',
                    zIndex: 9999999999999,
                  }}
                  icon="carbon:link"
                  color="#8F5CF1"
                />
              </Box>

              <Text
                mt="8px"
                fontSize="0.875rem"
                color="#E53E3E"
                textAlign="start"
              >
                {errors?.urlLink?.message && errors?.urlLink?.message}
              </Text>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter p="24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              onClose()
            }}
            w="full"
            variant="ghost"
            label="Cancelar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            label="Editar notificação"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
