import React from 'react'
// Components
import Header from 'components/Header'
// Styles
import Layout from 'layouts/Logged'
import { Container } from './styles'
import KillerConceptsTemplate from 'features/killer-concepts'

export const KillerConcepts: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Conceitos Matadores"
          subTitle="Crie conceitos matadores de questões para os alunos"
        />

        <KillerConceptsTemplate />
      </Container>
    </Layout>
  )
}
