import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react'
import { UserDataApiResponse } from 'pages/UserDetail/interfaces'
import React from 'react'
import { ListResetProjectX } from './TableResetProjectX'

interface AccordionListTableProps {
  user: UserDataApiResponse
}

export default function AccordionListTable({ user }: AccordionListTableProps) {
  return (
    <Accordion allowToggle maxW="350px" w="100%">
      <AccordionItem border="none">
        <AccordionButton
          px={0}
          _focus={{
            boxShadow: 'none',
            outline: 'none',
          }}
        >
          <Flex
            fontWeight="700"
            color="#E296FD"
            alignItems="flex-start"
            justifyContent="space-between"
            w="100%"
            fontSize="16px"
            as="span"
            h="1.8rem"
            flex="1"
            textAlign="left"
            borderBottom="2px solid #E296FD"
          >
            Resets Projeto X
            <AccordionIcon color="#FFFF" />
          </Flex>
        </AccordionButton>
        <AccordionPanel px="0" pb={4}>
          <ListResetProjectX user={user} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
