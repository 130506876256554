import Swal from 'sweetalert2'
import React, { useCallback, useMemo } from 'react'

import { Button, Tooltip } from '@chakra-ui/react'
import api from 'services/api'

interface GetInTouchProps {
  studentId: number
  contacted: boolean
  phoneNumber?: string | null
  size?: 'sm' | 'md'
}

export const GetInTouch: React.FC<GetInTouchProps> = ({
  size = 'sm',
  studentId,
  contacted,
  phoneNumber,
}) => {
  const getInTouch = useCallback(async () => {
    const text = 'Olá, eu gostaria de entrar em contato com você.'

    const result = await Swal.fire({
      title: 'Entrar em contato',
      text: 'Tem certeza que deseja entrar em contato?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, entrar em contato',
    })

    if (result.isConfirmed) {
      const textEncoded = text.replace('\n', '%0A')

      if (!phoneNumber) return

      const phoneNumberFormatted = phoneNumber.replace(/\D/g, '')

      if (!phoneNumberFormatted.length) return

      api.post('/monitor/contact', {
        studentId: studentId,
        subject: 'Entrar em contato',
      })

      window.open(
        `https://wa.me/${phoneNumberFormatted}?text=${textEncoded}`,
        '_blank',
      )
    }
  }, [phoneNumber, studentId])

  const tooltipLabel = useMemo(() => {
    if (!phoneNumber) {
      return 'Não possui número de telefone'
    }

    if (contacted) {
      return 'Aluno já contatado'
    }

    return ''
  }, [contacted, phoneNumber])

  return (
    <Tooltip mt="2" label={tooltipLabel} shouldWrapChildren>
      <Button
        size={size}
        onClick={getInTouch}
        isDisabled={!phoneNumber}
        colorScheme={contacted ? '' : 'whatsapp'}
        bg={contacted ? 'gray.500' : 'whatsapp.500'}
      >
        Entrar em contato
      </Button>
    </Tooltip>
  )
}
