import {
  Modal,
  ModalBody,
  Image,
  ModalContent,
  Text,
  ModalProps,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Flex,
} from '@chakra-ui/react'
import React from 'react'

interface ModalPreviewImageProps extends Omit<ModalProps, 'children'> {
  imageSrc: string | undefined
}

export default function ModalPreviewImage(props: ModalPreviewImageProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, imageSrc } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background={'rgba(0, 0, 0, 0.2)'} />

      <ModalContent
        maxW={'360'}
        w="full"
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="16px 16px 0px 16px"
        >
          <Text fontSize="2xl" fontWeight="700" color="white">
            Preview da imagem
          </Text>
          <ModalCloseButton position="unset" color="white" />
        </ModalHeader>

        <ModalBody maxW={'360'} w="full" p={'1rem'} borderRadius={'8px'}>
          <Flex justifyContent="center">
            <Image src={imageSrc} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
