import React from 'react'

import Sidebar from 'components/Sidebar'
import { Flex } from '@chakra-ui/react'

const Logged: React.FC = ({ children }) => {
  return (
    <Flex
      as="main"
      alignItems="center"
      justifyContent="space-around"
      overflow="hidden"
      position="relative"
      maxWidth="1440px"
      width="100vw"
      maxHeight="1024px"
      height="100vh"
      background="radial-gradient(
        123.22% 129.67% at 100.89% -5.6%,
        #302f37 0%,
        #1b1b1b 100%,
        #1b1b1b 100%
      )"
      boxShadow="0px 60px 60px rgba(0, 0, 0, 0.25)"
      sx={{
        '@media (max-width: 1100px)': {
          maxHeight: 'calc(100vh - 64px)',
        },

        '@media (min-width: 1100px)': {
          maxHeight: 'calc(100vh - 64px)',
        },

        '@media (min-width: 1440px)': {
          borderRadius: '16px',
          maxHeight: 'min(calc(100vh - 64px), 1024px)',
        },
      }}
    >
      <Sidebar />

      <Flex
        flex={1}
        h="100%"
        pt="2rem"
        pr="2rem"
        pl="4.5rem"
        overflowY="scroll"
        flexDirection="column"
      >
        <Flex flexDir="column">{children}</Flex>
      </Flex>
    </Flex>
  )
}

export default Logged
