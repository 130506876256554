import { Query } from '../queries/use-get-students-by-monitor'
import { ActionTypes } from '../actions/students-by-monitor-query-actions'

export function studentsByMonitorQueryReducer(state: Query, action: any) {
  switch (action.type) {
    case ActionTypes.FILTER_MONITOR:
      return {
        ...state,
        page: 1,
        monitorId: action.payload.monitorId,
      }

    case ActionTypes.CHANGE_PAGE:
      console.log('CHANGE_PAGE', action.payload.page)
      return {
        ...state,
        page: action.payload.page,
      }

    case ActionTypes.FILTER_BY_DAYS_WITHOUT_ACCESS:
      return {
        ...state,
        daysWithoutAccess: action.payload.daysWithoutAccess,
      }

    default:
      return state
  }
}
