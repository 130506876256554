/* eslint-disable react/display-name */
import { Button, ButtonProps } from '@chakra-ui/react'
import { forwardRef, useMemo } from 'react'
import React from 'react'

type Variants = 'primary' | 'secondary' | 'ghost'
type Sizes = 'lg' | 'md' | 'mds' | 'sm'
interface DefaultButtonProps extends Omit<ButtonProps, Variants> {
  label: string
  variant?: Variants
  size?: Sizes
}

export const DefaultButton = forwardRef<HTMLButtonElement, DefaultButtonProps>(
  (props, ref) => {
    const { label, variant = 'primary', size = 'mds', ...rest } = props

    /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

    const sizesByVariant = useMemo((): Record<Sizes, ButtonProps> => {
      return {
        sm: {
          height: '30px',
        },
        md: {
          height: '38px',
        },
        mds: {
          height: '42px',
        },
        lg: {
          height: '50px',
        },
      }
    }, [])

    const stylesByVariant = useMemo((): Record<Variants, ButtonProps> => {
      return {
        primary: {
          whiteSpace: 'break-spaces',
          backgroundColor: '#7D46B1',
          color: '#FFF',
          _hover: {
            background: '#905cf1e8',
          },
          _active: {
            background: '#905cf1e8',
          },
          fontSize: '16px',
          fontWeight: 400,
          borderRadius: '8px',
          fontFamily: 'Mulish',
          lineHeight: '20px',
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
        secondary: {
          whiteSpace: 'break-spaces',
          backgroundColor: '#8F5CF1',
          color: '#FFF',
          _hover: {
            background: '#905cf1d8',
          },
          _active: {
            background: '#905cf1d8',
          },
          fontSize: '16px',
          fontWeight: 400,
          borderRadius: '8px',
          fontFamily: 'Mulish',
          lineHeight: '20px',
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
        ghost: {
          whiteSpace: 'break-spaces',
          backgroundColor: 'inherit',
          borderRadius: '8px',
          height: '42px',
          fontFamily: 'Mulish',
          border: '1px solid #FFFFFF',
          color: '#FFFF',
          lineHeight: '20px',
          _hover: {
            background: 'inherit',
            border: '1px solid #7D46B1',
          },
          _active: {
            background: 'inherit',
          },
          _focus: {
            outline: 'none',
          },
          transition: 'none',
        },
      }
    }, [])

    /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

    return (
      <Button
        ref={ref}
        data-qa={variant}
        {...stylesByVariant[variant]}
        {...sizesByVariant[size]}
        {...rest}
      >
        {label}
      </Button>
    )
  },
)
