import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface Params {
  id: number
  name: string
  trackId: number
  mentoriaGroupLink: string
  membersLimit: number
  groupType: 'NEWBIE' | 'VETERAN'
  monitorUserId: number
}

async function updateWhatsappGroup(params: Params) {
  const response = await api.put(`/admin/mentoria_groups/${params.id}`, params)

  return response.data
}

export function useUpdateWhatsappGroup() {
  return useMutation(updateWhatsappGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-whatsapp-groups'])
    },
  })
}
