import * as Yup from 'yup'

export const schema = Yup.object().shape({
  title: Yup.string().required('O nome é obrigatório.'),
  institution: Yup.string()
    .transform(value => value && value.value)
    .nullable()
    .required('A instituição é obrigatória.'),
  trackId: Yup.number()
    .transform((_, value) => {
      if (value) {
        return value.value
      }
    })
    .nullable()
    .required('A trilha é obrigatória.'),
  contractType: Yup.string()
    .transform((value, originalValue) => originalValue && originalValue.value)
    .nullable()
    .required('O público é obrigatório'),
  examProductCode: Yup.string().optional().nullable(),
})
