import React from 'react'
import * as dateFns from 'date-fns'
import { ptBR } from 'date-fns/locale'
import {
  Messages,
  EventPropGetter,
  dateFnsLocalizer,
  Calendar as ReactBigCalendar,
} from 'react-big-calendar'

const localizer = dateFnsLocalizer({
  ...dateFns,
  locales: { 'pt-BR': ptBR },
})

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never

interface ICalendarProps<T> {
  events: ArrElement<T>[]
  onNavigate: (newDate: Date) => void
  eventPropGetter?: EventPropGetter<ArrElement<T>> | undefined
  tooltipAccessor?: any
}

export const Calendar = <T extends any[]>({
  events,
  onNavigate,
  eventPropGetter,
  tooltipAccessor,
}: ICalendarProps<T>) => {
  const messages: Messages = React.useMemo(
    () => ({
      day: 'Dia',
      time: 'Hora',
      date: 'Data',
      month: 'Mês',
      today: 'Hoje',
      week: 'Semana',
      next: 'Próximo',
      event: 'Evento',
      allDay: 'Todo dia',
      tomorrow: 'Amanhã',
      yesterday: 'Ontem',
      work_week: 'Semana',
      agenda: 'Calendário',
      previous: 'Anterior',
      noEventsInRange: 'Sem nenhuma agenda no intervalo',
      showMore: number => `+ ${number} ver mais`,
    }),
    [],
  )

  const styles: React.CSSProperties = React.useMemo(
    () => ({
      height: 820,
      minWidth: 800,
      marginTop: 40,
      minHeight: 820,
    }),
    [],
  )

  return (
    <ReactBigCalendar
      style={styles}
      events={events}
      culture="pt-BR"
      endAccessor="end"
      messages={messages}
      startAccessor="start"
      localizer={localizer}
      onNavigate={onNavigate}
      eventPropGetter={eventPropGetter}
      tooltipAccessor={tooltipAccessor}
    />
  )
}
