import {
  Button,
  Flex,
  IconButton,
  Image,
  TableRowProps,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import React, { useMemo } from 'react'
import { Icon } from '@iconify/react'

interface TrTableCallsProps extends TableRowProps {
  avatar: string
  monitor: string
  trail: (string | undefined)[]
  year: (number | null | undefined)[]
  orientator: string | undefined | null
  studentNumber: string
  status: string | undefined
  onEditClickButton: () => void
  sendToStudentsMonitorPage: () => void
  onClickOpenState: () => void
  onClickClosedState: () => void
}

export default function TrTableBody(props: TrTableCallsProps) {
  const {
    avatar,
    monitor,
    trail,
    year,
    status,
    orientator,
    studentNumber,
    onEditClickButton,
    sendToStudentsMonitorPage,
    onClickClosedState,
    onClickOpenState,
    ...rest
  } = props

  const avatarEndpoint = useMemo(() => {
    if (!monitor) return ''

    /**
     * See here for more options:
     * https://ui-avatars.com/
     */
    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${monitor.replaceAll(' ', '+')}`
  }, [monitor])

  return (
    <>
      <Tr pb="1rem" verticalAlign={'baseline'} {...rest}>
        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
          maxW={'195px !important'}
          minW="195px"
        >
          <Flex
            w="100%"
            minW={'190px'}
            justifyContent="flex-start"
            alignItems="center"
            lineHeight={'15px'}
          >
            {avatar ? (
              <Image
                mr="10px"
                w="15px"
                h="15px"
                borderRadius="6px"
                src={avatar}
              />
            ) : (
              <Image
                mr="10px"
                w="15px"
                h="15px"
                borderRadius="6px"
                src={avatarEndpoint}
              />
            )}

            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="500"
              fontSize="12px"
              lineHeight={'15px'}
              color="#FFFFFF"
            >
              {letterCounter(monitor, 20)}
            </Text>
          </Flex>
        </Td>
        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex minW={'100px !important'} justifyContent="center">
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="600"
              textAlign={'center'}
              fontSize="12px"
              lineHeight={'15px'}
              color={
                trail === undefined || trail.length === 0
                  ? '#BDBDC7'
                  : '#FFFFFF'
              }
            >
              {trail === undefined || trail === null || trail.length === 0
                ? 'Não listado'
                : trail.join(', ')}
            </Text>
          </Flex>
        </Td>
        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex w="100%" minW={'106px !important'} justifyContent={'center'}>
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="600"
              fontSize="12px"
              lineHeight={'15px'}
              textAlign={'center'}
              color={
                year === undefined ||
                year[0] === null ||
                year === null ||
                year.length === 0
                  ? '#BDBDC7'
                  : '#FFFFFF'
              }
            >
              {year === undefined ||
              year === null ||
              year[0] === null ||
              year.length === 0
                ? 'Não listado'
                : year.join(', ')}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          minW="162px"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex
            w="100%"
            minW="78px !important"
            alignSelf={'center'}
            justifyContent={'center'}
          >
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="600"
              fontSize="12px"
              lineHeight={'15px'}
              color={!orientator ? '#BDBDC7' : '#FFFFFF'}
            >
              {!orientator || orientator === null
                ? 'Não listado'
                : letterCounter(orientator, 20)}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderBottom="none"
          borderRight={'1px solid #60606C'}
        >
          <Flex w="100%" minW="85px !important" justifyContent={'center'}>
            <Button
              fontFamily="Mulish"
              maxW={'35px'}
              w="100%"
              h="28px"
              borderRadius={'4px'}
              backgroundColor={
                studentNumber && +studentNumber < 180 ? '#7D46B1' : '#60606C'
              }
              _hover={
                studentNumber && +studentNumber < 180
                  ? { backgroundColor: '#9e59de' }
                  : { backgroundColor: '#575761' }
              }
              fontWeight="600"
              fontSize={'12px'}
              lineHeight={'15px'}
              onClick={sendToStudentsMonitorPage}
              color="#FFFFFF"
            >
              {studentNumber}
            </Button>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex w="100%" minW="105px !important" justifyContent={'center'}>
            {status === 'OPEN' ? (
              <Button
                fontFamily="Mulish"
                p={'0px 10px 0px 8px'}
                justifyContent={'space-between'}
                iconSpacing={'10px'}
                maxW={'90px'}
                rightIcon={<Icon icon="eva:lock-fill" />}
                w="100%"
                h="28px"
                borderRadius={'4px'}
                backgroundColor={'#7D46B1'}
                _hover={{ backgroundColor: '#9e59de' }}
                fontWeight="400"
                fontSize={'12px'}
                lineHeight={'15px'}
                onClick={onClickOpenState}
              >
                Aberto
              </Button>
            ) : (
              <Button
                fontFamily="Mulish"
                p={'0px 10px 0px 8px'}
                justifyContent={'space-between'}
                iconSpacing={'10px'}
                rightIcon={<Icon icon="eva:lock-fill" />}
                maxW={'90px'}
                w="100%"
                h="28px"
                borderRadius={'4px'}
                backgroundColor={'#60606C'}
                _hover={{ backgroundColor: '#575761' }}
                fontWeight="400"
                fontSize={'12px'}
                lineHeight={'15px'}
                onClick={onClickClosedState}
              >
                <Text>Fechado</Text>
              </Button>
            )}
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderBottom="none"
        >
          <Flex
            w="100%"
            maxW={'80px'}
            minW="70px !important"
            justifyContent={'center'}
          >
            <IconButton
              aria-label={'edit'}
              icon={
                <Icon
                  icon="eva:edit-outline"
                  color="#FFFFFF"
                  fontSize={'12px'}
                />
              }
              backgroundColor="#393940"
              _hover={{ backgroundColor: '#302F37' }}
              borderRadius={'4px'}
              w="24px"
              h="24px"
              minW={'24px !important'}
              onClick={onEditClickButton}
            />
          </Flex>
        </Td>
      </Tr>
    </>
  )
}
