import React from 'react'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { FieldInput, FieldInputProps } from 'components/Form/FieldInput'

type FieldInputControllerProps = {
  name: string
  label?: string
  control?: Control<any>
  defaultValue?: string
  error?: FieldError
} & FieldInputProps

export const FieldInputController: React.FC<FieldInputControllerProps> = ({
  name,
  label,
  error,
  control,
  defaultValue,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontFamily={'Mulish'}
          fontWeight="600"
          color={'#FFFFFF'}
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => <FieldInput id={name} {...rest} {...field} />}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
