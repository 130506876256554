import React, { useCallback, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

// Styles
import Layout from 'layouts/Unlogged'

// Schemas
import { ForgotPasswordNewSchema } from './schemas'

// Services
import api from 'services/api'

// Interfaces
import { ForgotPasswordPostPayload } from './interfaces'

// Helpers
import { chakra, Flex, Text, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'

const ForgotPasswordReset: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const location = useLocation<{ token: string }>()
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordPostPayload>({
    resolver: yupResolver(ForgotPasswordNewSchema),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(
    async formData => {
      try {
        const query = new URLSearchParams(location.search)
        const resetPasswordToken = query.get('token')
        if (!resetPasswordToken) return

        await api.put('/public/forgot_password', {
          ...formData,
          resetPasswordToken,
        })

        Swal.fire({
          title: 'Sucesso',
          text: 'Senha redefinida com sucesso.',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (err) {
        Swal.fire({
          title: 'Aviso',
          text:
            'Falha ao solicitar redefinição de senha. Por favor tente novamente.',
          buttonsStyling: false,
        })
      }
    },
    [history, location.search],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Redirect user to login if no token is presented as query param.
     */
    const query = new URLSearchParams(location.search)
    const resetPasswordToken = query.get('token')
    if (!resetPasswordToken) return history.replace('/')
  }, [history, location])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Flex
        as="form"
        flexDir="column"
        justifyContent="space-around"
        alignItems="center"
        textAlign="center"
        w="514px"
        maxW="90vw"
        height="434px"
        maxH="calc(0.92 * 90vw)"
        bg="#2a2a2d"
        boxShadow="0px 0px 61px 13px rgba(0, 0, 0, 0.25)"
        borderRadius="10px"
        py="24px"
        onSubmit={handleSubmit(submitHandler as any)}
      >
        <Text lineHeight="30px" fontSize="24px" letterSpacing="0.2px">
          Digite a sua{' '}
          <chakra.span
            fontWeight="bold"
            lineHeight="20px"
            color="rgb(158, 89, 222)"
          >
            nova senha
          </chakra.span>
        </Text>

        <VStack w="full" mx="auto" maxW="380px">
          <FieldInputController
            placeholder="Senha"
            name="password"
            type="password"
            control={control}
            error={errors.password}
            w="full"
          />
          <FieldInputController
            placeholder="Confirme a senha"
            name="passwordConfirmation"
            type="password"
            control={control}
            error={errors.passwordConfirmation}
            w="full"
          />
        </VStack>

        <Flex
          flexDir="column"
          gap="1rem"
          alignItems="center"
          alignSelf="stretch"
        >
          <DefaultButton
            isLoading={isSubmitting}
            w="full"
            maxW="189px"
            label="Redefinir"
            type="submit"
          />
          <Link
            to="/"
            style={{ fontSize: '13px', color: 'rgba(255,255,255,0.6)' }}
          >
            Voltar
          </Link>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default ForgotPasswordReset
