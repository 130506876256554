import React from 'react'

// Components
import Header from 'components/Header'

// Styles
import { Container } from './styles'

import Layout from 'layouts/Logged'
import { useAuth } from 'contexts/auth'
import { AdvisorTable } from 'components/AdvisorTable'

export const AdvisorTablePage: React.FC = () => {
  const { isLoggedIn, isLoading, userRolesSlugs } = useAuth()

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Tabela de alunos"
          subTitle="Informações detalhadas sobre os alunos"
        />

        {isLoggedIn && !isLoading && userRolesSlugs.includes('advisor') && (
          <AdvisorTable />
        )}
      </Container>
    </Layout>
  )
}
