import React from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  ModalProps,
  VStack,
  Flex,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'

export type ModalSimulationQuitProps = {
  title?: string
  onFirstClick?: () => void
  onSecondClick?: () => void
} & Omit<ModalProps, 'children'>

export default function ModalSimulationQuit(props: ModalSimulationQuitProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constant.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { title, onFirstClick, onSecondClick, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isCentered {...rest}>
      <ModalOverlay background={'rgba(0, 0, 0, 0.2)'} />
      <ModalContent
        maxW={'360'}
        w="full"
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalBody maxW={'360'} w="full" p={'2rem'} borderRadius={'8px'}>
          <VStack spacing={'24px'}>
            <Text
              fontWeight={700}
              fontSize={'24px'}
              lineHeight={'30px'}
              textAlign={'center'}
              color={'#FFFFFF'}
            >
              {title}
            </Text>
            <Text
              fontWeight={400}
              fontSize={'12px'}
              lineHeight={'15px'}
              textAlign={'center'}
              color={'#FFF'}
            >
              Se continuar, toda as alterações serão perdidas
            </Text>

            <Flex
              alignItems={'center'}
              gap="24px"
              justifyContent={'center'}
              w="full"
            >
              <DefaultButton
                label="Sim"
                size="md"
                w={'100%'}
                onClick={onFirstClick}
              />

              <DefaultButton
                variant="ghost"
                label="Não"
                size="md"
                w={'100%'}
                onClick={onSecondClick}
              />
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
