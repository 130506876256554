import React from 'react'

import { Stack } from '@chakra-ui/react'

interface Props {
  children: React.ReactNode
}

export function ListFilters({ children }: Props) {
  return (
    <Stack direction={['column', 'row']} mb="8">
      {children}
    </Stack>
  )
}
