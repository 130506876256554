import React, { useEffect, useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  VStack,
  Box,
  ModalFooter,
  ModalProps,
  Text,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Icon } from '@iconify/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { useToastMessage } from 'components/Toast'
import { useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  EditWhatsappGroupFormInput,
  EditWhatsappGroupFormOutput,
} from 'features/whatsapp-groups/interfaces/WhatsappGroupsInterfaces'
import { useUpdateWhatsappGroup } from 'features/whatsapp-groups/mutations/use-mutate-update-whatsapp-group'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import { WhatsGroup } from 'features/whatsapp-groups/mutations/use-get-whatsapp-groups'
import { useGetAllMonitors } from 'components/MonitorTable/queries/use-fetch-monitors'

interface ModalEditWhatsappGroupProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  whatsappGroup: WhatsGroup | undefined
}

export default function ModalEditWhatsappGroup(
  props: ModalEditWhatsappGroupProps,
) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, whatsappGroup, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Memo.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const RESET_FORM: EditWhatsappGroupFormInput = useMemo(() => {
    return {
      trackId: null,
      name: '',
      mentoriaGroupLink: '',
      membersLimit: 0,
      groupType: null,
      monitorUserId: null,
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()
  const updateWhatsappGroup = useUpdateWhatsappGroup()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<EditWhatsappGroupFormInput>({
    resolver: yupResolver(schema),
  })
  const { data: ListMonitor, isLoading: isLoadingMonitors } = useGetAllMonitors(
    {},
  )
  const { data: trackAll } = useGetAllTracks()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: EditWhatsappGroupFormOutput) => {
    if (!whatsappGroup) return

    try {
      await updateWhatsappGroup.mutateAsync({ id: whatsappGroup.id, ...data })

      showToast({
        title: 'Sucesso',
        description: 'Grupo editado com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset(RESET_FORM)

      onClose()
    } catch (error) {
      reset(RESET_FORM)

      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível editar o grupo.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Effect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!whatsappGroup) return

    if (isOpen) {
      whatsappGroup?.name && setValue('name', whatsappGroup?.name)
      whatsappGroup?.groupType &&
        setValue('groupType', {
          label:
            whatsappGroup?.groupType === 'NEWBIE'
              ? 'Novato'
              : whatsappGroup?.groupType === 'VETERAN'
              ? 'Veterano'
              : null,
          value: whatsappGroup?.groupType ?? null,
        })
      whatsappGroup?.track &&
        setValue('trackId', {
          label: whatsappGroup?.track?.name,
          value: whatsappGroup?.track?.id,
        })
      whatsappGroup?.mentoriaGroupLink &&
        setValue('mentoriaGroupLink', whatsappGroup?.mentoriaGroupLink)
      whatsappGroup?.membersLimit &&
        setValue('membersLimit', whatsappGroup?.membersLimit)
      whatsappGroup?.monitorUser?.id &&
        setValue('monitorUserId', {
          label: whatsappGroup?.monitorUser?.name,
          value: whatsappGroup?.monitorUser?.id,
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        w="full"
        maxW="720px"
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Edição de grupo
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="20px">
            <FieldSelectController
              placeholder="Selecione a trilha de estudo"
              name="trackId"
              control={control}
              label="Trilha de Estudo"
              options={trackAll?.data?.map(track => {
                return {
                  label: track.name,
                  value: track.id,
                }
              })}
              error={errors.trackId as any}
            />

            <FieldInputController
              placeholder="Insira o nome do grupo"
              name="name"
              control={control}
              label="Nome"
              error={errors.name}
            />

            <FieldSelectController
              placeholder="Insira o tipo do grupo"
              name="groupType"
              control={control}
              options={[
                {
                  value: 'NEWBIE',
                  label: 'Novato',
                },
                {
                  value: 'VETERAN',
                  label: 'Veterano',
                },
              ]}
              label="Tipo do grupo"
              error={errors.groupType as any}
            />

            <Box w="full">
              <Box position={'relative'}>
                <FieldInputController
                  label={'Link do Whatsapp'}
                  control={control}
                  name="mentoriaGroupLink"
                  placeholder="Insira o link"
                />

                <Icon
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '8%',
                    transform: 'translateY(-50%)',
                    zIndex: 9999999999999,
                  }}
                  icon="carbon:link"
                  color="#8F5CF1"
                />
              </Box>

              {errors?.mentoriaGroupLink?.message && (
                <Text
                  mt="8px"
                  fontSize="0.875rem"
                  color="#E53E3E"
                  textAlign="start"
                >
                  {errors?.mentoriaGroupLink?.message}
                </Text>
              )}
            </Box>

            <FieldSelectController
              placeholder="Insira o monitor responsável"
              isLoading={isLoadingMonitors}
              name="monitorUserId"
              control={control}
              options={ListMonitor?.map(item => {
                return {
                  label: item.name,
                  value: item.id,
                }
              })}
              label="Monitor Responsável"
              error={errors.monitorUserId as any}
            />

            <FieldInputController
              placeholder="Insira o limite de alunos"
              name="membersLimit"
              type="number"
              control={control}
              label="Limite de alunos"
              error={errors.membersLimit}
            />
          </VStack>
        </ModalBody>

        <ModalFooter p="24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              reset(RESET_FORM)
            }}
            w="full"
            variant="ghost"
            label="Limpar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            label="Editar grupo"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
