import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface Params {
  title: string
  institution: string
  published: boolean
  trackId: number
  contractType: string
  examProductCode?: string
}

interface CreateExamsResponse {
  title: string
  institution: string
  track_id: number
  published: boolean
  created_at: string
  updated_at: string
  id: number
}

async function createExams(params: Params): Promise<CreateExamsResponse> {
  const response = await api.post('/admin/mock-exams', params)

  return response.data
}

export function useCreateExams() {
  return useMutation(createExams, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-simulation-exams'])
    },
  })
}
