import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  Text,
  VStack,
  Flex,
} from '@chakra-ui/react'
import { List } from 'features/ui/list'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import React, { useMemo, useState } from 'react'
import { Icon } from '@iconify/react'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import { MoreVertical } from 'react-feather'
import Pagination from 'components/Pagination'
import { ListHeader } from 'features/ui'
import { AxiosError } from 'axios'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import {
  useGetWhatsappGroups,
  WhatsGroup,
} from './mutations/use-get-whatsapp-groups'
import { useDeleteWhatsappGroup } from './mutations/use-mutate-delete-whatsapp-group'
import ModalNewWhatsappGroup from './modal/ModalNewWhatsappGroup/ModalNewWhatsappGroup'
import ModalEditWhatsappGroup from './modal/ModalEditWhatsappGroup/ModalEditWhatsappGroup'
import { SearchSectionField } from 'components/MonitorTable/components/SearchSection'
import { useDebounce } from 'hooks/use-debounce'

export default function WhatsappGroupsTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [page, setPage] = useState(1)
  const [selectedRow, setSelectedRow] = useState<WhatsGroup | undefined>(
    undefined,
  )
  const [
    isModalNewWhatsappGroupOpen,
    setIsModalNewWhatsappGroupOpen,
  ] = useState(false)
  const [
    isModalEditWhatsappGroupOpen,
    setIsModalEditWhatsappGroupOpen,
  ] = useState(false)
  const [
    isModalConfirmDeleteWhatsappGroupOpen,
    setIsModalConfirmDeleteWhatsappGroupOpen,
  ] = useState(false)
  const [search, setSearch] = useState<string>('')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()
  const debouncedSearch = useDebounce(search, 900)
  const {
    data: whatsapGroupsData,
    isLoading: isLoadingWhatsappGroups,
    isFetching: isFetchingWhatsappGroups,
  } = useGetWhatsappGroups({
    name: debouncedSearch ? debouncedSearch : undefined,
    page: page,
    limit: 10,
  })
  const deleteWhatsappGroup = useDeleteWhatsappGroup()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: <Text textAlign="center">Ações</Text>,
        fn(_, value) {
          return (
            <Box textAlign="center">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MoreVertical size="20px" color="#7D46B1" />}
                  bg="#393940"
                  height={'26px'}
                  width={'26px'}
                  minW={'26px !important'}
                  p="0px"
                />
                <Portal>
                  <MenuList
                    bg="#393940"
                    color="#ffffff"
                    border="1px solid #7D46B1"
                    minW="fit-content"
                    py="0px"
                  >
                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        setSelectedRow(value)
                        setIsModalEditWhatsappGroupOpen(true)
                      }}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        setSelectedRow(value)
                        setIsModalConfirmDeleteWhatsappGroupOpen(true)
                      }}
                    >
                      Excluir
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          )
        },
      },
      {
        label: 'Nome do grupo',
        fn(_, value) {
          return (
            <HStack minW={'150px'} noOfLines={1} w="100%">
              <Tooltip w="100%" label={`${value?.name}`} hasArrow>
                <Text maxW={'240px'} noOfLines={1}>
                  {value?.name ? `${value?.name}` : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Trilha</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={value?.track?.name ? value?.track?.name : '-'}
                hasArrow
              >
                <Text noOfLines={1}>
                  {value?.track?.name ? value?.track?.name : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Tipo do grupo</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={value?.groupType ? value?.groupType : '-'}
                hasArrow
              >
                <Text noOfLines={1}>
                  {value?.groupType ? value?.groupType : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Alunos</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Text noOfLines={1}>
                {value?.membersLimit
                  ? `${value?.totalStudents} / ${value?.membersLimit}`
                  : '-'}
              </Text>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Monitor</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={
                  value?.monitorUser?.name ? value?.monitorUser?.name : '-'
                }
                hasArrow
              >
                <Text noOfLines={1}>
                  {value?.monitorUser?.name ? value?.monitorUser?.name : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Link Whatsapp</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={
                  value?.mentoriaGroupLink ? value?.mentoriaGroupLink : '-'
                }
                hasArrow
              >
                <Text maxW="150px" noOfLines={1}>
                  {value?.mentoriaGroupLink ? value?.mentoriaGroupLink : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
    ] as TableHeader<WhatsGroup>[]
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack spacing="2rem" mt="2rem" align="flex-start">
      <Flex
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        flexDir={{ base: 'column', md: 'row' }}
        gap={{ base: '15px', md: '15px' }}
        alignItems={{ base: 'flex-start', sm: 'center' }}
        w="100%"
      >
        <DefaultButton
          label="Novo Grupo Whatsapp"
          leftIcon={<Icon icon="ic:baseline-whatsapp" />}
          onClick={() => setIsModalNewWhatsappGroupOpen(true)}
        />
        <Flex>
          <SearchSectionField
            setInput={setSearch}
            search={search}
            onChange={e => {
              setSearch(e.target.value)
            }}
          />
        </Flex>
      </Flex>

      <List background="#302F37" w="full" px="20px" py="10px" my="0px">
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={isLoadingWhatsappGroups}
          isFetching={isFetchingWhatsappGroups}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={whatsapGroupsData?.data}
          isLoading={isLoadingWhatsappGroups}
          tableCellProps={{
            border: 'none !important',
            p: '8px 12px',
            borderRight: '1px solid rgba(255, 255, 255, 0.06) !important',
            _last: {
              borderRight: 'none !important',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '14px',
            textTransform: 'none !important',
            p: '8px 12px',
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={whatsapGroupsData?.meta?.total || 0}
          registersPerPage={10}
          fontSize="12px"
          mt="16px !important"
          px="8px"
          buttonProps={{
            fontSize: '12px',
            fontWeight: '600',
            minH: '6',
            h: '6',
            w: '6',
            minW: '6',
          }}
        />
      </List>

      <ModalNewWhatsappGroup
        isOpen={isModalNewWhatsappGroupOpen}
        onClose={() => setIsModalNewWhatsappGroupOpen(false)}
      />

      <ModalEditWhatsappGroup
        isOpen={isModalEditWhatsappGroupOpen}
        onClose={() => setIsModalEditWhatsappGroupOpen(false)}
        whatsappGroup={selectedRow}
      />

      <ModalDefaultTwoButton
        isOpen={isModalConfirmDeleteWhatsappGroupOpen}
        subTitle="Tem certeza que deseja excluir o registro desse grupo?"
        title="Excluir grupo"
        onClose={() => setIsModalConfirmDeleteWhatsappGroupOpen(false)}
        confirmButtonIsLoading={deleteWhatsappGroup.isLoading}
        onConfirmClick={async () => {
          if (!selectedRow) return

          try {
            await deleteWhatsappGroup.mutateAsync({
              whatsappGroupId: selectedRow.id,
            })

            showToast({
              title: 'Sucesso',
              description: 'Grupo excluído com sucesso.',
              type: 'success',
              duration: 2000,
              mode: 'dark',
            })

            setIsModalConfirmDeleteWhatsappGroupOpen(false)
          } catch (error) {
            const message: AxiosError =
              error?.response?.data?.errors
                ?.map((error: AxiosError) => error.message)
                ?.join(', ') ||
              error?.response?.data ||
              'Não foi possível excluir o grupo no momento.'

            showToast({
              title: 'Erro',
              description: message.message ? message.message : message,
              type: 'error',
              duration: 2000,
              mode: 'dark',
            })
          }
        }}
      />
    </VStack>
  )
}
