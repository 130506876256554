export interface AdvisorMonitor {
  id: number
  name: string
  avatar: {
    id: number
    user_id: number
    url: string
    created_at: string
  } | null
}

export interface User {
  id: number // 11
  name: string // 'Student 2'
  email: string // 'student2@user.com'
  mobile_phone: string // '+5521981880331'
  is_email_validated: boolean // true
  is_reset_password_required: boolean // false
  last_login_at: string // '2020-12-26T17:06:43.836-03:00'
  created_at: string // '2020-12-26T17:03:04.690-03:00'
  updated_at: string // '2020-12-26T17:06:43.836-03:00'
  roles: Role[]
  contracts: Contract[]
  avatar?: {
    id: number
    url: string // "https://mentoria-residencia.s3.amazonaws.com/avatar/2ec576d8-a8e5-4303-b4d2-4e716545f1b4.png",
  }
  advisorMonitors?: AdvisorMonitor[]
}

export interface Role {
  id: number
  name: string
  slug: string
}

export enum ContractType {
  TRIAL = 'TRIAL',
  PURCHASE = 'PURCHASE',
}

export interface Contract {
  id: number // 2
  user_id: number // 12
  track_id: number // 1
  initial_date: string // '2020-12-26T03:00:00.000Z'
  final_date: string // '2021-01-31T03:00:00.000Z'
  canceled_at: string // null
  created_at: string // '2020-12-26T19:05:59.418-03:00'
  updated_at: string // '2020-12-26T19:05:59.418-03:00'
  type: keyof typeof ContractType
  track: {
    name: string
  }
}

export interface LogInResponse {
  user: User
  token: {
    token: string
    type: string
  }
}

export interface LogInPayload {
  email: string
  password: string
}

export interface AuthContextValues {
  user: User | undefined
  userRolesSlugs: string[]
  userContract: Contract | undefined
  isLoggedIn: boolean
  isLoading: boolean
  hasContract: boolean
  logIn: (logInPayload: LogInPayload) => void
  logOut(): void
  updateUserData(): void
}
