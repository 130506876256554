import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface QueryParams {
  whatsappGroupId: number
}

async function deleteWhatsappGroup(params: QueryParams): Promise<void> {
  await api.delete(`/admin/mentoria_groups/${params.whatsappGroupId}`)
}

export function useDeleteWhatsappGroup() {
  return useMutation(deleteWhatsappGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-whatsapp-groups'])
    },
  })
}
