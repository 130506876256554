import { debounce } from 'lodash'
import { Link, useParams } from 'react-router-dom'
import React, { useMemo, useReducer, useCallback } from 'react'

// UI
import {
  List,
  ListTable,
  ListHeader,
  ListFilters,
  ListPagination,
} from 'features/ui'
import { Button, NumberInput, NumberInputField } from '@chakra-ui/react'

// Students
import {
  onChangePageAction,
  GetStudentsByMonitor,
  useGetStudentsByMonitor,
  studentsByMonitorQueryReducer,
  filterByDaysWithoutAccessAction,
} from 'features/students'

// Types
import { TableHeader } from 'features/ui/list/list-table'

export function ListStudents() {
  const { monitorUserId } = useParams<{ monitorUserId?: string }>()

  const [query, dispatch] = useReducer(studentsByMonitorQueryReducer, {
    page: 1,
    monitorId: monitorUserId,
  })
  const studentsByMonitor = useGetStudentsByMonitor(query)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterByDaysWithoutAccess = useCallback(
    debounce((_, valueAsNumber: number) => {
      return dispatch(filterByDaysWithoutAccessAction(valueAsNumber))
    }, 1000),
    [],
  )

  const headers = useMemo(() => {
    return [
      {
        label: 'Nome',
        accessor: 'name',
      },
      {
        label: 'Email',
        accessor: 'email',
      },
      {
        label: 'Contato',
        accessor: 'mobilePhone',
        fn: (mobilePhone: string | null) => {
          return mobilePhone?.replace(
            /(\d{2}|\d{2} )(\d{2})(\d{5})(\d{4})/,
            '$1 ($2) $3-$4',
          )
        },
      },
      {
        label: '',
        fn(_, data) {
          return (
            <Button
              as={Link}
              size="sm"
              to={`/users/${data.id}`}
              colorScheme="brand"
            >
              Detalhes
            </Button>
          )
        },
      },
    ] as TableHeader<GetStudentsByMonitor>[]
  }, [])

  return (
    <List overflow={'auto'}>
      <ListHeader
        title="Alunos"
        isLoading={studentsByMonitor.isLoading}
        isFetching={studentsByMonitor.isFetching}
      />

      <ListFilters>
        <NumberInput
          min={0}
          size="sm"
          borderColor="whiteAlpha.200"
          defaultValue={query.daysWithoutAccess}
          onChange={handleFilterByDaysWithoutAccess}
        >
          <NumberInputField placeholder="Digite os dias sem acesso..." />
        </NumberInput>
      </ListFilters>

      <ListTable
        headers={headers}
        data={studentsByMonitor.data?.students}
        isLoading={studentsByMonitor.isLoading}
      />

      <ListPagination
        currentPage={query.page}
        totalCountOfRegisters={studentsByMonitor.data?.meta.total}
        onChangePage={(page: number) => dispatch(onChangePageAction(page))}
      />
    </List>
  )
}
