import { createGlobalStyle, css } from 'styled-components'
import { transparentize, darken } from 'polished'

const primaryGradientBackGround = css`
  background: linear-gradient(
    221.26deg,
    #9e59de -3.4%,
    ${darken(0.5, '#9e59de')} 101.08%
  );
`

const globalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

  }

  #root {
    ${primaryGradientBackGround};
    width: 100vw;
    min-height: 100vh;
    height: auto;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 16px 'Mulish', sans-serif;
  }

  button {
    cursor: pointer;
    border: 0;
  }

  li {
    list-style-type: none;
  }

  /* SweetAlert 2 */
  .swal2-title {
    display: block !important;

    font-family: 'Mulish';

    width: 393px;
    color: #373956;

    font-size: 24px;
    letter-spacing: 1.6px;
    line-height: 27px;
    text-align: center;

  }

  .swal2-modal {
    border-radius: 16px;
  }

  .swal2-header, .swal2-content, .swal2-actions {
    margin: 20px 0px;
  }

  button.swal2-confirm {
    height: 48px;
    align-self: stretch;
    border-radius: 10px;
    border: 0;
    margin: 0px 8px;
    padding: 0px 8px;
    background: ${({ theme: { colors } }) => colors.primary};
    font-family: 'Mulish';

    color: #fff;
    font-size: 17px;
    letter-spacing: 1.4px;
    line-height: 22px;
    text-align: center;

    display: flex;
    flex: .4;

    box-shadow: 0 5px 11px 0 rgba(122,120,120,0.43);
  }

  button.swal2-cancel {
    height: 48px;
    align-self: stretch;
    border-radius: 10px;
    border: 0;
    margin: 0px 8px;
    padding: 0px 8px;
    background: rgba(122,120,120,0.43);
    font-family: 'Mulish';

    color: #2a2a48;
    font-size: 17px;
    letter-spacing: 1.4px;
    line-height: 22px;
    text-align: center;

    display: flex;
    flex: .4;

    box-shadow: 0 5px 11px 0 rgba(122,120,120,0.43);
  }

  button.swal2-deny {
    height: 48px;
    align-self: stretch;
    border-radius: 10px;
    border: 0;
    margin: 0px 8px;
    padding: 0px 8px;
    background: rgba(122,120,120,0.43);
    font-family: 'Mulish';

    color: #2a2a48;
    font-size: 17px;
    letter-spacing: 1.4px;
    line-height: 22px;
    text-align: center;

    display: flex;
    flex: .4;

    box-shadow: 0 5px 11px 0 rgba(122,120,120,0.43);
  }

  .swal2-close {
    right: 24px;
  }

  /* React DatePicker */

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: ${({ theme: { colors } }) => colors.primary};
    color: #fff;
  }

  /* Input Range */

  input[type=range] {
    width: 100%;
    margin: 8.5px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    background: rgba(255, 107, 0, 0.78);
    border: 0.3px solid #9e59de;
    border-radius: 1.3px;
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type=range]::-webkit-slider-thumb {
    margin-top: -8.8px;
    width: 18px;
    height: 18px;
    background: #9e59de;
    border: 1px solid #9e59de;
    border-radius: 12px;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #9e59de;
  }

  input[type=range]::-moz-range-track {
    background: rgba(255, 107, 0, 0.78);
    border: 0.3px solid #9e59de;
    border-radius: 1.3px;
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type=range]::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #9e59de;
    border: 1px solid #9e59de;
    border-radius: 12px;
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 9.4px 0;
    color: transparent;
    width: 100%;
    height: 1px;
    cursor: pointer;
  }

  input[type=range]::-ms-fill-lower {
    background: #9e59de;
    border: 0.3px solid #9e59de;
    border-radius: 2.6px;
  }

  input[type=range]::-ms-fill-upper {
    background: rgba(255, 107, 0, 0.78);
    border: 0.3px solid #9e59de;
    border-radius: 2.6px;
  }
  input[type=range]::-ms-thumb {
    width: 18px;
    height: 18px;
    background: #9e59de;
    border: 1px solid #9e59de;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range]:focus::-ms-fill-lower {
    background: rgba(255, 107, 0, 0.78);
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #9e59de;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }

  /* Input type number */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /*
   * React Big Calendar
   */

  .rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

  button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled].rbc-btn {
    cursor: not-allowed; }

  button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0; }

  .rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch; }

  .rbc-calendar *,
  .rbc-calendar *:before,
  .rbc-calendar *:after {
    box-sizing: inherit; }

  .rbc-abs-full, .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

  .rbc-event-content {
    font-size: 12px;
  }

  .rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

  .rbc-rtl {
    direction: rtl; }

  .rbc-off-range {
    color: #999999; }

  .rbc-off-range-bg {
    background: ${({ theme: { colors } }) => colors.darkerGray};
  }

  .rbc-header {
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;

    padding-top: 12px;
    padding-bottom: 12px;

    min-height: 0;
    border: 0;
    /* border-bottom: 1px solid #DDD; */
  }
  .rbc-header + .rbc-header {
    /* border-left: 1px solid #DDD; */
    border: 0;
  }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border: 0;
    /* border-right: 1px solid #DDD; */
  }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
  }

  .rbc-header > span, .rbc-header > a {

    font-family: 'Mulish';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* display: flex; */
    /* align-items: center; */
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: rgba(252, 252, 252, 0.2);

  }

  .rbc-row-content {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
  }

  .rbc-row-content-scrollable {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .rbc-row-content-scrollable .rbc-row-content-scroll-container {
      height: 100%;
      overflow-y: scroll;
      /* Hide scrollbar for Chrome, Safari and Opera */
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
      .rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
        display: none; }

  .rbc-today {
    background-color: #2A2A2D;
  }

  .rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    font-size: 16px;
  }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* display: flex; */
    /* align-items: center; */
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #FCFCFC;
  }
    .rbc-toolbar button {
      color: #FFF;
      display: inline-block;
      margin: 0;
      text-align: center;
      vertical-align: middle;
      background: none;
      background-image: none;
      border: 1px solid #ccc;
      padding: .375rem 1rem;
      border-radius: 4px;
      line-height: normal;
      white-space: nowrap; }
      .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
        background-image: none;
        color: #373a3c;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        background-color: #e6e6e6;
        border-color: #adadad; }
        .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
          color: #373a3c;
          background-color: #d4d4d4;
          border-color: #8c8c8c; }
      .rbc-toolbar button:focus {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad; }
      .rbc-toolbar button:hover {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad; }

  .rbc-btn-group {
    display: inline-block;
    white-space: nowrap; }
    .rbc-btn-group > button:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .rbc-btn-group > button:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .rbc-btn-group > button:not(:first-child):not(:last-child) {
      border-radius: 0; }
    .rbc-btn-group button + button {
      margin-left: -1px; }
    .rbc-rtl .rbc-btn-group button + button {
      margin-left: 0;
      margin-right: -1px; }
    .rbc-btn-group + .rbc-btn-group,
    .rbc-btn-group + button {
      margin-left: 10px; }

  .rbc-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: ${({ theme: { colors } }) => colors.primary};
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left; }
    .rbc-slot-selecting .rbc-event {
      cursor: inherit;
      pointer-events: none; }
    .rbc-event.rbc-selected {
      background-color: ${({ theme: { colors } }) => colors.primary};
    }
    .rbc-event:focus {
      outline: 5px auto ${({ theme: { colors } }) => colors.primary};
    }

  .rbc-event-label {
    font-size: 80%; }

  .rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

  .rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

  .rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

  .rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

  .rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

  .rbc-row {
    display: flex;
    flex-direction: row; }

  .rbc-row-segment {
    padding: 0 1px 1px 1px; }

  .rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1); }

  a.rbc-show-more {
    color: #fff;
  }

  .rbc-show-more {
    /* background-color: rgba(255, 255, 255, 0.3); */
  background: ${({ theme: { colors } }) => transparentize(0.7, colors.primary)};
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
  }

  .rbc-month-view {
    position: relative;
    border: 0;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;

    background: ${({ theme: { colors } }) => colors.darkGray};
    overflow: hidden;

    border-radius: 12px;
    box-shadow: 0px 0px 61px 13px
    ${({ theme: { colors } }) => transparentize(0.75, colors.black)};
  }

  .rbc-month-header {
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.2);
  }

  .rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%; }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${({ theme: { colors } }) => colors.darkerGray};
  }

  .rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right;
  }
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: #FCFCFC;
    opacity: 0.8;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 4px;
    margin-right: 4px;
  }

  .rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden; }

  .rbc-day-bg {
    flex: 1 0 0%;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${({ theme: { colors } }) => colors.darkerGray};
  }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid ${({ theme: { colors } }) => colors.darkerGray};
  }

  .rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px; }
    .rbc-overlay > * + * {
      margin-top: 1px; }

  .rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px; }

  .rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: auto; }
    .rbc-agenda-view table.rbc-agenda-table {
      width: 100%;
      border: 1px solid #DDD;
      border-spacing: 0;
      border-collapse: collapse; }
      .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
        padding: 5px 10px;
        vertical-align: top; }
      .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
        padding-left: 15px;
        padding-right: 15px;
        text-transform: lowercase; }
      .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
        border-left: 1px solid #DDD; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
        border-left-width: 0;
        border-right: 1px solid #DDD; }
      .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
        border-top: 1px solid #DDD; }
      .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        padding: 3px 5px;
        text-align: left;
        border-bottom: 1px solid #DDD; }
        .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
          text-align: right; }

  .rbc-agenda-time-cell {
    text-transform: lowercase; }
    .rbc-agenda-time-cell .rbc-continues-after:after {
      content: ' »'; }
    .rbc-agenda-time-cell .rbc-continues-prior:before {
      content: '« '; }

  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    white-space: nowrap; }

  .rbc-agenda-event-cell {
    width: 100%; }

  .rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
    .rbc-time-column .rbc-timeslot-group {
      flex: 1; }

  .rbc-timeslot-group {
    border-bottom: 1px solid #DDD3;
    min-height: 40px;
    display: flex;
    flex-flow: column nowrap;
  }

  .rbc-time-gutter,
  .rbc-header-gutter {
    flex: none; }

  .rbc-label {
    padding: 0 5px;
    color: #fff3;
  }

  .rbc-day-slot {
    position: relative; }
    .rbc-day-slot .rbc-events-container {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      margin-right: 10px;
      top: 0; }
      .rbc-day-slot .rbc-events-container.rbc-rtl {
        left: 10px;
        right: 0; }
    .rbc-day-slot .rbc-event {
      border: 0;
      display: flex;
      max-height: 100%;
      min-height: 20px;
      flex-flow: column wrap;
      align-items: flex-start;
      overflow: hidden;
      position: absolute; }
    .rbc-day-slot .rbc-event-label {
      flex: none;
      padding-right: 5px;
      width: auto; }
    .rbc-day-slot .rbc-event-content {
      width: 100%;
      flex: 1 1 0;
      word-wrap: break-word;
      line-height: 1;
      height: 100%;
      min-height: 1em;
    }
  .rbc-day-slot .rbc-time-slot {
    border: 0;
  }

  .rbc-time-view-resources .rbc-time-gutter,
  .rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid #DDD;
    z-index: 10;
    margin-right: -1px; }

  .rbc-time-view-resources .rbc-time-header {
    overflow: hidden; }

  .rbc-time-view-resources .rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px; }

  .rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none; }

  .rbc-time-view-resources .rbc-day-slot {
    min-width: 140px; }

  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: 140px;
    flex: 1 1 0;
    flex-basis: 0 px; }

  .rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px; }

  .rbc-time-slot {
    flex: 1 0 0; }
    .rbc-time-slot.rbc-now {
      font-weight: bold; }

  .rbc-day-header {
    text-align: center; }

  .rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px; }

  .rbc-slot-selecting {
    cursor: move; }

  .rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    border: 0;
    min-height: 0;

    background: ${({ theme: { colors } }) => colors.darkGray};
    overflow: hidden;

    border-radius: 12px;
    box-shadow: 0px 0px 61px 13px
    ${({ theme: { colors } }) => transparentize(0.75, colors.black)};

  }
    .rbc-time-view .rbc-time-gutter {
      white-space: nowrap; }
    .rbc-time-view .rbc-allday-cell {
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      position: relative; }
    .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
      border-left: 1px solid #DDD; }
    .rbc-time-view .rbc-allday-events {
      position: relative;
      z-index: 4; }
    .rbc-time-view .rbc-row {
      box-sizing: border-box;
      min-height: 20px; }

  .rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;

  }
  .rbc-time-header.rbc-overflowing {
    border-right: 0;
  }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD7; }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD7; }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD7; }

  .rbc-time-header-cell-single-day {
    display: none; }

  .rbc-time-header-content {
    flex: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
    /* border-left: 1px solid #DDD7; */
    border: 0;
    background: rgba(0, 0, 0, 0.2);
  }
    .rbc-rtl .rbc-time-header-content {
      border-left-width: 0;
      border-right: 1px solid #DDD7; }
    .rbc-time-header-content > .rbc-row.rbc-row-resource {
      border-bottom: 1px solid #DDD7;
      flex-shrink: 0; }

  .rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 0;
    overflow-y: auto;
    position: relative;
  }

  .rbc-time-content > .rbc-time-gutter {
    flex: none;
  }

  .rbc-time-content > * + * > * {
    border-left: 1px solid #0001;
  }

  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #0001;
  }

  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
  }

  .rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${({ theme: { colors } }) => colors.primary};
    pointer-events: none; }


  /*
   * React Material Table
   */

  .MuiPaper-root {
    background: #302F37 !important;
    color: white !important;
    border-radius: 4px !important;
  }

  .MuiInput-underline:after {
    border-bottom-color: ${({ theme: { colors } }) => colors.primary};
  }

  .MuiSelect-select.MuiSelect-select,
  .MTablePaginationInner-root-14,
  .MuiIconButton-root,
  .MuiSelect-icon,
  .MuiInputBase-input,
  .material-icons,
  .MuiTableCell-root,
  .MuiFormHelperText-root,
  .MuiTypography-root.MuiTypography-caption {
    color: #FFFFFF !important;
  }

  .MuiTablePagination-root:last-child {
    border-bottom: 0 !important;
  }

  .MuiTableCell-root {
    border-top: 1px solid rgba(255, 255, 255, 0.16) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16) !important;
  }

  .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: ${({ theme: { colors } }) => colors.primary} !important;

    > span {
      color: ${({ theme: { colors } }) => colors.primary} !important;
    }
  }
`

export default globalStyles
