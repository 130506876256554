import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import {
  NewEventFormInput,
  NewEventFormOutput,
} from 'features/events/interfaces/EventsInterfaces'
import { Icon } from '@iconify/react'
import { TextAreaController } from 'components/Form/TextArea/TextAreaController'
import pt from 'date-fns/locale/pt'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { FieldAsyncSelectController } from 'components/Form/FieldAsyncSelect/FieldAsyncSelectController'
import { Monitors } from 'components/MonitorTable/queries/use-fetch-monitors'
import { AxiosError } from 'axios'
import { UsersApiResponse } from 'pages/Users/interfaces'
import { useCreateEvent } from 'features/events/mutations/use-mutate-create-event'
import api from 'services/api'
import { debounce } from 'lodash'
import { FieldSwitchController } from 'components/Form/FieldSwitch/FieldSwitchController'
import { FieldDateController } from 'components/Form/FieldDate/FieldDateController'

interface ModalNewEventProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
}

export default function ModalNewEvent(props: ModalNewEventProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, ...rest } = props

  const RESET_FORM: NewEventFormInput = useMemo(() => {
    return {
      title: '',
      startsAt: null,
      filter: null,
      contractType: null,
      trackIds: null,
      studentUserIds: null,
      monitorUserIds: null,
      hostUserId: null,
      urlLink: '',
      description: '',
      recordedMeeting: false,
    }
  }, [])

  registerLocale('pt', pt)
  setDefaultLocale('pt')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const createEventMutation = useCreateEvent()

  const showToast = useToastMessage()

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<NewEventFormInput>({
    resolver: yupResolver(schema),
    defaultValues: RESET_FORM,
    shouldUnregister: true,
  })

  const { data: trackAll } = useGetAllTracks()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: NewEventFormOutput) => {
    try {
      delete data?.filter
      await createEventMutation.mutateAsync(data)
      showToast({
        title: 'Sucesso',
        description: 'Evento criado com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset(RESET_FORM)

      onClose()
    } catch (error) {
      reset(RESET_FORM)

      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível criar o evento.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const loadMonitors = (
    inputValue: string,
    callback: (options: { label: string; value: number }[]) => void,
  ) => {
    try {
      api
        .get('/admin/users/monitors?activityStatus=ACTIVE&&sort=asc', {
          params: {
            name: inputValue,
          },
        })
        .then(response => {
          const data: Monitors[] = response.data
          callback(
            data.map(result => {
              return {
                label: result.name,
                value: result.id,
              }
            }),
          )
        })
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível carregar os monitores'

      showToast({
        title: 'Aviso',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const loadUsers = (
    inputValue: string,
    callback: (options: { label: string; value: number }[]) => void,
  ) => {
    try {
      api
        .get<UsersApiResponse>('/admin/users/?sort=asc', {
          params: {
            name: inputValue,
            limit: 100,
            isActive: true,
          },
        })
        .then(response => {
          const data = response.data.data
          callback(
            data.map(result => {
              return {
                label: result.name,
                value: result.id,
              }
            }),
          )
        })
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível carregar os alunos'

      showToast({
        title: 'Aviso',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | useEffect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (props.isOpen) {
      reset(RESET_FORM)
    }
  }, [RESET_FORM, props.isOpen, reset])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        w="full"
        maxW="720px"
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Novo evento
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="20px">
            <FieldInputController
              placeholder="Defina um título para o evento"
              name="title"
              control={control}
              label="Título do evento"
              error={errors.title}
            />

            <FieldDateController
              label="Data do evento"
              name="startsAt"
              control={control}
              autoComplete={'off'}
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              error={errors.startsAt}
            />

            <FieldSelectController
              placeholder="Selecione um filtro (opcional)"
              name="filter"
              control={control}
              label="Filtros"
              isClearable
              options={[
                {
                  label: 'Trilha de Estudo',
                  value: 'Trilha de Estudo',
                },
                {
                  label: 'Monitor',
                  value: 'Monitor',
                },
              ]}
              error={errors.filter as any}
            />

            {watch('filter')?.value === 'Trilha de Estudo' && (
              <FieldSelectController
                placeholder="Selecione a trilha de estudo"
                name="trackIds"
                control={control}
                label="Trilha de Estudo"
                options={trackAll?.data?.map(item => {
                  return {
                    label: item.name,
                    value: item.id,
                  }
                })}
                error={errors.trackIds as any}
                isMulti={true as any}
              />
            )}

            {watch('filter')?.value === 'Monitor' && (
              <FieldAsyncSelectController
                control={control}
                name="monitorUserIds"
                loadOptions={debounce(loadMonitors, 1000)}
                cacheOptions
                label="Monitor"
                placeholder="Digite para pesquisar"
                defaultOptions={[]}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue ? 'Digite para pesquisar' : inputValue
                }
                isMulti={true as any}
                error={errors.monitorUserIds as any}
              />
            )}

            {watch('filter')?.value !== 'Aluno' && (
              <FieldSelectController
                placeholder="Selecione o Tipo de Contrato"
                name="contractType"
                control={control}
                label="Tipo de Contrato"
                options={[
                  {
                    label: 'Todos',
                    value: undefined,
                  },
                  {
                    label: 'PURCHASE',
                    value: 'PURCHASE',
                  },
                  {
                    label: 'TRIAL',
                    value: 'TRIAL',
                  },
                ]}
                error={errors.contractType as any}
              />
            )}

            <TextAreaController
              name="description"
              label={'Descrição do evento'}
              backgroundColor="#393940"
              placeholder="Escreva uma descrição para este evento"
              color="white"
              control={control}
              boxShadow="none !important"
              error={errors.description}
              maxLength={2000}
            />

            <FieldAsyncSelectController
              control={control}
              name="hostUserId"
              loadOptions={debounce(loadUsers, 1000)}
              label="Host do evento"
              placeholder="Selecione o usuário"
              cacheOptions
              defaultOptions={[]}
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? 'Digite para pesquisar' : inputValue
              }
              error={errors.hostUserId as any}
            />

            <Box mt="0px !important" w="full">
              <Box position={'relative'}>
                <FieldInputController
                  label={'Link'}
                  control={control}
                  name="urlLink"
                  placeholder="Insira o link"
                />

                <Icon
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '8%',
                    transform: 'translateY(-50%)',
                    zIndex: 9999999999999,
                  }}
                  icon="carbon:link"
                  color="#8F5CF1"
                />
              </Box>

              <Text
                mt="8px"
                fontSize="0.875rem"
                color="#E53E3E"
                textAlign="start"
              >
                {errors?.urlLink?.message && errors?.urlLink?.message}
              </Text>
            </Box>

            <FieldSwitchController
              label="Evento será gravado?"
              name="recordedMeeting"
              control={control}
            />
          </VStack>
        </ModalBody>

        <ModalFooter p="24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              onClose()
            }}
            w="full"
            variant="ghost"
            label="Cancelar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            label="Criar evento"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
