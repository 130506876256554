import React, { useCallback, useEffect, useState } from 'react'

import { Calendar } from 'components/Calendar'
import SectionTitle from 'components/SectionTitle'

import api from 'services/api'

import { Box } from '@chakra-ui/react'

import { EventPropGetter } from 'react-big-calendar'
import { useParams } from 'react-router-dom'
import { AgendasResponse, GetFormattedAgendaResponse } from './types'
import { kebabCase } from 'lodash'
import { parseISO } from 'date-fns'
import { formatAgendaTooltip } from 'helpers/formatAgendaTooltip'

type EventType = {
  end: Date
  start: Date
  font: string
  title: string
  isDone: boolean
}

export const TabCalendar: React.FC = () => {
  const { userId } = useParams() as { userId: string }
  const [events, setEvents] = useState<GetFormattedAgendaResponse | undefined>(
    undefined,
  )
  const [viewDate, setViewDate] = useState(new Date())

  const handleNavigate = useCallback((newDate: Date) => {
    setViewDate(newDate)
  }, [])

  const eventPropGetter = useCallback((event: EventType) => {
    if (event.isDone) {
      return {
        className: 'is_done',
      }
    }

    if (event.font === 'estudo-pendente') {
      return {
        className: 'is_pending_study',
      }
    }
  }, []) as EventPropGetter<EventType> | undefined

  useEffect(() => {
    const fetchData = async () => {
      const { data: response } = await api.get<AgendasResponse>(
        '/app/agendas',
        {
          params: {
            userId: userId,
            limit: 2000,
            year: viewDate.getFullYear(),
            month: viewDate.getMonth() + 1,
          },
        },
      )

      const parsedAgendas: GetFormattedAgendaResponse = {
        data: response?.data?.map(agenda => ({
          id: agenda.id,
          title: agenda.title,
          notes: agenda.notes,
          themes: agenda.themes,
          isDone: agenda.is_done,
          theme_id: agenda.theme_id,
          metadata: agenda?.metadata,
          subjects: agenda?.subjects,
          font: kebabCase(agenda.font),
          type: kebabCase(agenda.type),
          subject_id: agenda.subject_id,
          end: parseISO(agenda.finishes_at),
          start: parseISO(agenda.starts_at),
          relatedThemes: agenda.relatedThemes || [],
          pending_study_id: agenda.pending_study_id,
          studyTimeInMinutes: agenda.studyTimeInMinutes,
          //event feat
          description: agenda?.description ?? undefined,
          hostUser: agenda?.hostUser ?? undefined,
          recorded_meeting: agenda?.recorded_meeting ?? undefined,
          url_link: agenda?.url_link ?? undefined,
        })),
        meta: response?.meta,
      }

      setEvents(parsedAgendas)
    }

    fetchData()
  }, [userId, viewDate])

  return (
    <Box
      sx={{
        '.rbc-event': {
          margin: 0,
          width: '100%',
          border: 'none',
          cursor: 'pointer',
          textAlign: 'left',
          boxShadow: 'none',
          padding: '2px 5px',
          borderRadius: '5px',
          boxSizing: 'border-box',
          color: '#fff !important',
          backgroundColor: '#FF6B00 !important',
        },
        '.rbc-event.is_done': {
          backgroundColor: '#34A853 !important',
        },
        '.rbc-event.is_pending_study': {
          backgroundColor: '#A970FF !important',
        },
      }}
    >
      <SectionTitle>Revisões inteligentes</SectionTitle>

      <Calendar
        events={events?.data ?? []}
        onNavigate={handleNavigate}
        eventPropGetter={eventPropGetter}
        tooltipAccessor={(agenda: any) => {
          return formatAgendaTooltip(agenda)
        }}
      />
    </Box>
  )
}
