import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface Params {
  q?: string
  sort?: string // by field. Ex: sort=-name / sort=+name
  limit?: number
  page?: number
  globalGoalMax?: number
  globalGoalMin?: number
  monitorId?: number
  contractTime?: string | number
  studentViewOrder?: string
}

interface MetaResponse {
  total: number
  per_page: number
  current_page: number
  last_page: number
  first_page: number
  first_page_url: string
  last_page_url: string
  next_page_url: string | null
  previous_page_url: string | null
}

export interface ContractStudent {
  id: number
  user_id: 347
  track_id: number
  initial_date: string
  final_date: string
  canceled_at: string
  created_at: string
  updated_at: string
  type: 'PURCHASE' | 'TRIAL'
}

export interface Advisor {
  correctAnswersPercentage: string
  daysWithoutAccess: number
  effortPerceptionPercentage: string
  email: string
  mobilePhone: string
  globalGoal: string
  id: number
  name: string
  numberOfQuestions: number
  studentAdvisorRecords: []
  whatsappGroupLink: string
  isStudentRecordViewed: boolean
  avatar: {
    id: number
    user_id: number
    url: string
    created_at: string
  }
  contract: ContractStudent[]
}

interface ContactedStudents {
  totalContacted: number
  totalContactedPercentage: number
  totalStudents: number
}

interface GetAdvisorResponse {
  meta: MetaResponse
  data: Advisor[]
  contactedStudents: ContactedStudents
}

async function getAdvisor(
  params?: Params,
): Promise<GetAdvisorResponse | undefined> {
  const { data } = await api.get<{
    meta: MetaResponse
    data: Advisor[]
    contactedStudents: ContactedStudents
  }>('/advisor/students', {
    params: {
      sort: params?.sort && params.sort,
      globalGoalMin: params?.globalGoalMin && params?.globalGoalMin,
      globalGoalMax: params?.globalGoalMax && params?.globalGoalMax,
      monitorId: params?.monitorId,
      q: params?.q,
      limit: params?.limit,
      page: params?.page,
      contractTime: params?.contractTime,
      studentViewOrder: params?.studentViewOrder,
    },
  })

  return data
}

export function useGetAdvisor(params?: Params) {
  return useQuery(['advisorFetchTable', params], () => getAdvisor(params), {
    refetchOnWindowFocus: false,
  })
}
