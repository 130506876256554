import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface ProductsOutput {
  contractFinalDate: string
  productCode: string
  productName: string
}

export async function GetProducts(): Promise<ProductsOutput[] | null> {
  const { data } = await api.get<ProductsOutput[]>('/admin/mentoria-products')

  return data
}

export function useGetProducts() {
  return useQuery(['get-events'], GetProducts, {
    refetchOnWindowFocus: false,
    retry: false,
  })
}
