import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { ResponsiveContainer } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;

  ${ResponsiveContainer}

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #d9d9d9;
    height: 8px;
    border-radius: 17px;
  }

  ::-webkit-scrollbar-thumb {
    background: #60606c;
    height: 8px;
    border-radius: 17px;
  }

  overflow-y: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const KPIsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const hoverStyle = css`
  background-color: ${({ theme: { colors } }) => colors.primary};
  transform: scale(1.04);
  transform: translateY(-4px);
`

export const KPICard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0px 12px 12px 0px;
  padding: 0px 24px;

  border: 1px solid ${({ theme }) => transparentize(0.8, theme.colors.primary)};
  height: 128px;
  width: calc(128px * 1.618);
  border-radius: 8px;

  transition: all 0.4s;

  &:hover {
    ${hoverStyle};
  }
`

export const KPITitle = styled.p`
  margin-bottom: 4px;
  text-transform: uppercase;
  font-weight: 100;
  text-align: center;
`

export const KPIValue = styled.p`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
`
export const ContainerAdvisor = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 24px;
  padding-top: 32px;
  padding-right: 24px;

  ${ResponsiveContainer}

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #d9d9d9;
    height: 8px;
    border-radius: 17px;
  }

  ::-webkit-scrollbar-thumb {
    background: #60606c;
    height: 8px;
    border-radius: 17px;
  }

  overflow-y: auto;
`
