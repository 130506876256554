import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponseApi } from 'types/response'

interface TracksResponseAPI {
  id: number
  name: string
  desription: string
  created_at: string
  updated_at: string
}

export async function fetchAllTracks(): Promise<{
  meta: MetaResponseApi
  data: TracksResponseAPI[]
}> {
  const { data } = await api.get<{
    meta: MetaResponseApi
    data: TracksResponseAPI[]
  }>('/admin/tracks')

  return data
}

export function useGetAllTracks() {
  return useQuery(['fetchTracks'], fetchAllTracks, {
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  })
}
