import React, { useCallback, useEffect, useState } from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import Swal from 'sweetalert2'
import format from 'date-fns/format'
import isSameMonth from 'date-fns/isSameMonth'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { ptBR } from 'date-fns/locale'

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { 'pt-BR': ptBR },
})

// Components
import Header from 'components/Header'

// Types
import { Agenda, AgendasResponse, BigCalendarEvent } from './interfaces'

// Services
import api from 'services/api'

// Styles
import Layout from 'layouts/Logged'
import { Container, Content } from './styles'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const Dashboard: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [searchDate, setSearchDate] = useState(() => new Date())
  const [agendas, setAgendas] = useState<Agenda[]>([])
  const [parsedAgendas, setParsedAgendas] = useState<BigCalendarEvent[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const alertCalendarEvent = useCallback((event: BigCalendarEvent) => {
    if (!event) return

    const formattedStartDate = format(event.start, 'dd/MM/yyyy HH:mm')

    Swal.fire({
      title: 'Revisão Inteligente',
      text: `${event.title} em ${formattedStartDate}h.`,
      buttonsStyling: false,
    })
  }, [])

  const viewChangeHandler = useCallback(
    (visualizedDate: Date) => {
      if (!visualizedDate || !searchDate) return

      if (isSameMonth(visualizedDate, searchDate)) return

      setSearchDate(visualizedDate)
    },
    [searchDate],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    /**
     * Update parsedAgendas whenever agendas vector change.
     */

    if (!agendas) return

    const parsedAgendas: BigCalendarEvent[] = agendas.map(agenda => ({
      start: new Date(agenda.starts_at),
      end: new Date(agenda.finishes_at),
      title: agenda.title,
    }))

    setParsedAgendas(parsedAgendas)
  }, [agendas])

  useEffect(() => {
    /**
     * Fetch agendas when searchDate month changes.
     */
    if (!searchDate) return

    api
      .get<AgendasResponse>('/app/agendas', {
        params: {
          month: searchDate.getMonth() + 1,
          year: searchDate.getFullYear(),
          limit: 2000,
        },
      })
      .then(({ data }) => setAgendas(data.data))
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Infelizmente não conseguimos consultar suas agendas agora.',
          buttonsStyling: false,
          icon: 'error',
        })
        console.trace('Error on fetching agendas: ', err)
      })
  }, [searchDate])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Revisões Inteligentes"
          subTitle="O seu guia customizado de estudos"
        />
        <Calendar
          onNavigate={date => viewChangeHandler(date)}
          onSelectEvent={alertCalendarEvent}
          localizer={localizer}
          events={parsedAgendas}
          culture={'pt-BR'}
          startAccessor="start"
          endAccessor="end"
          messages={{
            allDay: 'Todo dia',
            today: 'Hoje',
            previous: 'Anterior',
            next: 'Próximo',
            month: 'Mês',
            week: 'Semana',
            day: 'Dia',
            time: 'Hora',
            event: 'Evento',
            showMore: number => `+ ${number} ver mais`,
            yesterday: 'Ontem',
            tomorrow: 'Amanhã',
            agenda: 'Calendário',
            date: 'Data',
            work_week: 'Semana',
            noEventsInRange: 'Sem nenhuma agenda no intervalo',
          }}
          style={{ height: 720, marginTop: 40 }}
        />
        <Content />
      </Container>
    </Layout>
  )
}

export default Dashboard
