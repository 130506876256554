import styled from 'styled-components'
import { ResponsiveContainer } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 72px;
  padding-top: 32px;
  padding-right: 32px;

  ${ResponsiveContainer}

  overflow-y: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
