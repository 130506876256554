import React, { useEffect } from 'react'
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
} from '@chakra-ui/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { INSTITUTIONS } from 'constants/institutions'
import { useHistory } from 'react-router-dom'
import { useCreateExams } from 'features/simulation/mutations/use-mutate-create-exams'
import {
  CreateSimulationFormInput,
  CreateSimulationFormOutput,
} from 'features/simulation/types'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'

interface ModalSimulationNewProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
}

export default function ModalSimulationNew(props: ModalSimulationNewProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const createExamsMutation = useCreateExams()

  const { push } = useHistory()

  const showToast = useToastMessage()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateSimulationFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      institution: null,
      contractType: null,
      trackId: null,
    },
  })

  const { data: trackAll, isLoading: isTrackLoading } = useGetAllTracks()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: CreateSimulationFormOutput) => {
    try {
      const {
        title,
        institution,
        trackId,
        examProductCode,
        contractType,
      } = data

      const { id } = await createExamsMutation.mutateAsync({
        title,
        institution,
        trackId,
        published: false,
        contractType,
        examProductCode,
      })

      showToast({
        title: 'Sucesso',
        description: 'Simulação criada com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset({
        title: '',
        institution: null,
        trackId: null,
      })

      onClose()

      push(`/simulation/edit/${id}`)
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível criar a questão.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const institutionOptions = React.useMemo(() => {
    return INSTITUTIONS.map(institution => ({
      label: institution,
      value: institution,
    }))
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (props.isOpen) {
      reset({
        title: '',
        institution: null,
        trackId: null,
      })
    }
  }, [isOpen, props.isOpen, reset])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} isCentered size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Criação de novo simulado
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="24px">
            <FieldInputController
              placeholder="Digite o título do simulado"
              name="title"
              control={control}
              label="Título do Simulado"
              error={errors.title}
            />

            <FieldSelectController
              placeholder="Selecione ou digite a instituição"
              name="institution"
              control={control}
              label="Instituição"
              options={!institutionOptions ? [] : institutionOptions}
              error={errors.institution as any}
            />

            <FieldSelectController
              placeholder="Selecione o público deste simulado"
              name="contractType"
              control={control}
              label="Tipo de Aluno"
              options={[
                {
                  label: 'Purchase',
                  value: 'PURCHASE',
                },
                {
                  label: 'Trial',
                  value: 'TRIAL',
                },
              ]}
              error={errors.contractType as any}
            />

            <FieldSelectController
              placeholder="Selecione uma trilha de estudo"
              name="trackId"
              control={control}
              isLoading={isTrackLoading}
              label="Trilha de estudo"
              options={trackAll?.data?.map(item => {
                return {
                  label: item.name,
                  value: item.id,
                }
              })}
              error={errors.trackId as any}
            />

            <FieldInputController
              placeholder="Digite o código do simulado"
              name="examProductCode"
              control={control}
              label="Código do simulado (opcional)"
              error={errors.examProductCode}
            />
          </VStack>
        </ModalBody>

        <ModalFooter p="24px 18px 24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              onClose()
            }}
            w="full"
            variant="ghost"
            label="Cancelar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            label="Continuar"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
