import React, { ReactNode } from 'react'
import {
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalOverlayProps,
  Text,
  ThemingProps,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: ThemingProps<'Modal'>['size']
  children?: ReactNode
  title?: string
  titleNode?: ReactNode
  icon?: ReactNode
  subTitle: string
  onConfirmClick?: () => void
  confirmButtonIsLoading?: boolean
  isVerticalButton?: boolean
  modalContentProps?: ModalContentProps
  modalOverlayProps?: ModalOverlayProps
}

export const ModalDefaultTwoButton = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size = 'xs',
    title,
    titleNode,
    icon,
    subTitle,
    children,
    onConfirmClick,
    confirmButtonIsLoading,
    modalOverlayProps,
    modalContentProps,
    isVerticalButton,
  } = props

  return (
    <Modal isCentered isOpen={isOpen} size={size} onClose={onClose}>
      <ModalOverlay {...modalOverlayProps} />
      <ModalContent
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        boxShadow="none"
        {...modalContentProps}
      >
        <ModalBody p={'1.5rem'} borderRadius={'8px'}>
          <Flex w="100%" justifyContent={'center'} flexDir="column">
            <Flex flexDir="column">
              {icon && (
                <HStack justifyContent={'center'} mb="24px">
                  {icon}
                </HStack>
              )}

              {title && (
                <Text
                  fontFamily="Mulish"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize={'20px'}
                  color={'#FFFFFF'}
                  mb={'16px'}
                  textAlign={'center'}
                >
                  {title}
                </Text>
              )}

              {titleNode && titleNode}

              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                textAlign={'center'}
                fontSize="14px"
                lineHeight="18px"
                color="#BDBDC7"
              >
                {subTitle}
              </Text>
            </Flex>

            <Flex
              mt="24px"
              alignItems={'flex-end'}
              gap="12px"
              justifyContent={'center'}
              flexDir={isVerticalButton ? 'column' : 'row'}
            >
              <DefaultButton
                label="Não"
                size="md"
                onClick={onClose}
                variant="ghost"
                w={'100%'}
              />

              <DefaultButton
                label="Sim"
                size="md"
                w={'100%'}
                onClick={onConfirmClick}
                isLoading={confirmButtonIsLoading}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>

      {children}
    </Modal>
  )
}
