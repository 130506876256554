import React, { useEffect, useState } from 'react'

import { Kpi } from 'features/dashboard'

// Components
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Styles
import { Content, KPICard, KPITitle, KPIValue, KPIsContainer } from './styles'

export function DashboardAdmin() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [KPIs, setKPIs] = useState<Kpi[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    api.get<Kpi[]>('/admin/dashboard').then(({ data }) => {
      setKPIs(data)
    })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Content>
      <SectionTitle>KPI&apos;s</SectionTitle>
      <KPIsContainer>
        {KPIs.map(kpi => (
          <KPICard key={`kpi-card-id-${kpi.label}`}>
            <KPITitle>{kpi.label}</KPITitle>
            <KPIValue>{kpi.count}</KPIValue>
          </KPICard>
        ))}
      </KPIsContainer>
    </Content>
  )
}
