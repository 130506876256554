// Vendors
import React from 'react'
import { format } from 'date-fns'
import { Control, Controller, FieldError } from 'react-hook-form'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { Icon } from '@iconify/react'
// Styles
import {
  Flex,
  Input,
  FormLabel,
  InputProps,
  FormControl,
  FormErrorMessage,
  FormControlProps,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'

// Interfaces
export type FieldDateProps = Omit<ReactDatePickerProps, 'onChange'> & {
  name: string
  label?: string
  error?: FieldError
  containerProps?: FormControlProps
  inputProps?: InputProps
  isRequired?: boolean
  control: Control<any>
}

export const FieldDateController: React.FC<FieldDateProps> = (
  props: FieldDateProps,
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    error,
    placeholderText,
    selected,
    containerProps,
    inputProps,
    isRequired,
    control,
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl
      isInvalid={!!error}
      sx={{
        '.react-datepicker-wrapper': {
          width: '100%',
        },
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        {...containerProps}
      >
        {!!label && (
          <FormLabel fontWeight={600} color="#FFFFFF" htmlFor={name} m={0}>
            {label}
            {isRequired && (
              <Text
                ml={1}
                as="span"
                color="red.500"
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}
      </Flex>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            name={field.name}
            selected={field?.value}
            autoComplete={'off'}
            startDate={field?.value}
            endDate={undefined}
            onChange={field?.onChange}
            showTimeSelect
            customInput={
              props.customInput || (
                <InputGroup
                  border="none"
                  backgroundColor="#393940"
                  borderRadius="8px"
                  color="#FFFFFF"
                >
                  <Input
                    borderRadius="8px"
                    boxShadow="none !important"
                    border="none"
                    outline="none"
                    value={
                      field.value
                        ? format(field?.value, 'dd/MM/yyyy HH:mm')
                        : ''
                    }
                    onChange={e => {
                      field?.onChange(e.target.value)
                    }}
                    placeholder="Selecione a data do evento"
                    {...inputProps}
                  />

                  <InputRightElement>
                    <Icon icon="formkit:date" fontSize="16px" color="#8F5CF1" />
                  </InputRightElement>
                </InputGroup>
              )
            }
            {...rest}
          />
        )}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
