import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldFile } from 'components/Form/FieldFile'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { capitalize, get } from 'lodash'
import { useFetchArea } from '../mutations/use-get-area'
import { KillerConceptsFormInput, KillerConceptsFormOutput } from '../type/form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { useCreateKillerConcepts } from '../mutations/mutate-killer-concept'
import { useToastMessage } from 'components/Toast'
import { AxiosError } from 'axios'

interface ModalKillerConceptsProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
}

export default function ModalKillerConcepts(props: ModalKillerConceptsProps) {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, ...rest } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm<KillerConceptsFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      track: null,
      area: null,
      year: null,
      contractType: null,
      file: undefined,
    },
  })

  const { data: trackAll, isError: isTrackError } = useGetAllTracks()

  const {
    data: availableAreas,
    isError: isAreaError,
    isLoading: isLoadingArea,
  } = useFetchArea({
    trackId: Number(watch('track')?.value) ?? 0,
  })

  const {
    mutateAsync: mutateCreateKillerConcept,
    isLoading: isLoadingKillerConcept,
  } = useCreateKillerConcepts()

  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = useCallback(
    async (data: KillerConceptsFormOutput) => {
      try {
        const formContent = new FormData()
        formContent.append('fileDocument', data?.file?.[0])
        formContent.append('title', data?.title?.toString())
        formContent.append('fieldId', data?.area?.toString())
        formContent.append('year', data?.year?.toString())
        formContent.append('trackId', data?.track?.toString())
        formContent.append('contractType', data?.contractType?.toString())

        await mutateCreateKillerConcept(formContent)

        reset()

        onClose()

        showToast({
          title: 'Sucesso',
          description: 'Cadastro realizado com sucesso!',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })
      } catch (error) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível efetuar o cadastro.'

        showToast({
          title: 'Aviso',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [mutateCreateKillerConcept, onClose, reset, showToast],
  )

  useEffect(() => {
    if (watch('track')) {
      setValue('area', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, watch('track')])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest} isCentered>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Cadastro de Conceito Matador
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="24px">
            <FieldInputController
              placeholder="Digite o título do conceito matador"
              name="title"
              control={control}
              label="Título do Conceito Matador"
              error={errors.title}
            />

            <FieldSelectController
              placeholder="Favor inserir a trilha"
              name="track"
              control={control}
              label="Trilha"
              options={
                isTrackError
                  ? []
                  : trackAll?.data?.map(item => {
                      return {
                        value: item.id,
                        label: item.name,
                      }
                    })
              }
              error={errors?.track as any}
            />

            <FieldSelectController
              placeholder="Selecione a grande área"
              name="area"
              control={control}
              label="Grande Área"
              isLoading={isLoadingArea}
              options={
                isAreaError
                  ? []
                  : availableAreas?.fields.map(field => {
                      return {
                        label: capitalize(field.name),
                        value: field.id,
                      }
                    })
              }
              error={errors?.area as any}
            />

            <FieldSelectController
              placeholder="Selecione o ano"
              name="year"
              control={control}
              isMulti={true as any}
              label="Ano"
              options={[
                {
                  label: new Date().getFullYear(),
                  value: new Date().getFullYear(),
                },
                {
                  label: new Date().getFullYear() + 1,
                  value: new Date().getFullYear() + 1,
                },
                {
                  label: new Date().getFullYear() + 2,
                  value: new Date().getFullYear() + 2,
                },
              ]}
              error={errors?.year as any}
            />

            <FieldSelectController
              placeholder="Selecione o público deste simulado"
              name="contractType"
              control={control}
              label="Tipo de Aluno"
              options={[
                {
                  label: 'Purchase',
                  value: 'PURCHASE',
                },
                {
                  label: 'Trial',
                  value: 'TRIAL',
                },
              ]}
              error={errors.contractType as any}
            />

            <FieldFile
              fileName={get(watch('file'), '[0].name')}
              size="md"
              label="Upload do Arquivo"
              {...register('file')}
              type="file"
              allowedExtensions={['pdf']}
              error={errors?.file}
            />
          </VStack>
        </ModalBody>

        <ModalFooter p="50px 18px 24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              reset()
            }}
            w="full"
            variant="ghost"
            label="Limpar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isLoadingKillerConcept}
            label="Salvar"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
