import { BoxProps, Stack } from '@chakra-ui/react'
import React from 'react'

interface Props extends BoxProps {
  children: React.ReactNode
}

export function List({ children, ...rest }: Props) {
  return (
    <Stack
      p="8"
      my="8"
      spacing="8"
      borderRadius="8"
      bg="whiteAlpha.200"
      position="relative"
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#D9D9D9',
          borderRadius: '17px',
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#60606C',
          borderRadius: '17px',
          height: '8px',
        },
        '&::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
        },
      }}
      {...rest}
    >
      {children}
    </Stack>
  )
}
