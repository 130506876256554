import {
  Flex,
  Text,
  HStack,
  Box,
  Button,
  VStack,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { Icon } from '@iconify/react'
import './style.css'
type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'
import { AxiosError } from 'axios'
import { RESET_FORM } from 'components/MonitorTable/types'
import {
  Monitors,
  useGetAllMonitors,
} from 'components/MonitorTable/queries/use-fetch-monitors'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import {
  formatLabel,
  FormatTrail,
  FormatYear,
} from 'components/MonitorTable/ModalHelper'
import { useGetAllAdvisors } from 'components/MonitorTable/queries/use-fetch-advisor'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import { useUpdateMonitor } from 'components/MonitorTable/queries/use-patch-user'
import { yupValidator } from 'components/MonitorTable/schema'
import { useToastMessage } from 'components/Toast'
import { useGetWhatsappGroups } from 'features/whatsapp-groups/mutations/use-get-whatsapp-groups'
import { map } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { FormData } from '../../MonitorTable/types'
import { FieldSelect } from 'components/Form/FieldSelect'
import { ActionMeta } from 'react-select'
import { FieldSwitchController } from 'components/Form/FieldSwitch/FieldSwitchController'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: Size
  selectedRow: Monitors | undefined
} & ReactModalProps

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '256px',
    sm: '378px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalEditMonitor = (props: ModalProps) => {
  /*
  |-----------------------------------------------------------------------------
  | Consts.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, size, selectedRow, ...reactModalProps } = props
  const cancelRef = React.useRef<any>()

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(yupValidator) })
  const { data: trackAll, isError: isTrackError } = useGetAllTracks()
  const {
    data: whatsapGroupsData,
    isLoading: isLoadingWhatsappGroups,
  } = useGetWhatsappGroups({
    limit: 9999999,
  })
  const monitorMutate = useUpdateMonitor()
  const showToast = useToastMessage()
  const { data: ListAdvisor, error: ListAdvisorError } = useGetAllAdvisors()
  const { data: ListMonitor, error: GetAllMonitorsError } = useGetAllMonitors(
    {},
  )
  const {
    isOpen: disclosureIsOpen,
    onOpen: disclosureOnOpen,
    onClose: disclosureIsClose,
  } = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: FormData) => {
    if (!data) return
    try {
      await monitorMutate.mutateAsync({
        monitorId: data.monitor?.value!,
        dataInput: {
          advisorUserId: data.orientator!.value!,
          /* monitorStatus: 'OPEN', */
          trackIds: formatLabel(data.trail),
          years: formatLabel(data.year),
          mentoriaGroupIds: map(data.mentoriaGroupIds, 'value') as number[],
          monitorVideoLinks: data?.monitorVideoLinks
            ? [data.monitorVideoLinks]
            : undefined,
          activityStatus: data.activityStatus,
        },
      })

      showToast({
        title: 'Sucesso',
        description: 'Monitor editado com sucesso.',
        type: 'success',
        duration: 4000,
        mode: 'dark',
      })
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível realizar a edição do monitor.'

      showToast({
        title: 'Erro',
        description: message?.message ? message?.message : message,
        type: 'error',
        duration: 4000,
        mode: 'dark',
      })
    } finally {
      props.onClose()
    }
  }

  const handleRemoveLastItem = () => {
    const currentItems = getValues('mentoriaGroupIds')
    if (!currentItems) return disclosureIsClose()

    const filteredArray = currentItems.slice(0, -1)

    setValue('mentoriaGroupIds', filteredArray)
    disclosureIsClose()
  }

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!selectedRow) return

    if (props.isOpen) {
      reset(RESET_FORM)

      setValue('monitor', {
        label: selectedRow.name,
        value: selectedRow.id,
      })

      setValue(
        'trail',
        FormatTrail(selectedRow)?.map(item => {
          return {
            label: item?.label,
            value: item?.value,
          }
        }),
      )

      setValue(
        'year',
        FormatYear(selectedRow)?.map(item => {
          return {
            label: item,
            value: item,
          }
        }),
      )

      selectedRow.monitorDetail?.advisorUser &&
        setValue('orientator', {
          label: selectedRow.monitorDetail?.advisorUser.name,
          value: selectedRow.monitorDetail?.advisorUser.id,
        })

      selectedRow.mentoriaGroup &&
        setValue(
          'mentoriaGroupIds',
          selectedRow.mentoriaGroup.map(item => {
            return {
              label: `[${
                item.group_type === 'NEWBIE'
                  ? 'Novato'
                  : item.group_type === 'VETERAN'
                  ? 'Veterano'
                  : ''
              }] ${item?.name}`,
              value: item?.id,
            }
          }),
        )

      selectedRow?.monitorDetail?.monitor_video_links &&
        setValue(
          'monitorVideoLinks',
          selectedRow?.monitorDetail?.monitor_video_links?.[0],
        )

      selectedRow?.monitorDetail?.activity_status === 'ACTIVE'
        ? setValue('activityStatus', true)
        : setValue('activityStatus', false)
    }
  }, [props.isOpen, reset, selectedRow, setValue])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          padding: '24px',
          position: 'absolute',
          width: '100%',
          maxWidth: getWidthBySize('sm'),
          height: '100%',
          maxHeight: '628px',
          left: '50%',
          top: '54%',
          overflow: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#302F37',
          border: '0px',
        },
        overlay: {
          position: 'fixed',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 20,
          backgroundColor: 'rgba(14, 14, 15, 0.4)',
          height: '100%',
          overflowY: 'auto',
        },
      }}
      {...reactModalProps}
    >
      <Flex
        w="100%"
        gap={'32px'}
        justifyContent={'flex-start'}
        flexDir="column"
      >
        <Box>
          <HStack gap={'6px'} mb="8px" align="center" justify="space-between">
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="700"
              fontSize={{ base: '16px', sm: '24px' }}
              color={'#FFFFFF'}
              lineHeight={{ base: 'center', sm: '114.5%' }}
            >
              Edição de monitor
            </Text>

            <Icon
              fontSize={'24px'}
              onClick={props.onClose}
              color="#FFFFFF"
              icon="eva:close-fill"
              cursor={'pointer'}
            />
          </HStack>

          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize={{ base: '16px', sm: '14px' }}
            color={'#FFFFFF'}
            mt="6px"
            lineHeight={{ base: 'center', sm: '120%' }}
          >
            Adicione ou edite informações.
          </Text>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack gap={'15px'}>
            <FieldSelectController
              options={
                GetAllMonitorsError
                  ? []
                  : ListMonitor?.map(item => {
                      return {
                        label: item.name,
                        value: item.id,
                      }
                    })
              }
              isDisabled
              control={control}
              placeholder="Selecione um monitor"
              name={'monitor'}
              label={'Monitor'}
              error={errors.monitor as any}
            />

            <FieldSelectController
              control={control}
              name={'trail'}
              isMulti={true as any}
              options={
                isTrackError
                  ? []
                  : trackAll?.data?.map(item => {
                      return {
                        value: item.id,
                        label: item.name,
                      }
                    })
              }
              label={'Trilha'}
              placeholder="Selecione uma trilha"
              error={errors.trail as any}
            />

            <FieldSelectController
              control={control}
              name={'year'}
              isMulti={true as any}
              label={'Ano'}
              placeholder="Selecione o ano"
              options={[
                {
                  label: new Date().getFullYear(),
                  value: new Date().getFullYear(),
                },
                {
                  label: new Date().getFullYear() + 1,
                  value: new Date().getFullYear() + 1,
                },
                {
                  label: new Date().getFullYear() + 2,
                  value: new Date().getFullYear() + 2,
                },
              ]}
              error={errors.year as any}
            />

            <FieldSelectController
              control={control}
              name={'orientator'}
              label={'Orientador'}
              placeholder="Selecione um orientador"
              options={
                ListAdvisorError
                  ? []
                  : ListAdvisor?.map(item => {
                      return {
                        value: item.id,
                        label: item.name,
                      }
                    })
              }
              error={errors.orientator as any}
            />

            <FormControl isInvalid={!!errors.mentoriaGroupIds}>
              <FormLabel
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="600"
                fontSize="16px"
                lineHeight="20px"
                textAlign="left"
                letterSpacing="0.04em"
                htmlFor={'mentoriaGroupIds'}
                color={'#FFFFFF'}
              >
                Grupo de whats
              </FormLabel>

              <Controller
                name={'mentoriaGroupIds'}
                control={control}
                render={({ field }) => (
                  <FieldSelect
                    onChange={(
                      value: Array<{ label: string; value: number }>,
                      action: ActionMeta<
                        Array<{ label: string; value: number }>
                      >,
                    ) => {
                      if (action.removedValue) {
                        field.onChange(value)
                      } else {
                        field.onChange(value)

                        const mappedWatchSelectedMonitorIds = value?.map(
                          value => value?.value,
                        )

                        const selectedRowsMentoriaGroupIds = selectedRow?.mentoriaGroup?.map(
                          item => item.id,
                        )

                        const whatsapGroupsDataDiferrentFromSelectedMonitor = whatsapGroupsData?.data
                          ?.filter(
                            item =>
                              !!item.monitorUser &&
                              selectedRowsMentoriaGroupIds?.indexOf(item.id) ===
                                -1,
                          )
                          .map(item => item?.id)

                        const isLastedAdded =
                          mappedWatchSelectedMonitorIds?.[
                            mappedWatchSelectedMonitorIds.length - 1
                          ]

                        if (
                          whatsapGroupsDataDiferrentFromSelectedMonitor &&
                          isLastedAdded &&
                          whatsapGroupsDataDiferrentFromSelectedMonitor?.length >
                            0 &&
                          whatsapGroupsDataDiferrentFromSelectedMonitor.includes(
                            isLastedAdded,
                          )
                        ) {
                          disclosureOnOpen()
                        }
                      }
                    }}
                    value={field.value}
                    ref={field.ref}
                    isMulti={true as any}
                    options={
                      whatsapGroupsData?.data?.map(item => {
                        return {
                          label: `[${
                            item.groupType === 'NEWBIE'
                              ? 'Novato'
                              : item.groupType === 'VETERAN'
                              ? 'Veterano'
                              : ''
                          }] ${item?.name}`,
                          value: item?.id,
                        }
                      }) || []
                    }
                    placeholder="Insira o grupo do whatsapp"
                  />
                )}
              />

              {errors?.mentoriaGroupIds && (
                <FormErrorMessage color={'red.500'}>
                  {(errors?.mentoriaGroupIds as any)?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FieldInputController
              control={control}
              name={'monitorVideoLinks'}
              label={'Link do vídeo de apresentação'}
              placeholder="Insira o link da apresentação"
              error={errors.monitorVideoLinks}
            />

            <FieldSwitchController
              control={control}
              name="activityStatus"
              label={'Monitor Ativo?'}
              error={errors.activityStatus}
            />
          </VStack>

          <Flex mt="32px" gap="10px" alignItems={'center'}>
            <Button
              fontFamily="Mulish"
              fontWeight="700"
              fontSize="16px"
              color="#FFFFFF"
              height="50px"
              borderRadius="8px"
              border="1px solid #FFFFFF"
              maxW="160px"
              w="100%"
              onClick={() => {
                reset(RESET_FORM)
              }}
              backgroundColor={'#302F37'}
              _hover={{ backgroundColor: '#45454d' }}
            >
              Limpar
            </Button>

            <Button
              fontFamily="Mulish"
              isLoading={isSubmitting}
              fontWeight="700"
              fontSize="16px"
              color="#FFFFFF"
              height="50px"
              borderRadius="8px"
              maxW="160px"
              type="submit"
              w="100%"
              backgroundColor={'#7D46B1'}
              _hover={{ backgroundColor: '#703e9e' }}
            >
              Salvar
            </Button>
          </Flex>
        </form>
      </Flex>

      <AlertDialog
        isOpen={disclosureIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={disclosureIsClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Seleção de grupo de whats
            </AlertDialogHeader>

            <AlertDialogBody>
              O grupo já possui um monitor responsável, tem certeza que deseja
              prosseguir?
            </AlertDialogBody>

            <AlertDialogFooter display={'flex'} gap={4}>
              <Button colorScheme="red" onClick={handleRemoveLastItem} ml={3}>
                Deletar
              </Button>
              <Button ref={cancelRef} onClick={disclosureIsClose}>
                Continuar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ReactModal>
  )
}
