import { useMutation } from '@tanstack/react-query'
import api from 'services/api'
import { NewGoalFormDataOutput, NewGoalResponse } from '../interfaces'

async function createGoal(
  params: NewGoalFormDataOutput,
): Promise<NewGoalResponse> {
  const response = await api.post('/admin/track_goals', {
    ...params,
    trackId: +params.trackId.value,
  })

  return response.data
}

export function useCreateGoal() {
  return useMutation(createGoal, {
    onSuccess: () => {
      // queryClient.invalidateQueries(['get-goals'])
    },
  })
}
