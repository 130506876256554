import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable, { Filter } from 'material-table'

// Providers
import { localization } from 'providers/material-table/localization'
import { options } from 'providers/material-table/options'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Types
import { Goal, UsersApiResponse } from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import { ClearButton, Container, TableContainer } from './styles'
import { camelCase } from 'lodash'

const Goals: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [isLoading, setIsLoading] = useState(false)
  const [goals, setGoals] = useState<Goal[]>([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalGoals, setTotalGoals] = useState(0)
  const [filters, setFilters] = useState('')

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const fetchGoals = useCallback(
    (page: number, limit: number, filters: string) => {
      setIsLoading(true)
      api
        .get<UsersApiResponse>(
          `/admin/track_goals?page=${page}&limit=${limit}${filters}`,
        )
        .then(({ data }) => {
          setGoals(data.data)
          setTotalGoals(data.meta.total)
        })
        .catch()
        .finally(() => setIsLoading(false))
    },
    [],
  )

  const removeGoal = useCallback((goalId: number) => {
    api.delete(`/admin/track_goals/${goalId}`).then(() => {
      setGoals(currents => currents.filter(current => current.id !== goalId))
    })
  }, [])

  const newFiltersHandler = useCallback((newFilters: Filter<Goal>[]) => {
    setFilters(
      newFilters
        .map(({ column: { field }, operator, value }) => {
          return `&${camelCase(field)}${operator}${encodeURIComponent(value)}`
        })
        .join(''),
    )
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    fetchGoals(currentPage, perPage, filters)
  }, [currentPage, fetchGoals, filters, perPage])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Metas"
          subTitle="Lista completa de metas da plataforma"
        />
        <SectionTitle>Metas</SectionTitle>
        <TableContainer>
          <MaterialTable
            title="Lista de metas"
            localization={localization}
            editable={{
              onRowDelete: async d => removeGoal(d.id),
            }}
            columns={[
              {
                title: 'Trilha',
                field: 'track.name',
                type: 'string',
                resizable: true,
              },
              {
                title: 'Ano de Prova',
                field: 'trial_year',
                type: 'numeric',
                resizable: true,
              },
              {
                title: 'Mês',
                field: 'month',
                type: 'numeric',
                resizable: true,
              },
              {
                title: 'Ano',
                field: 'year',
                type: 'numeric',
                resizable: true,
              },
              {
                title: 'Percentual Acerto',
                field: 'correct_answers_percentage',
                type: 'string',
              },
              {
                title: 'Número Questões',
                field: 'number_of_questions',
                type: 'numeric',
              },
              {
                title: 'Tempo Estudo',
                field: 'study_minutes',
                type: 'datetime',
              },
              {
                title: 'Especialidade',
                field: 'intended_specialty',
                type: 'string',
              },
              {
                title: 'Senioridade',
                field: 'student_seniority',
                type: 'string',
                render: rowData => {
                  return rowData?.student_seniority === 'DEFAULT'
                    ? '-'
                    : rowData?.student_seniority === 'VETERAN'
                    ? 'Veterano'
                    : rowData?.student_seniority === 'NEWBIE'
                    ? 'Novato'
                    : ''
                },
              },
            ]}
            page={currentPage - 1}
            totalCount={totalGoals}
            onChangePage={(newPage, perPage) => {
              setCurrentPage(newPage + 1)
              setPerPage(perPage)
            }}
            onChangeRowsPerPage={newPerPage => setPerPage(newPerPage)}
            // onRowClick={(_, rowData) => {
            //   history.push(`/users/${rowData?.id}`)
            // }}
            onFilterChange={newFiltersHandler}
            data={goals}
            isLoading={isLoading}
            options={options}
          />
          {filters.length > 0 && (
            <ClearButton onClick={() => setFilters('')}>
              limpar filtros
            </ClearButton>
          )}
        </TableContainer>
      </Container>
    </Layout>
  )
}

export default Goals
