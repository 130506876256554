import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface QueryParams {
  eventId: number
}

async function deleteEvent(params: QueryParams): Promise<void> {
  await api.delete(`/admin/scheduled_events/${params.eventId}`)
}

export function useDeleteEvent() {
  return useMutation(deleteEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-events'])
    },
  })
}
