import {
  validateFileExtension,
  validateFileIsRequired,
  validateFileSize,
} from 'helpers/validateFile'
import { map } from 'lodash'
import * as Yup from 'yup'

export const FILE_SIZE = 50 * 1000 * 1000 // 50MB
export const FILE_SIZE_BYTES = FILE_SIZE / 1000 / 1000 // 50MB

export const schema = Yup.object().shape({
  title: Yup.string().required('O título é obrigatório.'),
  track: Yup.number()
    .transform((_, value) => {
      if (value) {
        return value.value
      }

      return null
    })
    .required('O nome da especialidade é obrigatório.')
    .typeError('O nome da especialidade é obrigatório.'),

  area: Yup.number()
    .nullable()
    .transform((_, value) => {
      if (value) {
        return value.value
      }

      return null
    })
    .required('O campo área é obrigatório'),

  year: Yup.string()
    .nullable()
    .transform((_, value) => {
      if (value) {
        const data = map(value, 'value')
        return data?.join(',')
      }

      return null
    })
    .required('O ano é obrigatório.'),

  contractType: Yup.string()
    .transform((value, originaValue) => originaValue && originaValue.value)
    .nullable()
    .required('O público é obrigatório'),

  file: Yup.mixed()
    .test('req', 'Por favor, carregue o arquivo.', validateFileIsRequired)
    .test(
      'fileSize',
      `O arquivo deve ser menor que ${FILE_SIZE_BYTES} MB`,
      value => {
        if (!value) return true
        return validateFileSize(value, FILE_SIZE)
      },
    )
    .test('fileSize', 'A extensão do arquivo permitida é apenas pdf', value =>
      validateFileExtension(value, 'application/pdf'),
    ),
})
