import api from 'services/api'
import { useQuery } from '@tanstack/react-query'
import { MetaResponse } from 'types/response'

interface ResetProjectXParams {
  user_id: number
  page: number
  limit: number
}

export interface ResetProjectX {
  id: number
  user_id: number
  activation_date: string
  reset_date: string
  created_at: string
  updated_at: string
}

interface ResetProjectXResponse {
  meta: MetaResponse
  data: ResetProjectX[]
}

async function getResetProjectXList(params: ResetProjectXParams) {
  const { data } = await api.get<ResetProjectXResponse>(
    `/admin/focus_learning/user/${params.user_id}`,
    {
      params: {
        page: params.page,
        limit: params.limit,
      },
    },
  )

  return data
}

export function useGetResetProjectXList(params: ResetProjectXParams) {
  return useQuery(
    ['getResetProjectX', params],
    () => getResetProjectXList(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
