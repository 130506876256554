import React from 'react'
import { useBreakpointValue, useToken } from '@chakra-ui/react'
import ReactApexChart from 'react-apexcharts'

type LineChartProps = {
  xaxis?: ApexXAxis
  yaxis?: ApexYAxis
  dataLabels?: ApexDataLabels
  options?: ApexCharts.ApexOptions
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries
}

const LineChart: React.FC<LineChartProps> = ({
  xaxis,
  yaxis,
  series,
  options,
  dataLabels,
}) => {
  const tickAmount = useBreakpointValue([4, 6, 8, 10])
  const isMobile = useBreakpointValue({ base: false, lg: true })
  const gray200 = useToken('colors', 'gray.200')

  if (!series) return null

  return (
    <ReactApexChart
      height="640px"
      series={series}
      options={{
        theme: {
          mode: 'dark',
        },
        chart: {
          foreColor: gray200,
          background: '#2a2a2d',
          height: 640,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 1,
        },
        tooltip: {
          theme: 'dark',
          x: {
            formatter: (_, opts) => {
              const seriesIndex = opts.seriesIndex
              const dataPointIndex = opts.dataPointIndex

              const title =
                opts.w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .tooltipTitle

              return title
            },
          },
        },
        colors: ['#FF6B00'],
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        dataLabels: {
          enabled: isMobile,
          ...dataLabels,
        },
        yaxis: {
          min: 0,
          decimalsInFloat: 0,
          ...yaxis,
        },
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },
          decimalsInFloat: 0,
          tickAmount: tickAmount,
          ...xaxis,
        },
        ...options,
      }}
    />
  )
}

export default LineChart
