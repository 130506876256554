import api from 'services/api'

import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'

async function deleteKillerConcepts({
  killerConceptsId,
}: {
  killerConceptsId: number
}) {
  await api.delete(`/admin/essential-concepts/${killerConceptsId}`)
}

export function useDeleteKillerConcepts() {
  return useMutation(deleteKillerConcepts, {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetch-killer-concepts'])
    },
  })
}
