import React, { useCallback, useEffect, useState } from 'react'
import { upperCase, groupBy, orderBy } from 'lodash'
import Swal from 'sweetalert2'
// import { useHistory } from 'react-router-dom'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Types
import {
  LastRegisterInDaysGroup,
  StudentLastRegisterInDays,
} from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import { Container, ChipsContainer, Chip, ChipText } from './styles'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Flex } from '@chakra-ui/react'

const Engagement: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  // const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [page, setPage] = useState(1)
  const [canLoadMore, setCanLoadMore] = useState(true)
  const [lastRegisterGroups, setLastRegisterGroups] = useState<
    LastRegisterInDaysGroup[]
  >([])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const openStudentDetailPageInNewTab = useCallback((studentId: number) => {
    const { host, protocol } = window.location
    const studentDetailUrl = `${protocol}//${host}/users/${studentId}`

    const newWindow = window.open(
      studentDetailUrl,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
  }, [])

  const fetchStudentsLastRegisterInDays = useCallback((page: number = 1) => {
    api
      .get<StudentLastRegisterInDays[]>('/admin/users/engagement', {
        params: { page },
      })
      .then(({ data }) => {
        if (!data.length) {
          Swal.fire({
            toast: true,
            title: 'Todos os registros já foram exibidos',
            position: 'bottom-right',
          })
          setCanLoadMore(false)
          return
        }

        const groupedData: {
          [key: string]: StudentLastRegisterInDays[]
        } = groupBy(data, 'last_access_in_days')

        const newGroupedData = Object.entries(groupedData).map(
          ([key, value]) => ({
            last_access_in_days: key,
            students: value,
          }),
        )

        const newGroupedSortedData = orderBy(
          newGroupedData,
          'last_access_in_days',
          'desc',
        )

        setLastRegisterGroups(currentData => [
          ...currentData,
          ...newGroupedSortedData,
        ])
      })
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Não foi possível buscar as incidências nesse momento',
          icon: 'error',
          buttonsStyling: false,
        })
        console.trace('Error on fetching incidences', err)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    fetchStudentsLastRegisterInDays(page)
  }, [fetchStudentsLastRegisterInDays, page])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Engajamento de alunos"
          subTitle="Tempo (em dias) desde o último registro de estudo"
        />
        {lastRegisterGroups.map(({ last_access_in_days, students }) => (
          <React.Fragment key={`group-key-${last_access_in_days}`}>
            <SectionTitle>
              {upperCase(last_access_in_days)} dias desde o último registro
            </SectionTitle>
            <ChipsContainer>
              {students.map(student => (
                <Chip
                  key={`student-key-${student.id}`}
                  onClick={() => openStudentDetailPageInNewTab(student.id)}
                >
                  <ChipText>{student.name}</ChipText>
                </Chip>
              ))}
            </ChipsContainer>
          </React.Fragment>
        ))}
        {!lastRegisterGroups.length && (
          <>
            <SectionTitle>Alunos</SectionTitle>
            <p>Nenhum registro encontrado.</p>
          </>
        )}

        {canLoadMore && (
          <Flex my="16px" maxW="189px">
            <DefaultButton
              w="full"
              label="Ver mais"
              onClick={() => setPage(current => 1 + current)}
            />
          </Flex>
        )}
      </Container>
    </Layout>
  )
}

export default Engagement
