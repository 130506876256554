import * as Yup from 'yup'

export const yupValidator = Yup.object().shape({
  monitor: Yup.mixed()
    .transform((v, o) => (o === '' ? null : v))
    .required('Favor, inserir um monitor'),

  trail: Yup.array()
    .nullable()
    .min(1, 'Favor, inserir uma trilha')
    .transform((v, o) => (o === '' ? null : v))
    .required('Favor, inserir uma trilha'),

  year: Yup.array()
    .nullable()
    .min(1, 'Favor, inserir um ano')
    .transform((v, o) => (o === '' ? null : v))
    .required('Favor, inserir um ano'),

  orientator: Yup.mixed()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required('Favor, inserir um orientador'),

  mentoriaGroupIds: Yup.array().when('activityStatus', {
    is: (value: string) => value === 'ACTIVE',
    then: Yup.array()
      .nullable()
      .min(1, 'Favor, inserir um grupo de mentoria')
      .transform((v, o) => (o === '' ? null : v))
      .required('Favor, inserir um grupo de mentoria'),
    otherwise: Yup.array().nullable(),
  }),

  monitorVideoLinks: Yup.string().optional(),
  /* .required('Favor, inserir um link do video do monitor')
    .test(
      'should contain embed in tag',
      'O link deve conter uma tag embed',
      value => {
        if (!value) {
          return true
        }

        return value.includes('embed')
      },
    ), */

  activityStatus: Yup.string().transform((value, originalValue) => {
    if (originalValue === true) {
      return 'ACTIVE'
    }

    return 'INACTIVE'
  }),
})
