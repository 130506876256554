import { Flex, Th, Thead, Text } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React from 'react'

interface TrTableHeadAdvisorProps {
  onViewClickOrderButton: () => void
  viewOrder: '+isStudentRecordViewed' | '-isStudentRecordViewed' | null
  onDaysOrder: () => void
  daysWithoutAccessOrder: '+daysWithoutAccess' | '-daysWithoutAccess' | null
  seniorityOrder: '+contractCreatedAt' | '-contractCreatedAt' | null
  onStudentOrderClick: () => void
}

export const TrTableHeadAdvisor = (props: TrTableHeadAdvisorProps) => {
  const {
    viewOrder,
    onViewClickOrderButton,
    onDaysOrder,
    daysWithoutAccessOrder,
    seniorityOrder,
    onStudentOrderClick,
  } = props

  return (
    <Thead textAlign={'center'}>
      <Th
        w={'160px !important'}
        minW="160px !important"
        textTransform={'none'}
        p="16px 0px 16px 16px"
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
      >
        <Flex w="100%" justifyContent={'flex-start'} gap="10px">
          <Text>Aluno</Text>

          {seniorityOrder === '-contractCreatedAt' && (
            <Icon
              icon={'uil:sort-amount-up'}
              fontSize={'18px'}
              color="#E296FD"
              cursor={'pointer'}
              onClick={onStudentOrderClick}
            />
          )}
          {seniorityOrder === '+contractCreatedAt' && (
            <Icon
              icon={'uil:sort-amount-down'}
              fontSize={'18px'}
              color="#E296FD"
              cursor={'pointer'}
              onClick={onStudentOrderClick}
            />
          )}
          {seniorityOrder === null && (
            <Icon
              icon={'uil:sort-amount-down'}
              fontSize={'18px'}
              color="#FFFFFF"
              cursor={'pointer'}
              onClick={onStudentOrderClick}
            />
          )}
        </Flex>
      </Th>

      <Th
        w="120px"
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex m="0 auto" justifyContent={'center'}>
          <Text>Meta Global</Text>
        </Flex>
      </Th>

      <Th
        w="120px"
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex m="0 auto" w="110px !important" justifyContent={'center'}>
          <Text>Qtd. realizadas</Text>
        </Flex>
      </Th>

      <Th
        w="80px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex m="0 auto" w="90px !important" justifyContent={'center'}>
          % Acerto
        </Flex>
      </Th>

      <Th
        w="115px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          % Esforço
        </Flex>
      </Th>

      <Th
        w="135px"
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex
          gap={'10px'}
          m="0 auto"
          w="150px !important"
          justifyContent={'center'}
          alignItems="center"
        >
          <Text>Dias sem acesso</Text>
          {daysWithoutAccessOrder === '+daysWithoutAccess' && (
            <Icon
              icon={'uil:sort-amount-up'}
              fontSize={'18px'}
              color="#E296FD"
              cursor={'pointer'}
              onClick={onDaysOrder}
            />
          )}
          {daysWithoutAccessOrder === '-daysWithoutAccess' && (
            <Icon
              icon={'uil:sort-amount-down'}
              fontSize={'18px'}
              color="#E296FD"
              cursor={'pointer'}
              onClick={onDaysOrder}
            />
          )}
          {daysWithoutAccessOrder === null && (
            <Icon
              icon={'uil:sort-amount-down'}
              fontSize={'18px'}
              color="#FFFFFF"
              cursor={'pointer'}
              onClick={onDaysOrder}
            />
          )}
        </Flex>
      </Th>

      <Th
        w="115px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Whatsapp
        </Flex>
      </Th>
      <Th
        w="90px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Prontuários
        </Flex>
      </Th>

      <Th
        w="90px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex
          w="100%"
          px="1rem"
          gap={'10px'}
          alignItems="center"
          justifyContent={'center'}
        >
          View
          {viewOrder === '+isStudentRecordViewed' && (
            <Icon
              fontSize={'18px'}
              cursor={'pointer'}
              icon={'uil:sort-amount-down'}
              color="#E296FD"
              onClick={onViewClickOrderButton}
            />
          )}
          {viewOrder === '-isStudentRecordViewed' && (
            <Icon
              fontSize={'18px'}
              cursor={'pointer'}
              icon={'uil:sort-amount-up'}
              color="#E296FD"
              onClick={onViewClickOrderButton}
            />
          )}
          {viewOrder === null && (
            <Icon
              fontSize={'18px'}
              cursor={'pointer'}
              icon={'uil:sort-amount-down'}
              color="#FFFF"
              onClick={onViewClickOrderButton}
            />
          )}
        </Flex>
      </Th>
    </Thead>
  )
}
