import { Button, Flex, Text, Link } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import './style.css'
type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: Size
  children?: ReactNode
  onConfirmClicked: () => void
} & ReactModalProps

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '252px',
    sm: '360px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const CustomModalApp = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    onConfirmClicked,
    size = 'sm',
    children,
    ...reactModalProps
  } = props

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          padding: '32px',
          position: 'absolute',
          width: '100%',
          maxWidth: getWidthBySize('sm'),
          height: '100%',
          maxHeight: '230px',
          left: '50%',
          top: '50%',
          overflow: 'auto',
          transform: 'translate(-50%, -50%)',
          background: '#302F37',
          border: '0px',
          borderRadius: '8px',
        },
        overlay: {
          position: 'fixed',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 99,
          backgroundColor: 'rgba(14, 14, 15, 0.3)',
          height: '100%',
          overflowY: 'auto',
        },
      }}
      {...reactModalProps}
    >
      <Flex
        className="confirmModal"
        w="100%"
        justifyContent={'center'}
        flexDir="column"
      >
        <Flex flexDir="column">
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize={'24px'}
            color={'#F5F5FA'}
            textAlign={'center'}
          >
            Tem certeza que deseja continuar?
          </Text>
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            textAlign={'center'}
            px={{ base: '0px', sm: '2rem' }}
            fontSize="12px"
            lineHeight="15px"
            mt="8px"
            color="rgba(255, 255, 255, 0.8)"
          >
            Se continuar, todos os views da tabela do aluno serão resetados.
          </Text>
        </Flex>

        <Flex
          mt="24px"
          alignItems={'flex-end'}
          gap="12px"
          justifyContent={'center'}
        >
          <Button
            fontFamily="Mulish"
            font-weight="700"
            font-size="16px"
            color="#FFFFFF"
            height="42px"
            borderRadius="12px"
            onClick={onClose}
            variant="ghost"
            maxW="128px"
            w="100%"
            border="1px solid #FFFFFF"
            _hover={{ backgroundColor: '#45454d' }}
          >
            Cancelar
          </Button>
          <Button
            _hover={{ backgroundColor: '#7d39be' }}
            w="100%"
            borderRadius="12px"
            background={'#7D46B1'}
            border="1px solid #7D46B1"
            maxW={'125px'}
            outline="none !important"
            height="42px"
            fontFamily="Mulish"
            font-weight="700"
            font-size="16px"
            color="#F5F5FA"
            onClick={onConfirmClicked}
          >
            Sim
          </Button>
        </Flex>
      </Flex>
      {children}
    </ReactModal>
  )
}
