import { Switch, SwitchProps } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

interface FieldSwitchProps extends SwitchProps {}

const FieldSwitchComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldSwitchProps
> = (props, ref) => {
  const { children, ...rest } = props
  return (
    <Switch ref={ref} colorScheme="purple" data-qa={props.name} {...rest}>
      {children}
    </Switch>
  )
}

export const FieldSwitch = forwardRef(FieldSwitchComponent)
