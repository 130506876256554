import {
  Badge,
  Button,
  Flex,
  IconButton,
  Image,
  TableRowProps,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import React, { useMemo, useRef, useState } from 'react'
import { Icon } from '@iconify/react'
import { AiFillCheckSquare } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
interface TrTableCallsProps extends TableRowProps {
  avatar: string | null
  student: string
  daysWithoutAccess: number
  globalGoal: string
  questions: number
  correctAnswersPercentage: string
  effort: string
  view: boolean
  userId: number
  isNewStudentUntil15Days: boolean | undefined
  isNewStudentUntil30Days: boolean | undefined
  isVeteranAfter30Days: boolean | undefined
  onEditClickButton?: () => void
  onClickOpenWhatsLink?: () => void
  isModalOpen?: boolean
  onViewSetToTrue?: any
  onViewSetToFalse?: any
}

export function TrTableBodyAdvisor(props: TrTableCallsProps) {
  const {
    avatar,
    student,
    daysWithoutAccess,
    globalGoal,
    effort,
    questions,
    isModalOpen,
    userId,
    correctAnswersPercentage,
    isNewStudentUntil15Days,
    isNewStudentUntil30Days,
    isVeteranAfter30Days,
    onEditClickButton,
    onClickOpenWhatsLink,
    view,
    onViewSetToTrue,
    onViewSetToFalse,
    ...rest
  } = props

  const avatarEndpoint = useMemo(() => {
    if (!student) return ''

    /**
     * See here for more options:
     * https://ui-avatars.com/
     */
    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${student.replaceAll(' ', '+')}`
  }, [student])

  const [isWhatsHovering, setIsWhatsHovering] = useState(false)
  const { push } = useHistory()

  const IconButtonMedicalRecord = useRef<SVGSVGElement>(null)

  const handleMouseEnter = () => {
    if (IconButtonMedicalRecord.current) {
      IconButtonMedicalRecord.current.style.color = '#E296FD'
    }
  }

  const handleMouseLeave = () => {
    if (IconButtonMedicalRecord.current) {
      IconButtonMedicalRecord.current.style.color = '#FFFF'
    }
  }

  return (
    <>
      <Tr pb="1rem" verticalAlign={'baseline'} {...rest}>
        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
          minW="140px"
          whiteSpace="nowrap"
        >
          <Flex
            w="100%"
            minW={'200px'}
            justifyContent="flex-start"
            alignItems="center"
            lineHeight={'15px'}
            onClick={() => userId && push(`/users/${userId}`)}
            cursor="pointer"
          >
            {avatar ? (
              <Image
                mr="10px"
                w="15px"
                h="15px"
                borderRadius="6px"
                src={avatar}
              />
            ) : (
              <Image
                mr="10px"
                w="15px"
                h="15px"
                borderRadius="6px"
                src={avatarEndpoint}
              />
            )}

            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="12px"
              lineHeight={'15px'}
              color="#FFFFFF"
              mr="10px"
            >
              {student && student.length > 15 ? (
                <Tooltip label={student}>{letterCounter(student, 15)}</Tooltip>
              ) : (
                student
              )}
            </Text>

            <Text
              color={'#0DDF15'}
              fontFamily="Mulish"
              fontWeight="700"
              fontSize="12px"
              lineHeight="18px"
            >
              {isNewStudentUntil15Days && 'Novo'}
            </Text>

            <Text
              color={'#E5C009'}
              fontFamily="Mulish"
              fontWeight="700"
              fontSize="12px"
              lineHeight="18px"
            >
              {isNewStudentUntil30Days && 'Junior'}
            </Text>

            <Text
              color={'#E56000'}
              fontFamily="Mulish"
              fontWeight="700"
              fontSize="12px"
              lineHeight="18px"
            >
              {isVeteranAfter30Days && 'Veterano'}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex w="100%" minW={'75px !important'} justifyContent={'center'}>
            {globalGoal && (
              <Badge
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="600"
                fontSize="12px"
                lineHeight={'15px'}
                maxW="46px"
                w="100%"
                p="4px 6px"
                borderRadius="4px"
                textAlign={'center'}
                color={'#302F37'}
                backgroundColor={
                  +globalGoal <= 60
                    ? '#F7B9B9'
                    : +globalGoal > 60 && +globalGoal <= 80
                    ? '#E4E2AC'
                    : +globalGoal > 80
                    ? '#B2DDBF'
                    : undefined
                }
              >
                {Number(globalGoal).toFixed() + '%'}
              </Badge>
            )}

            {!globalGoal && (
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="12px"
                lineHeight={'15px'}
              >
                Sem dados
              </Text>
            )}
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex
            w="100%"
            minW="60px !important"
            alignSelf={'center'}
            justifyContent={'center'}
          >
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="12px"
              lineHeight={'15px'}
              color={!questions ? '#BDBDC7' : '#FFFFFF'}
            >
              {questions}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderBottom="none"
          borderRight={'1px solid #60606C'}
        >
          <Flex w="100%" minW="80px !important" justifyContent={'center'}>
            {correctAnswersPercentage && (
              <Badge
                fontFamily="Mulish"
                p="4px 6px"
                maxW="46px"
                w="100%"
                fontStyle="normal"
                borderRadius="4px"
                fontWeight="600"
                fontSize="12px"
                lineHeight={'15px'}
                textAlign={'center'}
                color={'#302F37'}
                backgroundColor={
                  +correctAnswersPercentage <= 60
                    ? '#F7B9B9'
                    : +correctAnswersPercentage > 60 &&
                      +correctAnswersPercentage <= 80
                    ? '#E4E2AC'
                    : +correctAnswersPercentage > 80
                    ? '#B2DDBF'
                    : undefined
                }
              >
                {Number(correctAnswersPercentage).toFixed() + '%'}
              </Badge>
            )}

            {!correctAnswersPercentage && (
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="12px"
                lineHeight={'15px'}
              >
                Sem dados
              </Text>
            )}
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderBottom="none"
          borderRight={'1px solid #60606C'}
        >
          <Flex
            w="100%"
            maxW={'80px'}
            minW="70px !important"
            justifyContent={'center'}
          >
            {effort && (
              <Badge
                p="4px 6px"
                maxW="46px"
                w="100%"
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="600"
                fontSize="12px"
                lineHeight={'15px'}
                borderRadius="4px"
                textAlign={'center'}
                color={'#302F37'}
                backgroundColor={
                  +effort >= 8
                    ? '#F7B9B9'
                    : +effort === 6 || +effort === 7
                    ? '#E5C009'
                    : +effort <= 5
                    ? '#B2DDBF'
                    : '#FFFF'
                }
              >
                {Number(effort).toFixed() + '%'}
              </Badge>
            )}
            {!effort && (
              <Text
                fontFamily="Mulish"
                fontStyle="normal"
                fontWeight="400"
                fontSize="12px"
                lineHeight={'15px'}
              >
                Sem dados
              </Text>
            )}
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex minW={'100px !important'} justifyContent="center">
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              textAlign={'center'}
              fontSize="12px"
              lineHeight={'15px'}
              color={'#FFFFFF'}
            >
              {daysWithoutAccess + ' dias'}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex w="100%" minW="70px !important" justifyContent={'center'}>
            <Button
              fontFamily="Mulish"
              minW="28px !important"
              w="28px"
              _focus={{
                boxShadow: 'none',
                outline: 'none',
              }}
              h={'24px'}
              borderRadius={'4px'}
              backgroundColor={'#393940'}
              onMouseEnter={() => setIsWhatsHovering(true)}
              onMouseLeave={() => setIsWhatsHovering(false)}
              _hover={{
                backgroundColor: '#393940',
                outline: '1px solid #E296FD',
              }}
              onClick={onClickOpenWhatsLink}
              p="3px 6px 3px 6px"
            >
              <Icon
                icon={'mdi:whatsapp'}
                fontSize="15px"
                color={isWhatsHovering ? '#E296FD' : '#FFFF'}
              />
            </Button>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderBottom="none"
          borderRight={'1px solid #60606C'}
        >
          <Flex
            w="100%"
            maxW={'80px'}
            minW="45px !important"
            justifyContent={'center'}
          >
            <IconButton
              aria-label={'edit'}
              backgroundColor="#393940"
              _hover={{
                backgroundColor: '#393940',
                outline: '1px solid #E296FD',
              }}
              borderRadius={'4px'}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              _focus={{
                boxShadow: 'none',
                outline: 'none',
              }}
              w="24px"
              h="24px"
              minW={'24px !important'}
              onClick={onEditClickButton}
            >
              {isModalOpen ? (
                <Icon
                  icon="eva:file-remove-outline"
                  color={'#FFFFFF'}
                  fontSize={'18px'}
                  ref={IconButtonMedicalRecord}
                />
              ) : (
                <Icon
                  ref={IconButtonMedicalRecord}
                  icon="eva:file-remove-outline"
                  fontSize={'18px'}
                />
              )}
            </IconButton>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderBottom="none"
        >
          <Flex
            w="100%"
            maxW={'80px'}
            minW="45px !important"
            justifyContent={'center'}
          >
            {view ? (
              <IconButton
                aria-label={'edit'}
                icon={<AiFillCheckSquare color="#E296FD" size={'18px'} />}
                backgroundColor="#393940"
                _hover={{ backgroundColor: '#302F37' }}
                _focus={{
                  boxShadow: 'none',
                  outline: 'none',
                }}
                borderRadius={'4px'}
                w="24px"
                h="24px"
                minW={'24px !important'}
                onClick={onViewSetToFalse}
              />
            ) : (
              <IconButton
                aria-label={'edit'}
                _focus={{
                  boxShadow: 'none',
                  outline: 'none',
                }}
                icon={
                  <Icon
                    icon="eva:checkmark-square-2-outline"
                    color={view ? '#E296FD' : '#BDBDC7'}
                    fontSize={'18px'}
                  />
                }
                backgroundColor="#393940"
                _hover={{ backgroundColor: '#302F37' }}
                borderRadius={'4px'}
                w="24px"
                h="24px"
                minW={'24px !important'}
                onClick={onViewSetToTrue}
              />
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  )
}
