import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { Box, useBreakpointValue } from '@chakra-ui/react'

interface ScatterChartProps {
  series: any[]
  maxRangeChart?: number | undefined
}

export const ScatterChartAdvisor: React.FC<ScatterChartProps> = ({
  series,
  maxRangeChart,
}) => {
  const isSm = useBreakpointValue({ base: true, sm: false })

  return (
    <Box
      sx={{
        '.apexcharts-tooltip': {
          color: '#000',
        },
        '.apexcharts-tooltip-custom': {
          padding: '12px',
          borderRadius: '8px',
          borderWidth: '0px',
        },
        '.apexcharts-tooltip.apexcharts-theme-light': {
          border: '0',
          backgroundColor: '#302F37',
        },
      }}
    >
      <ReactApexChart
        type="scatter"
        series={series}
        height={484}
        options={{
          grid: {
            padding: {
              left: isSm ? 10 : 54,
              right: isSm ? 10 : 54,
              bottom: isSm ? 10 : 20,
              top: isSm ? 25 : 40,
            },
          },
          chart: {
            type: 'scatter',
            foreColor: '#FFFFFF',
            background: '#302F37',
            zoom: {
              enabled: true,
              type: 'xy',
            },
            toolbar: {
              tools: {
                download: false,
              },
              offsetX: isSm ? 0 : -45,
              offsetY: isSm ? 15 : 30,
            },
            fontFamily: 'Mulish',
          },
          xaxis: {
            tickAmount: 10,
            max: maxRangeChart,
            min: 0,
            title: {
              text: 'Quantidade de alunos',
              style: {
                fontFamily: 'Mulish',
                fontSize: '16px',
                fontWeight: '600',
              },
            },
          },
          yaxis: {
            tickAmount: 7,
            title: {
              text: 'Dias sem acesso',
              offsetX: isSm ? 3 : -10,
              style: {
                fontFamily: 'Mulish',
                fontSize: '16px',
                fontWeight: '600',
              },
            },
            labels: {
              offsetX: isSm ? 0 : 24,
            },
          },
          tooltip: {
            custom: (test: any) => {
              const data =
                test.w.globals.initialSeries[test.seriesIndex].data[
                  test.dataPointIndex
                ]

              const [firstItem, daysWithouthAcess, name] = data

              return (
                '<ul style="background:#302F37; border: none;" class="apexcharts-tooltip-custom">' +
                '<li><b style="color:#BDBDC7; font-size:16px; font-family: \'Mulish\'; font-weight: 400;">Nome</b>' +
                `<span style="color:#FFFF; font-size:16px;">: ${name}</span>` +
                '<li><b style="color:#BDBDC7; font-size:16px; font-family: \'Mulish\'; font-weight: 400;">Meta Global</b>: ' +
                `<span style="color:#FFFF; font-size:16px;">: ${firstItem}%</span>` +
                '<li><b style="color:#BDBDC7; font-size:16px; font-family: \'Mulish\'; font-weight: 400;">Dias sem acesso</b>: ' +
                `<span style="color:#FFFF; font-size:16px;">: ${daysWithouthAcess}</span>` +
                '</li>' +
                '</li>' +
                '</ul>'
              )
            },
          },
          markers: {
            size: 5,
          },
          annotations: {
            position: 'back',
            xaxis: [
              {
                x: 0,
                x2: 61,
                fillColor: '#5A4547',
              },
              {
                x: 61,
                x2: 81,
                fillColor: '#57543B',
              },
              {
                x: 81,
                x2: maxRangeChart,
                fillColor: '#40544A',
              },
            ],
          },
          legend: {
            show: !isSm,
            offsetY: -16,
            horizontalAlign: 'left',
            offsetX: 52,
          },
        }}
      />
    </Box>
  )
}
