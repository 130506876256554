import { Flex, Box, Circle, Text } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React from 'react'

interface NoDataTableErrorProps {
  headerMessage?: string
  message?: string
}

export const NoDataTableError = (props: NoDataTableErrorProps) => {
  const { message, headerMessage } = props
  return (
    <Flex
      maxH="331px"
      w="100%"
      h="331px"
      alignItems={'center'}
      justifyContent="center"
      backgroundColor={'#302F37'}
      flexDir="column"
    >
      <Box>
        <Circle backgroundColor={'#60606C'} size={'44px'}>
          <Icon fontSize={'28px'} color="#E296FD" icon="bxs:error-circle" />
        </Circle>
      </Box>

      <Text
        mt="24px"
        fontWeight="600"
        fontSize="16px"
        lineHeight="20px"
        color="#FFFFFF"
      >
        {headerMessage && 'Dado não encontrado'}
      </Text>

      <Text color="#BDBDC7" fontWeight="600" fontSize="12px" lineHeight="20px">
        {message ? message : 'Por favor, tente novamente'}
      </Text>
    </Flex>
  )
}
