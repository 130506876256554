import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import {
  CreateNotificationFormInput,
  CreateNotificationFormOutput,
} from 'features/notifications/type'
import { Icon } from '@iconify/react'
import { TextAreaController } from 'components/Form/TextArea/TextAreaController'
import pt from 'date-fns/locale/pt'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { FieldAsyncSelectController } from 'components/Form/FieldAsyncSelect/FieldAsyncSelectController'
import { Monitors } from 'components/MonitorTable/queries/use-fetch-monitors'
import { AxiosError } from 'axios'
import { UsersApiResponse } from 'pages/Users/interfaces'
import { useCreateNotification } from 'features/notifications/mutations/use-mutate-create-notification'
import api from 'services/api'
import { debounce } from 'lodash'
import { FieldDateController } from 'components/Form/FieldDate/FieldDateController'

interface ModalNewNotificationProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
}

export default function ModalNewNotification(props: ModalNewNotificationProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, ...rest } = props

  const RESET_FORM: CreateNotificationFormInput = useMemo(() => {
    return {
      title: '',
      startsAt: null,
      expiresAt: null,
      filter: null,
      contractType: null,
      trackIds: null,
      examYears: null,
      monitorUserIds: null,
      urlLink: '',
      message: '',
      studentUserIds: null,
    }
  }, [])

  registerLocale('pt', pt)
  setDefaultLocale('pt')

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const createNotificationMutation = useCreateNotification()

  const showToast = useToastMessage()

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateNotificationFormInput>({
    resolver: yupResolver(schema),
    defaultValues: RESET_FORM,
    shouldUnregister: true,
  })

  const { data: trackAll } = useGetAllTracks()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: CreateNotificationFormOutput) => {
    try {
      delete data?.filter
      await createNotificationMutation.mutateAsync(data)

      showToast({
        title: 'Sucesso',
        description: 'Notificação criada com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset(RESET_FORM)

      onClose()
    } catch (error) {
      reset(RESET_FORM)

      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível criar a questão.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const loadMonitors = (
    inputValue: string,
    callback: (options: { label: string; value: number }[]) => void,
  ) => {
    try {
      api
        .get('/admin/users/monitors?activityStatus=ACTIVE&&sort=asc', {
          params: {
            name: inputValue,
          },
        })
        .then(response => {
          const data: Monitors[] = response.data
          callback(
            data.map(result => {
              return {
                label: result.name,
                value: result.id,
              }
            }),
          )
        })
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível carregar monitores'

      showToast({
        title: 'Aviso',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const loadUsers = (
    inputValue: string,
    callback: (options: { label: string; value: number }[]) => void,
  ) => {
    try {
      api
        .get<UsersApiResponse>('/admin/users/?sort=asc', {
          params: {
            name: inputValue,
            limit: 100,
            isActive: true,
          },
        })
        .then(response => {
          const data = response.data.data
          callback(
            data.map(result => {
              return {
                label: result.name,
                value: result.id,
              }
            }),
          )
        })
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível carregar alunos'

      showToast({
        title: 'Aviso',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | useEffect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (props.isOpen) {
      reset(RESET_FORM)
    }
  }, [RESET_FORM, props.isOpen, reset])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        w="full"
        maxW="720px"
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Nova notificação
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="20px">
            <FieldInputController
              placeholder="Digite o título da notificação"
              name="title"
              control={control}
              label="Título da notificação"
              error={errors.title}
            />

            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing="10px"
              color="white"
              w="full"
              align={'flex-start'}
              mt="0px !important"
            >
              <FieldDateController
                label="Data de Publicação"
                name="startsAt"
                control={control}
                autoComplete={'off'}
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeSelect
                error={errors.startsAt}
              />

              <FieldDateController
                label="Data de Expiração"
                name="expiresAt"
                control={control}
                autoComplete={'off'}
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeSelect
                error={errors.expiresAt}
              />
            </Stack>

            <FieldSelectController
              placeholder="Selecione o filtro"
              name="filter"
              control={control}
              label="Filtros"
              isClearable
              options={[
                {
                  label: 'Trilha de Estudo',
                  value: 'Trilha de Estudo',
                },
                {
                  label: 'Ano de Prova',
                  value: 'Ano de Prova',
                },
                {
                  label: 'Monitor',
                  value: 'Monitor',
                },
                {
                  label: 'Aluno',
                  value: 'Aluno',
                },
              ]}
              error={errors.filter as any}
            />

            {watch('filter')?.value === 'Trilha de Estudo' && (
              <FieldSelectController
                placeholder="Selecione a trilha de estudo"
                name="trackIds"
                control={control}
                label="Trilha de Estudo"
                options={trackAll?.data?.map(item => {
                  return {
                    label: item.name,
                    value: item.id,
                  }
                })}
                error={errors.trackIds as any}
                isMulti={true as any}
              />
            )}

            {watch('filter')?.value === 'Ano de Prova' && (
              <FieldSelectController
                placeholder="Selecione o ano de prova"
                name="examYears"
                control={control}
                label="Ano de Prova"
                options={[
                  {
                    label: new Date().getFullYear(),
                    value: new Date().getFullYear(),
                  },
                  {
                    label: new Date().getFullYear() + 1,
                    value: new Date().getFullYear() + 1,
                  },
                  {
                    label: new Date().getFullYear() + 2,
                    value: new Date().getFullYear() + 2,
                  },
                ]}
                error={errors.examYears as any}
                isMulti={true as any}
              />
            )}

            {watch('filter')?.value === 'Monitor' && (
              <FieldAsyncSelectController
                control={control}
                name="monitorUserIds"
                loadOptions={debounce(loadMonitors, 1000)}
                cacheOptions
                label="Monitor"
                placeholder="Digite para pesquisar"
                defaultOptions={[]}
                isMulti={true as any}
                error={errors.monitorUserIds as any}
              />
            )}

            {watch('filter')?.value === 'Aluno' && (
              <FieldAsyncSelectController
                control={control}
                name="studentUserIds"
                loadOptions={debounce(loadUsers, 1000)}
                label="Aluno"
                placeholder="Digite para pesquisar"
                cacheOptions
                defaultOptions={[]}
                isMulti={true as any}
                error={errors.studentUserIds as any}
              />
            )}

            {watch('filter')?.value !== 'Aluno' && (
              <FieldSelectController
                placeholder="Selecione o Tipo de Contrato"
                name="contractType"
                control={control}
                label="Tipo de Contrato"
                options={[
                  {
                    label: 'PURCHASE',
                    value: 'PURCHASE',
                  },
                  {
                    label: 'TRIAL',
                    value: 'TRIAL',
                  },
                ]}
                error={errors.contractType as any}
              />
            )}

            <Box w="full">
              <TextAreaController
                name="message"
                label={'Mensagem'}
                backgroundColor="#393940"
                placeholder="Escreva a mensagem"
                color="white"
                control={control}
                boxShadow="none !important"
                error={errors.message}
                maxLength={2000}
                resize={'none'}
                minH="140px"
                helperText={`Caracteres: ${watch('message')?.length || 0}/600`}
              />

              {watch('message')?.length >= 600 && (
                <Text color="#E5C009" w="full" textAlign="start" mt="8px">
                  O número recomendado de caracteres é de 600. Apesar disso,
                  será possível o cadastro.
                </Text>
              )}
            </Box>

            <Box mt="0px !important" w="full">
              <Box position={'relative'}>
                <FieldInputController
                  label={'Link'}
                  control={control}
                  name="urlLink"
                  placeholder="Insira o link"
                />

                <Icon
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '8%',
                    transform: 'translateY(-50%)',
                    zIndex: 9999999999999,
                  }}
                  icon="carbon:link"
                  color="#8F5CF1"
                />
              </Box>

              <Text
                mt="8px"
                fontSize="0.875rem"
                color="#E53E3E"
                textAlign="start"
              >
                {errors?.urlLink?.message && errors?.urlLink?.message}
              </Text>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter p="24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              onClose()
            }}
            w="full"
            variant="ghost"
            label="Cancelar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            label="Criar notificação"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
