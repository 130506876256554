import {
  validateFileExtension,
  validateFileIsRequired,
  validateFileSize,
} from 'helpers/validateFile'
import * as Yup from 'yup'

export const FILE_SIZE = 5 * 1000 * 1000 // 5MB
export const FILE_SIZE_BYTES = FILE_SIZE / 1000 / 1000 // 5MB

export const schema = Yup.object().shape({
  incidence: Yup.mixed()
    .test('req', 'Por favor, carregue o arquivo.', validateFileIsRequired)
    .test(
      'fileSize',
      `O arquivo deve ser menor que ${FILE_SIZE_BYTES} MB`,
      value => {
        if (!value) return true
        return validateFileSize(value, FILE_SIZE)
      },
    )
    .test('fileSize', 'A extensão do arquivo permitida é apenas pdf', value =>
      validateFileExtension(value, 'application/pdf'),
    ),

  locationName: Yup.string().required('O nome da localização é obrigatório'),
  institutionName: Yup.string().required('O nome da instituição é obrigatório'),
  trackId: Yup.number()
    .nullable()
    .transform((_, originalValue) => {
      if (originalValue) {
        return Number(originalValue.value)
      }

      return null
    })
    .required('O campo é obrigatório'),
  isFree: Yup.boolean()
    .nullable()
    .transform((_, originalValue) => {
      if (originalValue) {
        return originalValue.value
      }

      return null
    })
    .required('O campo é obrigatório'),
})
