import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface Params {
  isStudentRecordViewed: boolean
  studentId: number
}

async function patchView(params: Params) {
  await api.patch(`/advisor/student_record/${params?.studentId}/viewed`, {
    isStudentRecordViewed: params?.isStudentRecordViewed,
  })
}

export function usePathView() {
  return useMutation(patchView, {
    onSuccess: () => {
      queryClient.invalidateQueries(['advisorFetchTable'])
    },
  })
}
