import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

// Assets
import logo from 'assets/images/logo.png'
import bernardo from 'assets/images/bernardo.png'

// Contexts
import { useAuth } from 'contexts/auth'
import { LogInPayload } from 'contexts/auth/interfaces'

// Schemas
import { LogInSchema } from './schemas'

// Styles
import { Container } from './styles'
import { chakra, Flex, Text, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Swal from 'sweetalert2'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const LogIn: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { logIn, isLoading } = useAuth()
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<LogInPayload>({
    resolver: yupResolver(LogInSchema),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const submitHandler = useCallback(
    async (formData: LogInPayload) => {
      try {
        logIn(formData)
      } catch (err) {
        Swal.fire({
          title: 'Erro',
          text: 'Não foi possível efetuar o login. Tente novamente mais tarde',
          icon: 'error',
        })
      }
    },
    [logIn],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container>
      <img src={logo} alt="mentoria logo" id="logo" />
      <div>
        <Flex
          as="form"
          flexDir="column"
          justify="space-around"
          alignItems="center"
          w="514px"
          h="434px"
          maxW="90vw"
          maxH="calc(0.92 * 90vw)"
          bg="#2a2a2d"
          boxShadow="0px 0px 61px 13px rgba(0, 0, 0, 0.25)"
          py="24px"
          borderRadius="10px"
          onSubmit={handleSubmit(submitHandler)}
        >
          <Text fontSize="24px" textAlign="center" letterSpacing="0.2px">
            Acesse{' '}
            <chakra.span
              color="rgb(158, 89, 222)"
              fontWeight="bold"
              lineHeight="20px"
            >
              sua conta
            </chakra.span>
          </Text>

          <VStack
            px={{ base: '10px', sm: 'unset' }}
            spacing="1rem"
            maxW="380px"
            w="full"
          >
            <FieldInputController
              placeholder="E-mail"
              type="email"
              name="email"
              control={control}
              error={errors.email}
            />
            <FieldInputController
              placeholder="Senha"
              type="password"
              name="password"
              control={control}
              error={errors.password}
            />
          </VStack>

          <VStack spacing="1rem">
            <DefaultButton
              maxW="189px"
              w="full"
              isLoading={isLoading}
              type="submit"
              label="Entrar"
            />
            <Link to="/forgot_password/new">
              <Text color="rgba(255, 255, 255, 0.6)" fontSize="0.8rem">
                esqueci minha senha
              </Text>
            </Link>
          </VStack>
        </Flex>
        <img src={bernardo} alt="bernardo" id="bernardo-welcome" />
      </div>
      <p>Além da Medicina | Todos os direitos reservados</p>
    </Container>
  )
}

export default LogIn
