import React, { useState } from 'react'
import { Stack } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import ModalRegisterTerms from '../Modal/ModalRegisterTerms'

interface NavigationProps {
  onButtonClick: () => void
}

export default function Navigation(props: NavigationProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { onButtonClick } = props

  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isModalRegisterTermsOpen, setIsModalRegisterTermsOpen] = useState(
    false,
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack
      w="100%"
      my="2rem"
      justifyContent="start"
      direction={['column', 'row']}
    >
      <DefaultButton
        leftIcon={<Icon icon={'eva:file-text-outline'} />}
        label="Novo Simulado"
        onClick={onButtonClick}
        variant="secondary"
      />

      <DefaultButton
        leftIcon={<Icon icon={'eva:file-text-outline'} />}
        label="Cadastrar Termos de Uso"
        onClick={() => setIsModalRegisterTermsOpen(true)}
        variant="secondary"
      />

      <ModalRegisterTerms
        isOpen={isModalRegisterTermsOpen}
        onClose={() => setIsModalRegisterTermsOpen(false)}
      />
    </Stack>
  )
}
