import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface Params {
  title?: string
  institution?: string
  published: boolean
  id: number
  trackId: number
}

async function publishExam(params: Params) {
  await api.patch(`/admin/mock-exams/${params.id}`, params)
}

export function usePublishExam() {
  return useMutation(publishExam, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-simulation-exams'])
    },
  })
}
