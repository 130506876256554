import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { MetaResponse } from 'types/response'

export interface Notification {
  id: number
  title: string
  message: string
  created_at: string
  updated_at: string
  starts_at: string
  expires_at: string
  url_link: string
  students_param_filters_metadata: StudentsParamFiltersMetadata
  created_by_user_id: number
  createdByUser: CreatedByUser
  studentsCount: string
}

export interface StudentsParamFiltersMetadata {
  contractType: string
  studentUserIds: number[]
  trackIds: number[]
  monitorUserIds: number[]
  examYears: number[]
}

export interface CreatedByUser {
  id: number
  name: string
  canAccessSeren: boolean
}

export type GetNotificationsResponse = {
  meta: MetaResponse
  data: Notification[]
}

type QueryParams = {
  limit: number
  page: number
  sort?: string
}

export async function getNotifications(
  params: QueryParams,
): Promise<GetNotificationsResponse> {
  const { limit, page, sort } = params
  const { data } = await api.get<GetNotificationsResponse>(
    '/admin/notifications',
    {
      params: {
        limit,
        page,
        sort,
      },
    },
  )

  return data
}

export function useGetNotifications(params: QueryParams) {
  return useQuery(
    ['get-notifications', params],
    () => getNotifications(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
