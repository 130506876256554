import { useMutation } from '@tanstack/react-query'
import api from 'services/api'
import { queryClient } from 'App'

interface Params {
  registryId: number
  description: string
  date: string
  isRedFlag: boolean
}

export async function advisorUpdateRecord(params: Params) {
  await api.patch(`/advisor/student_record/${params.registryId}`, params)
}

export function useUpdateAdvisorRecord() {
  return useMutation(advisorUpdateRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries(['studentRecordByAdvisor'])
    },
  })
}
