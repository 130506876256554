// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react'

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    50: '#ffffff',
    100: '#fafafa',
    200: '#f5edfb',
    300: '#e0caf4',
    400: '#cba6ed',
    500: '#b683e6',
    600: '#9e59de',
    700: '#7827c3',
    800: '#6220a0',
    900: '#4c197c',
  },
  purple: {
    500: '#9e59de',
  },
}

export const theme = extendTheme({ colors })
