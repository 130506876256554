import { Stack } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import React from 'react'

interface NavigationProps {
  onButtonClick: () => void
}

export default function Navigation(props: NavigationProps) {
  const { onButtonClick } = props

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Stack
      w="100%"
      my="2rem"
      justifyContent="space-between"
      spacing={4}
      direction={['column', 'row']}
    >
      <DefaultButton
        leftIcon={<Icon icon={'eva:file-text-outline'} />}
        label="Novo Conceito"
        onClick={onButtonClick}
        variant="secondary"
      />
    </Stack>
  )
}
