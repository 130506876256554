export function validateFileSize(value: FileList, size: number) {
  if (!value || !value[0]) return false
  return value[0].size <= size
}

export function validateFileIsRequired(value: FileList) {
  if (!value || !value[0]) return false
  return Object.keys(value).length > 0
}

export const validateFileExtension = (
  file: any,
  extension: string | string[],
) => {
  if (file && file?.[0]?.type) {
    if (!extension.includes(file[0].type)) {
      return false
    }
  }
  return true
}
