import {
  Badge,
  Flex,
  IconButton,
  Image,
  TableRowProps,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'
import React, { useMemo } from 'react'
import { Icon } from '@iconify/react'
import { useAuth } from 'contexts/auth'

interface TrTableCallsProps extends TableRowProps {
  avatar: string | null
  advisor: string
  roleAttendance: boolean
  date: string
  redFlag: boolean | null
  onEditClickButton?: () => void
}

export function TrTableBodyUser(props: TrTableCallsProps) {
  const {
    avatar,
    advisor,
    roleAttendance,
    date,
    redFlag,
    onEditClickButton,
    ...rest
  } = props

  const avatarEndpoint = useMemo(() => {
    if (!advisor) return ''

    /**
     * See here for more options:
     * https://ui-avatars.com/
     */
    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${advisor.replaceAll(' ', '+')}`
  }, [advisor])

  return (
    <>
      <Tr pb="1rem" verticalAlign={'baseline'} {...rest}>
        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
          minW="230px"
        >
          <Flex
            w="100%"
            minW={'110px'}
            justifyContent="flex-start"
            alignItems="center"
            lineHeight={'15px'}
          >
            {avatar ? (
              <Image
                mr="10px"
                w="15px"
                h="15px"
                borderRadius="6px"
                src={avatar}
              />
            ) : (
              <Image
                mr="10px"
                w="15px"
                h="15px"
                borderRadius="6px"
                src={avatarEndpoint}
              />
            )}

            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="12px"
              lineHeight={'15px'}
              color="#FFFFFF"
            >
              {advisor && advisor?.length > 16 ? (
                <Tooltip label={advisor}>{letterCounter(advisor, 16)}</Tooltip>
              ) : advisor?.length > 0 ? (
                advisor
              ) : (
                'Usuário inexistente'
              )}
            </Text>

            <Badge
              fontFamily="Mulish"
              ml="10px"
              maxW="30px"
              w="100%"
              p="4px 6px"
              h="21px"
              fontStyle="normal"
              fontWeight="600"
              fontSize="12px"
              lineHeight={'15px'}
              borderRadius="8px"
              textAlign={'center'}
              color={'#FFFFFF'}
              backgroundColor={'#7D46B1'}
            >
              {roleAttendance && 'OE'}
            </Badge>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex
            minW={'90px !important'}
            justifyContent="flex-start"
            alignItems={'flex-start'}
            h="100%"
          >
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              textAlign={'center'}
              fontSize="12px"
              lineHeight={'15px'}
              color={'#FFFFFF'}
            >
              {date}
            </Text>
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderRight={'1px solid #60606C'}
          borderBottom="none"
        >
          <Flex minW={'80px !important'} justifyContent="center">
            {redFlag === null ? (
              '-'
            ) : redFlag ? (
              <Icon icon={'fluent:warning-24-filled'} color={'#FF6363'} />
            ) : !redFlag ? (
              <Icon icon={'heroicons-outline:x'} color={'#E296FD'} />
            ) : (
              ''
            )}
          </Flex>
        </Td>

        <Td
          h="-webkit-fit-content"
          justifyContent="space-between"
          pt="0px"
          pb="8px"
          px="1rem"
          lineHeight={'28px'}
          borderBottom="none"
          maxW={'130px'}
        >
          <Flex w="100%" minW="45px !important" justifyContent={'center'}>
            <IconButton
              aria-label={'edit'}
              icon={
                <Icon
                  icon="eva:file-remove-outline"
                  color={'#E296FD'}
                  fontSize={'18px'}
                />
              }
              backgroundColor="#393940"
              _hover={{
                backgroundColor: '#393940',
                outline: '1px solid #E296FD',
              }}
              borderRadius={'4px'}
              _focus={{
                boxShadow: 'none',
                outline: 'none',
              }}
              w="24px"
              h="24px"
              minW={'24px !important'}
              onClick={onEditClickButton}
            />
          </Flex>
        </Td>
      </Tr>
    </>
  )
}
