import React, { useEffect, useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  VStack,
  Box,
  ModalFooter,
  ModalProps,
  Text,
} from '@chakra-ui/react'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Icon } from '@iconify/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { useToastMessage } from 'components/Toast'
import { parseISO } from 'date-fns'
import { pt } from 'date-fns/locale'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  EditEventFormInput,
  EditEventFormOutput,
  Event,
} from 'features/events/interfaces/EventsInterfaces'
import { useUpdateEvent } from 'features/events/mutations/use-mutate-update-event'
import { FieldDateController } from 'components/Form/FieldDate/FieldDateController'
import { FieldAsyncSelectController } from 'components/Form/FieldAsyncSelect/FieldAsyncSelectController'
import { debounce } from 'lodash'
import api from 'services/api'
import { UsersApiResponse } from 'pages/Users/interfaces'
import { FieldSwitchController } from 'components/Form/FieldSwitch/FieldSwitchController'

interface ModalEditEventProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  event: Event | undefined
}

export default function ModalEditEvent(props: ModalEditEventProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, event, ...rest } = props
  registerLocale('pt', pt)
  setDefaultLocale('pt')

  /*
  |-----------------------------------------------------------------------------
  | Memo.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const RESET_FORM: EditEventFormInput = useMemo(() => {
    return {
      title: '',
      urlLink: '',
      startsAt: null,
      recordedMeeting: undefined,
      hostUserId: null,
    }
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()
  const updateEvent = useUpdateEvent()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<EditEventFormInput>({
    resolver: yupResolver(schema),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const onSubmit = async (data: EditEventFormOutput) => {
    if (!event) return

    try {
      await updateEvent.mutateAsync({
        id: +event.id,
        ...data,
      })

      showToast({
        title: 'Sucesso',
        description: 'Evento editado com sucesso.',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })

      reset(RESET_FORM)

      onClose()
    } catch (error) {
      reset(RESET_FORM)

      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível editar o evento.'

      showToast({
        title: 'Erro',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  const loadUsers = (
    inputValue: string,
    callback: (options: { label: string; value: number }[]) => void,
  ) => {
    try {
      api
        .get<UsersApiResponse>('/admin/users/?sort=asc', {
          params: {
            name: inputValue,
            limit: 100,
            isActive: true,
          },
        })
        .then(response => {
          const data = response.data.data
          callback(
            data.map(result => {
              return {
                label: result.name,
                value: result.id,
              }
            }),
          )
        })
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível carregar alunos'

      showToast({
        title: 'Aviso',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Effect.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!event) return

    if (isOpen) {
      event.title && setValue('title', event.title)
      event.url_link && setValue('urlLink', event.url_link)
      event.starts_at &&
        setValue('startsAt', new Date(parseISO(event.starts_at)))

      setValue('recordedMeeting', event.recorded_meeting)
      event?.hostUser?.id &&
        event.hostUser.name &&
        setValue('hostUserId', {
          label: event.hostUser.name,
          value: event.hostUser.id,
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any)}
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
        w="full"
        maxW="720px"
      >
        <ModalHeader display="flex" gap="10px" justifyContent="space-between">
          <Heading
            as="h1"
            color="white"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
          >
            Editar Evento
          </Heading>

          <ModalCloseButton
            top="0px"
            color="white"
            right="0px"
            position="relative"
          />
        </ModalHeader>

        <ModalBody p={'0px 18px'} borderRadius={'8px'}>
          <VStack gap="20px">
            <FieldInputController
              placeholder="Defina um título para o evento"
              name="title"
              control={control}
              label="Título do Evento"
              error={errors.title}
            />

            <FieldDateController
              label="Data de Publicação"
              name="startsAt"
              control={control}
              autoComplete={'off'}
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              error={errors.startsAt}
            />

            <Box mt="0px !important" w="full">
              <Box position={'relative'}>
                <FieldInputController
                  label={'Link'}
                  control={control}
                  name="urlLink"
                  placeholder="Insira o link do evento"
                />

                <Icon
                  style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '8%',
                    transform: 'translateY(-50%)',
                    zIndex: 9999999999999,
                  }}
                  icon="carbon:link"
                  color="#8F5CF1"
                />
              </Box>

              <Text
                mt="8px"
                fontSize="0.875rem"
                color="#E53E3E"
                textAlign="start"
              >
                {errors?.urlLink?.message && errors?.urlLink?.message}
              </Text>
            </Box>

            <FieldAsyncSelectController
              control={control}
              name="hostUserId"
              loadOptions={debounce(loadUsers, 1000)}
              label="Host do evento"
              placeholder="Digite para pesquisar"
              cacheOptions
              defaultOptions={[]}
              noOptionsMessage={({ inputValue }) =>
                !inputValue
                  ? 'Digite para pesquisar pelo nome do host'
                  : inputValue
              }
              error={errors.hostUserId as any}
            />

            <FieldSwitchController
              label="Evento será gravado?"
              name="recordedMeeting"
              control={control}
            />
          </VStack>
        </ModalBody>

        <ModalFooter p="24px 18px" display="flex" gap="10px">
          <DefaultButton
            onClick={() => {
              onClose()
            }}
            w="full"
            variant="ghost"
            label="Cancelar"
          />

          <DefaultButton
            w="full"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            label="Salvar evento"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
