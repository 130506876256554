import styled, { createGlobalStyle } from 'styled-components'
import { ResponsiveContainer, Scroll } from 'styles/common'

export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding: 24px;

  ${ResponsiveContainer}

  overflow-y: auto;

  ${Scroll}
`
