import styled from 'styled-components'
import { transparentize } from 'polished'
import { Scroll } from 'styles/common'

const breakpointInPixels = 800

interface ResponsiveProps {
  shouldSidebarBeWide: boolean
}

export const Container = styled.aside<ResponsiveProps>`
  display: flex;

  flex-direction: column;

  align-items: center;

  height: 100%;
  width: ${({ shouldSidebarBeWide }) =>
    shouldSidebarBeWide ? '292px' : '64px'};

  padding-top: 32px;

  background: #2a2a2d;
`

export const Logo = styled.img`
  @media (max-width: ${breakpointInPixels}px) {
    display: none;
  }
`

export const List = styled.ul`
  width: 100%;

  margin-top: 16px;

  overflow-y: auto;

  ${Scroll}

  /* overflow-x: scroll; */

  hr {
    border-top: 1px solid ${transparentize(0.94, '#dfe0eb')};
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;

    margin-bottom: 16px;
  }

  > a {
    text-decoration: none;
  }
`

export const MenuButton = styled.button`
  display: none;
  @media (max-width: ${breakpointInPixels}px) {
    display: block;
  }

  background-color: transparent;
  color: #595a63;
`

interface ListItemProps extends ResponsiveProps {
  isActive: boolean
}
export const ListItem = styled.li<ListItemProps>`
  display: flex;
  justify-content: ${({ shouldSidebarBeWide: is }) =>
    is ? 'flex-start' : 'center'};
  align-items: center;

  height: 56px;
  width: 100%;

  padding-left: ${({ shouldSidebarBeWide: is }) => (is ? '20%' : '0%')};

  cursor: pointer;

  transition: background 0.4s, color 0.4s;

  color: #595a63;
  user-select: none;

  svg {
    margin-right: ${({ shouldSidebarBeWide: is }) => (is ? '8px' : '0px')};
  }

  &:hover {
    background: ${transparentize(0.92, '#dde2ff')};
    border-left: ${({ isActive }) =>
      isActive ? '4px solid #E296FD' : '4px solid #dde2ff'};
    color: #dde2ff;
    padding-left: calc(20% - 4px);
  }

  ${({ isActive }) =>
    isActive &&
    `
    background: ${transparentize(0.92, '#dde2ff')};
    border-left: 4px solid #E296FD;
    color: #dde2ff;
    padding-left: calc(20% - 4px);
  `}

  ${({ shouldSidebarBeWide }) =>
    !shouldSidebarBeWide &&
    `
      p {
        display: none;
      }
    `}
`
