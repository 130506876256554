import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface DataMonitor {
  advisorUserId: number
  monitorStatus?: string
  trackIds: (number | undefined)[]
  years: (number | undefined)[]
  mentoriaGroupIds: number[]
  monitorVideoLinks?: string[]
  activityStatus: boolean
}

interface Params {
  monitorId: number
  dataInput?: DataMonitor
}

export async function updateMonitor(params: Params) {
  await api.patch(`/admin/users/${params.monitorId}/monitors`, params.dataInput)
}

export function useUpdateMonitor() {
  return useMutation(updateMonitor, {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchMonitorList'])
      queryClient.invalidateQueries(['get-whatsapp-groups'])
    },
  })
}
