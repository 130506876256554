import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import {
  GetEventsQueryParams,
  GetEventsResponse,
} from '../interfaces/EventsInterfaces'

export async function getEvents(
  params: GetEventsQueryParams,
): Promise<GetEventsResponse> {
  const { limit, page, sort } = params
  const { data } = await api.get<GetEventsResponse>('/admin/scheduled_events', {
    params: {
      limit,
      page,
      sort,
    },
  })

  return data
}

export function useGetEvents(params: GetEventsQueryParams) {
  return useQuery(['get-events', params], () => getEvents(params), {
    refetchOnWindowFocus: false,
    retry: false,
  })
}
