import {
  Modal,
  ModalBody,
  ModalContent,
  Text,
  ModalProps,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { schema } from './schema'
import { CreateExamsTermsInput, CreateExamsTermsOutput } from '../../types'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { INSTITUTIONS } from 'constants/institutions'
import api from 'services/api'
import { FieldFile } from 'components/Form/FieldFile'
import { get } from 'lodash'
import { AxiosError } from 'axios'
import { useToastMessage } from 'components/Toast'

interface ModalRegisterTermsProps extends Omit<ModalProps, 'children'> {}

export default function ModalRegisterTerms(props: ModalRegisterTermsProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose } = props

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    handleSubmit,
    watch,
    reset,
    register,
    formState: { errors, isSubmitting },
  } = useForm<CreateExamsTermsInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      institution: null,
      pdfFile: undefined,
    },
  })

  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const institutionOptions = React.useMemo(() => {
    return INSTITUTIONS.map(institution => ({
      label: institution,
      value: institution,
    }))
  }, [])

  const submitCreateExams = useCallback(
    async (data: CreateExamsTermsOutput) => {
      try {
        const formData = new FormData()

        formData.append('institution', data.institution)
        formData.append('pdfFile', data.pdfFile[0])

        await api.post(
          `admin/institutions/${data.institution}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        showToast({
          title: 'Sucesso',
          description: 'Cadastro realizado com sucesso!',
          type: 'success',
          duration: 2000,
          mode: 'dark',
        })

        reset()
        onClose()
      } catch (error) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível efetuar o cadastro.'

        showToast({
          title: 'Aviso',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 2000,
          mode: 'dark',
        })
      }
    },
    [onClose, reset, showToast],
  )

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background={'rgba(0, 0, 0, 0.2)'} />

      <ModalContent
        w="full"
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="16px 16px 0px 16px"
        >
          <Text fontSize="xl" fontWeight="700" color="white">
            Cadastrar Termos e Condições
          </Text>
          <ModalCloseButton position="unset" color="white" />
        </ModalHeader>

        <ModalBody w="full" p={'1rem'} borderRadius={'8px'}>
          <VStack
            gap="16px"
            as="form"
            onSubmit={handleSubmit(submitCreateExams as any)}
          >
            <FieldSelectController
              placeholder="Selecione ou digite a instituição"
              name="institution"
              control={control}
              label="Instituição"
              options={!institutionOptions ? [] : institutionOptions}
              error={errors.institution as any}
            />

            <FieldFile
              fileName={get(watch('pdfFile'), '[0].name')}
              size="md"
              label="Upload do Arquivo"
              {...register('pdfFile')}
              type="file"
              allowedExtensions={['pdf']}
              error={errors?.pdfFile}
            />

            <DefaultButton
              w="full"
              variant="primary"
              type="submit"
              isLoading={isSubmitting}
              label="Cadastrar"
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
