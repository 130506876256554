import React, { useCallback } from 'react'
import { get, intersection } from 'lodash'
import { Route, Redirect, RouteProps } from 'react-router-dom'

// Contexts
import { useAuth } from 'contexts/auth'
import { ROLES } from 'constants/roles'

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean
  rolesAllowed?: ROLES[]
  component: React.ComponentType
}

const whitelistpages = ['/student/report']

export const mapDefaultPath = {
  [ROLES.admin]: '/dashboard',
  [ROLES.monitor]: '/dashboard',
  [ROLES.advisor]: '/dashboard',
  [ROLES.support]: '/events',
}
const CustomRoute: React.FC<CustomRouteProps> = ({
  isPrivate = false,
  component: Component,
  rolesAllowed = [],
  path,
  ...rest
}) => {
  const { isLoggedIn, isLoading, userRolesSlugs } = useAuth()

  const renderRule = useCallback(() => {
    let isAllowed = intersection(rolesAllowed, userRolesSlugs).length > 0

    if (rolesAllowed.length === 0) {
      isAllowed = true
    }

    // Auth Loading
    if (isLoading) return <p>Carregando ...</p>

    // Check if page is whitelisted
    if (intersection(whitelistpages, [path].flat(Infinity)).length)
      return <Component />

    // Logged and accessing private page
    if (isPrivate === isLoggedIn && isAllowed) return <Component />

    // Redirect if none condition above is satisfied
    return (
      <Redirect
        to={{
          pathname: isPrivate
            ? '/'
            : mapDefaultPath[userRolesSlugs[0] as keyof typeof mapDefaultPath],
        }}
      />
    )
  }, [
    path,
    Component,
    isLoading,
    isPrivate,
    isLoggedIn,
    rolesAllowed,
    userRolesSlugs,
  ])

  return <Route {...rest} path={path} render={renderRule} />
}

export default CustomRoute
