import { Input, InputProps } from '@chakra-ui/react'
import React, { useMemo } from 'react'

type Sizes = 'lg' | 'md' | 'sm'

export interface FieldInputProps extends InputProps {
  size?: Sizes
}

const FieldInputComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldInputProps
> = (props, ref) => {
  const sizesBySize = useMemo((): Record<Sizes, InputProps> => {
    return {
      sm: {
        height: '32px',
      },
      md: {
        height: '42px',
      },
      lg: {
        height: '48px',
      },
    }
  }, [])

  const { size = 'md', ...rest } = props

  return (
    <Input
      w="100%"
      borderRadius="8px"
      fontFamily={'Mulish'}
      border={'0px'}
      autoComplete="off"
      lineHeight={'20px'}
      backgroundColor={'#393940'}
      fontSize={'15px'}
      color={'#FFFFFF'}
      transition={'none'}
      outline={'0px !important'}
      _placeholder={{ color: 'BDBDC7' }}
      _focus={{
        boxShadow: 'none !important',
        border: '1px solid #7D46B1',
      }}
      data-qa={props.name}
      outlineColor={'none'}
      boxShadow="none !important"
      {...sizesBySize[size]}
      {...rest}
      ref={ref}
    />
  )
}

export const FieldInput = React.forwardRef(FieldInputComponent)
