import {
  Box,
  Text,
  Grid,
  Heading,
  Stack,
  HStack,
  Tooltip,
  IconButton,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { AxiosError } from 'axios'
import { FieldSelect } from 'components/Form/FieldSelect'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { useGetAllTracks } from 'components/MonitorTable/queries/use-fetchTracks'
import Pagination from 'components/Pagination'
import { useToastMessage } from 'components/Toast'
import { List } from 'features/ui/list'
import { ListHeader } from 'features/ui/list/list-header'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import { capitalize } from 'lodash'
import React, { useMemo, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import api from 'services/api'
import ModalKillerConcepts from './modal'
import { useDeleteKillerConcepts } from './mutations/use-delete-killer-concepts'
import { useFetchArea } from './mutations/use-get-area'
import {
  KillerConcepts,
  useGetListKillerConcepts,
} from './mutations/use-get-list-killer-concepts'
import Navigation from './navigation'
import { SelectOptions, SelectOptionsYear } from './type/form'

export default function KillerConceptsTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | State
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [fieldYear, setFieldYear] = useState<SelectOptionsYear | undefined>(
    undefined,
  )
  const [fieldArea, setFieldArea] = useState<SelectOptions | undefined>(
    undefined,
  )
  const [fieldTrack, setFieldTrack] = useState<SelectOptions | undefined>(
    undefined,
  )

  /*
  |-----------------------------------------------------------------------------
  | Hooks
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { data: trackAll, isError: isTrackError } = useGetAllTracks()

  const {
    data: availableAreas,
    isError: isAreaError,
    isLoading: isLoadingArea,
  } = useFetchArea({
    trackId: fieldTrack?.value ?? 0,
  })

  const {
    data: dataKillerConcepts,
    isLoading: isLoadingKillerConcepts,
  } = useGetListKillerConcepts({
    limit: 10,
    page,
    trackId: fieldTrack?.value,
    fieldId: fieldArea?.value,
    year: fieldYear?.value,
  })

  const {
    mutateAsync: deleteKillerConcepts,
    isLoading: isLoadingDeleteKillerConcepts,
  } = useDeleteKillerConcepts()

  const showToast = useToastMessage()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: <Text>Título do Conceito Matador</Text>,
        accessor: 'title',
        fn(value) {
          return (
            <Tooltip maxW="487px" w="100%" label={value} hasArrow>
              <Text noOfLines={1}>{value}</Text>
            </Tooltip>
          )
        },
      },
      {
        label: <Text textAlign="center">Contrato do usuário</Text>,
        accessor: 'contract_type',
        fn(value) {
          return (
            <Tooltip maxW="487px" w="100%" label={capitalize(value)} hasArrow>
              <Text noOfLines={1} textAlign="center">
                {capitalize(value)}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: <Text textAlign="center">Download</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent="center">
              <IconButton
                aria-label={'download pdf'}
                backgroundColor="#393940"
                borderRadius={'4px'}
                _hover={{
                  backgroundColor: '#393940c3',
                }}
                _active={{
                  backgroundColor: '#393940',
                }}
                w="24px"
                h="24px"
                minW={'24px !important'}
                onClick={async () => {
                  try {
                    const { data } = await api.get<string>(
                      `/admin/essential-concepts/${value.id}/download`,
                    )

                    const link = document.createElement('a')
                    link.href = data
                    link.click()
                  } catch (error) {
                    const message: AxiosError =
                      error?.response?.data?.errors
                        ?.map((error: AxiosError) => error.message)
                        ?.join(', ') ||
                      error?.response?.data ||
                      'Não foi possível efetuar a remoção'

                    showToast({
                      title: 'Aviso',
                      description: message.message ? message.message : message,
                      type: 'error',
                      duration: 2000,
                      mode: 'dark',
                    })
                  }
                }}
              >
                <Icon
                  fontSize="14px"
                  color="#7D46B1"
                  icon="material-symbols:download"
                />
              </IconButton>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Excluir</Text>,
        accessor: 'id',
        fn(value) {
          return (
            <HStack w="100%" justifyContent="center">
              <IconButton
                aria-label={'download pdf'}
                backgroundColor="#393940"
                borderRadius={'4px'}
                _hover={{
                  backgroundColor: '#393940c3',
                }}
                _active={{
                  backgroundColor: '#393940',
                }}
                w="24px"
                h="24px"
                minW={'24px !important'}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <Icon
                  fontSize="14px"
                  color="#7D46B1"
                  icon="material-symbols:delete-outline"
                />
              </IconButton>

              <ModalDefaultTwoButton
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                title="Tem certeza que deseja remover o estudo?"
                subTitle="Esta ação não poderá ser desfeita."
                confirmButtonIsLoading={isLoadingDeleteKillerConcepts}
                onConfirmClick={async () => {
                  try {
                    await deleteKillerConcepts({ killerConceptsId: value })

                    setIsDeleteModalOpen(false)

                    showToast({
                      title: 'Sucesso',
                      description: 'Remoção realizada com sucesso!',
                      type: 'success',
                      duration: 2000,
                      mode: 'dark',
                    })
                  } catch (error) {
                    const message: AxiosError =
                      error?.response?.data?.errors
                        ?.map((error: AxiosError) => error.message)
                        ?.join(', ') ||
                      error?.response?.data ||
                      'Não foi possível efetuar a remoção'

                    showToast({
                      title: 'Aviso',
                      description: message.message ? message.message : message,
                      type: 'error',
                      duration: 2000,
                      mode: 'dark',
                    })
                  }
                }}
                modalOverlayProps={{
                  background: 'rgba(0, 0, 0, 0.04)',
                }}
              />
            </HStack>
          )
        },
      },
    ] as TableHeader<KillerConcepts>[]
  }, [
    deleteKillerConcepts,
    isDeleteModalOpen,
    isLoadingDeleteKillerConcepts,
    showToast,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Box maxW="811px" w="100%">
      <Navigation onButtonClick={() => setIsModalOpen(true)} />

      <Stack spacing="16px">
        <Heading fontWeight="700" fontSize="24px" lineHeight="30px" as="h3">
          Filtros
        </Heading>

        <Grid
          templateColumns={{ base: '1fr', md: '1.4fr 1.8fr 0.9fr' }}
          gridGap="16px"
        >
          <FieldSelect
            placeholder="Selecione ou digite uma trilha"
            options={
              isTrackError
                ? []
                : trackAll?.data
                    ?.map(item => {
                      return {
                        value: item.id,
                        label: item.name,
                      }
                    })
                    .concat({
                      value: 0,
                      label: 'Todos',
                    })
            }
            onChange={(e: OptionTypeBase) => {
              setFieldArea({
                value: 0,
                label: 'Todos',
              })
              setFieldYear({
                value: 0,
                label: 'Todos',
              })
              setPage(1)
              setFieldTrack(e as SelectOptions)
            }}
            value={fieldTrack}
          />

          <FieldSelect
            placeholder="Selecione ou digite uma grande área"
            isLoading={isLoadingArea}
            options={
              isAreaError
                ? []
                : availableAreas?.fields
                    .map(field => {
                      return {
                        label: capitalize(field.name),
                        value: field.id,
                      }
                    })
                    .concat({
                      value: 0,
                      label: 'Todos',
                    })
            }
            onChange={(e: OptionTypeBase) => setFieldArea(e as SelectOptions)}
            value={fieldArea}
          />

          <FieldSelect
            placeholder="Ano de prova"
            w="10%"
            options={[
              {
                label: new Date().getFullYear(),
                value: new Date().getFullYear(),
              },
              {
                label: new Date().getFullYear() + 1,
                value: new Date().getFullYear() + 1,
              },
              {
                label: new Date().getFullYear() + 2,
                value: new Date().getFullYear() + 2,
              },
              {
                value: 0,
                label: 'Todos',
              },
            ]}
            onChange={(e: OptionTypeBase) =>
              setFieldYear(e as SelectOptionsYear)
            }
            value={fieldYear}
          />
        </Grid>

        <List background="#302F37" px="20px" py="10px" my="0px">
          <ListHeader
            progressProps={{ top: '1px', left: '1px' }}
            isLoading={false}
            isFetching={false}
          />

          <ListTable
            mt="0px !important"
            headers={headers}
            data={dataKillerConcepts?.data}
            isLoading={isLoadingKillerConcepts}
            tableCellProps={{
              border: 'none !important',
              p: '8px',
              _notFirst: {
                paddingInline: '0.75rem',
              },
              borderRight: '1px solid rgba(255, 255, 255, 0.06) !important',
              _last: {
                borderRight: 'none !important',
              },
              fontWeight: '400',
              color: '#FFFFFF',
              fontFamily: 'Mulish',
              fontSize: '12px',
            }}
            tableCellPropsHeader={{
              border: 'none !important',
              fontWeight: '600',
              color: '#FFFFFF',
              isTruncated: true,
              fontFamily: 'Mulish',
              fontSize: '16px',
              textTransform: 'none !important',
              p: '8px',
              _notFirst: {
                paddingInline: '0.75rem',
              },
            }}
          />

          <Pagination
            currentPage={page}
            onPageChange={setPage}
            totalCountOfRegisters={dataKillerConcepts?.meta.total || 0}
            registersPerPage={10}
            fontSize="12px"
            mt="16px !important"
            px="8px"
            buttonProps={{
              fontSize: '12px',
              fontWeight: '600',
              minH: '6',
              h: '6',
              w: '6',
              minW: '6',
            }}
          />
        </List>
      </Stack>

      <ModalKillerConcepts
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Box>
  )
}
