import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'
import { NewWhatsappGroupFormOutput } from '../interfaces/WhatsappGroupsInterfaces'

async function createWhatsappGroup(params: NewWhatsappGroupFormOutput) {
  const response = await api.post('/admin/mentoria_groups', params)

  return response.data
}

export function useCreateWhatsappGroup() {
  return useMutation(createWhatsappGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-whatsapp-groups'])
    },
  })
}
