import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiSearchAlt2 } from 'react-icons/bi'
import { useDetectClickOutside } from 'react-detect-click-outside'

interface SearchSectionProps extends InputProps {
  setInput: React.Dispatch<React.SetStateAction<string>>
  search: string
  maxWInputGroup?: string
}

const SearchSection: React.ForwardRefRenderFunction<any, SearchSectionProps> = (
  props,
  ref,
) => {
  const [inputFocus, setInputFocus] = useState(false)
  const refClickOutside = useDetectClickOutside({
    onTriggered: () => setInputFocus(false),
  })
  return (
    <>
      <InputGroup maxW={props.maxWInputGroup} w="100%" h="42px">
        <InputLeftElement h="42px">
          <BiSearchAlt2
            color={
              inputFocus
                ? '#E296FD'
                : !inputFocus && props.search !== ''
                ? '#E296FD'
                : '#FFFFFF'
            }
            size={'1rem'}
          />
        </InputLeftElement>
        <Input
          ref={refClickOutside}
          onFocus={() => setInputFocus(true)}
          onChange={e => props.setInput(e.target.value)}
          value={props.search}
          _focus={inputFocus ? { border: ' 1px solid #9747FF' } : undefined}
          /* onMouseOut={() => setInputFocus(prev => !prev)} */
          _placeholder={{ color: 'rgba(255,255,255,0.9)' }}
          _hover={{
            borderColor: '#9747FF',
          }}
          color={!inputFocus && props.search !== '' ? '#E296FD' : '#B8B8C1'}
          sx={{ borderColor: '#FFFF', boxShadow: 'none !important' }}
          placeholder="Buscar por nome"
          h="42px"
          fontFamily="Mulish"
          fontStyle="normal"
          fontWeight="400"
          fontSize="14px"
          border={'0px'}
          borderRadius="8px"
          lineHeight="18px"
          backgroundColor={'#393940'}
          {...props}
        />
      </InputGroup>
    </>
  )
}

export const SearchSectionField = React.forwardRef(SearchSection)
