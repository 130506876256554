import { VStack, Flex } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { FieldMaskController } from 'components/Form/FieldMask/FieldMaskController'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { Role } from 'contexts/auth/interfaces'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import Swal from 'sweetalert2'
import { CreateUserFormInput, CreateUserFormOutput } from './interfaces'
import { useGetProducts } from './mutations/use-get-products'
import { NewUserSchema } from './schemas'

export default function NewUserTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [roles, setRoles] = useState<Role[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const RESET_FORM: CreateUserFormInput = {
    roles: null,
    name: '',
    email: '',
    mobilePhone: '',
    cpf: '',
    productCode: null,
  }

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateUserFormInput>({
    resolver: yupResolver(NewUserSchema),
    shouldUnregister: true,
  })

  const { data: productsData, isLoading: isLoadingProducts } = useGetProducts()

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const createNewUser = async (formData: CreateUserFormOutput) => {
    try {
      await api
        .post('/admin/users', {
          email: formData.email,
          roles: formData?.roles,
          mobilePhone: formData.mobilePhone,
          name: formData.name,
          //CPF and productCode is only needed for students
          cpf: formData?.roles?.includes(3) ? formData.cpf : undefined,
          productCode: formData?.roles?.includes(3)
            ? formData.productCode
            : undefined,
        })
        .then(() => {
          reset(RESET_FORM)
          setValue('mobilePhone', '+55')

          Swal.fire({
            buttonsStyling: false,
            title: 'Sucesso',
            text: 'Cadastro realizado com sucesso',
            icon: 'success',
          })
        })
    } catch (err: any) {
      let errorMessage = 'Não foi possível criar o usuário'
      if (get(err, 'response.status') === 422) {
        const errors = get(err, 'response.data.errors')
        if (Array.isArray(errors)) {
          errorMessage = errors
            .map(error => `\n${error.field} - ${error.message}`)
            .join('')
        }
      }

      Swal.fire({
        buttonsStyling: false,
        title: 'Aviso',
        html: `<p style="text-align:center">${errorMessage}</p>`,
        icon: 'error',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    /**
     * Fetch roles.
     */
    api
      .get<{ data: Role[] }>('/admin/roles?limit=1000')
      .then(({ data: { data } }) => setRoles(data))
      .catch(err => console.trace(err))
  }, [])

  useEffect(() => {
    setValue('mobilePhone', '+55')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /*
  |-----------------------------------------------------------------------------
  | Render.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack
      as="form"
      spacing="1rem"
      pt="1rem"
      maxW="380px"
      w="full"
      onSubmit={handleSubmit(createNewUser as any)}
    >
      <FieldSelectController
        name="roles"
        placeholder="Perfil de acesso"
        control={control}
        isMulti={true as any}
        isLoading={roles.length === 0}
        options={roles.map(role => ({
          label: role.name,
          value: role.id,
        }))}
        error={errors.roles as any}
      />

      {watch('roles')?.find(item => item.label === 'Student') && (
        <FieldSelectController
          name="productCode"
          placeholder="Selecione o produto"
          control={control}
          isLoading={isLoadingProducts}
          options={productsData?.map(data => ({
            label: data?.productName,
            value: data?.productCode,
          }))}
          error={errors.productCode as any}
        />
      )}

      {watch('roles')?.find(item => item.label === 'Student') && (
        <FieldMaskController
          name="cpf"
          mask="999.999.999-99"
          placeholder="CPF"
          control={control}
          error={errors.cpf}
        />
      )}

      <FieldInputController
        name="name"
        type="text"
        placeholder="Nome completo"
        control={control}
        error={errors.name}
      />

      <FieldInputController
        name="email"
        type="email"
        placeholder="E-mail"
        control={control}
        error={errors.email}
      />

      <FieldInputController
        name="mobilePhone"
        placeholder="Telefone"
        control={control}
        error={errors.mobilePhone}
      />

      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        w="full"
        mt="12px"
        gap="12px"
      >
        <DefaultButton
          type="submit"
          w="full"
          label="Cadastrar"
          isLoading={isSubmitting}
        />
        <DefaultButton
          w="full"
          onClick={() => reset(RESET_FORM)}
          variant="ghost"
          label="Limpar"
        />
      </Flex>
    </VStack>
  )
}
