import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

// Types
import { ForgotPasswordPostPayload } from './interfaces'

// Schemas
import { ValidateAccountSchema } from './schemas'

// Services
import api from 'services/api'

// Styles
import Layout from 'layouts/Unlogged'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { chakra, Flex, Text, VStack } from '@chakra-ui/react'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const ValidateAccount: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ForgotPasswordPostPayload>({
    resolver: yupResolver(ValidateAccountSchema),
  })

  const history = useHistory()
  const location = useLocation<{ token: string }>()

  /*
  |-----------------------------------------------------------------------------
  | Callbacks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const submitHandler = useCallback(
    async (formData: ForgotPasswordPostPayload) => {
      try {
        const query = new URLSearchParams(location.search)
        const validationEmailToken = query.get('token')
        if (!validationEmailToken) return

        await api.put('/public/validate_account', {
          ...formData,
          validationEmailToken,
        })

        Swal.fire({
          title: 'Sucesso',
          text:
            'Conta validada com sucesso. Você já pode fazer o seu login. Seja bem vindo!',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (error) {
        Swal.fire({
          title: 'Aviso',
          text: 'Falha ao validar a sua conta. Por favor tente novamente.',
          buttonsStyling: false,
        })
      }
    },
    [history, location.search],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    /**
     * Redirect user to login if no token is presented as query param.
     */
    const query = new URLSearchParams(location.search)
    const validationEmailToken = query.get('token')
    if (!validationEmailToken) return history.replace('/')
  }, [history, location])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Flex
        as="form"
        flexDir="column"
        justify="space-around"
        alignItems="center"
        w="514px"
        h="434px"
        maxW="90vw"
        maxH="calc(0.92 * 90vw)"
        bg="#2a2a2d"
        boxShadow="0px 0px 61px 13px rgba(0, 0, 0, 0.25)"
        py="24px"
        borderRadius="10px"
        onSubmit={handleSubmit(submitHandler)}
      >
        <Text fontSize="24px" textAlign="center" letterSpacing="0.2px">
          Defina a{' '}
          <chakra.span
            color="rgb(158, 89, 222)"
            fontWeight="bold"
            lineHeight="20px"
          >
            sua senha
          </chakra.span>
        </Text>

        <VStack
          px={{ base: '10px', sm: 'unset' }}
          spacing="1rem"
          maxW="380px"
          w="full"
        >
          <FieldInputController
            placeholder="Senha"
            type="password"
            name="password"
            control={control}
            error={errors.password}
          />

          <FieldInputController
            placeholder="Confirme a senha"
            type="password"
            name="passwordConfirmation"
            control={control}
            error={errors.passwordConfirmation}
          />
        </VStack>

        <DefaultButton
          maxW="189px"
          w="full"
          isLoading={isSubmitting}
          label="Definir"
          type="submit"
        />
      </Flex>
    </Layout>
  )
}

export default ValidateAccount
