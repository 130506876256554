/* eslint-disable quotes */
import React, { useMemo } from 'react'

// UI
import { ListTable, TableHeader } from 'features/ui/list/list-table'
// Styles
import { Avatar, Flex, HStack, Text, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import {
  ResetProjectX,
  useGetResetProjectXList,
} from 'pages/UserDetail/queries/use-get-project-x-reset-by-student'
import { List } from 'features/ui/list'
import { ListHeader } from 'features/ui/list/list-header'
import { UserDataApiResponse } from 'pages/UserDetail/interfaces'
import Pagination from 'components/Pagination'
import { Icon } from '@iconify/react'
import { orderBy } from 'lodash'

interface ListResetProjectXProps {
  user: UserDataApiResponse
}

export function ListResetProjectX({ user }: ListResetProjectXProps) {
  const { id, name, avatar } = user

  const [page, setPage] = React.useState(1)

  const [sortBy, setSortBy] = React.useState<'asc' | 'desc'>('desc')

  const { data, isLoading, isFetching } = useGetResetProjectXList({
    user_id: id,
    limit: 4,
    page: page,
  })

  const headers = useMemo(() => {
    return [
      {
        label: 'Feito por',
        accessor: 'name',
        fn() {
          return (
            <HStack maxW="120px" w="full">
              <Avatar
                h="16px"
                w="16px"
                size="xs"
                src={avatar?.url}
                name={user.name}
                fontSize="12px !important"
              />
              <Tooltip label={name} hasArrow>
                <Text noOfLines={1}>{name}</Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: (
          <Flex gap="10px">
            <Text>Data</Text>
            {sortBy === 'asc' && (
              <Icon
                icon="uil:sort-amount-down"
                onClick={() => {
                  setSortBy(sortBy === 'asc' ? 'desc' : 'asc')
                }}
                cursor="pointer"
              />
            )}

            {sortBy === 'desc' && (
              <Icon
                icon="uil:sort-amount-up"
                onClick={() => {
                  setSortBy(sortBy === 'desc' ? 'asc' : 'desc')
                }}
                cursor="pointer"
              />
            )}
          </Flex>
        ),
        accessor: 'reset_date',
        fn(value) {
          return (
            <Tooltip
              label={format(new Date(value), "dd/MM/yyyy, 'às' HH:mm")}
              hasArrow
            >
              <Text noOfLines={1}>
                {format(new Date(value), "dd/MM/yyyy, 'às' HH:mm")}
              </Text>
            </Tooltip>
          )
        },
      },
    ] as TableHeader<ResetProjectX>[]
  }, [avatar?.url, name, sortBy, user.name])

  return (
    <List background="#302F37" px="20px" py="10px" my="0px">
      <ListHeader
        progressProps={{ top: '1px', left: '1px' }}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <ListTable
        mt="0px !important"
        headers={headers}
        data={orderBy(data?.data, 'reset_date', sortBy)}
        isLoading={isLoading}
        tableCellProps={{
          border: 'none !important',
          p: '8px',
          _notFirst: { paddingLeft: '1.5rem' },
          _first: {
            borderRight: '1px solid rgba(255, 255, 255, 0.06) !important',
          },
          fontWeight: '400',
          color: '#FFFFFF',
          fontFamily: 'Mulish',
          fontSize: '12px',
        }}
        tableCellPropsHeader={{
          border: 'none !important',
          fontWeight: '600',
          color: '#FFFFFF',
          isTruncated: true,
          fontFamily: 'Mulish',
          fontSize: '16px',
          textTransform: 'none !important',
          p: '8px',
          _notFirst: { paddingLeft: '1.5rem' },
        }}
      />

      <Pagination
        currentPage={page}
        onPageChange={setPage}
        totalCountOfRegisters={data?.meta?.total ?? 0}
        registersPerPage={4}
        fontSize="12px"
        buttonProps={{
          fontSize: '12px',
          fontWeight: '600',
          minH: '6',
          h: '6',
          w: '6',
          minW: '6',
        }}
      />
    </List>
  )
}
