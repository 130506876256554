import React from 'react'

import Header from 'components/Header'

import { Container } from './styles'
import Layout from 'layouts/Logged'
import NotificationsByStudentsTemplate from 'features/notificationsByStudent'

export const NotificationsByStudents: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Notificações"
          subTitle="Lista de notificações por alunos Mentoria."
        />

        <NotificationsByStudentsTemplate />
      </Container>
    </Layout>
  )
}
