import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'
import { Notification } from './use-get-notifications'

interface Params {
  startsAt: string
  expiresAt: string
  studentUserIds?: number[]
  trackIds?: number[]
  monitorUserIds?: number[]
  examYears?: number[]
  contractType: 'PURCHASE' | 'TRIAL'
  title: string
  urlLink: string
  message: string
}

async function createNotification(
  params: Params,
): Promise<Omit<Notification, 'createdByUser' | 'studentCount'>> {
  const response = await api.post('/admin/notifications', params)

  return response.data
}

export function useCreateNotification() {
  return useMutation(createNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-notifications'])
    },
  })
}
