import React from 'react'

import Header from 'components/Header'

import { Container } from './styles'
import Layout from 'layouts/Logged'
import EventsTemplate from 'features/events'

const Events: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Container>
        <Header
          title="Eventos"
          subTitle="Crie eventos para os alunos Mentoria."
        />

        <EventsTemplate />
      </Container>
    </Layout>
  )
}

export default Events
