// Vendors
import React, { useMemo } from 'react'
import { addMinutes } from 'date-fns'
import { FieldError } from 'react-hook-form'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

// Components

// Styles
import {
  Flex,
  Input,
  FormLabel,
  InputProps,
  FormControl,
  FormErrorMessage,
  FormControlProps,
  Text,
} from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'

// Interfaces
export type FieldDateProps = ReactDatePickerProps & {
  name: string
  label?: string
  error?: FieldError
  canModify?: boolean
  containerProps?: FormControlProps
  inputProps?: InputProps
  helperText?:
    | string
    | {
        text: string
        color?: string
      }
  isRequired?: boolean
}

export const FieldDate: React.FC<FieldDateProps> = (
  props: FieldDateProps,
): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    error,
    placeholderText,
    selected,
    helperText,
    canModify = true,
    containerProps,
    inputProps,
    isRequired,
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl
      isInvalid={!!error}
      sx={{
        '.react-datepicker-wrapper': {
          width: '100%',
        },
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        {...containerProps}
      >
        {!!label && (
          <FormLabel htmlFor={name} m={0}>
            {label}
            {isRequired && (
              <Text
                ml={1}
                as="span"
                color="red.500"
                fontWeight="bold"
                userSelect="none"
              >
                *
              </Text>
            )}
          </FormLabel>
        )}
      </Flex>

      <DatePicker
        id={name}
        name={name}
        selected={selected}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholderText || 'Selecione a data...'}
        customInput={
          <Input _placeholder={{ color: 'blackAlpha.900' }} {...inputProps} />
        }
        {...rest}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
