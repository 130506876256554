import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'

import {
  FormLabel,
  FormControl,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react'
import { FieldMask } from '.'
import { FieldInputProps } from '../FieldInput'
import { Props as InputMaskProps } from 'react-input-mask'

type FieldMaskController = FieldInputProps &
  InputMaskProps & {
    name: string
    label?: string
    control: Control<any>
    error?: FieldError
    helperText?: string | React.ReactNode
  }

export const FieldMaskController: React.FC<FieldMaskController> = ({
  name,
  label,
  error,
  control,
  helperText,
  defaultValue,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => <FieldMask {...rest} {...field} />}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}
