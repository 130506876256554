import React, { useCallback, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

// Styles
import Layout from 'layouts/Unlogged'

// Components
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { DefaultButton } from 'components/Buttons/DefaultButton'

// Schemas
import { ForgotPasswordNewSchema } from './schemas'

// Services
import api from 'services/api'

// Interfaces
import { ForgotPasswordPostPayload } from './interfaces'
import { chakra, Flex, Text } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const ForgotPasswordNew: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordPostPayload>({
    resolver: yupResolver(ForgotPasswordNewSchema),
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const submitHandler = useCallback(
    async formData => {
      try {
        await api.post('/public/forgot_password', formData)

        Swal.fire({
          title: 'Sucesso',
          text:
            'Seu pedido de redefinição de senha foi processado com sucesso. Acesse o seu e-mail para concluir.',
          buttonsStyling: false,
        }).then(() => history.replace('/'))
      } catch (err) {
        Swal.fire({
          title: 'Aviso',
          text:
            'Falha ao solicitar redefinição de senha. Por favor tente novamente.',
          buttonsStyling: false,
          icon: 'error',
        })
      }
    },
    [history],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <Flex
        as="form"
        flexDir="column"
        justifyContent="space-around"
        alignItems="center"
        textAlign="center"
        w="514px"
        maxW="90vw"
        height="434px"
        maxH="calc(0.92 * 90vw)"
        bg="#2a2a2d"
        boxShadow="0px 0px 61px 13px rgba(0, 0, 0, 0.25)"
        borderRadius="10px"
        py="24px"
        onSubmit={handleSubmit(submitHandler as any)}
      >
        <Text lineHeight="30px" fontSize="24px" letterSpacing="0.2px">
          Informe o{' '}
          <chakra.span
            fontWeight="bold"
            lineHeight="20px"
            color="rgb(158, 89, 222)"
          >
            seu email
          </chakra.span>
        </Text>

        <FieldInputController
          placeholder="E-mail"
          name="email"
          type="email"
          control={control}
          error={errors.email}
          w="full"
          maxW="380px"
        />

        <Flex
          flexDir="column"
          gap="1rem"
          alignItems="center"
          alignSelf="stretch"
        >
          <DefaultButton
            isLoading={isSubmitting}
            w="full"
            maxW="189px"
            label="Enviar"
            type="submit"
          />
          <Link
            to="/"
            style={{ fontSize: '13px', color: 'rgba(255,255,255,0.6)' }}
          >
            Voltar
          </Link>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default ForgotPasswordNew
