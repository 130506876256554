import React, { Dispatch, SetStateAction, useMemo } from 'react'

import { Button, ButtonGroup, Flex, Spacer } from '@chakra-ui/react'
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi'
import { Pagination, Query } from 'types/response'

interface ListPaginationProps {
  pagination: {
    current: Pagination
    set: Dispatch<SetStateAction<Pagination>>
  }
  query: {
    current: Query
    set: Dispatch<SetStateAction<Query>>
  }
}

export default function ListPagination({
  query,
  pagination,
}: ListPaginationProps) {
  const calculated = useMemo(() => {
    if (!pagination)
      return {
        min: 0,
        max: 0,
        of: '',
      }
    return {
      min:
        pagination.current.perPage * (pagination.current.currentPage - 1) + 1,
      max:
        pagination.current.perPage * (pagination.current.currentPage - 1) +
        pagination.current.currentPageCount,
      of: pagination.current.total,
    }
  }, [pagination])

  return (
    <Flex as="section" pb="2rem !important" mt="8">
      {pagination.current.total > 0 && (
        <div className="count">
          {`Mostrando ${calculated.min} a ${calculated.max} de `}
          <b>{calculated.of}</b> registros
          {query.current.q === '' || query.current.q === undefined
            ? '.'
            : ' de sua busca.'}
        </div>
      )}

      <Spacer />

      {pagination.current.firstPage !== pagination.current.lastPage && (
        <ButtonGroup spacing="2">
          {pagination.current.firstPage !== pagination.current.currentPage && (
            <Button
              bg="whiteAlpha.200"
              _hover={{ bg: 'whiteAlpha.300' }}
              _active={{ bg: 'whiteAlpha.200' }}
              onClick={() => {
                query.set({
                  ...query.current,
                  page: pagination.current.firstPage,
                })
              }}
            >
              <FiChevronsLeft />
            </Button>
          )}

          {pagination.current.currentPage > 1 && (
            <Button
              bg="whiteAlpha.200"
              _hover={{ bg: 'whiteAlpha.300' }}
              className="prev"
              onClick={() => {
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage - 1,
                })
              }}
            >
              <FiChevronLeft />
            </Button>
          )}

          {pagination.current.currentPage - 1 >
            pagination.current.firstPage && (
            <Button
              bg="whiteAlpha.200"
              _hover={{ bg: 'whiteAlpha.300' }}
              _active={{ bg: 'whiteAlpha.200' }}
              onClick={() => {
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage - 1,
                })
              }}
            >
              {pagination.current.currentPage - 1}
            </Button>
          )}

          <Button className="current" bg="whiteAlpha.400" pointerEvents="none">
            {pagination.current.currentPage}
          </Button>

          {pagination.current.currentPage + 1 <=
            pagination.current.lastPage && (
            <Button
              bg="whiteAlpha.200"
              _hover={{ bg: 'whiteAlpha.300' }}
              _active={{ bg: 'whiteAlpha.200' }}
              onClick={() => {
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage + 1,
                })
              }}
            >
              {pagination.current.currentPage + 1}
            </Button>
          )}

          {pagination.current.lastPage > pagination.current.currentPage && (
            <Button
              bg="whiteAlpha.200"
              _hover={{ bg: 'whiteAlpha.300' }}
              _active={{ bg: 'whiteAlpha.200' }}
              className="next"
              onClick={() => {
                query.set({
                  ...query.current,
                  page: pagination.current.currentPage + 1,
                })
              }}
            >
              <FiChevronRight />
            </Button>
          )}

          {pagination.current.lastPage !== pagination.current.currentPage && (
            <Button
              bg="whiteAlpha.200"
              _hover={{ bg: 'whiteAlpha.300' }}
              _active={{ bg: 'whiteAlpha.200' }}
              onClick={() => {
                query.set({
                  ...query.current,
                  page: pagination.current.lastPage,
                })
              }}
            >
              <FiChevronsRight />
            </Button>
          )}
        </ButtonGroup>
      )}
    </Flex>
  )
}
