import { useMutation } from '@tanstack/react-query'
import api from 'services/api'
import { queryClient } from 'App'

export async function createKillerConcepts(params: any) {
  await api.post('/admin/essential-concepts', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function useCreateKillerConcepts() {
  return useMutation(createKillerConcepts, {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetch-killer-concepts'])
    },
  })
}
