import React, { useCallback, useMemo } from 'react'
import { upperCase } from 'lodash'
import Swal from 'sweetalert2'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Styles
import Layout from 'layouts/Logged'
import { Container, ChipsContainer, Chip, ChipText } from './styles'
import { useDisclosure } from '@chakra-ui/react'
import ModalFilterDate from './components/ModalFilterDate'

const Extractions: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, onOpen } = useDisclosure()

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  // alunos, monitores, admin, instituições, provas, themas, subtemas

  const extractions = useMemo(
    () => [
      { name: 'Alunos', path: '/admin/extractions/students' },
      { name: 'Monitores', path: '/admin/extractions/monitors' },
      { name: 'Administradores', path: '/admin/extractions/administrators' },
      { name: 'Temas', path: '/admin/extractions/themes' },
      { name: 'Sub Temas', path: '/admin/extractions/sub-themes' },
      { name: 'Instituições', path: '/admin/extractions/institutions' },
      { name: 'Universidades', path: '/admin/extractions/colleges' },
    ],
    [],
  )

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const downloadHandler = useCallback((path: string, name: string) => {
    api
      .get(path, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name.replace(/\s+/, '-')}.csv`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Não foi possível fazer o download do arquivo.',
          buttonsStyling: false,
        })
        console.trace(err)
      })
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Layout>
      <ModalFilterDate isOpen={isOpen} onClose={onClose} />

      <Container>
        <Header
          title="Extrações de dados"
          subTitle="Faça download de arquivos de dados"
        />
        {extractions.map(({ name, path }) => (
          <React.Fragment key={`group-key-${name}`}>
            <SectionTitle>{upperCase(name)}</SectionTitle>

            <ChipsContainer>
              <Chip
                key={`institution-name-key-${name}`}
                onClick={() => downloadHandler(path, name)}
              >
                <ChipText>{name}</ChipText>
              </Chip>
            </ChipsContainer>

            {name === 'Alunos' && (
              <ChipsContainer>
                <Chip
                  key={`institution-name-key-${name}-trial`}
                  onClick={onOpen}
                >
                  <ChipText>{name} trial</ChipText>
                </Chip>
              </ChipsContainer>
            )}
          </React.Fragment>
        ))}
      </Container>
    </Layout>
  )
}

export default Extractions
