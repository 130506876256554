import {
  validateFileExtension,
  validateFileIsRequired,
  validateFileSize,
} from 'helpers/validateFile'
import * as Yup from 'yup'

export const FILE_SIZE = 50 * 1000 * 1000 // 50MB
export const FILE_SIZE_BYTES = FILE_SIZE / 1000 / 1000 // 50MB

export const schema = Yup.object().shape({
  institution: Yup.string()
    .transform(value => value && value.value)
    .nullable()
    .required('A instituição é obrigatória.'),

  pdfFile: Yup.mixed()
    .test('req', 'Por favor, carregue o arquivo.', validateFileIsRequired)
    .test(
      'fileSize',
      `O arquivo deve ser menor que ${FILE_SIZE_BYTES} MB`,
      value => {
        if (!value) return true
        return validateFileSize(value, FILE_SIZE)
      },
    )
    .test('fileSize', 'A extensão do arquivo permitida é apenas pdf', value =>
      validateFileExtension(value, 'application/pdf'),
    ),
})
