import React, { useMemo, useState } from 'react'
import { intersection } from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import {
  PieChart,
  CheckSquare,
  Settings,
  Bell,
  MessageCircle,
  Users,
  Crosshair,
  TrendingUp,
  FileText,
  List as ListIcon,
  Database,
} from 'react-feather'

import { useAuth } from 'contexts/auth'

import logo from 'assets/images/logo.png'

import { Container, List, ListItem, Logo } from './styles'
import { useWindowSize } from 'hooks/useWindowsSize'
import { BiPieChartAlt } from 'react-icons/bi'
import { Icon } from '@iconify/react'

const Sidebar: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { pathname } = useLocation()
  const { userRolesSlugs } = useAuth()
  const { width } = useWindowSize()
  const [isSidebarSetToWide] = useState(() => false)

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sideBarItems = useMemo(() => {
    return {
      primary: [
        {
          isExternal: false,
          label: 'Dashboard',
          path: '/dashboard',
          icon: <PieChart size={16} />,
          isDisplay:
            intersection(['admin', 'monitor', 'advisor'], userRolesSlugs)
              .length > 0,
        },
        {
          isExternal: false,
          label: 'Eventos',
          path: '/events',
          icon: <Icon icon="carbon:event" fontSize="16px" />,
          isDisplay:
            intersection(['admin', 'support'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Tabela orientados',
          path: '/advisorTable',
          icon: <BiPieChartAlt size={16} />,
          isDisplay: intersection(['advisor'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Usuários',
          path: '/users/new',
          icon: <Users size={16} />,
          isDisplay: intersection(['admin'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Usuários',
          path: '/users',
          icon: <Users size={16} />,
          isDisplay:
            intersection(['monitor', 'advisor', 'support'], userRolesSlugs)
              .length > 0,
        },
        {
          isExternal: false,
          label: 'Monitores',
          path: '/monitors',
          icon: <Users size={16} />,
          isDisplay: intersection(['admin'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Grupos Whatsapp',
          path: '/whatsapp-groups',
          icon: <MessageCircle size={16} />,
          isDisplay: intersection(['admin'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Meus mentorados',
          path: '/students',
          icon: <Users size={16} />,
          isDisplay: intersection(['monitor'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Temas',
          path: '/themes/new',
          icon: <ListIcon size={16} />,
          isDisplay:
            intersection(['admin', 'support'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'SubTemas',
          path: '/subjects/new',
          icon: <ListIcon size={16} />,
          isDisplay:
            intersection(['admin', 'support'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Metas',
          path: '/goals/new',
          icon: <Crosshair size={16} />,
          isDisplay:
            intersection(['admin', 'support'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Conceitos Matadores',
          path: '/killerConcepts',
          icon: <Icon icon="carbon:concept" fontSize="16px" />,
          isDisplay:
            intersection(['admin', 'support'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Engajamento',
          path: '/engagement',
          icon: <TrendingUp size={16} />,
          isDisplay:
            intersection(['admin', 'monitor', 'advisor'], userRolesSlugs)
              .length > 0,
        },
        {
          isExternal: false,
          label: 'Incidências',
          path: '/incidences',
          icon: <CheckSquare size={16} />,
          isDisplay:
            intersection(['admin', 'monitor', 'advisor'], userRolesSlugs)
              .length > 0,
        },
        {
          isExternal: false,
          label: 'Extrações',
          path: '/extractions',
          icon: <Database size={16} />,
          isDisplay: intersection(['admin'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Notificações',
          path: '/notifications',
          icon: <Bell size={16} />,
          isDisplay: intersection(['admin'], userRolesSlugs).length > 0,
        },
        {
          isExternal: false,
          label: 'Simulados Reta Final',
          path: '/simulation',
          icon: <FileText size={16} />,
          isDisplay:
            intersection(['admin', 'support'], userRolesSlugs).length > 0,
        },
        // {
        //   isExternal: false,
        //   label: 'Contato',
        //   path: '/contact',
        //   icon: <AtSign size={16} />,
        //   isDisplay:
        //     intersection(['admin', 'monitor', 'advisor'], userRolesSlugs)
        //       .length > 0,
        // },
      ],
      secondary: [
        {
          isExternal: false,
          label: 'Minha Conta',
          path: '/account',
          icon: <Settings size={16} />,
          isDisplay:
            intersection(
              ['admin', 'monitor', 'advisor', 'support'],
              userRolesSlugs,
            ).length > 0,
        },
      ],
    }
  }, [userRolesSlugs])

  const shouldSidebarBeWide = useMemo(() => {
    if (!width) return true
    if (width >= 800) return true
    return isSidebarSetToWide
  }, [isSidebarSetToWide, width])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <Container shouldSidebarBeWide={shouldSidebarBeWide}>
      <Logo src={logo} alt="side bar logo" draggable={false} />
      <List>
        {sideBarItems.primary.map(item => {
          if (!item.isDisplay) return null

          if (item.isExternal) {
            return (
              <a
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                key={item.path}
              >
                <ListItem
                  key={item.label}
                  isActive={item.path.startsWith(pathname)}
                  shouldSidebarBeWide={shouldSidebarBeWide}
                >
                  {item.icon}
                  <p>{item.label}</p>
                </ListItem>
              </a>
            )
          }

          return (
            <Link to={item.path} key={item.path}>
              <ListItem
                key={item.label}
                isActive={item.path.startsWith(pathname)}
                shouldSidebarBeWide={shouldSidebarBeWide}
              >
                {item.icon}
                <p>{item.label}</p>
              </ListItem>
            </Link>
          )
        })}
        <hr />
        {sideBarItems.secondary.map(item => {
          if (!item.isDisplay) return null

          if (item.isExternal) {
            return (
              <a
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                key={item.path}
              >
                <ListItem
                  shouldSidebarBeWide={shouldSidebarBeWide}
                  key={item.label}
                  isActive={pathname === item.path}
                >
                  {item.icon}
                  <p>{item.label}</p>
                </ListItem>
              </a>
            )
          }

          return (
            <Link to={item.path} key={item.path}>
              <ListItem
                shouldSidebarBeWide={shouldSidebarBeWide}
                key={item.label}
                isActive={pathname === item.path}
              >
                {item.icon}
                <p>{item.label}</p>
              </ListItem>
            </Link>
          )
        })}
      </List>
    </Container>
  )
}

export default Sidebar
