import {
  Modal,
  ModalContent,
  Text,
  ModalProps,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Box,
  ModalBody,
  Spinner,
} from '@chakra-ui/react'
import { useGetPdfByInstitution } from 'features/simulation/mutations/use-get-institutionPDF'
import React from 'react'

interface ModalPreviewPDFProps extends Omit<ModalProps, 'children'> {
  institution: string
}

export default function ModalPreviewPDF(props: ModalPreviewPDFProps) {
  /*
  |-----------------------------------------------------------------------------
  | Const.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { isOpen, onClose, institution } = props

  const {
    data: getPdfByInstitution,
    isLoading,
    isError,
  } = useGetPdfByInstitution({
    institution,
  })

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Modal size={'3xl'} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background={'rgba(0, 0, 0, 0.2)'} />

      <ModalContent
        w="full"
        transition={'all 0.2s ease-in-out'}
        background={'#302F37'}
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="16px 16px 0px 16px"
        >
          <Text fontSize="2xl" fontWeight="700" color="white">
            Preview do PDF
          </Text>
          <ModalCloseButton position="unset" color="white" />
        </ModalHeader>

        <ModalBody minH={'750px'} w="full" p={'1rem'} borderRadius={'8px'}>
          {getPdfByInstitution && (
            <iframe
              scrolling="auto"
              src={`${getPdfByInstitution}#&navpanes=0&view=FitH`}
              style={{
                width: '100%',
                height: '100%',
                minHeight: '750px',
                borderRadius: '8px',
              }}
            />
          )}

          {isError && (
            <Box
              left="50%"
              top="50%"
              position="absolute"
              transform="translate(-50%, -50%)"
            >
              <Text color="whiteAlpha.700">Erro ao carregar o PDF.</Text>
            </Box>
          )}

          {isLoading && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
