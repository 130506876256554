import { Button, Text, Tooltip } from '@chakra-ui/react'
import Pagination from 'components/Pagination'
import { ListHeader, ListTable, ListPagination, List } from 'features/ui'
import { TableHeader } from 'features/ui/list/list-table'
import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  NotifiedStudent,
  useGetNotifiedStudentsByNotificationId,
} from './mutations/use-get-notified-students-by-notification-id'

export default function NotificationsByStudentsTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [page, setPage] = useState(1)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { notificationId } = useParams<{ notificationId: string }>()
  const {
    data: getNotifiesStudentsByNotification,
    isLoading: isLoadingGetNotifiesStudentsByNotification,
    isFetching: isFetchingGetNotifiesStudentsByNotification,
  } = useGetNotifiedStudentsByNotificationId({
    notificationId: +notificationId,
    limit: 10,
    page: page,
  })

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: 'Nome',
        accessor: 'name',
        fn(name, _) {
          return (
            <Tooltip label={!name ? '-' : name} hasArrow>
              <Text isTruncated maxW="300px">
                {!name ? '-' : name}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Email',
        accessor: 'email',
        fn(email, _) {
          return (
            <Tooltip label={!email ? '-' : email} hasArrow>
              <Text isTruncated maxW="300px">
                {!email ? '-' : email}
              </Text>
            </Tooltip>
          )
        },
      },
      {
        label: 'Visualizada',
        accessor: 'is_viewed',
        fn: (_, data) => {
          return data.is_viewed ? 'Sim' : 'Não'
        },
      },
      {
        label: 'Aluno',
        fn(_, data) {
          return (
            <Button
              as={Link}
              size="sm"
              to={`/users/${data.id}`}
              colorScheme="brand"
            >
              Detalhes
            </Button>
          )
        },
      },
    ] as TableHeader<NotifiedStudent>[]
  }, [])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <>
      <List overflow={'auto'}>
        <ListHeader
          title="Alunos"
          isLoading={isLoadingGetNotifiesStudentsByNotification}
          isFetching={isFetchingGetNotifiesStudentsByNotification}
        />

        <ListTable
          headers={headers}
          data={getNotifiesStudentsByNotification?.data}
          isLoading={isLoadingGetNotifiesStudentsByNotification}
        />

        <Pagination
          currentPage={page}
          totalCountOfRegisters={
            getNotifiesStudentsByNotification?.meta?.total ?? 0
          }
          registersPerPage={10}
          onPageChange={setPage}
        />
      </List>
    </>
  )
}
