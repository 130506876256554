import React, { useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import {
  Flex,
  Text,
  FormLabel,
  InputProps,
  InputGroup,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Input,
  Box,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'

type Sizes = 'lg' | 'md' | 'sm'
type Variants = 'primary' | 'secondary'

export type FieldFileProps = InputProps & {
  name: string
  label?: string
  fileName?: string
  size?: Sizes
  variant?: Variants
  error?: FieldError
  allowedExtensions?: string[]
  isRequired?: boolean
  adicionalLabelText?: React.ReactNode
}

const FieldFileBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldFileProps
> = (props: FieldFileProps, ref): JSX.Element => {
  /*
  |-----------------------------------------------------------------------------
  | Constants
  |-----------------------------------------------------------------------------
  |
  |
  */
  const {
    name,
    label,
    error,
    fileName,
    allowedExtensions,
    isRequired,
    adicionalLabelText,
    size = 'md',
    variant = 'primary',
    ...rest
  } = props

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sizesBySize = useMemo((): Record<Sizes, InputProps> => {
    return {
      sm: {
        height: '32px',
      },
      md: {
        height: '42px',
      },
      lg: {
        height: '48px',
      },
    }
  }, [])

  const stylesByColor = useMemo((): Record<Variants | string, any> => {
    return {
      primary: {
        background: '#393940',
      },
      secondary: {
        background: '#2A2A30',
      },
    }
  }, [])

  const allowedExtensionsMemo = useMemo(() => {
    if (!allowedExtensions) return { caption: '', accept: '' }

    const acceptedExtensions = allowedExtensions.join(', .')

    return {
      caption: allowedExtensions.join(', '),
      accept: `.${acceptedExtensions}`,
    }
  }, [allowedExtensions])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <FormControl isInvalid={!!error}>
      <Flex justifyContent="space-between" alignItems="center">
        {!!label && (
          <FormLabel
            display="flex"
            gap="8px"
            color="#FFFFFF"
            htmlFor={name}
            mb={2}
            fontWeight={600}
            justifyContent="space-between"
          >
            <Box placeSelf="center">
              {label}
              {isRequired && (
                <Text
                  as="span"
                  color="red.500"
                  ml={1}
                  fontWeight="600"
                  userSelect="none"
                >
                  *
                </Text>
              )}
            </Box>
            {adicionalLabelText}
          </FormLabel>
        )}
      </Flex>

      <InputGroup position="relative" w="100%" h="100%" {...sizesBySize[size]}>
        <Input
          id={name}
          ref={ref}
          type="file"
          name={name}
          display="none"
          accept={allowedExtensionsMemo.accept}
          {...rest}
        />

        <Text
          htmlFor={name}
          as="label"
          px={4}
          {...stylesByColor[variant]}
          fontSize={'15px'}
          color={'#FFFFFF'}
          outline={'0px !important'}
          _placeholder={{
            color: '#D7D7D9',
          }}
          fontWeight="400"
          outlineColor={'none'}
          boxShadow="none !important"
          w="full"
          h="full"
          display="block"
          borderRadius="8px"
          cursor={rest.isDisabled ? 'not-allowed' : 'pointer'}
        >
          <Text
            fontWeight="400"
            fontFamily={'Mulish'}
            fontSize={'15px'}
            color="#D7D7D9"
            w="85%"
            top="50%"
            transform="translateY(-50%)"
            position="absolute"
            noOfLines={1}
          >
            {fileName || 'Nenhum arquivo selecionado'}
          </Text>

          <Box
            right="4"
            top="50%"
            transform="translateY(-50%)"
            position="absolute"
          >
            <Icon
              icon="ic:outline-file-upload"
              fontSize="16px"
              color="#8F5CF1"
            />
          </Box>
        </Text>
      </InputGroup>

      {allowedExtensions && !error && (
        <FormHelperText color="gray.500" lineHeight="3" mt="2" fontSize="sm">
          Extensões permitidas: {allowedExtensionsMemo.caption}
        </FormHelperText>
      )}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const FieldFile = React.forwardRef(FieldFileBase)
