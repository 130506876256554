import * as Yup from 'yup'

export const schema = Yup.object().shape({
  title: Yup.string().required('O nome é obrigatório.'),

  startsAt: Yup.string()
    .nullable()
    .transform((_, originalValue: Date) => {
      if (originalValue) {
        return originalValue?.toISOString()
      }

      return ''
    })
    .required('A data de publicação é obrigatória.'),

  urlLink: Yup.string().optional(),

  recordedMeeting: Yup.boolean().required('O tipo de evento é obrigatório.'),

  hostUserId: Yup.number()
    .transform((_, originalValue: { label: string; value: number }) => {
      if (originalValue) {
        return Number(originalValue.value)
      }

      return null
    })
    .typeError('O host do evento é obrigatório.')
    .required('O host do evento é obrigatório.'),
})
