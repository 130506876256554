import * as Yup from 'yup'

export const schema = Yup.object().shape({
  trackId: Yup.number()
    .typeError('A trilha é obrigatória.')
    .required('A trilha é obrigatória.')
    .transform((_, originalValue: { label: string; value: number }) => {
      if (originalValue) {
        return +originalValue.value
      }

      return null
    }),
  name: Yup.string().required('O nome do evento é obrigatório.'),
  groupType: Yup.string()
    .required('O nome do evento é obrigatório.')
    .nullable()
    .transform(
      (
        _,
        originalValue: {
          label: string
          value: 'NEWBIE' | 'VETERAN'
        },
      ) => {
        if (originalValue) {
          return originalValue.value
        }

        return null
      },
    ),
  mentoriaGroupLink: Yup.string().required('O link do grupo é obrigatório.'),
  membersLimit: Yup.number()
    .min(1)
    .required('O limite de alunos é obrigatório.')
    .typeError('O limite de alunos deve ser um número entre 1 e 180'),
  monitorUserId: Yup.number()
    .transform((_, originalValue: { label: string; value: number }) => {
      if (originalValue) {
        return originalValue.value
      }

      return null
    })
    .required('O monitor é obrigatório.'),
})
