import { Box, ButtonProps, Stack, StackProps, Text } from '@chakra-ui/react'
import React from 'react'
import PaginationItem from './PaginationItem'

interface PaginationProps extends StackProps {
  totalCountOfRegisters: number
  registersPerPage?: number
  showRegistersPerPage?: boolean
  currentPage?: number
  onPageChange?: (page: number) => void
  buttonProps?: ButtonProps
}

const siblingsCount = 2

function geratePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter(page => page > 0)
}

export default function Pagination({
  totalCountOfRegisters,
  registersPerPage = 10,
  showRegistersPerPage = true,
  currentPage = 1,
  buttonProps,
  onPageChange,
  ...props
}: PaginationProps) {
  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)
  const previousPage =
    currentPage > 1
      ? geratePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPage =
    currentPage < lastPage
      ? geratePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage),
        )
      : []

  return (
    <Stack
      direction={['column', 'row']}
      spacing={6}
      mt="4"
      justify={showRegistersPerPage ? 'space-between' : 'end'}
      align={'center'}
      {...props}
    >
      {showRegistersPerPage ? (
        <Box>
          <strong>{(currentPage - 1) * registersPerPage + 1}</strong> -{' '}
          <strong>
            {Math.min(currentPage * registersPerPage, totalCountOfRegisters)}
          </strong>{' '}
          de <strong>{totalCountOfRegisters} registros</strong>
        </Box>
      ) : null}

      <Stack direction={'row'} spacing={2}>
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem
              buttonProps={buttonProps}
              number={1}
              onPageChange={onPageChange}
            />
            {currentPage > 2 + siblingsCount - 1 && (
              <Text color="gray.300" width="8" textAlign={'center'}>
                ...
              </Text>
            )}
          </>
        )}
        {previousPage.length > 0 &&
          previousPage.map(page => (
            <PaginationItem
              buttonProps={buttonProps}
              key={page}
              number={page}
              onPageChange={onPageChange}
            />
          ))}
        <PaginationItem
          number={currentPage}
          buttonProps={buttonProps}
          isCurrent
          onPageChange={onPageChange}
        />
        {nextPage.length > 0 &&
          nextPage.map(page => (
            <PaginationItem
              buttonProps={buttonProps}
              key={page}
              number={page}
              onPageChange={onPageChange}
            />
          ))}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + siblingsCount < lastPage && (
              <Text color="gray.300" width="8" textAlign={'center'}>
                ...
              </Text>
            )}
            <PaginationItem
              buttonProps={buttonProps}
              number={lastPage}
              onPageChange={onPageChange}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}
