import { OptionTypeBase } from 'react-select'
import React from 'react'
import { Image } from '@chakra-ui/react'
import { letterCounter } from 'helpers/charactersCount'

export interface Option extends OptionTypeBase {
  value: number | undefined
  label: string
  imageUrl?: string
}

export interface Month {
  value: string
  label: string
  month: number
}

interface CustomOptionProps {
  person: OptionTypeBase
  avatarEndpoint: string
}
export const CustomOption = ({ person, avatarEndpoint }: CustomOptionProps) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {person?.avatar && (
          <Image
            width="15px"
            height="15px"
            src={person.avatar}
            objectFit={'contain'}
            borderRadius="6px"
          />
        )}
        {!person?.avatar && (
          <Image w="15px" h="15px" borderRadius="6px" src={avatarEndpoint} />
        )}
        <p>{`${letterCounter(person.label, 16)}`}</p>
      </div>
    </>
  )
}
