import { uniq } from 'lodash'
import { Monitors } from './queries/use-fetch-monitors'

export const FormatYear = (data: Monitors) => {
  const monitorTrackYears = data?.monitorTracks?.map(item => item.years)

  const formattedTrackArray = monitorTrackYears?.flat()
  const formattedTrack = uniq(formattedTrackArray)
  return formattedTrack
}

export const FormatTrack = (data: Monitors) => {
  const monitorTrackYears = data?.monitorTracks?.map(item => item.name)

  const formattedTrackArray = monitorTrackYears?.flat()
  const formattedTrack = uniq(formattedTrackArray)
  return formattedTrack
}

export const FormatTrail = (data: Monitors) => {
  const monitorTrackYears = data?.monitorTracks?.map(item => {
    return {
      label: item.name,
      value: item.id,
    }
  })

  const formattedTrackArray = monitorTrackYears?.flat()
  const formattedTrack = uniq(formattedTrackArray)
  return formattedTrack
}

export const formatLabel = (
  data:
    | {
        label: string | number | undefined
        value: number | undefined
      }[]
    | null,
): (number | undefined)[] => {
  const label = data?.map(item => item.value)
  const formattedLabelArray = label?.flat()
  const formattedLabel = uniq(formattedLabelArray)
  return formattedLabel
}
