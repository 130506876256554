import { Button, Flex, Text, Link } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import './style.css'
type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xsm'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size: Size
  children?: ReactNode
  linkWhats?: string
  onConfirmClick?: () => void
} & ReactModalProps

const contentStyles = () => {
  return {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  }
}

const getWidthBySize = (size: Size) => {
  const sizes: Record<Size, string> = {
    xsm: '252px',
    sm: '360px',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return sizes[size]
}

export const ModalWhatsAppAdvisorTable = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    size,
    children,
    linkWhats,
    onConfirmClick,
    ...reactModalProps
  } = props

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root') as HTMLElement}
      style={{
        content: {
          ...contentStyles(),
          width: getWidthBySize('sm'),
          padding: '32px',
          background: '#302F37',
          border: '0px',
        },
        overlay: {
          zIndex: 999999999,
          backgroundColor: 'rgba(14, 14, 15, 0.40)',
        },
      }}
      {...reactModalProps}
    >
      <Flex w="100%" justifyContent={'center'} flexDir="column">
        <Flex flexDir="column">
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize={'24px'}
            color={'#FFFFFF'}
            textAlign={'center'}
          >
            Tem certeza que deseja continuar?
          </Text>
          <Text
            fontFamily="Mulish"
            mt="8px"
            fontStyle="normal"
            fontWeight="400"
            textAlign={'center'}
            fontSize="12px"
            lineHeight="15px"
            color="rgba(255, 255, 255, 0.8)"
          >
            Se continuar, você será direcionado para o whatsapp.
          </Text>
        </Flex>

        <Flex
          mt="24px"
          alignItems={'center'}
          gap="12px"
          justifyContent={'center'}
        >
          <Button
            fontFamily="Mulish"
            font-weight="700"
            font-size="16px"
            color="#FFFFFF"
            height="38px"
            borderRadius="12px"
            onClick={onClose}
            variant="ghost"
            maxW="128px"
            w="100%"
            h="42px"
            border="1px solid #FFFFFF"
            _hover={{ backgroundColor: '#45454d' }}
          >
            Não
          </Button>

          <Link
            href={`https://wa.me/${linkWhats}`}
            textDecoration="none !important"
            target="_blank"
            w="100%"
            maxW={'125px'}
          >
            <Button
              _hover={{ backgroundColor: '#7d39be' }}
              w="100%"
              maxW={'125px'}
              colorScheme="red"
              borderRadius="12px"
              variant="outline"
              background={'#7D46B1'}
              border="1px solid #7D46B1"
              height="42px"
              fontFamily="Mulish"
              font-weight="700"
              borderColor={'none'}
              font-size="16px"
              color="#FFFFFF"
              onClick={onConfirmClick}
            >
              Sim
            </Button>
          </Link>
        </Flex>
      </Flex>
      {children}
    </ReactModal>
  )
}
