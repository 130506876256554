import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export interface Monitors {
  id: number
  name: string
  avatar: {
    id: number
    user_id: number
    url: string
    created_at: string
  }
  studentsCount: string
  monitorDetail: {
    id: number
    whatsapp_group_link: string
    monitor_status: string
    monitor_user_id: number
    advisor_user_id: number
    created_at: string
    updated_at: string
    monitor_type: string
    monitor_video_links: string[]
    advisorUser: {
      id: number
      name: string
    }
    activity_status: 'ACTIVE' | 'INACTIVE'
  } | null
  mentoriaGroup: Array<{
    id: number
    name: string
    mentoria_group_link: string
    members_limit: number
    group_type: string
    monitor_user_id: number
    track_id: number
    created_at: string
    updated_at: string
  }> | null
  monitorTracks: Array<{
    id: number
    name: string
    years: number[]
  }> | null
}

type Params = {
  name?: string
  monitorType?: string
  trackId?: number
  activityStatus?: 'ACTIVE' | 'INACTIVE'
}

export async function fetchAllMonitors(params: Params): Promise<Monitors[]> {
  const { data } = await api.get<Monitors[]>('/admin/users/monitors?sort=asc', {
    params,
  })
  return data
}

export function useGetAllMonitors(params: Params) {
  return useQuery(
    ['fetchMonitorList', params],
    () => fetchAllMonitors(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
