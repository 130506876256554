import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'
import { Event } from '../interfaces/EventsInterfaces'

interface Params {
  id: number
  title: string
  urlLink: string
  startsAt: string
  recordedMeeting: boolean
  hostUserId: number
}

async function updateEvent(
  params: Params,
): Promise<Omit<Event, 'createdByUser' | 'studentCount'>> {
  const response = await api.put(`/admin/scheduled_events/${params.id}`, params)

  return response.data
}

export function useUpdateEvent() {
  return useMutation(updateEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-events'])
    },
  })
}
