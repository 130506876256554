import { debounce } from 'lodash'
import React, { ChangeEvent, useCallback, useEffect } from 'react'

import Header from 'components/Header'
import { GetInTouch } from 'components/GetInTouch'
import ListPagination from 'components/ListPagination'

import api from 'services/api'

import {
  Td,
  Th,
  Tr,
  Box,
  Flex,
  Badge,
  Table,
  Tbody,
  Thead,
  Input,
  Select,
  HStack,
  Skeleton,
} from '@chakra-ui/react'
import Layout from 'layouts/ChakraLogged'

import { MetaUnformatted, Pagination, Query } from 'types/response'

interface Students {
  id: number
  name: string
  email: string
  contacted: boolean
  lastLoginAt: string
  globaGoal: string | null
  mobilePhone: string | null
}

export const UsersGlobalMeta: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [students, setStudents] = React.useState<Students[]>([])
  const [query, setQuery] = React.useState<Query>({
    limit: 10,
    order: 'desc',
    orderByColumn: 'global_goal_percentage_achieved',
  } as Query)
  const [pagination, setPagination] = React.useState<Pagination>(
    {} as Pagination,
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (value.length === 0) {
        setQuery(oldState => ({ ...oldState, filter: undefined }))
      }

      if (value.length < 3) return

      setQuery(oldState => ({ ...oldState, filter: value }))
    }, 500),
    [],
  )

  const handleSearchMeta = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target

      switch (value) {
        case '0-60':
          setQuery(oldState => ({
            ...oldState,
            page: 1,
            order: 'desc',
            globalGoalMin: 0,
            globalGoalMax: 60.99,
            orderByColumn: 'global_goal_percentage_achieved',
          }))
          break

        case '61-80':
          setQuery(oldState => ({
            ...oldState,
            page: 1,
            order: 'asc',
            globalGoalMin: 61,
            globalGoalMax: 80.99,
            orderByColumn: 'global_goal_percentage_achieved',
          }))
          break

        case '81-100':
          setQuery(oldState => ({
            ...oldState,
            page: 1,
            order: 'desc',
            globalGoalMin: 81,
            globalGoalMax: 100,
            orderByColumn: 'global_goal_percentage_achieved',
          }))
          break

        default:
          setQuery(oldState => ({
            ...oldState,
            page: 1,
            order: 'desc',
            globalGoalMin: undefined,
            globalGoalMax: undefined,
            orderByColumn: 'global_goal_percentage_achieved',
          }))
      }
    },
    [],
  )

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const { data: response } = await api.get<{
          meta: MetaUnformatted
          data: Students[]
        }>('/monitor/students', {
          params: query,
        })

        if (!response) return

        const { meta } = response
        setPagination({
          firstPage: 1,
          total: meta.total,
          perPage: meta.per_page,
          lastPage: meta.last_page,
          currentPage: meta.current_page,
          currentPageCount: response.data.length,
        })
        setStudents(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [query])

  const colorBadge = useCallback((value?: number | string | null) => {
    if (!value) return 'gray'

    const numberValue = Number(value)

    if (numberValue <= 61) {
      return 'red'
    }

    if (numberValue > 60 && numberValue <= 81) {
      return 'yellow'
    }

    if (numberValue > 80) {
      return 'green'
    }

    return 'gray'
  }, [])

  return (
    <Layout>
      <Header
        title="Metas globais de usuários"
        subTitle="Lista completa das metas globais de usuários"
      />

      <Box mt="16">
        <HStack>
          <Input
            size="sm"
            onChange={handleSearch}
            placeholder="Buscar pelo nome (mínimo 3 caracteres)"
          />

          <Select
            size="sm"
            placeholder="Todas as metas"
            colorScheme="blackAlpha"
            onChange={handleSearchMeta}
            sx={{
              option: {
                color: 'blackAlpha.700',
              },
            }}
          >
            <option value="81-100">81% ~ 100%</option>
            <option value="61-80">61% ~ 80%</option>
            <option value="0-60">0% ~ 60%</option>
          </Select>
        </HStack>

        <Table mt="6">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Meta global</Th>
              <Th>Contato via whatsapp</Th>
            </Tr>
          </Thead>

          <Tbody>
            {isLoading && (
              <Tr>
                <Td>
                  <Skeleton height="4" />
                </Td>
                <Td>
                  <Skeleton height="4" />
                </Td>
                <Td>
                  <Skeleton height="4" />
                </Td>
              </Tr>
            )}

            {!isLoading &&
              students.length > 0 &&
              students.map(student => (
                <Tr key={student.id}>
                  <Td>{student.name}</Td>
                  <Td>
                    <Badge colorScheme={colorBadge(student.globaGoal)}>
                      {student.globaGoal == null
                        ? 'Sem conteúdo'
                        : student.globaGoal + '%'}
                    </Badge>
                  </Td>
                  <Td>
                    <GetInTouch
                      studentId={student.id}
                      contacted={student.contacted}
                      phoneNumber={student.mobilePhone}
                    />
                  </Td>
                </Tr>
              ))}

            {!isLoading && !students.length && (
              <Tr>
                <Td colSpan={3} mx="auto">
                  <Flex justifyContent="center">
                    Nenhum usuário encontrado.
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>

        <ListPagination
          query={{ set: setQuery, current: query }}
          pagination={{
            set: setPagination,
            current: pagination,
          }}
        />
      </Box>
    </Layout>
  )
}
