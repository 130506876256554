import { Box, Button, chakra, Flex, Text, Tooltip } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { ScatterChartAdvisor } from 'components/ApexChart/AdvisorScatterChart'
import { FieldSelect } from 'components/Form/FieldSelect'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CardKpi } from './components/card-kpi'
import { useHistory } from 'react-router-dom'
import { FaRegHandPaper } from 'react-icons/fa'
import { differenceInDays, startOfMonth } from 'date-fns'
import { Option, CustomOption } from './Types'
import { useAuth } from 'contexts/auth'
import { useGetAdvisorDashboard } from './queries/use-fetch-advisor'
import { capitalize, filter, get, map, max } from 'lodash'
import { GetDashboardAdvisor } from './queries/use-fetch-advisor'
import {
  SelectMonth,
  SelectMonthOption,
  SelectYearOption,
} from '../../../constants/months'
import { formatDateUTC } from 'helpers/formatDate'
import { ptBR } from 'date-fns/locale'
import { NoDataTableError } from 'components/MonitorTable/components/NoDataTable'
import { OptionTypeBase } from 'react-select'

export function DashboardAdvisor() {
  /*
  |-----------------------------------------------------------------------------
  | State
  |-----------------------------------------------------------------------------
  |
  |
  */
  const currentMonth = startOfMonth(new Date()) // current month

  const [months, setMonths] = useState<SelectMonthOption>({
    label: capitalize(
      formatDateUTC(currentMonth.getMonth().toLocaleString(), 'MMMM', {
        locale: ptBR,
      }),
    ),
    value: new Date().getMonth(),
  })

  const [year, setYear] = useState<SelectYearOption>({
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  })

  const [monitorFilter, setMonitorFilter] = useState<Option>({
    label: 'Todos monitores',
    value: undefined,
  })

  const { push } = useHistory()
  const { user, updateUserData } = useAuth()

  const { data: AdvisorDashboardData, error, isError } = useGetAdvisorDashboard(
    {
      monitorId: monitorFilter.value,
      month: months.value,
      year: year.value,
    },
  )

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const avatarEndpoint = useCallback((user: string) => {
    if (!user) return ''

    const baseUrl = 'https://ui-avatars.com/api/?'
    const options = 'background=fff&color=222&bold=true'
    return `${baseUrl}${options}&name=${user.replaceAll(' ', '+')}`
  }, [])

  const CurrentSelectedDate = useMemo(() => {
    return new Date(`${year.value}/${months.value}/01`)
  }, [months.value, year.value])

  const differenceBetweenDays = differenceInDays(
    CurrentSelectedDate,
    currentMonth,
  )

  const studentsWhoAchievedPercentage = useMemo(() => {
    if (
      !AdvisorDashboardData?.meta.globalGoal ||
      !AdvisorDashboardData.meta.students
    )
      return 0

    return Math.round(
      (AdvisorDashboardData?.meta.globalGoal.studentsWhoAchieved /
        AdvisorDashboardData.meta.students.total) *
        100,
    )
  }, [
    AdvisorDashboardData?.meta.globalGoal,
    AdvisorDashboardData?.meta.students,
  ])

  /*
  |-----------------------------------------------------------------------------
  | Callbacks
  |-----------------------------------------------------------------------------
  |
  |
  */

  const sanitizeChartData = useCallback(
    (DashboardData: GetDashboardAdvisor) => {
      const { studentsData } = DashboardData

      const overEightyPercent = map(
        filter(studentsData, student => {
          const globalGoal = get(
            student,
            'trackGoal.goalPercentageAchieved.globalGoal',
            0,
          )

          return +globalGoal > 80
        }),
        student => [
          +get(student, 'trackGoal.goalPercentageAchieved.globalGoal', 0),
          student.daysWithoutAccess,
          student.name,
        ],
      )

      const betweenSixtyAndEighty = map(
        filter(studentsData, student => {
          const globalGoal = get(
            student,
            'trackGoal.goalPercentageAchieved.globalGoal',
            0,
          )

          return +globalGoal >= 61 && +globalGoal <= 80
        }),
        student => [
          +get(student, 'trackGoal.goalPercentageAchieved.globalGoal', 0),
          student.daysWithoutAccess,
          student.name,
        ],
      )

      const underSixty = map(
        filter(studentsData, student => {
          const globalGoal = get(
            student,
            'trackGoal.goalPercentageAchieved.globalGoal',
            0,
          )

          return +globalGoal < 61
        }),
        student => [
          +get(student, 'trackGoal.goalPercentageAchieved.globalGoal', 0),
          student.daysWithoutAccess,
          student.name,
        ],
      )

      return {
        series: [
          {
            name: 'Alunos com mais de 81% de conclusão',
            data: overEightyPercent,
            color: '#9AE6B4',
          },
          {
            name: 'Alunos entre 61% e 80% de conclusão',
            data: betweenSixtyAndEighty,
            color: '#FFCE56',
          },
          {
            name: 'Alunos com menos de 60% de conclusão',
            data: underSixty,
            color: '#FF7F7F',
          },
        ],
      }
    },
    [],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    updateUserData()
  }, [updateUserData])

  return (
    <>
      <Flex flexDir={'column'} mb="16px" mt="32px" w="100%">
        <Text
          fontFamily={'Mulish'}
          mb="1rem"
          fontSize={'24px'}
          fontWeight="700"
          lineHeight={'30px'}
        >
          Filtros
        </Text>

        <Flex
          justifyContent={{ base: 'flex-start', sm: 'space-between' }}
          flexDir={{ base: 'column', lg: 'row' }}
          gap={{ base: '1rem', lg: '0px' }}
          w="100%"
        >
          <Flex
            flexDir={{ base: 'column', sm: 'row' }}
            maxW={'800px'}
            w="100%"
            gap={'16px'}
          >
            <Box w="100%" maxW={{ base: '100%', sm: '180px' }}>
              <FieldSelect
                placeholder="Período"
                options={SelectMonth}
                onChange={(e: OptionTypeBase) =>
                  setMonths(e as SelectMonthOption)
                }
                value={months}
              />
            </Box>

            <Box w="100%" maxW={{ base: '100%', sm: '130px' }}>
              <FieldSelect
                maxW="216px"
                w="100%"
                placeholder="Escolha o ano"
                options={[
                  {
                    label: new Date().getFullYear(),
                    value: new Date().getFullYear(),
                  },
                  {
                    label: new Date().getFullYear() - 1,
                    value: new Date().getFullYear() - 1,
                  },
                ]}
                onChange={(e: OptionTypeBase) => setYear(e as SelectYearOption)}
                value={year}
              />
            </Box>

            <Box w="100%" maxW={{ base: '100%', sm: '240px' }}>
              <FieldSelect
                maxW="216px"
                w="100%"
                placeholder="Escolha o monitor(a)"
                options={
                  user?.advisorMonitors
                    ? [
                        ...user?.advisorMonitors?.map(monitors => {
                          return {
                            label: monitors.name,
                            value: monitors.id,
                            avatar: monitors.avatar?.url,
                          }
                        }),
                        {
                          label: 'Todos monitores',
                          value: undefined,
                        },
                      ]
                    : []
                }
                onChange={(e: OptionTypeBase) => setMonitorFilter(e as Option)}
                value={monitorFilter}
                formatOptionLabel={(person: OptionTypeBase) =>
                  CustomOption({
                    avatarEndpoint: avatarEndpoint(person.label),
                    person: person,
                  })
                }
              />
            </Box>
          </Flex>

          <Button
            as={'a'}
            onClick={() => push('/advisorTable')}
            cursor="pointer"
            fontWeight="500"
            fontSize="16px"
            lineHeight="20px"
            color="#FFFFFF"
            leftIcon={<Icon icon={'eva:folder-outline'} color="#E296FD" />}
            backgroundColor="#393940"
            maxW={'181px'}
            w="100%"
            borderRadius="6px"
            _active={{ backgroundColor: '#393940', outline: 'none' }}
            _focus={{ boxShadow: 'none', outline: '1px solid #E296FD' }}
            _hover={{ background: '#313136', outline: '1px solid #E296FD' }}
          >
            Tabela de alunos
          </Button>
        </Flex>

        {AdvisorDashboardData && differenceBetweenDays < 0 && (
          <Flex
            gap={{ base: '16px', sm: '24px', md: '32px' }}
            mt="32px"
            flexDir={{ base: 'column', sm: 'column', xl: 'row' }}
          >
            <CardKpi
              grid={'two'}
              icon={'lucide:target'}
              titleSection={'Meta global'}
              titleGrid={
                AdvisorDashboardData?.meta?.globalGoal?.conclusionAvg?.toFixed(
                  1,
                ) ?? '-'
              }
              secondTitleGrid={
                AdvisorDashboardData?.meta?.globalGoal?.studentsWhoAchieved ??
                '-'
              }
              descriptionGrid={'Média das metas'}
              secondDescriptionGrid={
                <Text
                  fontFamily="Mulish"
                  fontStyle="normal"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="15px"
                  color="#BDBDC7"
                >
                  Alunos que atingiram{' '}
                  <chakra.span color={'#E296FD'}>
                    {`${studentsWhoAchievedPercentage}%`}
                  </chakra.span>{' '}
                  de{' '}
                  <chakra.span
                    fontFamily="Mulish"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="12px"
                    lineHeight="18px"
                    color="#F5F5FA"
                  >
                    {AdvisorDashboardData?.meta?.students?.total
                      ? AdvisorDashboardData?.meta?.students?.total
                      : 0}{' '}
                    {'alunos'}
                  </chakra.span>
                </Text>
              }
            />

            <CardKpi
              grid={'two'}
              icon={'mdi:college-outline'}
              titleSection={'Revisões'}
              titleGrid={
                AdvisorDashboardData?.meta?.revisions?.conclusionAvg ?? '-'
              }
              secondTitleGrid={
                AdvisorDashboardData?.meta?.revisions?.lateAvg ?? '-'
              }
              descriptionGrid={'Média de concluídos'}
              secondDescriptionGrid={'Média de atrasados'}
            />

            <CardKpi
              grid={'one'}
              icon={'mdi:college-outline'}
              titleSection={'Alunos'}
              titleGrid={
                AdvisorDashboardData?.meta?.students?.noAccessInTenDays ?? '-'
              }
              descriptionGrid={'Sem acesso há 10 dias'}
            />
          </Flex>
        )}

        <Box mt="2rem">
          <Text
            fontFamily="Mulish"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
            color="#FFFFFF"
          >
            Dias de acesso alunos
            <Tooltip
              background={'#302F37'}
              borderRadius="8px"
              p={'1rem'}
              label={
                <Text
                  fontFamily="Mulish"
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="18px"
                  color="#FFFFFF"
                >
                  Para ver alunos acima de 100% selecione a opção{' '}
                  <FaRegHandPaper style={{ display: 'inline-block' }} /> e
                  arraste para esquerda
                </Text>
              }
              hasArrow
              shouldWrapChildren
            >
              <Icon
                fontSize={'16px'}
                style={{ display: 'inline', marginLeft: '16px' }}
                icon="mdi:question-mark-circle-outline"
              />
            </Tooltip>
          </Text>

          <Text
            fontFamily="Mulish"
            fontWeight="400"
            fontSize="12px"
            lineHeight="15px"
            color="#FFFFFF"
            mb="16px"
          >
            (apenas visualização)
          </Text>

          {AdvisorDashboardData && differenceBetweenDays < 0 && (
            <ScatterChartAdvisor
              series={sanitizeChartData(AdvisorDashboardData)?.series}
              maxRangeChart={max(
                map(
                  filter(AdvisorDashboardData?.studentsData, student => {
                    const globalGoal = get(
                      student,
                      'trackGoal.goalPercentageAchieved.globalGoal',
                      0,
                    )

                    return +globalGoal > 80
                  }),
                  student => [
                    +get(
                      student,
                      'trackGoal.goalPercentageAchieved.globalGoal',
                      0,
                    ),
                  ],
                ).flat(),
              )}
            />
          )}

          {(differenceBetweenDays >= 0 || isError) && (
            <NoDataTableError
              headerMessage="Dado não encontrado"
              message="Erro / Data selecionada superior a data de hoje"
            />
          )}
        </Box>
      </Flex>
    </>
  )
}
