import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { AxiosError } from 'axios'

import api from 'services/api'
import Swal from 'sweetalert2'

import {
  AuthContextValues,
  User,
  LogInPayload,
  LogInResponse,
} from './interfaces'

const STORAGE_KEYS = {
  USER: '@mentoria-admin:user',
  TOKEN: '@mentoria-admin:token',
}

const AuthContext = createContext({} as AuthContextValues)

export const AuthContextProvider: React.FC = ({ children }) => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const history = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const saveUserToStorage = useCallback((user: User) => {
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user))
  }, [])

  const saveTokenToStorage = useCallback((token: string) => {
    localStorage.setItem(STORAGE_KEYS.TOKEN, token)
  }, [])

  const loadUserFromStorage = useCallback(() => {
    const userStr = localStorage.getItem(STORAGE_KEYS.USER)
    if (!userStr) return undefined
    return JSON.parse(userStr)
  }, [])

  const loadTokenFromStorage = useCallback(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN)
    if (!token) return
    return token
  }, [])

  const logIn = useCallback(
    async ({ email, password }: LogInPayload) => {
      setIsLoading(true)
      try {
        const newSessionPayload = { email, password }
        const {
          data: { token, user: userData },
        } = await api.post<LogInResponse>('/public/sessions', newSessionPayload)

        if (
          !userData.roles.some(role =>
            ['admin', 'monitor', 'advisor', 'support'].includes(role.slug),
          )
        ) {
          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            text: 'Você não possui acesso a essa plataforma.',
            icon: 'warning',
          })

          setIsLoading(false)

          return
        }

        setUser(userData)
        saveUserToStorage(userData)
        api.defaults.headers.Authorization = `Bearer ${token.token}`
        saveTokenToStorage(token.token)
        setIsLoading(false)
        history.replace('dashboard')
      } catch (error: AxiosError | any) {
        setIsLoading(false)
        let errorMessage =
          'Infelizmente houve uma falha no seu login. Reveja os dados e tente novamente.'

        if (error.response?.data?.errors) {
          errorMessage = error.response.data.errors
            .map((err: { [key: string]: string }) => err.message)
            .join('\n')
        }

        const apiErrorString = get(error, 'response.data')
        if (typeof apiErrorString === 'string') {
          errorMessage = apiErrorString
        }

        Swal.fire({
          title: 'Aviso',
          buttonsStyling: false,
          text: errorMessage,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, saveTokenToStorage, saveUserToStorage],
  )

  const logOut = useCallback(() => {
    localStorage.removeItem(STORAGE_KEYS.USER)
    localStorage.removeItem(STORAGE_KEYS.TOKEN)

    setUser(undefined)
    api.defaults.headers.Authorization = ''
  }, [])

  const updateUserData = useCallback(async () => {
    try {
      const { data: userProfile } = await api.get<User>('/app/profiles')

      setUser(userProfile)
      saveUserToStorage(userProfile)
    } catch (error) {
      console.trace('Erro ao atualizar perfil do usuário', error)
    }
  }, [saveUserToStorage])

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    const token = loadTokenFromStorage()
    const user = loadUserFromStorage()

    if (token && user) {
      setUser(user)
      api.defaults.headers.Authorization = `Bearer ${token}`
    }

    setIsLoading(false)
  }, [loadTokenFromStorage, loadUserFromStorage])

  /*
  |-----------------------------------------------------------------------------
  | Memos.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const authContextValue: AuthContextValues = useMemo(
    () => ({
      user,
      userRolesSlugs: user ? user.roles.map(({ slug }) => slug) : [],
      hasContract: Boolean(user?.contracts?.length),
      userContract: user?.contracts.length ? user.contracts[0] : undefined,
      isLoggedIn: !!user,
      logIn,
      logOut,
      isLoading,
      updateUserData,
    }),
    [isLoading, logIn, logOut, updateUserData, user],
  )

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}
