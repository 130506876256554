import React from 'react'
import { Box, Flex, Input, InputProps } from '@chakra-ui/react'
import { IconType } from 'react-icons'

type FieldInputProps = {
  InputRightCustomIcon?: IconType
} & InputProps

const FieldInputSearchComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  FieldInputProps
> = (props, ref) => {
  const { InputRightCustomIcon } = props

  return (
    <Box position={'relative'}>
      {InputRightCustomIcon && (
        <Flex
          position={'absolute'}
          boxSizing={'border-box'}
          zIndex="9999"
          right={'8px'}
          top={'50%'}
          transform="translate(-50%, -50%)"
        >
          <InputRightCustomIcon
            style={{ boxSizing: 'border-box' }}
            size={'18px'}
            color="#E296FD"
          />
        </Flex>
      )}
      <Input ref={ref} {...props} />
    </Box>
  )
}

export const FieldInputSearch = React.forwardRef(FieldInputSearchComponent)
