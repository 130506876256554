import { transparentize } from 'polished'
import styled from 'styled-components'
import { ResponsiveContainer } from 'styles/common'
export const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  padding-left: 24px;
  padding-top: 32px;
  padding-right: 24px;

  ${ResponsiveContainer}

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #d9d9d9;
    height: 8px;
    border-radius: 17px;
  }

  ::-webkit-scrollbar-thumb {
    background: #60606c;
    height: 8px;
    border-radius: 17px;
  }

  overflow-y: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
