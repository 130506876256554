import React, { useCallback, useEffect, useState } from 'react'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Schemas
import { NewSubjectSchema } from './schemas'

// Services
import api from 'services/api'

// Styles
import { Container, Content } from './styles'
import Layout from 'layouts/Logged'

// Interfaces
import { Field, SubjectsFormInput, Track } from './interfaces'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { Flex } from '@chakra-ui/react'
import { FieldSelectController } from 'components/Form/FieldSelect/FieldSelectController'
import { FieldInputController } from 'components/Form/FieldInput/FieldInputController'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const NewSubject: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const history = useHistory()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<SubjectsFormInput>({
    resolver: yupResolver(NewSubjectSchema),
    defaultValues: {
      fieldId: null,
      name: '',
      trackId: null,
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [fields, setFields] = useState<Field[]>([])
  const [tracks, setTracks] = useState<Track[]>([])

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const goToSubjectList = useCallback(() => {
    history.push('/subjects')
  }, [history])

  const createNewSubject = useCallback(
    formData => {
      api
        .post('/admin/subjects', formData)
        .then(() => {
          reset()

          Swal.fire({
            buttonsStyling: false,
            title: 'Sucesso',
            text: 'Cadastro realizado com sucesso!',
            icon: 'success',
          })
        })
        .catch(err => {
          let errorMessage = 'O cadastro não pode ser feito concluído.'

          if (get(err, 'response.status') === 422) {
            const errors = get(err, 'response.data.errors')
            if (Array.isArray(errors)) {
              errorMessage = errors
                .map(error => `\n${error.field} - ${error.message}`)
                .join('')
            }
          }

          console.trace(err)
          Swal.fire({
            buttonsStyling: false,
            title: 'Aviso',
            text: errorMessage,
            icon: 'error',
          })
        })
    },
    [reset],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    /**
     * Fetch tracks.
     */
    api
      .get<{ data: Track[] }>('/admin/tracks?limit=1000')
      .then(({ data: { data } }) => setTracks(data))
      .catch(err => console.trace(err))
  }, [])

  useEffect(() => {
    /**
     * Fetch fields for selected track.
     */
    if (!watch('trackId.value')) return

    api
      .get<{ data: Field[] }>('/admin/fields', {
        params: { limit: 1000, trackId: watch('trackId.value') },
      })
      .then(({ data: { data } }) => {
        setValue('fieldId', null)
        setFields(data)
      })
      .catch(err => console.trace(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, watch('trackId.value')])

  /*
  |-----------------------------------------------------------------------------
  | Renders
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header title="Cadastro de SubTemas" subTitle="Insira novos subtemas" />
        <Content>
          <SectionTitle>Listagem</SectionTitle>
          <DefaultButton
            maxW="189px"
            label="Exibir"
            onClick={goToSubjectList}
          />
          <SectionTitle>Cadastro</SectionTitle>

          <Flex
            as="form"
            flexDir="column"
            maxW="380px"
            pt="16px"
            gap="1rem"
            onSubmit={handleSubmit(createNewSubject)}
          >
            <FieldSelectController
              control={control}
              name="trackId"
              placeholder="Trilha"
              options={tracks.map(role => ({
                label: role.name,
                value: role.id,
              }))}
              error={errors.trackId as any}
            />

            <FieldSelectController
              control={control}
              name="fieldId"
              placeholder="Grande área"
              isDisabled={!watch('trackId')}
              options={fields.map(role => ({
                label: role.name,
                value: role.id,
              }))}
              error={errors.fieldId as any}
            />

            <FieldInputController
              control={control}
              name="name"
              placeholder="Nome do subtema"
              error={errors.name}
            />

            <Flex maxW="380px" mt="12px" gap="12px">
              <DefaultButton
                isLoading={isSubmitting}
                type="submit"
                w="full"
                label="Cadastrar"
              />
              <DefaultButton
                w="full"
                onClick={() => reset()}
                variant="ghost"
                label="Limpar"
              />
            </Flex>
          </Flex>
        </Content>
      </Container>
    </Layout>
  )
}

export default NewSubject
