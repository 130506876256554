import {
  Divider,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import React, { ReactNode } from 'react'

interface CardKpiProps {
  grid: 'one' | 'two'
  icon: string
  titleSection: string
  titleGrid: string | number
  descriptionGrid: ReactNode | string
  secondTitleGrid?: string | number
  secondDescriptionGrid?: ReactNode | string
  maxSectionWidth?: string
}

export function CardKpi(props: CardKpiProps) {
  const {
    grid,
    icon,
    maxSectionWidth = '177px',
    descriptionGrid,
    titleGrid,
    titleSection,
    secondDescriptionGrid,
    secondTitleGrid,
  } = props

  const isSm = useBreakpointValue({ base: true, sm: false })

  if (grid === 'one') {
    return (
      <Flex flexDir={'column'} borderRadius={'4px'} gap="8px">
        <HStack spacing={'8px'}>
          <Icon icon={icon} fontSize="14px" color="#FFFFF" />
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize="18px"
            lineHeight="23px"
            color="#FFFFFF"
          >
            {titleSection}
          </Text>
        </HStack>

        <Flex
          h="auto"
          maxW={isSm ? 'unset' : maxSectionWidth}
          w="100%"
          textAlign={{ base: 'center', sm: 'unset' }}
          backgroundColor={'#302F37'}
          p="16px 12px"
          gap={'2px'}
          flexDir={'column'}
          minH={{ base: 'unset', sm: '96px' }}
        >
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize="24px"
            lineHeight="30px"
            color="#FFFFFF"
          >
            {titleGrid}
          </Text>

          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="400"
            fontSize="12px"
            lineHeight="15px"
            color="#BDBDC7"
          >
            {descriptionGrid}
          </Text>
        </Flex>
      </Flex>
    )
  } else {
    return (
      <Flex flexDir="column" gap={'8px'}>
        <HStack spacing={'8px'}>
          <Icon icon={icon} fontSize="14px" color="#FFFFF" />
          <Text
            fontFamily="Mulish"
            fontStyle="normal"
            fontWeight="700"
            fontSize="18px"
            lineHeight="23px"
            color="#FFFFFF"
          >
            {titleSection}
          </Text>
        </HStack>

        <Flex flexDir={{ base: 'column', sm: 'row' }}>
          <Flex
            backgroundColor={'#302F37'}
            p="16px 12px"
            gap={'2px'}
            h="auto"
            maxW={isSm ? 'unset' : maxSectionWidth}
            w="100%"
            textAlign={{ base: 'center', sm: 'unset' }}
            flexDir={'column'}
          >
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="700"
              fontSize="24px"
              lineHeight="30px"
              color="#FFFFFF"
            >
              {titleGrid + '%'}
            </Text>

            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="12px"
              lineHeight="15px"
              color="#BDBDC7"
            >
              {descriptionGrid}
            </Text>
          </Flex>

          <Flex
            alignItems={'center'}
            h={isSm ? '2px' : '100%'}
            m={{ base: '0 auto', sm: 'unset' }}
            backgroundColor={'#302F37'}
            w={{ base: '100%', sm: 'unset' }}
            /* display={{ base: 'none', sm: 'flex' }} */
          >
            <Divider
              backgroundColor={'#302F37'}
              orientation={isSm ? 'horizontal' : 'vertical'}
              h={{ base: '3px', sm: '53px' }}
              w={{ base: '100%', sm: 'unset' }}
            />
          </Flex>

          <Flex
            h="auto"
            maxW={isSm ? 'unset' : maxSectionWidth}
            w="100%"
            textAlign={{ base: 'center', sm: 'unset' }}
            backgroundColor={'#302F37'}
            p="16px 12px"
            gap={'2px'}
            flexDir={'column'}
          >
            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="700"
              fontSize="24px"
              lineHeight="30px"
              color="#FFFFFF"
            >
              {secondTitleGrid}
            </Text>

            <Text
              fontFamily="Mulish"
              fontStyle="normal"
              fontWeight="400"
              fontSize="12px"
              lineHeight="15px"
              color="#BDBDC7"
            >
              {secondDescriptionGrid}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}
