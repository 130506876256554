import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  Text,
  Button,
  VStack,
} from '@chakra-ui/react'
import { List } from 'features/ui/list'
import { useToastMessage } from 'components/Toast'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import React, { useMemo, useState } from 'react'
import { Icon } from '@iconify/react'
import { ListTable, TableHeader } from 'features/ui/list/list-table'
import { MoreVertical } from 'react-feather'
import Pagination from 'components/Pagination'
import { ListHeader } from 'features/ui'
import { format } from 'date-fns'
import { AxiosError } from 'axios'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { Event } from './interfaces/EventsInterfaces'
import { useGetEvents } from './mutations/use-get-events'
import { useDeleteEvent } from './mutations/use-mutate-delete-event'
import ModalNewEvent from './modal/ModalNewEvent'
import ModalEditEvent from './modal/ModalEditEvent'

export default function EventsTemplate() {
  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [createdAtFilter, setCreatedAtFilter] = useState<
    '+created_at' | '-created_at' | undefined
  >(undefined)
  const [startsAtFilter, setStartsAtFilter] = useState<
    '+starts_at' | '-starts_at' | undefined
  >(undefined)
  const [page, setPage] = useState(1)
  const [selectedRow, setSelectedRow] = useState<Event | undefined>(undefined)
  const [modalNewEvent, setModalNewEvent] = useState(false)
  const [modalEditEvent, setModalEditEvent] = useState(false)
  const [modalConfirmDeleteEvent, setModalConfirmDeleteEvent] = useState(false)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const showToast = useToastMessage()
  const {
    data: eventsData,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents,
  } = useGetEvents({
    page: page,
    limit: 6,
    sort: createdAtFilter
      ? createdAtFilter
      : startsAtFilter
      ? startsAtFilter
      : undefined,
  })
  const deleteEvent = useDeleteEvent()

  /*
  |-----------------------------------------------------------------------------
  | Memos
  |-----------------------------------------------------------------------------
  |
  |
  */

  const headers = useMemo(() => {
    return [
      {
        label: <Text textAlign="center">Ações</Text>,
        fn(_, value) {
          return (
            <Box textAlign="center">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MoreVertical size="20px" color="#7D46B1" />}
                  bg="#393940"
                  height={'26px'}
                  width={'26px'}
                  minW={'26px !important'}
                  p="0px"
                />
                <Portal>
                  <MenuList
                    bg="#393940"
                    color="#ffffff"
                    border="1px solid #7D46B1"
                    minW="fit-content"
                    py="0px"
                  >
                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        setSelectedRow(value)
                        setModalEditEvent(true)
                      }}
                    >
                      Editar
                    </MenuItem>

                    <MenuItem
                      _hover={{ backgroundColor: '#302F37' }}
                      _focus={{ backgroundColor: '#302F37' }}
                      borderBlockEnd="1px solid #2C2C31"
                      onClick={() => {
                        setSelectedRow(value)
                        setModalConfirmDeleteEvent(true)
                      }}
                    >
                      Excluir
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          )
        },
      },
      {
        label: 'Evento',
        fn(_, value) {
          return (
            <HStack minW={'150px'} noOfLines={1} w="100%">
              <Tooltip w="100%" label={`${value?.title}`} hasArrow>
                <Text maxW={'240px'} noOfLines={1}>
                  {value?.title ? `${value?.title}` : '-'}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Host do evento</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={!value.hostUser.name ? '-' : value.hostUser.name}
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value.hostUser.name ? '-' : value.hostUser.name}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: <Text textAlign="center">Gravação</Text>,
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Text noOfLines={1}>
                {value.recorded_meeting ? 'sim' : 'não'}
              </Text>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify="center">
            <Text textAlign="center">Criação</Text>
            {createdAtFilter === '-created_at' ? (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setStartsAtFilter(undefined)
                  setCreatedAtFilter('+created_at')
                }}
                icon={'uil:sort-amount-down'}
              />
            ) : (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setStartsAtFilter(undefined)
                  setCreatedAtFilter('-created_at')
                }}
                icon={'uil:sort-amount-up'}
              />
            )}
          </HStack>
        ),
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={
                  !value.created_at
                    ? '-'
                    : format(
                        new Date(value.created_at),
                        // eslint-disable-next-line quotes
                        "dd/MM/yyyy 'às' HH:mm",
                      )
                }
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value.created_at
                    ? '-'
                    : format(
                        new Date(value.created_at),
                        // eslint-disable-next-line quotes
                        "dd/MM/yyyy 'às' HH:mm",
                      )}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
      {
        label: (
          <HStack justify="center">
            <Text textAlign="center">Evento em</Text>
            {startsAtFilter === '-starts_at' ? (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setCreatedAtFilter(undefined)
                  setStartsAtFilter('+starts_at')
                }}
                icon={'uil:sort-amount-down'}
              />
            ) : (
              <Icon
                cursor="pointer"
                onClick={() => {
                  setCreatedAtFilter(undefined)
                  setStartsAtFilter('-starts_at')
                }}
                icon={'uil:sort-amount-up'}
              />
            )}
          </HStack>
        ),
        fn(_, value) {
          return (
            <HStack justifyContent={'center'}>
              <Tooltip
                w="100%"
                label={
                  !value.starts_at
                    ? '-'
                    : // eslint-disable-next-line quotes
                      format(new Date(value.starts_at), "dd/MM/yyyy 'às' HH:mm")
                }
                hasArrow
              >
                <Text noOfLines={1}>
                  {!value.starts_at
                    ? '-'
                    : format(
                        new Date(value.starts_at),
                        // eslint-disable-next-line quotes
                        "dd/MM/yyyy 'às' HH:mm",
                      )}
                </Text>
              </Tooltip>
            </HStack>
          )
        },
      },
    ] as TableHeader<Event>[]
  }, [createdAtFilter, startsAtFilter])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <VStack spacing="2rem" mt="2rem" align="flex-start">
      <DefaultButton
        label="Novo Evento"
        leftIcon={<Icon icon="carbon:event" />}
        onClick={() => setModalNewEvent(true)}
      />

      <ModalNewEvent
        isOpen={modalNewEvent}
        onClose={() => setModalNewEvent(false)}
      />

      <ModalEditEvent
        isOpen={modalEditEvent}
        onClose={() => setModalEditEvent(false)}
        event={selectedRow}
      />

      <ModalDefaultTwoButton
        isOpen={modalConfirmDeleteEvent}
        subTitle="Tem certeza que deseja excluir este evento?"
        title="Excluir evento"
        onClose={() => setModalConfirmDeleteEvent(false)}
        confirmButtonIsLoading={deleteEvent.isLoading}
        onConfirmClick={async () => {
          if (!selectedRow) return

          try {
            await deleteEvent.mutateAsync({
              eventId: selectedRow.id,
            })

            showToast({
              title: 'Sucesso',
              description: 'Evento excluído com sucesso.',
              type: 'success',
              duration: 2000,
              mode: 'dark',
            })

            setModalConfirmDeleteEvent(false)
          } catch (error) {
            const message: AxiosError =
              error?.response?.data?.errors
                ?.map((error: AxiosError) => error.message)
                ?.join(', ') ||
              error?.response?.data ||
              'Não foi possível excluir o evento no momento.'

            showToast({
              title: 'Erro',
              description: message.message ? message.message : message,
              type: 'error',
              duration: 2000,
              mode: 'dark',
            })
          }
        }}
      />

      <List
        background="#302F37"
        maxW="800px"
        w="full"
        px="20px"
        py="10px"
        my="0px"
      >
        <ListHeader
          progressProps={{ top: '1px', left: '1px' }}
          isLoading={isLoadingEvents}
          isFetching={isFetchingEvents}
        />

        <ListTable
          mt="0px !important"
          headers={headers}
          data={eventsData?.data}
          isLoading={isLoadingEvents}
          tableCellProps={{
            border: 'none !important',
            p: '8px 12px',
            borderRight: '1px solid rgba(255, 255, 255, 0.06) !important',
            _last: {
              borderRight: 'none !important',
            },
            fontWeight: '400',
            color: '#FFFFFF',
            fontFamily: 'Mulish',
            fontSize: '12px',
          }}
          tableCellPropsHeader={{
            border: 'none !important',
            fontWeight: '600',
            color: '#FFFFFF',
            isTruncated: true,
            fontFamily: 'Mulish',
            fontSize: '14px',
            textTransform: 'none !important',
            p: '8px 12px',
          }}
        />

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalCountOfRegisters={eventsData?.meta.total || 0}
          registersPerPage={6}
          fontSize="12px"
          mt="16px !important"
          px="8px"
          buttonProps={{
            fontSize: '12px',
            fontWeight: '600',
            minH: '6',
            h: '6',
            w: '6',
            minW: '6',
          }}
        />
      </List>
    </VStack>
  )
}
