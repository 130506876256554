import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
  Flex,
  Spinner,
  IconButton,
} from '@chakra-ui/react'
import { FieldFile } from 'components/Form/FieldFile'
import { FieldRadio } from 'components/Form/FieldRadio'
import { RadioGroupController } from 'components/Form/FieldRadioGroup/RadioGroupController'
import { TextAreaController } from 'components/Form/TextArea/TextAreaController'
import { useHistory, useParams } from 'react-router-dom'
import { useToastMessage } from 'components/Toast'
import ErrorPage from '../error'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { MockExamNewQuestionForm } from './types'
import { get } from 'lodash'
import ModalSimulationQuit from '../Modal/ModalSimulationQuit'
import { useGetExamsById } from '../mutations/use-get-exams-by-id'
import { useGetQuestionById } from '../mutations/use-get-question-by-id'
import { useCreatequestion } from '../mutations/use-mutate-create-question'
import api from 'services/api'
import Pagination from 'components/Pagination'
import { Icon } from '@iconify/react'
import ModalPreviewImage from '../Modal/ModalPreviewImage'
import { DefaultButton } from 'components/Buttons/DefaultButton'
import { ModalDefaultTwoButton } from 'components/Modal/ModalDefaultTwoButton'
import { useDeleteImage } from '../mutations/use-mutate-delete-image'

export default function SimulationEdit() {
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [filter, setFilter] = useState('')
  const [modalOption, setModalOption] = useState(false)
  const [activedIndex, setActivedIndex] = useState<number | null>(null)
  const [modalPreview, setModalPreview] = useState(false)
  const [activeImageModalPreview, setActiveImageModalPreview] = useState('')
  const [isModalDeleteImageOpen, setIsModalDeleteImageOpen] = useState(false)
  const [imageIdToDelete, setImageIdToDelete] = useState('')
  const ref = useRef<HTMLDivElement>(null)

  /*
  |-----------------------------------------------------------------------------
  | Hooks.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const { push } = useHistory()

  const { id } = useParams<{ id: string }>()

  const {
    data: exam,
    error: errorGetExamsById,
    isLoading: isLoadingGetExamsById,
  } = useGetExamsById({
    id,
  })

  const {
    data: question,
    isLoading: isLoadingGetQuestionById,
    refetch: refetchQuestionById,
  } = useGetQuestionById({
    id: exam?.questions?.[activedIndex! - 1]?.id
      ? exam?.questions?.[activedIndex! - 1]?.id
      : undefined,
  })

  const createQuestionMutate = useCreatequestion()
  const deleteImage = useDeleteImage()

  const showToast = useToastMessage()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<MockExamNewQuestionForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      statement: '',
      alternativeA: '',
      alternativeB: '',
      alternativeC: '',
      alternativeD: '',
      alternativeE: '',
      comment: '',
      essentialConcept: '',
      imageHighlightFile: null,
      commentImageFile: null,
      essentialConceptImageFile: null,
      correctAlternative: '',
    },
  })

  /*
  |-----------------------------------------------------------------------------
  | Functions
  |-----------------------------------------------------------------------------
  |
  |
  */
  const handlePageChange = (page: number) => {
    if (!activedIndex || !exam) return

    clearErrors()
    setActivedIndex(page)
    ref?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onSave = async (data: MockExamNewQuestionForm) => {
    if (activedIndex === null || !exam) return

    if (activedIndex > exam.questions.length) {
      try {
        const formContent = new FormData()
        formContent.append('statement', data.statement)
        formContent.append('alternativeA', data.alternativeA)
        formContent.append('alternativeB', data.alternativeB)
        formContent.append('alternativeC', data.alternativeC)
        formContent.append('alternativeD', data.alternativeD)
        data?.alternativeE &&
          formContent.append('alternativeE', data?.alternativeE)
        formContent.append('correctAlternative', data.correctAlternative)
        data.comment && formContent.append('comment', data.comment)
        data.essentialConcept &&
          formContent.append('essentialConcept', data.essentialConcept)
        data?.essentialConceptImageFile?.[0] &&
          formContent.append(
            'essentialConceptImageFile',
            data.essentialConceptImageFile[0],
          )
        data?.imageHighlightFile?.[0] &&
          formContent.append('imageHighlightFile', data.imageHighlightFile[0])
        data?.commentImageFile?.[0] &&
          formContent.append('commentImageFile', data.commentImageFile[0])
        formContent.append('mockExamId', exam?.id.toString())

        await createQuestionMutate.mutateAsync(formContent)

        setActivedIndex(state => state && state + 1)

        reset()

        showToast({
          title: 'Sucesso!',
          description: 'Você criou uma nova questão.',
          type: 'success',
          duration: 5000,
          mode: 'dark',
        })
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível criar a questão.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 5000,
          mode: 'dark',
        })
      } finally {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      try {
        const formContent = new FormData()
        formContent.append('statement', data.statement)
        formContent.append('alternativeA', data.alternativeA)
        formContent.append('alternativeB', data.alternativeB)
        formContent.append('alternativeC', data.alternativeC)
        formContent.append('alternativeD', data.alternativeD)
        data?.alternativeE &&
          formContent.append('alternativeE', data?.alternativeE)
        formContent.append('correctAlternative', data.correctAlternative)
        data?.comment && formContent.append('comment', data.comment)
        data.essentialConcept &&
          formContent.append('essentialConcept', data.essentialConcept)
        data?.essentialConceptImageFile?.[0] &&
          formContent.append(
            'essentialConceptImageFile',
            data.essentialConceptImageFile[0],
          )
        data?.imageHighlightFile?.[0] &&
          formContent.append('imageHighlightFile', data.imageHighlightFile[0])
        data?.commentImageFile?.[0] &&
          formContent.append('commentImageFile', data.commentImageFile[0])
        formContent.append('mockExamId', exam?.id.toString())

        await api.patch(
          `/admin/mock-exam-questions/${
            exam?.questions?.[activedIndex! - 1]?.id
          }`,
          formContent,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        await refetchQuestionById()

        showToast({
          title: 'Sucesso!',
          description: 'Questão editada com sucesso.',
          type: 'success',
          duration: 5000,
          mode: 'dark',
        })
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível editar a questão.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 5000,
          mode: 'dark',
        })
      } finally {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const onSubmit = async (data: MockExamNewQuestionForm) => {
    if (activedIndex === null || !exam) return

    if (activedIndex > exam.questions.length) {
      try {
        const formContent = new FormData()
        formContent.append('statement', data.statement)
        formContent.append('alternativeA', data.alternativeA)
        formContent.append('alternativeB', data.alternativeB)
        formContent.append('alternativeC', data.alternativeC)
        formContent.append('alternativeD', data.alternativeD)
        data?.alternativeE &&
          formContent.append('alternativeE', data?.alternativeE)
        formContent.append('correctAlternative', data.correctAlternative)
        data.comment && formContent.append('comment', data.comment)
        data.essentialConcept &&
          formContent.append('essentialConcept', data.essentialConcept)
        data?.essentialConceptImageFile?.[0] &&
          formContent.append(
            'essentialConceptImageFile',
            data.essentialConceptImageFile[0],
          )
        data?.imageHighlightFile?.[0] &&
          formContent.append('imageHighlightFile', data.imageHighlightFile[0])
        data?.commentImageFile?.[0] &&
          formContent.append('commentImageFile', data.commentImageFile[0])
        formContent.append('mockExamId', exam?.id.toString())

        await createQuestionMutate.mutateAsync(formContent)

        showToast({
          title: 'Sucesso!',
          description: 'Você criou uma nova questão.',
          type: 'success',
          duration: 5000,
          mode: 'dark',
        })

        push('/simulation')
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível criar a questão.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 5000,
          mode: 'dark',
        })
      }
    } else {
      try {
        const formContent = new FormData()
        formContent.append('statement', data.statement)
        formContent.append('alternativeA', data.alternativeA)
        formContent.append('alternativeB', data.alternativeB)
        formContent.append('alternativeC', data.alternativeC)
        formContent.append('alternativeD', data.alternativeD)
        data?.alternativeE &&
          formContent.append('alternativeE', data?.alternativeE)
        formContent.append('correctAlternative', data.correctAlternative)
        data?.comment && formContent.append('comment', data.comment)
        data.essentialConcept &&
          formContent.append('essentialConcept', data.essentialConcept)
        data?.essentialConceptImageFile?.[0] &&
          formContent.append(
            'essentialConceptImageFile',
            data.essentialConceptImageFile[0],
          )
        data?.imageHighlightFile?.[0] &&
          formContent.append('imageHighlightFile', data.imageHighlightFile[0])
        data?.commentImageFile?.[0] &&
          formContent.append('commentImageFile', data.commentImageFile[0])
        formContent.append('mockExamId', exam?.id.toString())

        await api.patch(
          `/admin/mock-exam-questions/${
            exam?.questions?.[activedIndex! - 1]?.id
          }`,
          formContent,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        showToast({
          title: 'Sucesso!',
          description: 'Simulado salvo com sucesso.',
          type: 'success',
          duration: 5000,
          mode: 'dark',
        })

        push('/simulation')
      } catch (error: any) {
        const message: AxiosError =
          error?.response?.data?.errors
            ?.map((error: AxiosError) => error.message)
            ?.join(', ') ||
          error?.response?.data ||
          'Não foi possível editar a questão.'

        showToast({
          title: 'Erro',
          description: message.message ? message.message : message,
          type: 'error',
          duration: 5000,
          mode: 'dark',
        })
      }
    }
  }

  const onDeleteImage = async () => {
    try {
      await deleteImage.mutateAsync({
        questionId: question?.id!,
        imageId: imageIdToDelete,
      })

      setIsModalDeleteImageOpen(false)

      showToast({
        title: 'Sucesso',
        description: 'Imagem removida com sucesso!',
        type: 'success',
        duration: 2000,
        mode: 'dark',
      })
    } catch (error) {
      const message: AxiosError =
        error?.response?.data?.errors
          ?.map((error: AxiosError) => error.message)
          ?.join(', ') ||
        error?.response?.data ||
        'Não foi possível remover a imagem.'

      showToast({
        title: 'Aviso',
        description: message.message ? message.message : message,
        type: 'error',
        duration: 2000,
        mode: 'dark',
      })
    }
  }

  /*
  |-----------------------------------------------------------------------------
  | UseEffect
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    if (!activedIndex || !exam) return

    if (activedIndex && question) {
      setValue('statement', question?.statement)
      setValue('alternativeA', question?.alternative_a)
      setValue('alternativeB', question?.alternative_b)
      setValue('alternativeC', question?.alternative_c)
      setValue('alternativeD', question?.alternative_d)
      setValue('alternativeE', question?.alternative_e)
      setValue('comment', question?.comment)
      setValue('essentialConcept', question?.essential_concept)
      setValue('essentialConceptImageFile', null)
      setValue('commentImageFile', null)
      setValue('imageHighlightFile', null)
      setValue('correctAlternative', question?.correct_alternative)
    }
  }, [activedIndex, exam, question, setValue])

  useEffect(() => {
    setActivedIndex(exam?.questions?.length! + 1)
  }, [exam?.questions?.length])

  useEffect(() => {
    if (!activedIndex || !exam) return

    if (activedIndex > exam.questions.length) {
      reset()
    }
  }, [activedIndex, exam, reset])
  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  if (!id || errorGetExamsById) {
    return <ErrorPage />
  }

  if (isLoadingGetExamsById || isLoadingGetQuestionById) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    )
  }

  return (
    <div ref={ref}>
      <HStack
        w="fit-content"
        my="1em"
        _hover={{ translateY: '1px', fontWeight: 600 }}
        as="button"
        onClick={() => push('/simulation')}
      >
        <Icon icon={'bi:arrow-left'} fontSize={'16px'} />
        <Text>Voltar para simulados</Text>
      </HStack>
      <Box
        as="form"
        flexDir={'column'}
        w="full"
        maxW={'868px'}
        py="2rem"
        onSubmit={handleSubmit(onSubmit as any)}
      >
        <Heading
          fontWeight={700}
          fontFamily={'Mulish, sans-serif'}
          fontSize={'18px'}
          mb="16px"
          as={'h3'}
        >
          {`Questão ${activedIndex}`}
        </Heading>

        <VStack w="full" spacing="1rem">
          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="statement"
              label={'Enunciado'}
              control={control}
              error={errors.statement}
              maxLength={5000}
            />
          </VStack>

          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="alternativeA"
              label={'a)'}
              control={control}
              error={errors.alternativeA}
              maxLength={5000}
            />
          </VStack>

          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="alternativeB"
              label={'b)'}
              control={control}
              error={errors.alternativeB}
              maxLength={5000}
            />
          </VStack>

          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="alternativeC"
              label={'c)'}
              control={control}
              error={errors.alternativeC}
              maxLength={5000}
            />
          </VStack>

          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="alternativeD"
              label={'d)'}
              control={control}
              error={errors.alternativeD}
              maxLength={5000}
            />
          </VStack>

          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="alternativeE"
              label={'e)'}
              control={control}
              error={errors.alternativeE}
              maxLength={5000}
            />
          </VStack>

          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="comment"
              label={'Comentário'}
              control={control}
              error={errors.comment}
              maxLength={5000}
            />
          </VStack>

          <VStack w="full" spacing="0.5rem">
            <TextAreaController
              name="essentialConcept"
              label={'Conceito matador'}
              control={control}
              error={errors.essentialConcept}
              maxLength={5000}
            />
          </VStack>

          <Stack
            direction={{ base: 'column', sm: 'row' }}
            align="flex-end"
            w="100%"
            gap="24px"
            justifyContent="space-between"
          >
            <FieldFile
              variant="secondary"
              fileName={get(watch('imageHighlightFile'), '[0].name')}
              {...register('imageHighlightFile')}
              label={'Imagem destaque da questão'}
              type="file"
              allowedExtensions={['jpg', 'png']}
              error={errors.imageHighlightFile}
              adicionalLabelText={
                <HStack>
                  <IconButton
                    aria-label="delete image confirmation modal"
                    bg="inherit"
                    color="#FF6363"
                    _hover={{ bg: '#27262bd1' }}
                    display={!question?.image_highlight ? 'none' : 'flex'}
                    onClick={() => {
                      if (!question?.image_highlight) return
                      const imageExists = exam?.questions.find(
                        item => item.id === question.id,
                      )
                      setIsModalDeleteImageOpen(true)
                      if (imageExists)
                        setImageIdToDelete(imageExists.image_highlight)
                    }}
                  >
                    <Icon icon="ph:trash-bold" />
                  </IconButton>
                  <IconButton
                    aria-label="preview open modal"
                    bg="inherit"
                    _hover={{ bg: '#27262bd1' }}
                    display={!question?.image_highlight ? 'none' : 'flex'}
                    onClick={() => {
                      if (!question?.image_highlight) return
                      setModalPreview(true)
                      setActiveImageModalPreview(question?.image_highlight)
                    }}
                  >
                    <Icon icon="icon-park-outline:preview-open" />
                  </IconButton>
                </HStack>
              }
            />

            <FieldFile
              variant="secondary"
              fileName={get(watch('commentImageFile'), '[0].name')}
              {...register('commentImageFile')}
              label={'Imagem do comentário'}
              type="file"
              allowedExtensions={['jpg', 'png']}
              error={errors.commentImageFile}
              adicionalLabelText={
                <HStack>
                  <IconButton
                    aria-label="delete image confirmation modal"
                    bg="inherit"
                    color="#FF6363"
                    _hover={{ bg: '#27262bd1' }}
                    display={!question?.comment_image ? 'none' : 'flex'}
                    onClick={() => {
                      if (!question?.comment_image) return
                      const imageExists = exam?.questions.find(
                        item => item.id === question.id,
                      )
                      setIsModalDeleteImageOpen(true)
                      if (imageExists)
                        setImageIdToDelete(imageExists.comment_image)
                    }}
                  >
                    <Icon icon="ph:trash-bold" />
                  </IconButton>
                  <IconButton
                    aria-label="preview open modal"
                    bg="inherit"
                    _hover={{ bg: '#27262bd1' }}
                    display={!question?.comment_image ? 'none' : 'flex'}
                    onClick={() => {
                      if (!question?.comment_image) return
                      setModalPreview(true)
                      setActiveImageModalPreview(question?.comment_image)
                    }}
                  >
                    <Icon icon="icon-park-outline:preview-open" />
                  </IconButton>
                </HStack>
              }
            />

            <FieldFile
              variant="secondary"
              fileName={get(watch('essentialConceptImageFile'), '[0].name')}
              {...register('essentialConceptImageFile')}
              label={'Imagem do conceito matador'}
              type="file"
              allowedExtensions={['jpg', 'png']}
              error={errors.essentialConceptImageFile}
              adicionalLabelText={
                <HStack>
                  <IconButton
                    aria-label="delete image confirmation modal"
                    bg="inherit"
                    color="#FF6363"
                    _hover={{ bg: '#27262bd1' }}
                    display={
                      !question?.image_essential_concept ? 'none' : 'flex'
                    }
                    onClick={() => {
                      if (!question?.image_essential_concept) return
                      const imageExists = exam?.questions.find(
                        item => item.id === question.id,
                      )
                      setIsModalDeleteImageOpen(true)
                      if (imageExists)
                        setImageIdToDelete(imageExists.image_essential_concept)
                    }}
                  >
                    <Icon icon="ph:trash-bold" />
                  </IconButton>
                  <IconButton
                    aria-label="preview open modal"
                    bg="inherit"
                    _hover={{ bg: '#27262bd1' }}
                    display={
                      !question?.image_essential_concept ? 'none' : 'flex'
                    }
                    onClick={() => {
                      if (!question?.image_essential_concept) return
                      setModalPreview(true)
                      setActiveImageModalPreview(
                        question?.image_essential_concept,
                      )
                    }}
                  >
                    <Icon icon="icon-park-outline:preview-open" />
                  </IconButton>
                </HStack>
              }
            />
          </Stack>

          <RadioGroupController
            name="correctAlternative"
            label={'Marque a alternativa correta'}
            value={filter}
            onChange={setFilter}
            control={control}
            error={errors.correctAlternative}
            w="full"
          >
            <HStack mt="0.5rem" spacing={'18px'} position="relative">
              <HStack spacing={'8px'}>
                <FieldRadio name="a" value={'a'} />
                <Text>a)</Text>
              </HStack>

              <HStack spacing={'8px'}>
                <FieldRadio name="b" value={'b'} />
                <Text>b)</Text>
              </HStack>

              <HStack spacing={'8px'}>
                <FieldRadio name="c" value={'c'} />
                <Text>c)</Text>
              </HStack>

              <HStack spacing={'8px'}>
                <FieldRadio name="d" value={'d'} />
                <Text>d)</Text>
              </HStack>

              <HStack spacing={'8px'}>
                <FieldRadio name="e" value={'e'} />
                <Text>e)</Text>
              </HStack>
            </HStack>
          </RadioGroupController>

          <Stack
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: '0.5rem', sm: '1rem' }}
            justifyContent="space-between"
            w="full"
            mt="24px !important"
          >
            <Flex
              flexDir={{ base: 'column', sm: 'row' }}
              gap={{ base: '0.5rem', sm: '1rem' }}
              w={{ base: '100%', sm: '40%' }}
            >
              <DefaultButton
                w="full"
                label="Salvar"
                variant="ghost"
                onClick={handleSubmit(onSave)}
                isLoading={isSubmitting}
                maxW={{ base: 'unset', sm: '278px' }}
              />
            </Flex>

            <Flex w={{ base: '100%', sm: '278px' }}>
              <DefaultButton
                onClick={handleSubmit(onSubmit)}
                w="full"
                label="Finalizar"
                variant="secondary"
                maxW={{ base: 'unset', sm: '278px' }}
              />
            </Flex>
          </Stack>

          <Pagination
            totalCountOfRegisters={exam?.questions?.length! + 1 ?? 0}
            currentPage={activedIndex ?? 0}
            onPageChange={handlePageChange}
            registersPerPage={1}
            w="full"
          />
        </VStack>

        <ModalSimulationQuit
          title={'Tem certeza que deseja sair sem salvar?'}
          isOpen={modalOption}
          onClose={() => setModalOption(false)}
          onFirstClick={() => push('/simulation')}
          onSecondClick={() => setModalOption(false)}
        />

        <ModalDefaultTwoButton
          title="Tem certeza que deseja excluir esta imagem?"
          subTitle="Esta ação não poderá ser desfeita."
          isOpen={isModalDeleteImageOpen}
          onClose={() => setIsModalDeleteImageOpen(false)}
          onConfirmClick={onDeleteImage}
        />
        <ModalPreviewImage
          isOpen={modalPreview}
          onClose={() => setModalPreview(false)}
          imageSrc={activeImageModalPreview}
        />
      </Box>
    </div>
  )
}
