import axios from 'axios'
import Swal from 'sweetalert2'
import { filter, get, map, max } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import api from 'services/api'

import ScatterChart from 'components/ScatterChart'
import {
  KpiList,
  ChartData,
  KpiListProps,
  IDashboardMonitor,
} from 'features/dashboard'

import { Box } from '@chakra-ui/react'

export function DashboardMonitor() {
  const [kpiList, setKpiList] = useState<KpiListProps | undefined>(undefined)
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined)
  const [maxGlobal, setMaxGlobal] = useState<number>()

  const sanitizeChartData = useCallback(
    (data: IDashboardMonitor): ChartData => {
      const { studentsData } = data

      const maxChartPercentage = map(
        filter(studentsData, student => {
          const globalGoal = get(
            student,
            'trackGoal.goalPercentageAchieved.globalGoal',
            0,
          )

          return +globalGoal > 80
        }),
        student => [
          +get(student, 'trackGoal.goalPercentageAchieved.globalGoal', 0),
        ],
      )
      const FormattedGlobalmaxPercentage = maxChartPercentage.flat()
      setMaxGlobal(max(FormattedGlobalmaxPercentage))

      const overEightyPercent = map(
        filter(studentsData, student => {
          const globalGoal = get(
            student,
            'trackGoal.goalPercentageAchieved.globalGoal',
            0,
          )

          return +globalGoal > 80
        }),
        student => [
          +get(student, 'trackGoal.goalPercentageAchieved.globalGoal', 0),
          student.daysWithoutAccess,
          student.name,
        ],
      )

      const betweenSixtyAndEighty = map(
        filter(studentsData, student => {
          const globalGoal = get(
            student,
            'trackGoal.goalPercentageAchieved.globalGoal',
            0,
          )

          return +globalGoal >= 61 && +globalGoal <= 80
        }),
        student => [
          +get(student, 'trackGoal.goalPercentageAchieved.globalGoal', 0),
          student.daysWithoutAccess,
          student.name,
        ],
      )

      const underSixty = map(
        filter(studentsData, student => {
          const globalGoal = get(
            student,
            'trackGoal.goalPercentageAchieved.globalGoal',
            0,
          )

          return +globalGoal < 61
        }),
        student => [
          +get(student, 'trackGoal.goalPercentageAchieved.globalGoal', 0),
          student.daysWithoutAccess,
          student.name,
        ],
      )

      return {
        series: [
          {
            name: 'Alunos com mais de 81% de conclusão',
            data: overEightyPercent,
            color: '#9AE6B4',
          },
          {
            name: 'Alunos entre 61% e 80% de conclusão',
            data: betweenSixtyAndEighty,
            color: '#FFCE56',
          },
          {
            name: 'Alunos com menos de 60% de conclusão',
            data: underSixty,
            color: '#FF7F7F',
          },
        ],
      }
    },
    [],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await api.get<IDashboardMonitor>(
          '/monitor/dashboard',
        )

        setKpiList(response.meta)
        setChartData(sanitizeChartData(response))
      } catch (error) {
        if (axios.isAxiosError(error)) {
          switch (error?.response?.status) {
            case 403:
              Swal.fire({
                icon: 'warning',
                title: 'Acesso negado',
                text: 'Você não tem permissão para acessar esta página',
              })
              break
            case 500:
              Swal.fire({
                icon: 'error',
                title: 'Erro interno',
                text: 'Ops... Ocorreu um erro interno',
              })
              break
            default:
              Swal.fire({
                icon: 'warning',
                title: 'Aviso',
                text: 'Não foi possível carregar os dados',
              })
              break
          }

          return
        }

        Swal.fire({
          title: 'Aviso',
          icon: 'warning',
          text: 'Não foi possível carregar os dados',
        })
      }
    }

    fetchData()
  }, [sanitizeChartData])

  return (
    <Box>
      <KpiList {...kpiList} />

      {chartData && chartData.series.length > 0 && (
        <ScatterChart series={chartData.series} maxRangeChart={maxGlobal} />
      )}
    </Box>
  )
}
