import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface QueryParams {
  notificationId: number
}

async function deleteNotification(params: QueryParams): Promise<void> {
  await api.delete(`/admin/notifications/${params.notificationId}`)
}

export function useDeleteNotification() {
  return useMutation(deleteNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-notifications'])
    },
  })
}
