import { Box, Table, Tbody } from '@chakra-ui/react'
import { ModalEditAdvisor } from 'components/Modal/ModalEditAdvisor'
import { useAuth } from 'contexts/auth'
import { formatDateUTC, formatDateUTCDate } from 'helpers/formatDate'
import React, { useState } from 'react'
import { TrTableBodyUser } from './components/TrTableBodyAdvisor'
import { TrTableHeadUser } from './components/TrTableHeadAdvisor'
import { Advisor } from './queries/use-fetch-student-record'

interface UserTableProps {
  data: Advisor[] | undefined
  onDateClickButton: () => void
  dateOrder: '+date' | '-date'
  setDateOrder: React.Dispatch<React.SetStateAction<'+date' | '-date'>>
}

export function UserTable(props: UserTableProps) {
  const { data, dateOrder, onDateClickButton, setDateOrder } = props

  const { user, userRolesSlugs } = useAuth()
  /*
  |-----------------------------------------------------------------------------
  | States
  |-----------------------------------------------------------------------------
  |
  |
  */

  const [modalEditAdvisor, setModalEditAdvisor] = useState(false)
  const [selectedUser, setSelectedUser] = useState<Advisor>()

  if (data) {
    return (
      <>
        <Box
          mt="1rem"
          maxH="508px"
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#D9D9D9',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#60606C',
              borderRadius: '17px',
              height: '8px',
            },
          }}
          overflow="auto"
          w={'100%'}
        >
          <Table background={'#302F37'} borderRadius="6px">
            <TrTableHeadUser
              onDateClickButton={onDateClickButton}
              dateOrder={dateOrder}
              setDateOrder={setDateOrder}
            />
            <Tbody borderBottomWidth={'20px'} borderBottomColor="#302F37">
              {data?.map((item, index) => {
                return (
                  <TrTableBodyUser
                    key={`TableSectionData${index}`}
                    onClick={() => {
                      setSelectedUser(item)
                    }}
                    avatar={item?.advisor?.avatar}
                    advisor={item?.advisor?.name}
                    roleAttendance={!!item?.advisor}
                    date={item?.date && formatDateUTC(item?.date, 'dd-MM-yyyy')}
                    redFlag={item?.is_red_flag}
                    onEditClickButton={() => setModalEditAdvisor(true)}
                  />
                )
              })}
            </Tbody>
          </Table>

          {selectedUser && (
            <ModalEditAdvisor
              isOpen={modalEditAdvisor}
              onClose={() => setModalEditAdvisor(false)}
              closeIcon={() => setModalEditAdvisor(false)}
              showLastEdition
              lastEditionDate={formatDateUTC(selectedUser?.updated_at)}
              isPatch
              textDefaultValue={selectedUser?.description}
              dateDefaultValue={formatDateUTCDate(selectedUser.date)}
              registryId={selectedUser?.id}
              redFlagDefaultValue={selectedUser?.is_red_flag}
              disableTextArea={
                userRolesSlugs.includes('advisor')
                  ? user?.id !== selectedUser.advisor_user_id
                  : true
              }
              disableDatePicker={
                userRolesSlugs.includes('advisor')
                  ? user?.id !== selectedUser.advisor_user_id
                  : true
              }
              disableSubmitButton={
                userRolesSlugs.includes('advisor')
                  ? user?.id !== selectedUser.advisor_user_id
                  : true
              }
            />
          )}
        </Box>
      </>
    )
  }
  return null
}
