/* eslint-disable prettier/prettier */
export const INSTITUTIONS = [
  ...new Set([
    'ABC',
    'ACMFC-FLORIANÓPOLIS',
    'AMP',
    'AMRIGS',
    'AMS-APUCARANA',
    'AMS-LONDRINA',
    'APCC-PI',
    'AUSTA-SP',
    'BOS',
    'CCANSPS-MG',
    'CCG-MS',
    'CEOQ-BA',
    'CEREM-MS',
    'CERMAM',
    'CESUPA',
    'CMC-SP',
    'COC-MT',
    'EMCM-RN',
    'EMESCAM-ES',
    'ENARE',
    'ESPCE',
    'FAMEMA',
    'FAMERP',
    'FBHC',
    'FESF SUS-BA',
    'FHEMIG',
    'FJG-RJ',
    'FMC-CAMPOS',
    'FMJ',
    'FMP',
    'FUBOG',
    'HA-AL',
    'HA-SP (ALVORADA)',
    'HAC-PR',
    'HASP (AERONÁUTICA)',
    'HCB-RO',
    'HCB-SP',
    'HCG',
    'HCGV',
    'HCMT',
    'HCOR',
    'HCPA',
    'HCUFPR',
    'HDG-MG',
    'HEA-AC',
    'HECI-ES',
    'HEDA-PI',
    'HEJSN-ES',
    'HEL-PR',
    'HEVV-ES',
    'HFA-DF',
    'HFR-MG',
    'HIAE',
    'HIS-SP',
    'HIVS-RN',
    'HMAR',
    'HMASP',
    'HMDI-GO',
    'HMJLF-MG',
    'HMMG-SP',
    'HMMKB-SC',
    'HNMD',
    'HOA-AC',
    'HOA-GO',
    'HOB-DF',
    'HOC-BA',
    'HOC-TO',
    'HOG-GO',
    'HOG-SP',
    'HOL-PA',
    'HOS-SP',
    'HPEV',
    'HPM-MG',
    'HPP',
    'HRMS',
    'HRPG-PR',
    'HRS-ES',
    'HSA-GUARUJÁ',
    'HSD-MA',
    'HSI-SE',
    'HSJ-MS',
    'HSL-RP',
    'HSJ-PR',
    'HSJC-SC',
    'HSJC-SP',
    'HSL-SP',
    'HSM-DF',
    'HSP-SP',
    'HUBFS-HUJBB',
    'HUOL-RN',
    'HUSE',
    'HVC-SP',
    'HVL-SP',
    'IAMSPE',
    'ICRS',
    'IDOR',
    'IFF',
    'IHOA-GO',
    'INCA',
    'INTO',
    'IO-GO',
    'IPSEMG',
    'ISCMA-SP',
    'ISCMSC-SP',
    'MFC-UERJ',
    'MULTIVIX-ES',
    'PMC-PR',
    'PMF-SP',
    'PMFI-PR',
    'PMSO-SP',
    'POLICLIN-SP',
    'PSU-AL',
    'PSU-MG',
    'PSU-GO',
    'PUC-PR',
    'PUC-RS',
    'PUC-SP',
    'REVALIDA INEP',
    'REVALIDA UFMT',
    'RMEAP',
    'SCM-ARAÇATUBA',
    'SCM-BARRA MANSA',
    'SCMBH',
    'SCM-CG',
    'SCM-GO',
    'SCM-RP',
    'SCMC-MT',
    'SCML-SP',
    'SCMM-AL',
    'SCMMA-SP',
    'SCMPA-PA',
    'SCMRP',
    'SCMSJRP-SP',
    'SCMSP',
    'SCMV-SP',
    'SCO-SP',
    'SES-DF',
    'SES-GO',
    'SES-MA',
    'SES-PB',
    'SES-PE',
    'SES-RJ',
    'SES-SC',
    'SEMAD-RJ',
    'SESAU/FIOCRUZ - MS',
    'SGHC-MG',
    'SISE-SUS-TO',
    'SMS-CG',
    'SMS-FLORIANÓPOLIS',
    'SMS-JP',
    'SMS-LRV',
    'SMS-OLÍMPIA',
    'SMS-PIRACICABA',
    'SMS-PR',
    'SMS-SINOP',
    'SMS-SJP',
    'SMS-RJ',
    'SMS-SP',
    'SSP-PG',
    'SUPREMA-NG',
    'SURCE',
    'SUS-BA',
    'SUS-RR',
    'SUS-SP',
    'TEGO',
    'UCPEL-RS',
    'UDI-MA',
    'UEL-PR',
    'UEM',
    'UEPA-SANTARÉM',
    'UEPA',
    'UERJ',
    'UERN',
    'UESPI',
    'UEVA-GO',
    'UFAL',
    'UFCG-PB',
    'UFES',
    'UFF',
    'UFFS-RS',
    'UFGD-MS',
    'UFMA',
    'UFMS',
    'UFMT',
    'UFPB',
    'UFPI',
    'UFPR',
    'UFRJ',
    'UFRN EMCM',
    'UFRN',
    'UFS-SE',
    'UFSC',
    'UFSCAR-SP',
    'UFSM-RS',
    'UFT',
    'UFU-MG',
    'UNAERP-SP',
    'UNB',
    'UNCISAL',
    'UNESC-ES',
    'UNESP',
    'UNIATENAS',
    'UNICAMP',
    'UNICESUMAR-PR',
    'UNIFESP',
    'UNIMONTES-MG',
    'UNIOESTE-PR',
    'UNIRG-TO',
    'UNIRIO',
    'UNIRV-GO',
    'UNITAU-SP',
    'UNIUBE-MG',
    'USCS',
    'USP',
    'USP-RP',
    'USP-SP',
    'HSR-CUIABÁ',
    'HB-DF',
    'HSVP-BRASÍLIA',
    'SÃO CAMILO-SP',
    'SES-MT',
    'SCM-SP CABEÇA E PESCOÇO',
    'USP-SP CABEÇA E PESCOÇO',
    'SUS-SP CABEÇA E PESCOÇO',
    'SCM-RP CARP',
    'UNIFESP CABEÇA E PESCOÇO',
    // ADICOES PROJETO X 2022
    'UFCSPA',
    'GHC',
    'SCMM-AL',
    'CERMAN',
    'HAOC (HOSPITAL OSWALDO CRUZ)',
    'HPM-MG',
    'UFPA',
    'UNIMED-RJ',
    'UHG (UNITED HEALTH GROUP)',
    'HP-SP (HOSPITAL PAULISTA)',
    'UEVA (ANÁPOLIS)',

    // INSTITUICOES SEM PROVAS MAPEADAS DO PROJETO X 2022
    'EMCM',
    'INEP REVALIDA',
    'FUNDEP',
    'HSCMV',
    'FELUMA',
    'CSM',
    'HRD',
    'EMESCAM',
    'AFAMCI',
    'SEMAD',
    'HAC',
    'HMMG',
    'HSA',
    'COSC',
    'HOSG',
    'IBAP',
    'HT',
    'HNSG',
    'HELGJ',
    'HU-UFJF',
    'EBSERH',
    'HMSJC',
    'SCMSJC',
    'CEPOA',
    'ACM',
    'HMMG',
    'HEDA',
    'UNIFESO',
    'HRPP (UNOESTE)',
    'PUC-RS',
    'HMMD',
    'HUC',
    'UFMA',
    'HSD',
    'HJM',
    'HGC',
    'ISCL',
    'HFR',
    'ICEPI',
    'CHOV',
    'HUJBB',
    'AMS APUCARANA',
    'HEJSN',
    'HM',
    'HSRC',
    'SMS FLORIANÓPOLIS',
    'HRPP',
    'HMAR',
    'HEVV',
    'HECI',
    'IGESP',
    // eslint-disable-next-line quotes
    "D'OR",
    'MANDIC',
    'SCM CAMPINAS',
    'AC CAMARGO',
    'VITÓRIA APART HOSPITAL',
    'PUCCAMP',
    'PREVENT SÊNIOR',
    'HBP (BENEFICÊNCIA PORTUGUESA)',
    'HAOC',
    'PROVA NACIONAL DE ACESSO',
    'OUTRO',
  ]),
]
