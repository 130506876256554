import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

// Components
import Header from 'components/Header'
import SectionTitle from 'components/SectionTitle'

// Services
import api from 'services/api'

// Contexts
import { useAuth } from 'contexts/auth'

// Types
import { Monitor, Student } from './interfaces'

// Styles
import Layout from 'layouts/Logged'
import {
  Container,
  ChipsContainer,
  Chip,
  ChipText,
  ButtonContainer,
} from './styles'
import { DefaultButton } from 'components/Buttons/DefaultButton'

const Engagement: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const { user } = useAuth()
  const { replace } = useHistory()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [page, setPage] = useState(1)
  const [monitor, setMonitor] = useState<Monitor>()
  const [students, setStudents] = useState<Student[]>([])
  const [totalStudents, setTotalStudents] = useState<number>(0)

  /*
  |-----------------------------------------------------------------------------
  | Functions.
  |-----------------------------------------------------------------------------
  |
  |
  */

  const openStudentDetailPageInNewTab = useCallback((studentId: number) => {
    const { host, protocol } = window.location
    const studentDetailUrl = `${protocol}//${host}/users/${studentId}`

    const newWindow = window.open(
      studentDetailUrl,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
  }, [])

  const fetchMonitorStudents = useCallback(
    (page: number = 1) => {
      if (!user) return

      api
        .get<{ data: Student[]; meta: { total: number } }>(
          `/admin/users/monitors/${user.id}/students`,
          {
            params: { page },
          },
        )
        .then(({ data: { data, meta } }) => {
          if (!data.length) {
            Swal.fire({
              toast: true,
              title: 'Todos os alunos já foram exibidos.',
              position: 'bottom-right',
            })
            return
          }
          setTotalStudents(meta.total)
          setStudents(currentData => [...currentData, ...data])
        })
        .catch(err => {
          Swal.fire({
            title: 'Aviso',
            text: 'Não foi possível buscar os alunos nesse momento',
            icon: 'error',
            buttonsStyling: false,
          })
          console.trace('Error on fetching incidences', err)
        })
    },
    [user],
  )

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */

  useEffect(() => {
    fetchMonitorStudents(page)
  }, [fetchMonitorStudents, page])

  useEffect(() => {
    if (!user) return

    api
      .get(`/admin/users/${user.id}`)
      .then(({ data }) => {
        setMonitor(data)
      })
      .catch(err => {
        Swal.fire({
          title: 'Aviso',
          text: 'Não foi possível buscar o monitor nesse momento',
          icon: 'error',
          buttonsStyling: false,
        })
        console.trace('Error on fetching monitor', err)
      })
  }, [user])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */

  return (
    <Layout>
      <Container>
        <Header
          title="Alunos por Monitor"
          subTitle="Lista completa de alunos"
        />

        {/* <ButtonContainer>
          <Button title="voltar" onClick={() => replace('/monitors')} />
        </ButtonContainer> */}

        <SectionTitle>
          Alunos de {monitor?.name} (total de {students.length})
        </SectionTitle>
        <ChipsContainer>
          {students.map(student => (
            <Chip
              key={`student-key-${student.id}`}
              onClick={() => openStudentDetailPageInNewTab(student.id)}
            >
              <ChipText>{student.name}</ChipText>
            </Chip>
          ))}
        </ChipsContainer>

        {!students.length && (
          <>
            <SectionTitle>Alunos</SectionTitle>
            <p>Nenhum registro encontrado.</p>
          </>
        )}

        {totalStudents > students.length && (
          <ButtonContainer>
            <DefaultButton
              maxW="190px"
              w="full"
              label="Ver mais"
              onClick={() => setPage(current => 1 + current)}
            />
          </ButtonContainer>
        )}
      </Container>
    </Layout>
  )
}

export default Engagement
