import api from 'services/api'
import { useMutation } from '@tanstack/react-query'

interface PostEnableOnboardingProps {
  user_id: number
}

async function PostEnableOnboarding(params: PostEnableOnboardingProps) {
  await api.post(`/admin/users/${params.user_id}/enable_onboarding`)
}

export function usePostEnableOnboarding() {
  return useMutation(PostEnableOnboarding)
}
