import * as Yup from 'yup'
import { validateFileExtension, validateFileSize } from 'helpers/validateFile'

export const FILE_SIZE = 2 * 1000 * 1000 // 2MB
export const FILE_SIZE_BYTES = FILE_SIZE / 1000 / 1000 // 2MB
export const VALID_ANSWERS = ['a', 'b', 'c', 'd', 'e']
export const VALID_FILE_TYPES = ['image/jpeg', 'image/png']

export const schema = Yup.object().shape({
  statement: Yup.string().required('O enunciado é obrigatório.').max(5000),
  alternativeA: Yup.string()
    .required('O texto da alternativa é obrigatório.')
    .max(5000),
  alternativeB: Yup.string()
    .required('O texto da alternativa é obrigatório.')
    .max(5000),
  alternativeC: Yup.string()
    .required('O texto da alternativa é obrigatório.')
    .max(5000),
  alternativeD: Yup.string()
    .required('O texto da alternativa é obrigatório.')
    .max(5000),
  alternativeE: Yup.string().optional().nullable().max(5000),
  comment: Yup.string().optional().nullable().max(5000),
  essentialConcept: Yup.string().optional().nullable().max(5000),
  imageEssentialConcept: Yup.mixed()
    .test(
      'fileSize',
      `O arquivo deve ser menor que ${FILE_SIZE_BYTES} MB`,
      value => {
        if (!value) return true
        return validateFileSize(value, FILE_SIZE)
      },
    )
    .test(
      'fileSize',
      'Somente são permitidos arquivos com extensão .jpg ou .png',
      value => validateFileExtension(value, VALID_FILE_TYPES),
    ),
  imageHighlightFile: Yup.mixed()
    .test(
      'fileSize',
      `O arquivo deve ser menor que ${FILE_SIZE_BYTES} MB`,
      value => {
        if (!value) return true
        return validateFileSize(value, FILE_SIZE)
      },
    )
    .test(
      'fileSize',
      'Somente são permitidos arquivos com extensão .jpg ou .png',
      value => validateFileExtension(value, VALID_FILE_TYPES),
    ),
  commentImage: Yup.mixed()
    .test(
      'fileSize',
      `O arquivo deve ser menor que ${FILE_SIZE_BYTES} MB`,
      value => {
        if (!value) return true
        return validateFileSize(value, FILE_SIZE)
      },
    )
    .test(
      'fileSize',
      'Somente são permitidos arquivos com extensão .jpg ou .png',
      value => validateFileExtension(value, VALID_FILE_TYPES),
    )
    .optional(),
  correctAlternative: Yup.string()
    .required('A resposta correta é obrigatória')
    .oneOf(VALID_ANSWERS, 'Você deve escolher a resposta correta.'),
})
