import { Flex, Th, Thead, Text } from '@chakra-ui/react'
import React from 'react'

export const TrTableHead = () => {
  return (
    <Thead textAlign={'center'}>
      <Th
        w={'170px !important'}
        minW="170px !important"
        textTransform={'none'}
        p="16px 0px 16px 16px"
        border={'0px'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
      >
        <Flex w="100%" justifyContent={'flex-start'}>
          <Text>Monitor</Text>
        </Flex>
      </Th>

      <Th
        w="127px"
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex
          gap={'10px'}
          m="0 auto"
          w="125px !important"
          justifyContent={'center'}
          alignItems="center"
        >
          <Text>Trilha</Text>
        </Flex>
      </Th>
      <Th
        w="120px"
        textTransform={'none'}
        fontFamily="Mulish"
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex m="0 auto" w="125px !important" justifyContent={'center'}>
          <Text>Ano</Text>
        </Flex>
      </Th>
      <Th
        w="115px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex m="0 auto" w="125px !important" justifyContent={'center'}>
          Orientador
        </Flex>
      </Th>

      <Th
        w="115px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Num. alunos
        </Flex>
      </Th>

      <Th
        w="115px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Status
        </Flex>
      </Th>

      <Th
        w="90px"
        fontFamily="Mulish"
        textTransform={'none'}
        fontStyle="normal"
        fontWeight="600"
        fontSize="14px"
        lineHeight="18px"
        letterSpacing="0.02em"
        color="#FFFFFF"
        p="16px 0px 16px 0px"
        border={'0px'}
      >
        <Flex w="100%" justifyContent={'center'}>
          Ações
        </Flex>
      </Th>
    </Thead>
  )
}
