import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Chart from 'react-apexcharts'

import {
  Card,
  CardContent,
  CardTitle,
  Container,
  PageSubTitle,
  PageTitle,
  Paper,
  Row,
} from './styles'

import { PieChart } from './PieChart'
import { VerticalBarChart } from './VerticalBarChart'
import { HorizontalBarChart } from './HorizontalBarChart'

import api from 'services/api'

interface ReportData {
  period: string
  student: {
    name: string
    trialYear: number
    track: string
  }
  goal: {
    hoursOfStudy: string
    numberOfQuestions: number
    correctAnswersRate: number
  }
  monthData: {
    hoursOfStudyByField: {
      fieldName: string
      value: number
    }[]
    numberOfQuestionsByField: {
      fieldName: string
      value: number
    }[]
    correctAnswersRate: number
    averagePerceptionEffort: number
    hoursOfStudyByType: {
      theoretical: number
      practical: number
    }
    finishedRevisionsRate: number
  }
  goalAchievement: {
    general: number
    byCategory: {
      name: string
      value: number
    }[]
  }
  evolution: {
    hoursOfStudy: {
      data: number[]
      name: string
    }[]
    numberOfQuestions: {
      data: number[]
      name: string
    }[]
    correctAnswersRate: {
      data: number[]
      name: string
    }[]
  }
}

const StudentReport: React.FC = () => {
  /*
  |-----------------------------------------------------------------------------
  | Constants.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const location = useLocation()

  /*
  |-----------------------------------------------------------------------------
  | States.
  |-----------------------------------------------------------------------------
  |
  |
  */
  const [reportData, setReportData] = useState<ReportData>()

  /*
  |-----------------------------------------------------------------------------
  | Effects.
  |-----------------------------------------------------------------------------
  |
  |
  */
  useEffect(() => {
    const query = new URLSearchParams(location.search)

    api
      .get<ReportData>('/app/reports/fetch_data', {
        params: {
          id: query.get('userId'),
          name: query.get('userName'),
          trackGoalId: query.get('trackGoalId'),
        },
      })
      .then(({ data }) => {
        setReportData(data)
      })
      .catch(err => {
        console.trace(err)
      })
  }, [location.search])

  /*
  |-----------------------------------------------------------------------------
  | Renders.
  |-----------------------------------------------------------------------------
  |
  |
  */
  if (reportData === undefined) {
    return (
      <Container>
        <Paper>
          <PageTitle>Nenhum dado para exibir.</PageTitle>
        </Paper>
      </Container>
    )
  }

  return (
    <Container>
      <Paper>
        <PageTitle>Mentoria Residência &middot; Relatório Mensal</PageTitle>
        <PageSubTitle>{reportData.period}</PageSubTitle>
        <Row>
          <Card>
            <CardTitle>Dados do aluno</CardTitle>
            <CardContent>
              Nome:<span>{reportData.student.name}</span>
            </CardContent>
            <CardContent>
              Trilha:<span>{reportData.student.track}</span>
            </CardContent>
            <CardContent>
              Ano de Prova:<span>{reportData.student.trialYear}</span>
            </CardContent>
          </Card>
          <Card>
            <CardTitle>Metas do Mês</CardTitle>
            <CardContent>
              Quantidade de questões:
              <span>{reportData.goal.numberOfQuestions}</span>
            </CardContent>
            <CardContent>
              Percentual de acerto:
              <span>{reportData.goal.correctAnswersRate}%</span>
            </CardContent>
            <CardContent>
              Horas de estudo:<span>{reportData.goal.hoursOfStudy}</span>
            </CardContent>
          </Card>
        </Row>
        <Row>
          <Card>
            <CardTitle>Conclusão da Meta</CardTitle>
            <Row>
              <Chart
                options={{
                  title: {
                    text: '% da Meta Atingido',
                  },
                  labels: ['% Geral'],
                  colors: ['#ff6b00'],
                }}
                series={[reportData.goalAchievement.general]}
                type="radialBar"
                width="360"
              />
              <HorizontalBarChart
                title="% da Meta Atingido Por Categoria"
                width="360"
                height="280"
                series={[
                  {
                    name: 'atingimento-meta-por-categoria',
                    data: reportData.goalAchievement.byCategory.map(
                      a => a.value,
                    ),
                  },
                ]}
                categories={reportData.goalAchievement.byCategory.map(
                  a => a.name,
                )}
              />
            </Row>
          </Card>
        </Row>
        <Row>
          <Card>
            <CardTitle>Evolução Mês Anterior</CardTitle>
            <Row>
              <VerticalBarChart
                title="Horas de estudos"
                categories={['Horas']}
                height="310"
                width="240"
                series={reportData.evolution.hoursOfStudy}
              />
              <VerticalBarChart
                title="Quantidade de Questões"
                categories={['Quantidade de Questões']}
                height="310"
                width="240"
                series={reportData.evolution.numberOfQuestions}
              />
              <VerticalBarChart
                title="Percentual de Acerto"
                categories={['% acertos']}
                height="310"
                width="240"
                series={reportData.evolution.correctAnswersRate}
              />
            </Row>
          </Card>
        </Row>
      </Paper>
      <Paper>
        <PageTitle>Mentoria Residência &middot; Relatório Mensal</PageTitle>
        <PageSubTitle>{reportData.period}</PageSubTitle>
        <Row>
          <Card>
            <CardTitle>Resumo Mensal</CardTitle>
            <Row>
              <HorizontalBarChart
                title="Horas de Estudo"
                width="360"
                height="380"
                series={[
                  {
                    name: 'horas-estudo-por-área',
                    data: reportData.monthData.hoursOfStudyByField.map(
                      a => a.value,
                    ),
                  },
                ]}
                categories={reportData.monthData.hoursOfStudyByField.map(
                  a => a.fieldName,
                )}
              />
              <HorizontalBarChart
                title="Quantidade de Questões"
                width="360"
                height="380"
                series={[
                  {
                    name: 'questoes-por-área',
                    data: reportData.monthData.numberOfQuestionsByField.map(
                      a => a.value,
                    ),
                  },
                ]}
                categories={reportData.monthData.numberOfQuestionsByField.map(
                  a => a.fieldName,
                )}
              />
            </Row>
            <Row>
              <Chart
                options={{
                  title: {
                    text: '% de Acerto',
                  },
                  labels: ['% Acerto'],
                  colors: ['#ff6b00'],
                }}
                series={[reportData.monthData.correctAnswersRate]}
                type="radialBar"
                width="320"
              />
              <Chart
                options={{
                  title: {
                    text: 'Percepção de esforço média',
                  },
                  labels: ['Média'],
                  colors: ['#ff6b00'],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: '16px',
                          fontWeight: 400,
                          color: '#ff6b00',
                        },
                        value: {
                          fontSize: '16px',
                          fontWeight: 400,
                        },
                        total: {
                          show: true,
                          label: 'Média',
                          fontWeight: 400,
                          color: '#ff6b00',
                          formatter: function (w: any) {
                            return String(
                              Math.round((w.config.series[0] / 10) * 100) / 100,
                            )
                          },
                        },
                      },
                    },
                  },
                }}
                series={[reportData.monthData.averagePerceptionEffort]}
                type="radialBar"
                width="320"
              />
            </Row>
            <Row>
              <PieChart
                title="Teoria versus Prática"
                series={[
                  reportData.monthData.hoursOfStudyByType.theoretical,
                  reportData.monthData.hoursOfStudyByType.practical,
                ]}
                labels={['TEORIA', 'PRÁTICA']}
                legendPosition="bottom"
                width="320"
              />
              <Chart
                options={{
                  title: {
                    text: 'Percentual revisões concluídas',
                  },
                  labels: ['% Conclusão'],
                  colors: ['#ff6b00'],
                }}
                series={[
                  Math.round(reportData.monthData.finishedRevisionsRate),
                ]}
                type="radialBar"
                width="320"
              />
            </Row>
          </Card>
        </Row>
      </Paper>
    </Container>
  )
}

export default StudentReport
