import React from 'react'
import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  Divider,
  Heading,
  GridItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { IconType } from 'react-icons'

interface Stats {
  label: string
  value: string | number
  subValue?: string | number
}

interface ICardKpiProps {
  title: string
  href?: string
  stats: Stats[]
  icon?: IconType
}

export function CardKpi({ href, title, stats, icon: Icon }: ICardKpiProps) {
  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    lg: 'vertical',
  }) as 'horizontal' | 'vertical'

  return React.cloneElement(
    <GridItem />,
    {
      colSpan: { base: 1, lg: stats.length },
      ...(href && {
        to: href,
        as: Link,
        borderRadius: 'md',
        sx: {
          position: 'relative',
          display: 'inline-block',
          'background-color': '#9147ff',

          '&:before, &:after': {
            content: '""',
            display: 'block',
            'background-color': '#9147ff',
            width: '12px',
            height: '12px',
            position: 'absolute',
            transition: 'all .15s ease',
          },

          '&:before': {
            top: '3.85px',
            left: 0,
            'transform-origin': 'top left',
            transform: 'rotate(-45deg) scale(0)',
          },

          '&:after': {
            right: '3.5px',
            bottom: 0,
            'transform-origin': 'bottom right',
            transform: 'rotate(45deg) scale(0)',
          },

          '.kpi__card': {
            width: '100% !important',
            'z-index': 10,
            display: 'block',
            position: 'relative',
            transition: 'all .15s ease',
            transform: 'translate(0, 0)',
          },

          '&:hover .kpi__card': {
            transform: 'translate(6px, -6px)',
          },

          '&:hover:before': {
            transform: 'rotate(-45deg) scale(1)',
          },

          '&:hover:after': {
            transform: 'rotate(45deg) scale(1)',
          },
        },
      }),
    },
    <Box
      py="4"
      px="4"
      w="full"
      bg="#2a2a2d"
      boxShadow="md"
      borderRadius="md"
      className="kpi__card"
    >
      <Flex justifyContent="space-between" top="2" right="2">
        <HStack mb="4">
          {Icon && <Icon size="20" />}

          <Heading fontSize="xl" ml="4">
            {title}
          </Heading>
        </HStack>

        {href && <FiExternalLink />}
      </Flex>

      <Stack
        spacing={{ lg: '4' }}
        alignItems="center"
        justifyContent="space-evenly"
        direction={{ base: 'column', lg: 'row' }}
        divider={
          <Divider
            borderColor="whiteAlpha.400"
            h={{ lg: '36' }}
            orientation={dividerOrientation}
          />
        }
      >
        {stats.map(stat => (
          <Flex
            key={stat.label}
            py="4"
            px="3"
            w="44"
            h="40"
            textAlign="center"
            flexDirection="column"
          >
            <Text mb={{ base: '2', lg: '4' }} fontSize="4xl" fontWeight="bold">
              {stat.value}
            </Text>

            <Text>{stat.label}</Text>

            {stat.subValue && (
              <Box
                mt="2"
                px="2"
                py="1.5"
                mx="auto"
                w="fit-content"
                bg="brand.500"
                borderRadius="md"
              >
                <Text fontSize="sm">{stat.subValue}</Text>
              </Box>
            )}
          </Flex>
        ))}
      </Stack>
    </Box>,
  )
}
