import React, { useMemo, useRef } from 'react'
import { Flex } from '@chakra-ui/react'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import {
  OptionTypeBase,
  OptionsType,
  Props as ReactSelectProps,
} from 'react-select'
import { transparentize } from 'polished'

type Sizes = 'lg' | 'md' | 'sm'

export type FieldSelectProps = {
  labelText?: string
  selectName?: string
  error?: string
  size?: Sizes
  loadOptions: (
    inputValue: string,
    callback: (options: OptionsType<OptionTypeBase>) => void,
  ) => void | Promise<any>
} & AsyncProps<OptionTypeBase> &
  ReactSelectProps<OptionTypeBase>

const FieldAsyncSelectComponent: React.ForwardRefRenderFunction<
  any,
  FieldSelectProps
> = ({ size = 'md', ...props }, ref) => {
  const { error, labelText, selectName, loadOptions, ...rest } = props

  const MenuList = (props: any) => {
    const menuListRef = useRef<HTMLDivElement>(null)

    return (
      <Flex
        backgroundColor={'#302F37'}
        borderRadius={'0px 0px 8px 8px'}
        flexDir={'column'}
        w="full"
        paddingInlineEnd={'8px'}
      >
        <Flex
          flexDir={'column'}
          sx={{
            maxHeight: '264px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
              boxShadow: 'inset 0 0 5px #60606c',
              borderRadius: '17px',
              height: '8px',
              borderLeft: '2px solid transparent',
              borderRight: '2px solid transparent',
              marginTop: '10px',
              marginBottom: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#7D46B1',
              borderRadius: '17px',
              height: '8px',
            },
            '&::-webkit-scrollbar-corner': {
              background: 'rgba(0,0,0,0)',
            },
          }}
        >
          <div ref={menuListRef}>{props.children}</div>
        </Flex>
      </Flex>
    )
  }

  const sizesBySize = useMemo((): Record<Sizes, any> => {
    return {
      sm: {
        height: props.isMulti ? 'auto' : '32px',
      },
      md: {
        height: props.isMulti ? 'auto' : '42px',
      },
      lg: {
        height: props.isMulti ? 'auto' : '48px',
      },
    }
  }, [props.isMulti])

  return (
    <AsyncSelect
      components={{ MenuList }}
      captureMenuScroll={false}
      id={props.name}
      ref={ref}
      styles={{
        valueContainer: (base, props) => ({
          ...base,
          paddingTop: props.isMulti ? '7px' : '8px',
          paddingBottom: props.isMulti ? '7px' : '8px',
          ...sizesBySize[size],
        }),
        container: base => ({
          ...base,
          width: '100%',
          ...sizesBySize[size],
          borderRadius: '8px',
        }),
        control: base => ({
          ...base,
          width: '100%',
          fontFamily: 'Mulish',
          display: 'flex',
          height: '100%',
          backgroundColor: '#393940',
          color: transparentize(0.1, '#fff'),
          borderRadius: '8px',
          outline: 0,
          border: 0,
          paddingLeft: 8,
          paddingRight: 8,
          '&:hover': {
            borderColor: 'transparent',
          },
          boxShadow: 'none',
        }),
        input: base => ({
          ...base,
          color: '#FFF',
        }),
        indicatorSeparator: base => ({
          ...base,
          backgroundColor: 'transparent',
        }),
        dropdownIndicator: base => ({
          ...base,
          color: '#7D46B1',
          '&:hover': {
            color: '#7D46B1',
          },
          display: props.hideIcon ? 'none' : 'block',
        }),
        placeholder: base => ({
          ...base,
          fontFamily: 'Mulish',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '18px',
          color: '#BDBDC7 !important',
        }),
        menuList: base => ({
          ...base,
          fontFamily: 'Mulish',
          backgroundColor: 'transparent',
          zIndex: 2000,
          borderRadius: '0px 0px 16px 16px',
          paddingTop: 0,
          paddingBottom: 0,
        }),
        menu: base => ({
          ...base,
          fontFamily: 'Mulish',
          borderRadius: '0px 0px 16px 16px',
          color: '#7D46B1',
          backgroundColor: '#302F37',
          zIndex: 3000,
          marginTop: -1,
          height: '100%',
          maxHeight: 264,
        }),
        singleValue: base => ({
          ...base,
          color: transparentize(0.1, '#fff'),
          fontFamily: 'Mulish',
          fontSize: '14px',
        }),
        option: (base, { isSelected }) => ({
          ...base,
          background: '#302F37',
          color: isSelected ? '#FFF' : '#BDBDC7',
          '&:hover': {
            background: '#2A2A30',
            borderLeft: '2px solid #7D46B1',
          },
        }),
        multiValue: base => ({
          ...base,
          backgroundColor: '#302F37',
        }),
        multiValueLabel: (base, props) => ({
          ...base,
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '15px',
          color: '#FFFFFF',
        }),
        multiValueRemove: (base, props) => ({
          ...base,
          backgroundColor: '#302F37',
          '&:hover': {
            backgroundColor: '#222127',
          },
          color: '#7D46B1',
        }),
      }}
      cacheOptions
      loadOptions={loadOptions}
      {...rest}
    />
  )
}

export const FieldAsyncSelect = React.forwardRef(FieldAsyncSelectComponent)
