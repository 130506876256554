import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

type Question = {
  id: number
  statement: string
  alternative_a: string
  alternative_b: string
  alternative_c: string
  alternative_d: string
  alternative_e?: string
  correct_alternative: string
  comment: string
  mock_exam_id: number
  comment_image: string
  image_highlight: string
  created_at: string
  updated_at: string
  image_essential_concept: string
}

export type GetExamById = {
  id: number
  title: string
  institution: string
  published: boolean
  created_at: string
  updated_at: string
  questions: Question[]
}

type Params = {
  id: string | null
}

export async function getExamsById(params: Params): Promise<GetExamById> {
  const { data } = await api.get<GetExamById>(`/admin/mock-exams/${params.id}`)
  return data
}

export function useGetExamsById(params: Params) {
  return useQuery(
    ['get-simulation-by-id', params],
    () => getExamsById(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}
