import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'App'
import api from 'services/api'

interface Params {
  monitorId: number
  monitorStatus: 'OPEN' | 'CLOSED'
}

export async function updateStatusMonitor(params: Params) {
  await api.patch(`/admin/users/${params.monitorId}/monitors`, {
    monitorStatus: params.monitorStatus,
  })
}

export function useUpdateStatusMonitor() {
  return useMutation(updateStatusMonitor, {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchMonitorList'])
    },
  })
}
