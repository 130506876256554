import React, { ChangeEvent, useCallback, useEffect } from 'react'
import Layout from 'layouts/ChakraLogged'

import Header from 'components/Header'
import { GetInTouch } from 'components/GetInTouch'

import {
  Box,
  Flex,
  HStack,
  Input,
  Select,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import api from 'services/api'
import { format } from 'date-fns'
import { debounce } from 'lodash'
import ListPagination from 'components/ListPagination'
import { MetaUnformatted, Pagination, Query } from 'types/response'
import { ListMonitorResponse } from 'types/Monitors'

interface Students {
  id: number
  name: string
  email: string
  contacted: boolean
  lastLoginAt: string
  mobilePhone: string | null
}

export const UsersWithoutAccess: React.FC = () => {
  const [monitors, setMonitors] = React.useState<ListMonitorResponse[]>([])
  const [isFetchingMonitors, setIsFetchingMonitors] = React.useState(false)

  const [isLoading, setIsLoading] = React.useState(false)
  const [students, setStudents] = React.useState<Students[]>([])
  const [query, setQuery] = React.useState<Query>({
    limit: 10,
    order: 'desc',
    orderByColumn: 'lastLoginAt',
  } as Query)
  const [pagination, setPagination] = React.useState<Pagination>(
    {} as Pagination,
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (value.length === 0) {
        setQuery(oldState => ({ ...oldState, filter: undefined }))
      }

      if (value.length < 3) return

      setQuery({
        filter: value,
      })
    }, 500),
    [],
  )

  const handleSort = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target

    switch (value) {
      case 'desc':
        setQuery(oldState => ({ ...oldState, order: 'desc' }))
        break

      case 'asc':
        setQuery(oldState => ({ ...oldState, order: 'asc' }))
        break

      default:
        break
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingMonitors(true)
        const response = await api.get<ListMonitorResponse[]>(
          '/admin/users/monitors/all',
        )

        if (!response) return

        setMonitors(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetchingMonitors(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const { data: response } = await api.get<{
          meta: MetaUnformatted
          data: Students[]
        }>('/monitor/students', {
          params: { ...query, daysWithoutAccess: 14 },
        })

        if (!response) return

        const { meta } = response
        setPagination({
          firstPage: 1,
          total: meta.total,
          perPage: meta.per_page,
          lastPage: meta.last_page,
          currentPage: meta.current_page,
          currentPageCount: response.data.length,
        })

        setStudents(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [query])

  return (
    <Layout>
      <Header
        title="Usuários sem acesso"
        subTitle="Lista completa de usuários sem acesso ao sistema"
      />

      <Box mt="16">
        <HStack>
          <Input
            size="sm"
            onChange={handleSearch}
            placeholder="Buscar pelo nome (mínimo 3 caracteres)"
          />

          <Select
            size="sm"
            colorScheme="blackAlpha"
            onChange={handleSort}
            sx={{
              option: {
                color: 'blackAlpha.700',
              },
            }}
          >
            <option value="desc">Mais recente</option>
            <option value="asc">Mais antigo</option>
          </Select>

          <Select
            size="sm"
            colorScheme="blackAlpha"
            // onChange={handleSort}
            sx={{
              option: {
                color: 'blackAlpha.700',
              },
            }}
          >
            {isFetchingMonitors ? (
              <option disabled>Carregando...</option>
            ) : (
              monitors.map(monitor => (
                <option key={monitor.id} value={monitor.id}>
                  {monitor.name}
                </option>
              ))
            )}
          </Select>
        </HStack>

        <Table mt="6">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Último acesso</Th>
              <Th>Contato via whatsapp</Th>
            </Tr>
          </Thead>

          <Tbody>
            {isLoading && (
              <Tr>
                <Td>
                  <Skeleton height="4" />
                </Td>
                <Td>
                  <Skeleton height="4" />
                </Td>
                <Td>
                  <Skeleton height="4" />
                </Td>
              </Tr>
            )}

            {!isLoading &&
              students.length > 0 &&
              students.map(student => (
                <Tr key={student.id}>
                  <Td>{student.name}</Td>
                  <Td>{format(new Date(student.lastLoginAt), 'dd/MM/yyyy')}</Td>
                  <Td>
                    <GetInTouch
                      studentId={student.id}
                      contacted={student.contacted}
                      phoneNumber={student.mobilePhone}
                    />
                  </Td>
                </Tr>
              ))}

            {!isLoading && !students.length && (
              <Tr>
                <Td colSpan={3} mx="auto">
                  <Flex justifyContent="center">
                    Nenhum usuário encontrado.
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>

        <ListPagination
          query={{ set: setQuery, current: query }}
          pagination={{
            set: setPagination,
            current: pagination,
          }}
        />
      </Box>
    </Layout>
  )
}
